import React, {Fragment, useEffect, useRef, useState} from 'react'
import { userStore } from '../../../store/userStore'
import { Link } from "react-router-dom";
import authQueries from '../../../queries/authQueries'
import ApplicantDetails from './ApplicantDetails'
import DocumentDetails from './DocumentDetails'
import HOT from '../../../assets/images/hot-icon-2.png'
import WARM from '../../../assets/images/warm-icon-2.png'
import COLD from '../../../assets/images/cold-icon-2.png'
import LONG from '../../../assets/images/longterm-icon-3.png'
import HOLD from '../../../assets/images/on-hold-icon.png'
import LoaderCommon from '../../Common/LoaderCommon';

const ClientLayout =() => {

	const focusLeadStatus = useRef(null)

	const [proStatus, setProStatus] = useState('HOT');
	const [loading, setLoading] = useState(true)
	const user = userStore();
	const appStore = userStore();
	
	const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
				setProStatus(response?.data?.applicant?.lead_status)
				setLoading(false)
            }
        }
    )

	useEffect(()=> {
		if(appStore.appid){
			viewAllApplicationEditData.mutateAsync(appStore.appid)
		}else{
			setLoading(false)
		}
	}, [])

	const activeStyle = {
		display:'flex', 
		alignItems:'center', 
		justifyContent:'center',
		width: '80px',
		gap:4,
		borderRadius:'5px', 
		background:'#EF0',
		height: 35,
		textAlign: 'center'
	}

	const normalStyle = {
		display:'flex', 
		alignItems:'center',
		justifyContent:'center',
		width: '80px',
		gap:4,
		cursor:'pointer',
		height: 35,
	}

  return (

    <div>

		<div className="breadcrumb-and-otherbuttons">
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
				<li className="breadcrumb-item active" aria-current="page">Add Clients</li>
				</ol>
			</nav>
		</div>
				
		<div className="add_clients">	
			<div className="grid grid-cols-12" style={{alignItems:'center'}}>

				<div className="col-span-3">
					<div className="flex items-center gap-[4px]">
						<i className="followup-icon">
							<img src={require('../../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
						</i>
						<div style={{display:'flex', flexDirection:'column'}}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
								<span className="text-[16px] font-medium">Add Clients</span>	
							</div>
						</div>
                	</div>
				</div>

				{(user.user.role !== "DOCUMENTSTAFF" && user.user.role !== "SOPSTAFF") &&
					<div className="col-span-9 hotwarmcold-wrpr justify-flex-end outline-none" style={{display:'flex',justifyContent:'flex-end'}} ref={focusLeadStatus} tabIndex={0}>
						<div style={{display:'flex', background:'#fff', alignItems:'center', gap:20, borderRadius:'10px', padding:"8px"}}>
							<div className='pl-3' style={{fontSize:'12px', fontWeight: 600}}>Profile Status</div>
							<div className="profile-status-btn-wrpr gap-3 pr-2">
								<div style={proStatus === "HOT" ? activeStyle : normalStyle} onClick={()=>{setProStatus("HOT")}} >
									<img src={HOT} width="12px" />
									<div style={{fontSize:'12px', fontWeight: 600}}>Hot</div>
								</div>
								<div style={proStatus === "WARM" ? activeStyle : normalStyle} onClick={()=>{setProStatus("WARM")}}>
									<img src={WARM} width="15px" />
									<div style={{fontSize:'12px', fontWeight: 600}}>Warm</div>
								</div>
								<div style={proStatus === "COLD" ? activeStyle : normalStyle} onClick={()=>{setProStatus("COLD")}}>
									<img src={COLD} width="12px" />
									<div style={{fontSize:'12px', fontWeight: 600, textAlign: 'center'}}>Cold</div>
								</div>
							</div>		
						</div>
					</div>	
				}

			</div>

			<div id="accordion" className="add_clients_accordion_form">
			
				{
					(user.user.role !== "DOCUMENTSTAFF" && user.user.role !== "SOPSTAFF") &&
					<Fragment>
						{
							loading?
							<LoaderCommon />:
							<ApplicantDetails lead_status={proStatus} focusLeadStatus={focusLeadStatus} />

						}
					</Fragment>
				}
					
				{
					(user.user.role === "DOCUMENTSTAFF" || user.user.role === "SOPSTAFF") &&
						<Fragment>
							{
								viewAllApplicationEditData.isLoading ?
								<LoaderCommon />:
								<DocumentDetails />
							}
						</Fragment>
				} 
	

			</div>


		</div>
	</div>
    
  )
}

export default ClientLayout