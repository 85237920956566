import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import {  message, message as messages, Popconfirm } from 'antd';
import PaginationComponent from "../../Common/PaginationComponent";
import MasterModal from "../../Common/MasterModal";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MasterHeader from "../../Common/MasterHeader";
import LoaderCommon from "../../Common/LoaderCommon";
import InputUseForm from "../../Common/InputUseForm";
import { generateValidationSchema } from "../../Common/validationSchema";
import InputSelectForm from "../../Common/InputSelectForm";


const UsaProgramLevel =()=>{

    const [result, setResult] = useState([]);
    const [name, setName] = useState('')
    const [loader, setLoader] = useState(true)
    const master = userStore();

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
        
    useEffect(()=>{
        listingLevel.mutateAsync({search: name, page:currentPage, size:25});
    },[name, currentPage])
   
    const listingLevel =authQueries.useUsaProgramLevelListing(
        async (response) => {
            setResult(response);
            setLoader(false);
        }
    );
   
    // useUsaProgramLevelListing,
    // useUsaProgramLevelreate,
    // useUsaLevelEdit,
    // useUsaLevelDelete,
    const inputFields = [
        {
            name: "level",
            label: "Level",
            placeholder:"Level",
            size: "6",
            type: InputUseForm,
            required: true,
            min: 2,
            max: 250
        },
       
    ]
    
    const { register ,reset, handleSubmit, formState:{ errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })
    
    const deleteProgram = authQueries.useUsaLevelDelete(
        (response) => {
            listingLevel.mutateAsync({search: name, page:currentPage, size:25});
            messages.success("Successfully deleted")
        }
    );

    const deleteProgramData = (event, id) => {
        event.preventDefault();
        deleteProgram.mutateAsync(id)
    }

    const [visible, setVisible] = useState(false)

    const handleOpen =async(item)=>{
        if(!item){
            await master.setMaster(null)
            setVisible(true)
            return 
        }else{
            reset(item)
            await master.setMaster(item.id)
            setVisible(true)
        }
    }

    const handleClose =async()=>{
        const getvalue = getValues()
        for(const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
              setValue(key, "")
            }
        }
        clearErrors()
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null)
    }

    const createMaster = authQueries.useUsaProgramLevelcreate(
        (response) => {
            
            if (response?.message === "Error") {
                response.error.map((error) =>
                    Object.values(error).map((errorMessage) =>
                        message.error(errorMessage)
                    )
                );
                return;
            }
            if (response?.message === "Location already exists") {
                message.error("Location already exists");
                return;
            }
            handleClose()
            if(response?.message=== "Success"){
                message.success("Successfully updated")
                listingLevel.mutateAsync({search: name, page:currentPage, size:25});
            }
        }   
    );
        
    const updateMaster = authQueries.useUsaLevelEdit(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                message.success("Successfully updated Location")
                listingLevel.mutateAsync({search: name, page:currentPage, size:25});
            }
        }   
    );


    return(
        <div>
            <div className="">	
                <MasterHeader handleOpen={handleOpen} header="Level" breadcrumb="Level" />			
				<div className="add_clients">
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Program Level</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
                            <div className="card-header text-[14px]">
                                
                            </div>
		    				<div id="appointment_any" className="collapse show" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-md-12">
                                        
                                            <div className="table-list-search-wrpr">
                                                <div className="row">
                                                    <div className="col-md-6" />
                                                    <div className="col-md-6 text-right">
                                                        <div className="table-search-filter-wrpr">
                                                            {/* <div className="table-search-wrpr">
                                                                <input style={{minWidth: '300px'}} onChange={(e)=>{setName(e.target.value)}} className="form-control form-control-sm" type="text" placeholder="Search Country" />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                          
                                            {
                                                loader ?
                                                <LoaderCommon />:
                                                <div className="table-wrpr">
                                                    <div className="table-section">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="table prospect-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="5%">#</th>
                                                                            <th>Level</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="ui-sortable">
                                                                    {
                                                                            result?.data?.items?.map((item, index) =>(
                                                                            <tr className="ui-sortable-handle" key={index} >
                                                                                <th className="">{(index + 1) + result?.data?.currentPage * 25}</th>
                                                                                <td>{item?.level}</td>
                                                                                <td className="action-icons flex gap-2 justify-end">                                                     
                                                                                    <button onClick={()=>{handleOpen(item)}} className="btn-nextrow mb-0">Update</button>
                                                                                    <Popconfirm
                                                                                        placement="left"
                                                                                        title="Are you sure to delete this Level ?"
                                                                                        onConfirm={(event) => {deleteProgramData(event, item.id)}}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button className="btn-removerow mb-0">Delete</button>
                                                                                    </Popconfirm>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <MasterModal
                                            width="50%"
                                            header="Level"
                                            visible={visible} 
                                            handleClose={handleClose} 
                                            inputFields={inputFields} 
                                            reset={reset} 
                                            control={control}
                                            handleSubmit={handleSubmit} 
                                            register={register} 
                                            errors={errors} 
                                            getValues={master?.master}
                                            defaultValues={getValues()}
                                            create={createMaster}
                                            update={updateMaster}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
				</div>
			</div>
        </div>
    )
}

export default UsaProgramLevel