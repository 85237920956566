import React, { useState, useEffect, Fragment } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import authQueries from '../../../queries/authQueries'
import * as yup from 'yup'
import { message as messages, Popconfirm } from 'antd'
import { userStore } from '../../../store/userStore'
import moment from 'moment'
import { AutoCompleteAntd, AutoCompleteAntdInteded } from './AutoComplete';
import AutoCompleteInput from '../../Common/AutoCompleteInput'
import InputUseForm from '../../Common/InputUseForm'
import InputSelectForm from '../../Common/InputSelectForm'
import { generateValidationSchema } from '../../Common/validationSchema'
import toast from 'react-hot-toast'
import EditOverseasEducationModal from './EditOverseasEducationModal'

const OverseasEducation = () => {

    const appStore = userStore();

    const [result, setResult] = useState(appStore?.appAllData?.overseas_education_historys)
    const [countryResult, setCountryResult] = useState([])

    useEffect(() => {
        listCountry.mutateAsync({ search: '', status: '', page: 0, size: 500 })
    }, [])

    const inputFields = [
        {
            label: "Country",
            size: 3,
            iptype: "select",
            required: true,
            options: countryResult?.data?.items,
            nameShow: true,
            name: 'country_id',
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            label: "College/University",
            size: 3,
            name: 'college',
            required: true,
            iptype: "text"
        },
        {
            label: "Program Name",
            size: 3,
            name: 'program_name',
            required: true,
            iptype: "text"
        },
        {
            label: "Visa Term",
            size: 3,
            name: 'visa_term',
            required: true,
            iptype: "text"
        },
        {
            label: "Existing/Closed",
            size: 3,
            iptype: "select",
            required: true,
            options: [{name:"Existing", id: "existing"}, {name: "Closed",id:"closed"}],
            name: 'existing_or_closed',
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            label: "Return Date",
            size: 3,
            name: 'return_date',
            iptype: "date"
        }
    ]

    const listCountry = authQueries.useCountryMutationList(
        (response) => {
            setCountryResult(response)
        }
    );

    const { register, reset, control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const addOverseas = authQueries.useOverseasAdd(
        (response) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
            inputFields.forEach((item) => {
                setValue(item.name, "")
            })
        }
    );

    const onSubmit = (data) => {
        const datavalues = {
            applicant_id: appStore.appid,
            ...data
        }
        addOverseas.mutateAsync(datavalues);
    }

    const deleteOverseas = authQueries.useOverseasDelete(
        (response) => {
            toast.success("Successfully deleted")
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const deleteOverseasData = async(event, id) => {
        event.preventDefault();
        deleteOverseas.mutateAsync(id)
    }

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
                setResult(response?.data?.overseas_education_historys)
            }
        }
    )

    return (

        <div>

            <div className="card">
                <div className="card-header">
                    <a className="collapsed card-link" data-toggle="collapse" href="#overseas_education">
                        Overseas Education History, If any
                    </a>
                    <div className={result?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                        <span className={result?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
                    </div>
                </div>
                <div id="overseas_education" className="collapse" data-parent="#accordion">
                    <div className="card-body">

                        <form onSubmit={handleSubmit(onSubmit)}>   
                            <div className='grid grid-cols-12 max-[1347px]:grid-cols-10 max-[1053px]:grid-cols-8 max-[910px]:grid-cols-6 max-[661px]:grid-cols-4 max-[481px]:grid-cols-2 gap-2 mb-3'>
                                {
                                    inputFields?.map((item, index)=>(
                                        <div className={`col-span-${item?.size} flex flex-col gap-2`} key={index}>
                                            {
                                                item?.iptype === "select" ?
                                                <InputSelectForm
                                                    item={item}
                                                    register={register}
                                                    errors={errors}
                                                    options={item?.options}
                                                    optionProps={item?.optionProps}
                                                />:
                                                (item?.iptype === "text" || item?.iptype === "number" || item?.iptype === "date" ) &&
                                                <InputUseForm
                                                    item={item}
                                                    control={control}
                                                    register={register}
                                                    errors={errors}
                                                />
                                            }
                                        </div>
                                    ))
                                }
                                <div className="col-span-1">
                                    <div className='flex flex-col gap-2'>
                                        <div className="text-[12px] h-[18px] font-[500]" />
                                        <button 
                                            type='submit' 
                                            className="btn-customized-large"
                                        >Create</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-2">
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table prospect-table">
                                                    <thead>
                                                        <tr>
                                                            <th width="40">#</th>
                                                            <th width="200">Country</th>
                                                            <th width="250">College/University</th>
                                                            <th width="300">Program Name</th>
                                                            <th width="250">Visa Term</th>
                                                            <th width="200">Existing/Closed</th>
                                                            <th width="120">Return Date</th>
                                                            <th width="100">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="sortable6" className="ui-sortable">
                                                        {result?.map((item, index) => (
                                                            <Fragment key={index}>
                                                                <tr className="ui-sortable-handle">
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.country?.name}</td>
                                                                    <td>{item.college}</td>
                                                                    <td>{item.program_name}</td>
                                                                    <td>{item.visa_term}</td>
                                                                    <td>{item.existing_or_closed}</td>
                                                                    <td>{item.return_date && moment(item.return_date).format('DD-MM-YYYY')}</td>
                                                                    <td className="flex gap-1 justify-end">
                                                                        <EditOverseasEducationModal item={item} viewAllApplicationData={viewAllApplicationData} countryResult={countryResult} />
                                                                        <Popconfirm
                                                                            placement="left"
                                                                            title="Are you sure to delete this details?"
                                                                            onConfirm={(event) => {deleteOverseasData(event, item.id)}}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <button type='button' className="bg-[#dc3545] h-[30px] w-[60px] text-white rounded-[6px] text-[12px]">Delete</button>
                                                                        </Popconfirm>
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default OverseasEducation