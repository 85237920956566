import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { userStore } from '../../../store/userStore';
import { message as messages, Popconfirm } from 'antd';

const WorkExpForm = ({item}) => {

    useEffect(()=> {
        reset(item)
    }, [])

    const validationSchema = yup.object({
        employers_name: yup
          .string('⚠ Enter Employer Name')
          .required('⚠ Name is required'),
        position_held: yup
          .string('⚠ Enter your Position')
          .required('⚠ Position is required'),
        total_years_and_months: yup
          .string('⚠ Enter your Year and Month')
          .required('⚠ Year and Month is required'),
        exp_duration: yup
          .string('⚠ Enter your duration')
          .required('⚠ Duration is required')
    });

    const appStore = userStore();
    const [edit, setEdit] = useState(0)

    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const deleteWorkExp = authQueries.useWorkMutationDelete(
        (response) => {
            viewAllApplicationEditData.mutateAsync(appStore?.appid)
        }   
    );

    const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )    

    const editWorkExp = authQueries.useWorkMutationEdit(
        (response) => {
            setEdit(0)
            viewAllApplicationEditData.mutateAsync(appStore?.appid)
        }   
    );

    const onDelete =()=> {
        deleteWorkExp.mutateAsync(item?.id); 
        messages.success("successfully deleted")
    }

    const onSubmit = (data) => {
        const datavalues = {
            id: item?.id,
            applicant_id : appStore.appid,
            employers_name: data.employers_name,
            position_held: data.position_held,
            total_years_and_months: data.total_years_and_months,
            exp_duration: data.exp_duration,
            status:"ACTIVE"
        };
        
        editWorkExp.mutateAsync(datavalues);
    }
    

    const editButton =(e)=>{
        e.preventDefault();
        setEdit(item.id)
    }

    const deleteButton =(e)=>{
        e.preventDefault();
        onDelete();
    }


  return (

                            
    <form>
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Employer’s Name:</label>
                            <input disabled={parseInt(edit) !==item?.id ? true: false} id=""  name="employers_name" {...register("employers_name")} placeholder="" type="text" className="form-control form-control-sm !w-full" />
                            <div className="error-msg">{errors.employers_name?.message}</div>
                        </div>	
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Position held:</label>
                            <input disabled={parseInt(edit) !==item?.id ? true: false} id="" name="position_held" {...register("position_held")}  placeholder="" type="text" className="form-control form-control-sm !w-full" />
                            <div className="error-msg">{errors.position_held?.message}</div>
                        </div>	
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Experience Duration:</label>
                            <input disabled={parseInt(edit) !==item?.id ? true: false} name="exp_duration" {...register("exp_duration")}  className="form-control form-control-sm !w-full" placeholder="" />
                            <div className="error-msg">{errors.exp_duration?.message}</div>
                        </div>	
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label htmlFor="">Total years and Months:</label>
                            <input disabled={parseInt(edit) !==item?.id ? true: false} name="total_years_and_months" {...register("total_years_and_months")}  className="form-control form-control-sm !w-full"  placeholder="Ex:3 year and 2 months)" />
                            <div className="error-msg">{errors.total_years_and_months?.message}</div>
                        </div>	
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="spouse_name">&nbsp;</label><br/>
                            <div style={{display:'flex', gap:10}}>
                                <Popconfirm
                                    placement="left"
                                    title="Are you sure to delete this Details ?"
                                    onConfirm={(e) => {deleteButton(e)}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <button type='button' className="btn btn-default btn-removerow text-white">Remove</button>
                                </Popconfirm>
                                {parseInt(edit) !== item?.id ?
                                    <button type='button' onClick={(e)=>editButton(e)} className="btn btn-default btn-nextrow text-white">
                                        Edit
                                    </button>:
                                    <button type='submit' onClick={handleSubmit(onSubmit)} className="btn btn-default btn-nextrow text-white">
                                        Update
                                    </button>
                                }
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
        </div>
    </form>

  )
}

const ExpForm = ({spouseEnabled}) => {

    const validationSchema = yup.object({
        name: yup
          .string('⚠ Enter Employer Name')
          .required('⚠ Name is required'),
        position: yup
          .string('⚠ Enter your Position')
          .required('⚠ Position is required'),
        experience: yup
          .string('⚠ Enter your Year and Month')
          .required('⚠ Year and Month is required'),
        duration: yup
          .string('⚠ Enter your duration')
          .required('⚠ Duration is required')
    });

    const appStore = userStore();

    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )  

    const addWorkExp = authQueries.useWorkMutationAdd(
        (response) => {
            const data = {
                name:"",
                position:"",
                experience:"",
                duration:""
            }
            reset({...data})
            viewAllApplicationEditData.mutateAsync(appStore.appid)
        }   
    );


    const onSubmit = (data) => {
        const datavalues = {
            applicant_id : appStore.appid,
            spouse_enabled : spouseEnabled,
            employers_name: data.name,
            position_held: data.position,
            total_years_and_months: data.experience,
            exp_duration: data.duration,
            status:"ACTIVE"
        };
        
        addWorkExp.mutateAsync(datavalues);
    }



  return (

    <div>
          
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="">Employer’s Name:</label>
                                <input id=""  {...register("name")} placeholder="" type="text" className="form-control form-control-sm !w-full" />
                                <div className="error-msg">{errors.name?.message}</div>
                            </div>	
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="">Position held:</label>
                                <input id="" {...register("position")}  placeholder="" type="text" className="form-control form-control-sm !w-full" />
                                <div className="error-msg">{errors.position?.message}</div>
                            </div>	
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="">Experience Duration:</label>
                                <input {...register("duration")}  className="form-control form-control-sm !w-full" placeholder="" />
                                <div className="error-msg">{errors.duration?.message}</div>
                            </div>	
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="">Total years and Months:</label>
                                <input {...register("experience")}  className="form-control form-control-sm !w-full"  placeholder="Ex:3 year and 2 months)" />
                                <div className="error-msg">{errors.experience?.message}</div>
                            </div>	
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="spouse_name">&nbsp;</label><br/>
                                <button 
                                type='submit'
                                className="btn btn-default btn-nextrow text-white"
                                >Create</button>
                            </div>	
                        </div>
                    </div>
                </div>

                
            </div>
        </form>
        
    </div>

  )
}

const WorkExp = ({spouseData}) => {

    const [spouseEnabled, setSpouseEnabled] = useState("NO")

    const appStore = userStore();

    return (

    <div>

        <div className="card">
            <div className="card-header">
            <a className="collapsed card-link" data-toggle="collapse" href="#work_experience">
                Work Experience
            </a>
            <div className={appStore?.appAllData?.experiences?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                <span className={appStore?.appAllData?.experiences?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
            </div>
            </div>
            <div id="work_experience" className="collapse" data-parent="#accordion">
                <div className="card-body">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a onClick={()=>{setSpouseEnabled("NO")}} className="nav-link active" data-toggle="tab" href="#workexpprimaryApplicant">Primary Applicant</a>
                        </li>
                            {spouseData &&
                                <li className="nav-item">
                                    <a onClick={()=>{setSpouseEnabled("YES")}} className="nav-link" data-toggle="tab" href="#workexpspouse">Spouse</a>
                                </li>
                            }
                    </ul>

                    <div className="tab-content" style={{marginTop:"25px"}}>
                        {
                            appStore?.appAllData?.experiences?.map((item, index) => {
                                return(
                                    <Fragment>
                                        {
                                            spouseEnabled === item?.spouse_enabled && 
                                            <WorkExpForm item={item} key={index} />
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </div>

                    <ExpForm spouseEnabled={spouseEnabled} />


                </div>
            </div>
        </div>
        
    </div>

  )
}

export default WorkExp