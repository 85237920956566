function getCurrentFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0-based index (0 = January, 11 = December)
    const currentYear = today.getFullYear();
  
    let startYear, endYear;
  
    if (currentMonth < 3) {
      startYear = currentYear - 1;
      endYear = currentYear;
    } else {
      startYear = currentYear;
      endYear = currentYear + 1;
    }
  
    const financialYearStart = new Date(startYear, 3, 1); // April 1st
    const financialYearEnd = new Date(endYear, 2, 31); // March 31st
  
    return formatDate(financialYearStart, financialYearEnd)
}

function getNextFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0-based index (0 = January, 11 = December)
    const currentYear = today.getFullYear();
  
    let startYear, endYear;
  
    if (currentMonth < 3) {
      startYear = currentYear + 0;
      endYear = currentYear + 1;
    } else {
      startYear = currentYear + 1;
      endYear = currentYear + 2;
    }
  
    const financialYearStart = new Date(startYear, 3, 1); // April 1st
    const financialYearEnd = new Date(endYear, 2, 31); // March 31st
  
    return formatDate(financialYearStart, financialYearEnd)

}


function getPreviousFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0-based index (0 = January, 11 = December)
    const currentYear = today.getFullYear();
  
    let startYear, endYear;
  
    if (currentMonth < 3) {
      startYear = currentYear - 2;
      endYear = currentYear - 1;
    } else {
      startYear = currentYear - 1;
      endYear = currentYear;
    }
  
    const financialYearStart = new Date(startYear, 3, 1);
    const financialYearEnd = new Date(endYear, 2, 31);
  
    return formatDate(financialYearStart, financialYearEnd)
}

function formatDate(financialYearStart, financialYearEnd) {
    const startYear = financialYearStart.getFullYear();
    const endYear = financialYearEnd.getFullYear();
    return `${startYear}-${endYear.toString().slice(2)}`;
}


export { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear };
