import React, { useEffect, useState } from "react"
import authQueries from '../../../queries/authQueries'
import { Link, useNavigate } from "react-router-dom"
import { Modal, useModal, Button, Text } from "@nextui-org/react"
import { userStore } from '../../../store/userStore'
import LoaderCommon from "../../Common/LoaderCommon"

const Admissionreport =()=>{
    const user = userStore();
    const [result, setResult] = useState([])
    const [status, setStatus] = useState('')
    const [visaDesc, setvisaDesc] = useState('')
    const [dataType, setDataType] = useState('')
    const [applicantresult, setApplicantresult] = useState([])
    const [visibleStatus, setVisibleStatus] = useState(false)
    const [loader, setLoader] = useState(false)
    let sno=0;
    const userId= user?.user?.id;
    const role = user?.user?.rid;
    const officeId = user?.user?.office[0]?.oid;
    const vidArray = user?.user?.visa?.map(visa => visa.vid);
    const Vids = vidArray?.join(',');

    useEffect(()=>{
        user.setToggleMenu(false);
        admissionReportApi.mutateAsync();
    },[])


    const admissionReportApi = authQueries.useAdmissionstaffReport(
        (response) => {
            console.log("response",response.data[0])
            setResult(response.data[0])        
        }   
    
    )



    return(
        <div>

            <div className="breadcrumb-and-otherbuttons">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Admission Staff - Perfomance Report</li>
                    </ol>
                </nav>
            </div>
            

            <div className="add_clients" style={{marginBottom:'0px'}}>
                
                    <div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Admission Staff - Perfomance Report</h2>    
                        </div>
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    

                    <div id="accordion" className="add_clients_accordion_form"  style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Admission Staff - Perfomance Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                    
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                    
                                            <table border={1} className="table report collageprogram  countrywise">
                                                <thead>
                                                    <tr>
                                    
                                                    <th>Admission Staff</th>
                                                    <th style={{ width: '100px' }}>Total Assigned Clients</th>
                                                    <th>Full Docs</th>
                                                    <th >Application submitted month to date</th>
                                                    <th >Application submitted Rejected/ Not Proceeding</th>
                                                    <th >Not Proceeding any offer</th>
                                                    <th >Total active application submitted</th>
                                                    <th >Conditional Offer</th>
                                                    <th >UnConditional Offer</th>
                                                    <th >Conditional/UnConditional with a due date within 7 days</th>
                                                    <th >Offer Accepted</th>
                                                    <th >Unique applicant month to date</th>
                                                    <th >Unique applicant year to date</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { admissionReportApi.isLoading && "Loading" }
                                                {   
                                                    result?.map((item, index) =>
                                                    {  
                                    
                                                    return(
                                                        <tr className="ui-sortable-handle">
                                                        <td>{item.firstname+' '+item?.lastname }</td>
                                                        <td>{item.assigned_users }</td>
                                                        <td>{item.full_docs?item.full_docs:0}</td>
                                                        <td>{item.application_submitted?item.application_submitted:0}</td>
                                                        <td>{item.application_rejected?item.application_rejected:0}</td>

                                                        <td>{item.not_proceeding?item.not_proceeding:0}</td>
                                                        <td>{item.active_application_submitted?item.active_application_submitted:0}</td>
                                                        <td>{item.conditionaloffer?item.conditionaloffer:0}</td>
                                                        <td>{item.unconditionaloffer?item.unconditionaloffer:0}</td>
                                                        <td>{item.duedayscnt?item.duedayscnt:0}</td>
                                                        <td>{item.offeraccepted?item.offeraccepted:0}</td>
                                                        <td>{item.uniqmnt?item.uniqmnt:0}</td>
                                                        <td>{item.uniqyear?item.uniqyear:0}</td>
                                                        </tr>
                                                    )})
                                                }
                                                 <tr>
  
                                                </tr>
                                            </tbody>

                                            </table>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
                
            </div>
    </div>
    )    
}
export default Admissionreport