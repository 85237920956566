import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup';
import authQueries from '../../queries/authQueries';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store/userStore';

const ResetPassword = () => {

    const navigate = useNavigate()

    const user = userStore()

    const validationSchema = yup.object({
        oldpwd: yup
            .string('Enter Password')
            .required('Password is required'),
        newpwd: yup
            .string('Enter Password')
            .required('Password is required')
            .min(4,'Password must be more than 4 characters '),
        conpwd: yup
            .string('Enter Confirm Password')
            .required('Confirm Password is required')
            .min(4,'Confirm Password must be more than 4 characters ')
            .oneOf([yup.ref('newpwd'), null], 'Your passwords do not match.')
      });

    const {handleSubmit, register, formState:{errors}} = useForm({
        resolver: yupResolver(validationSchema)
    })

    const changePassword = authQueries.useChangePasswordMutation(
        (res)=>{
            if(res?.message === "Old password is incorrect"){
                toast.error("Old password is incorrect")
            }

            if(res?.message === "success"){
                toast.success("Successfully changed password")
                navigate('/')
            }
        }
    )

    const onSubmit =(data)=>{
        changePassword.mutateAsync(data)
        console.log(data)
    }

  return (
    <div className='flex justify-center items-center min-h-[80vh]'>
        <div className='w-[500px] bg-white p-5'>
            <div className='text-[16px] font-semibold'>Change Password</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4 mt-4 text-[13px]'>  
                    <InputBox name="oldpwd" register={register} label="Old Password" error={errors} />
                    <InputBox name="newpwd" register={register} label="New Password" error={errors} />
                    <InputBox name="conpwd" register={register} label="Confirm Password" error={errors} />
                    <div className='mt-3'>
                        <button type='submit' className='btn-customized-large text-[14px] h-[45px]'>
                            Change Password
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default ResetPassword

const InputBox =({label, name, error, register})=>{
    return(
        <div>
            <div className='font-medium mb-1'>{label}</div>
            <input {...register(name)} type="password" placeholder="••••••••" className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg pl-3 focus-within:outline-blue-400 h-[45px] w-full' />
            <div className='text-[red] mt-[2px]'>
                {error?.[name]?.message}
            </div>
        </div>
    )
}