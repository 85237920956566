import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../../store/userStore';
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../Common/financialYear';
import LoaderCommon from "../../Common/LoaderCommon";

const OfficeDetailedReport = ({datevalue,result}) => {
    const user = userStore();
    const [loader, setLoader] = useState(false);
    const navigation = useNavigate();
    const currentFinancialYear = getCurrentFinancialYear();
    const nextFinancialYear = getNextFinancialYear();
    const previousFinancialYear = getPreviousFinancialYear();
    const [dateRange, setDateRange] = useState("CURRENT");




    // Group results by office and user
    const groupedResults = result.reduce((acc, item) => {
        if (!acc[item.office]) {
            acc[item.office] = {};
        }
        if (!acc[item.office][item.username]) {
            acc[item.office][item.username] = [];
        }
        acc[item.office][item.username].push(item);
        return acc;
    }, {});

    // Calculate office totals
    const officeTotals = Object.keys(groupedResults).reduce((acc, office) => {
        acc[office] = Object.keys(groupedResults[office]).reduce((officeTotal, user) => {
            return officeTotal + groupedResults[office][user].reduce((userTotal, item) => {
                return userTotal + (item.preparing_visa + item.visa_applied + item.visa_approved || 0);
            }, 0);
        }, 0);
        return acc;
    }, {});

    // Calculate grand totals
    const grandTotals = {
        totalOffices: Object.values(officeTotals).reduce((acc, total) => acc + total, 0),
        totalStaff: 0,
        preparingVisa: 0,
        visaApplied: 0,
        visaApproved: 0,
        expectedTotalVisa: 0
    };

    Object.keys(groupedResults).forEach(office => {
        const users = groupedResults[office];
        Object.keys(users).forEach(user => {
            users[user].forEach(item => {
                grandTotals.totalStaff += item.preparing_visa + item.visa_applied + item.visa_approved || 0;
                grandTotals.preparingVisa += item.preparing_visa || 0;
                grandTotals.visaApplied += item.visa_applied || 0;
                grandTotals.visaApproved += item.visa_approved || 0;
                grandTotals.expectedTotalVisa += item.preparing_visa + item.visa_applied + item.visa_approved;
            });
        });
    });

    return (
        <Fragment>
            <div className="add_clients">
               
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr_employee">
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 appointments-hing-wrpr">
                                        <h2>Detailed Office Report</h2>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="table-head-wrpr">
                                            <table className="table collageprogram alt-colors">
                                                <thead className="fixedHeader table-hdr">
                                                    <tr>
                                                        <th>Office</th>
                                                        <th>Total for office</th>
                                                        <th>Total for Staff</th>
                                                        <th>Counselor</th>
                                                        <th>Country</th>
                                                        <th>Preparing Visa</th>
                                                        <th>Visa Applied</th>
                                                        <th>Visa Approved</th>
                                                        <th>Expected Total Visa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ fontWeight: "bold", backgroundColor:'rgb(109, 174, 230)' }}>
                                                        <td>Grand Total</td>
                                                        <td>{grandTotals.totalOffices}</td>
                                                        <td>{grandTotals.totalStaff}</td>
                                                        <td colSpan={2}></td>
                                                        <td>{grandTotals.preparingVisa}</td>
                                                        <td>{grandTotals.visaApplied}</td>
                                                        <td>{grandTotals.visaApproved}</td>
                                                        <td>{grandTotals.expectedTotalVisa}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="table-body-wrpr">
                                            <div style={{ position: "relative" }}>
                                                <table border={1} className="table" style={{ fontSize: "12px" }}>
                                                    <tbody className="table-bdy">
                                                        {Object.keys(groupedResults).map((office, officeIndex) => {
                                                            const users = groupedResults[office];
                                                          
                                                            const userKeys = Object.keys(users);

                                                            
                                                            const officeRowCount = userKeys.reduce((acc, user) => acc + users[user].length, 0);
                                                            const officeBgClass = officeIndex % 2 === 0 ? "office-bg-1" : "office-bg-2"; // Alternate background class

                                                            return userKeys.map((user, userIndex) => {
                                                                const items = users[user];
                                                               
                                                                const staffTotal = items.reduce((total, item) => total + (item.preparing_visa +item.visa_applied +item.visa_approved || 0), 0);

                                                                return items.map((item, itemIndex) => {
                                                                    return (
                                                                        <tr key={`${officeIndex}-${userIndex}-${itemIndex}`} className={`ui-sortable-handle ${officeBgClass}`}>
                                                                            {userIndex === 0 && itemIndex === 0 && (
                                                                                <td className={officeBgClass} rowSpan={officeRowCount} style={{ textAlign: "center", fontWeight: "bold" }}>{office}</td>
                                                                            )}
                                                                            {userIndex === 0 && itemIndex === 0 && (
                                                                                <td className={officeBgClass} rowSpan={officeRowCount} style={{ textAlign: "center", fontWeight: "bold" }}>{officeTotals[office]}</td>
                                                                            )}
                                                                            {itemIndex === 0 && (
                                                                                <td className={officeBgClass} rowSpan={items.length}>{staffTotal}</td>
                                                                            )}
                                                                            {itemIndex === 0 && (
                                                                                <td className={officeBgClass} rowSpan={items.length}>{user}</td>
                                                                            )}
                                                                            <td className={officeBgClass}>{item.country}</td>
                                                                            <td className={officeBgClass}>{item.preparing_visa || 0}</td>
                                                                            <td className={officeBgClass}>{item.visa_applied || 0}</td>
                                                                            <td className={officeBgClass}>{item.visa_approved || 0}</td>
                                                                            <td className={officeBgClass}>{item.preparing_visa +item.visa_applied +item.visa_approved }</td>
                                                                      
                                                                        </tr>
                                                                    );
                                                                });
                                                            });
                                                        })}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr style={{ fontWeight: "bold", backgroundColor:'rgb(109, 174, 230)' }}>
                                                            <td>Grand Total</td>
                                                            <td>{grandTotals.totalOffices}</td>
                                                            <td>{grandTotals.totalStaff}</td>
                                                            <td colSpan={2}></td>
                                                            <td>{grandTotals.preparingVisa}</td>
                                                            <td>{grandTotals.visaApplied}</td>
                                                            <td>{grandTotals.visaApproved}</td>
                                                            <td>{grandTotals.expectedTotalVisa}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                  
                                        <div className="table-list-search-wrpr table-container tableContainer">
                                            <div className="row">
                                                <div className="col-md-6"></div>
                                                <div className="col-md-6 text-right"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default OfficeDetailedReport;
