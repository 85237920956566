import { Pagination, PaginationItem } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Empty } from 'antd'
import authQueries from '../../queries/authQueries'

const AdminExportList = () => {

    const [result, setResult] = useState([])
    const [search, setSearch] = useState([])

    const applicantList = authQueries.useSearchAllApplicant(
        (response)=> {
            setResult(response)
        }
    )

    const {register, handleSubmit} = useForm()

    const onSubmit =(data)=>{

        const name = data.name ? data.name : ''
        const nameArr = name.split(' ')
        let fname = ""
        let mname = ""
        let lname = ""
        if(nameArr.length > 2){
            fname = nameArr[0]
            mname = nameArr[1]
            lname = nameArr[2]
        }else if(nameArr.length > 1){
            fname = nameArr[0]
            lname = nameArr[1]
        }else{
            fname = nameArr[0]
        }

        const dataValues = {
            page: 0,
            size: 25,
            fname: fname,
            mname: mname,
            lname: lname,
            email: data.email,
            mobile: data.mobile,
        }

        setSearch(dataValues)
    
        applicantList.mutateAsync(dataValues)
    }

    const handleChange =(event, value)=> {
        search.page = value - 1

        applicantList.mutateAsync(search)
    }

  return (
    <div>
        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Clients</li>
                </ol>
            </nav>
        </div>
				
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Clients</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            Search All Clients
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">   


                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Applicant Name:</label>
                                            <input {...register('name')} name="name" id="spouse_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Email:</label>
                                            <input {...register('email')} name="email" id="spouse_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Phone No:</label>
                                            <input {...register('mobile')} name="mobile" id="spouse_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group" style={{display:'grid', marginLeft:'10px', marginRight:'10px'}}>
                                        <label htmlFor="spouse_name">&nbsp;</label>
                                        <button style={{height:'100%'}} type="submit" className="btn btn-md btn-dark btn-add-new-client btn-add-new-client2">
                                            <i className="icon-search4" style={{height:'33px', width:'33px'}}></i>
                                            &nbsp;&nbsp;Search
                                        </button>
                                    </div>
                                </div>
                            </form>


                            <div className="table-list-search-wrpr simple-search">

                            <div className="row">
                                <div className="col-md-12 search-results-count-wrpr">
                                    Search Results {result?.data?.items?.length} of {result?.data?.totalItems}
                                </div>
                            </div>

                            {applicantList?.isLoading ?
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <div className="spinner-border m-3" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    {result?.data?.totalItems > 0 &&
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <table className="table collageprogram ">
                                                    <thead>
                                                        <tr className='alt-colors'>
                                                        <th width="30">#</th>
                                                        <th width="200">Applicant Name</th>
                                                        <th>Office</th>
                                                        <th>Visa Type</th>
                                                        <th>Mobile No.</th>
                                                        <th>Email</th>
                                                        <th>Created Date</th>
                                                        <th>Reg. Date</th>
                                                        <th>Prospect Status</th>
                                                        <th>Intake</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ui-sortable">
                                                        {result?.data?.items?.map((item, index) => (
                                                            <Fragment key={index}>
            
                                                                <tr  style={{background: item.registered_status === "YES" && '#E5F5E3'}}>
                                                                    <th className="">{(index+1) + (result.data?.currentPage * 25)}</th>
                                                                    <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                                    <td>{item?.office?.name}</td>
                                                                    <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                                    <td>{item.mobile_no}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                    <td>{item?.registered_date ? moment(item?.registered_date).format('DD-MM-YYYY'):'-'}</td>
                                                                    <td>
                                                                        {item?.status_of_prospect?.name}
                                                                    </td>
                                                                    
                                                                    <td>{item?.intake?.intake}</td>
                                                                </tr>
            
                                                            </Fragment>
            
                                                        ))}												  
                                                    </tbody>
                                                </table>
                                                <ul className="pagination pagination-sm justify-content-center table-pagination mt-5 custom-pagination">
                                                    {result?.data?.totalPages > 1 &&
                                                    <Pagination
                                                            count={result?.data?.totalPages} 
                                                            nextText="Next"
                                                            prevText="Previous"
                                                            renderItem={
                                                                (item) => (     
                                                                    <PaginationItem component="li" {...item} />
                                                                )
                                                            }
                                                            variant="outlined"
                                                            page={result?.data?.currentPage+1}
                                                            shape="rounded"
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        result?.data?.totalItems === 0 &&
                                        <div style={{marginTop:'5%', marginBottom: '10%'}} className="text-center">
                                            <Empty
                                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                imageStyle={{ height: 80 }}
                                                description={
                                                <h6>
                                                    Sorry! No Leads found.
                                                </h6>
                                                }
                                            >
                                            </Empty>
                                        </div>
                                    }
                                </Fragment>
                            }

                            </div>	
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
  )
}

export default AdminExportList