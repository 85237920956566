import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";

const RefferalAdd =()=>{

    const validationSchema = yup.object({
        name: yup
          .string('⚠ Enter your Refferal Name')
          .required('⚠ Refferal Name is required')
          .min(2, '⚠ Refferal Name should be of Minimum of 2 characters length'),
        isOpen: yup
          .string('⚠ Enter your Refferal Is open pool enabled')
          .required('⚠ Is open pool enabled is required'),
        status: yup
          .string('⚠ Enter your status')
          .required('⚠ Status is required'),
    });

      
    const [errName, setErrName] = useState('');
    const [isOpenErr, setisOpenErr] = useState('');

    const navigate = useNavigate();


    const { register , control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const addProgram = authQueries.useRefferalMutationAdd(
        (response) => {

            if(response?.message === "Success"){
                navigate('/refferal')
            }else if(response?.error[0].refferal){
                setErrName("⚠ "+response?.error[0].refferal)
            }         

        }   
    );
        
    
    const onSubmit = (data) => {
        const datavalues = {
            refferal: data.name,
            isopenpool_enabled:data.isOpen,
            status: data.status,
        };
        
        addProgram.mutateAsync(datavalues);
    }
    

    
    return(
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/refferal">Refferal List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add Refferal</li>
                </ol>
            </nav>

            <div className="add_clients">
                <div className="card">
                    <div className="card-header">
                        Create Refferal
                    </div>
                    <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        
                                            <div id="parent" className="form-group">
                                                <label >Refferal Name</label>
                                                <input 
                                                    {...register("name")}
                                                    type="text" 
                                                    className="form-control"  
                                                    name="name"/>
                                                <div className='error-msg'>{errors.name?.message}{errName}</div>
                                            </div>
                                            <div id="output"></div>
                                        
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        
                                            <div id="parent" className="form-group">
                                                <label >Is open pool enabled</label>
                                                <select  {...register("isOpen")}  className="form-control"  name="isOpen">
                                                    <option value="">Select</option>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                </select>    
                                                <div className='error-msg'>{errors.isOpen?.message}</div>
                                            </div>
                                            <div id="output"></div>
                                        
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div className="form-group">
                                        
                                            <div id="parent" className="form-group">
                                                <label >Status</label>
                                                <select  {...register("status")}  className="form-control"  name="status">
                                                    <option value="">Select</option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="INACTIVE">INACTIVE</option>
                                                </select>       
                                                <div className='error-msg'>{errors.status?.message}</div>
                                            </div>

                                            <div id="output"></div>
                                        
                                    </div>
                                </div>                                                       
                                
                                <div className="col-md-12 text-center">
                                    <button type="submit" className="btn btn-default btn-save">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>


        </div>
    )
}

export default RefferalAdd;