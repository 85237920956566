import React, { Fragment, useState } from 'react'
import { userStore } from '../../store/userStore';
import { useApi } from './ApiProvider';
import authQueries from '../../queries/authQueries';
import { Loading } from '@nextui-org/react';

const StepIn = ({item, listApplicants}) => {

    const { setApiDataAndNotify } = useApi()
    const user = userStore((state) => state.user);
    const [appid, setAppid] = useState(null);

    const handleAssign=()=>{
        const dataValues = {
            applicant_id: item?.id,
            user_id: user?.id,
            role_id: 20,
            status: 'ACTIVE',
            office: ""
        }
        setAppid(item?.id)
        addAssign.mutateAsync(dataValues);
    }

    const addAssign = authQueries.useAssignAdd(
        (res) => {
            setApiDataAndNotify(res)
        }
    );

  return (
    <Fragment>
        {
            addAssign.isLoading ?
            <button
            disabled={item?.assignUser?.spc ? true : false}
            style={{cursor: item?.assignUser?.spc ? "not-allowed" : "pointer", opacity: item?.assignUser?.spc ? "0.5" : "1"}}
            className='btn-customized w-full'>
                <Loading color='white' size='xs' />
            </button>:
            <button
            disabled={item?.assignUser?.spc ? true : false}
            style={{cursor: item?.assignUser?.spc ? "not-allowed" : "pointer", opacity: item?.assignUser?.spc ? "0.5" : "1"}}
            onClick={()=>{handleAssign(item)}} 
            className='btn-customized w-full'>
                Step In
            </button>
        }
    </Fragment>
  )
}

export default StepIn