import React from 'react'
import { message } from 'antd';

export const DeleteMessage = ({type, content}) => {

    message.open({
        type: type,
        content: content,
      });

}

export const SuccessMessage = ({type, content}) => {

    message.open({
        type: type,
        content: content,
      });

}

