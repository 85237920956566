import React, { Fragment, useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import authQueries from '../../queries/authQueries';
import { Avatar } from 'antd';
import moment from 'moment';
import { Loading, Popover } from '@nextui-org/react';
import LoaderCommon from './LoaderCommon';
import { IconBell, IconExclamationCircle, IconX } from '@tabler/icons-react';
import FollowUp from './FollowUp';
import { useExistModal } from '../../store/existModalStore';

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};

const NotificationPage = () => {

    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0)
    const [totalItems, setTotalItems] = useState(0)

    const setAppid = useExistModal((state) => state.setAppid)
	const setExistOpen = useExistModal((state) => state.setOpen)
    const important = useExistModal((state) => state.important)

    const fetchMoreData = () => {
      if (items.length >= totalItems) {
        setHasMore(false);
        return;
      }
      setTimeout(() => {
        setPage(page + 1)
      }, 500);
    };

    useEffect(() => {
        notificationList.mutateAsync({ page: page, size: 20, important: important });
	}, [page]);

	const notificationList = authQueries.useNotificationList(
		async (response) => {
            const newItems = [...items, ...response?.data?.items]
            setItems(newItems)
            setTotalItems(response?.data?.totalItems)
		}
	);

    const readNotification = (id) => {
		const clickItem = items?.find(item => item.id === id)
		if (clickItem) {
			clickItem.status = "READ"
			// notificationData.setNotification(notificationData.notificationData)
			readAsNotification.mutateAsync(id)
		}
	}

	const readAsNotification = authQueries.useNotificationReadMutation(
		(response) => {
		}
	)

    const readAll = () => {
		items?.map(item => {
			item.status = "READ"
		})
        setItems(items)
		readAllNotification.mutateAsync()
	}

	const readAllNotification = authQueries.useNotificationReadAllMutation(
		(response) => {
		}
	)


	const handleDetails =(item)=>{
		setExistOpen(true)
		setAppid(item?.application?.id)
	}
  

  return (
    <Fragment>
        <div className='w-full flex justify-between items-center'>
            <div className='flex items-center gap-1'>
                <IconBell size={20} color='gold' fill='gold' />
                Notifications
            </div>
            <div className='w-[100px]'>
                <button
                onClick={readAll}
                className='btn-customized-large h-[35px]'
                >Read All
                </button>
            </div>
        </div>
        <div className='mt-4'>
            <InfiniteScroll
            dataLength={items?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
                <div style={{ textAlign: 'center' }}>
                    <Loading />
                </div>
            }
            endMessage={
                <p style={{ textAlign: "center" }}>
                    <b></b>
                </p>
            }
            >
            {items.map((item, index) => (
                <div style={{ marginBottom: 10, background: item?.visaapproved === "YES" ? '#9DBDFF' : item?.status === "NEW" ? '#C7F270' : '#fff'  }} key={index} className='w-full p-2 text-[12px] rounded-xl bg-red-400'>
                    <div className='flex p-2 items-center gap-3'>
                        <div>
                            <Avatar src={item?.send?.userdetail?.image} />
                        </div>
                        <div className='flex flex-col gap-1 w-full'>
                            <div className='flex justify-between'>
                                <div className='flex gap-1 items-center'>
                                    <div className=' text-[13px] font-semibold'>{item?.title}</div> {item?.status === "NEW" && <span style={{ marginLeft: '10px' }} className='badge badge-success'>New</span>}
                                </div>
                                {
                                    item?.status === "NEW" &&
                                    <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => readNotification(item?.id)}
                                    >
                                        <i className="icon-close"></i>
                                    </div>
                                }
                            </div>
                            <div>{item?.description}</div>
                            <div className='flex justify-between'>
                                <div className='text-[#28a745] font-semibold'>
                                    {
                                        moment(item?.createdAt).format('DD MMM YYYY hh:mm') === moment().format('DD MMM YYYY hh:mm') ?
                                        "Just Now" :
                                        moment(item?.createdAt).format('DD MMM YYYY') === moment().format('DD MMM YYYY') ?
                                        "Today" :
                                        moment(item?.createdAt).format('DD MMM YYYY') === moment().subtract(1, 'days').format('DD MMM YYYY') ?
                                        "Yesterday" :
                                        moment(item?.createdAt).format('DD MMM YYYY') < moment().format('DD MMM YYYY') ?
                                        moment(item?.createdAt).format('DD MMM YYYY') :
                                        moment(item?.createdAt).format('hh:mm A')
                                    }
                                </div>
                                {
                                    item?.exist_notification === "YES" ?
                                    <button className='text-[12px]' onClick={()=>{handleDetails(item)}}>
                                        <span className='cursor-pointer'>View Details</span>
                                    </button>:
                                    <div className='w-[130px]'>
                                        <FollowUp item={item?.application} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </InfiniteScroll>
        </div>
    </Fragment>
  )
}

export default NotificationPage