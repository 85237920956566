import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../../store/userStore";

const EditCountry =()=>{

    const master = userStore();

    const validationSchema = yup.object({
        name: yup
          .string('⚠ Enter Country Name')
          .required('⚠ Country Name is required')
          .min(2, '⚠ Name should be of Minimum of 2 characters length'),
        cc: yup
          .string('⚠ Enter Country Code')
          .required('⚠ Country Code is required')
          .min(2,'⚠ Country Code must be more than 1 character '),
        status: yup
          .string('⚠ Enter your status')
          .required('⚠ Status is required'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');

    const navigate = useNavigate();

    useEffect(()=>{
        CountryData.mutateAsync(master.country);
    },[])

    const CountryData = authQueries.useCountryMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    cc:response?.data.country_code,
                    name:response?.data.name,
                    status:response?.data.status
                })
                reset({ ...datas });
            }
        }   
    );


    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const editCountry = authQueries.useCountryMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/country')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );
        
    
    const onSubmit = (data) => {
        const datavalues = {
            id:master.country,
            name: data.name,
            country_code: data.cc,
            status: data.status,
        };
        
        editCountry.mutateAsync(datavalues);
    }
    

    
    return(
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/country">Country List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Country</li>
                </ol>
            </nav>

            <div className="card">
                <div className="card-header">
                    Edit Country
                </div>
                <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Name</label>
                                            <input 
                                                {...register("name")}
                                                type="text" 
                                                className="form-control"  
                                                name="name"/>
                                            <p className='error'>{errors.name?.message}{errName}</p>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Country Code</label>
                                            <input 
                                                {...register("cc")}
                                                type="text" 
                                                className="form-control"  
                                                name="cc"/>
                                            <p className='error'>{errors.cc?.message}{errName}</p>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Status</label>
                                            <select  {...register("status")}  className="form-control"  name="status">
                                                <option value="">Select</option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="INACTIVE">INACTIVE</option>
                                            </select>       
                                            <p className='error'>{errors.status?.message}</p>
                                        </div>

                                        <div id="output"></div>
                                    
                                </div>
                            </div>                                                       
                            
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-default btn-save">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        </div>
    )
}

export default EditCountry