import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { usePaginationStore, userStore } from '../../../store/userStore';
import { message, message as messages, Modal, Popconfirm, Popover } from 'antd';
import { Pagination } from '@nextui-org/react';
import LoaderCommon from '../../Common/LoaderCommon';
import MasterHeader from "../../Common/MasterHeader";
import { generateValidationSchema } from "../../Common/validationSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PaginationComponent from "../../Common/PaginationComponent";
import MasterModal from "../../Common/MasterModal";
import InputUseForm from "../../Common/InputUseForm";
import InputMultiSelect from "../../Common/InputMultiSelect";
import InputCheckBox from "../../Common/InputCheckBox";
import InputRadio from "../../Common/InputRadio";


const ListProspect = () => {

    const navigate = useNavigate()

    const [result, setResult] = useState([])
    const [name, setName] = useState('')
    const [loader, setLoader] = useState(true)
    const [visibleVisa, setVisibleVisa] = useState(false)
    const [visibleRole, setVisibleRole] = useState(false)
    const [visa, setVisa] = useState([])
    const [role, setRole] = useState([])
    const [statusVisa, setStatusVisa] = useState([])
    const [statusRole, setStatusRole] = useState([])

    const master = userStore();

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const inputFields = [
        {
            name: "name",
            label: "Prospect Status",
            size: "6",
            min: 2,
            max: 250,
            type: InputUseForm,
            required: true
        },
        {
            name: "show_status",
            options: [{ label: "Before Registration", value: "BEFORE" }, { label: "After Registration", value: "AFTER" }, { label: "Both", value: "BOTH" }],
            label: "After Registration",
            size: "6",
            type: InputRadio
        }
    ]


    const { register, reset, handleSubmit, formState: { errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    useEffect(() => {
        statusListing.mutateAsync({ search: name, page: currentPage, size: 25 });
    }, [name, currentPage]);

    const visaListing = authQueries.useVisaTypeMutationList(
        (response) => {
            setVisa(response?.data?.items)
        }
    );    
    
    const roleListing = authQueries.useRoleMutationList(
        (response) => {
            setRole(response?.data?.items)
        }
    );

    useEffect(() => {
        visaListing.mutateAsync({size: 500000})
        roleListing.mutateAsync({size:20000})
    }, []);

    const statusListing = authQueries.useProspectList(
        (response) => {
            setResult(response)
            setLoader(false)
        }
    );

    const downloadExcel = (id) => {
        downloadPdf.mutateAsync(id)
    }

    const downloadPdf = authQueries.useExportPdf(
        (response) => {
            if (response.message === "Sorry! You can not export more than 10000 records.") {
                messages.error("Sorry! You can not export more than 10000 records.")
            }
            if (response.filename) {
                window.open(`${process.env.REACT_APP_BASE_URL}/api/impex/download/${response.filename}`, '_blank');
            }
        }
    );

    const [visible, setVisible] = useState(false)

    const handleOpen = async (item) => {
        if (!item) {
            await master.setMaster(null)
            setVisible(true)
            return
        } else {
            reset(item)
            await master.setMaster(item.id)
            setVisible(true)
        }
    }

    const handleClose = async () => {
        const getvalue = getValues()
        for (const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
                setValue(key, "")
            }
        }
        clearErrors()
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null)
    }

    const createMaster = authQueries.useProspectAdd(
        (response) => {
            if (response?.message === "Error") {
                {
                    response.error.map((error, index) => (
                        Object.values(error).map((errorMessage, innerIndex) => (
                            message.error(errorMessage)
                        ))
                    ))
                }
                return
            }
            handleClose()
            if (response?.message === "Success") {
                message.success("Successfully created status of prospect")
                statusListing.mutateAsync({ search: name, page: currentPage, size: 25 });
            }
        }
    );

    const updateMaster = authQueries.useProspectEdit(
        (response) => {
            if (response?.message === "Error") {
                {
                    response.error.map((error, index) => (
                        Object.values(error).map((errorMessage, innerIndex) => (
                            message.error(errorMessage)
                        ))
                    ))
                }
                return
            }
            handleClose()
            if (response?.message === "Success") {
                message.success("Successfully updated status of prospect")
                statusListing.mutateAsync({ search: name, page: currentPage, size: 25 });
            }
        }
    );

    const roleWiseVisaListing = authQueries.useRoleWiseStatusListingMutation(
        (res)=>{
            if(res?.message === "success"){
                setStatusRole(res)
            }
        }
    )

    const visaWiseVisaListing = authQueries.useVisaWiseStatusListingMutation(
        (res)=>{
            if(res?.message === "success"){
                setStatusVisa(res)
            }
        }
    )

    const handleVisa = (item) => {
        setVisibleVisa(true)
        visaWiseVisaListing.mutateAsync(item.id)
    }

    const handleVisaClose = () => {
        setVisibleVisa(false)
    }

    const handleRole = (item) => {
        setVisibleRole(true)
        roleWiseVisaListing.mutateAsync(item.id)
    }

    const handleRoleClose = () => {
        setVisibleRole(false)
    }

    const handleVisaCreate=(id)=>{
        const newValue = {
            vid: id,
            sid: statusVisa.status
        }
        visaWiseVisaCreate.mutateAsync(newValue)
    }

    const visaWiseVisaCreate = authQueries.useVisaWiseStatusCreateMutation(
        (res)=>{
            visaWiseVisaListing.mutateAsync(res?.data?.sid)
        }
    )

    const handleRoleCreate=(id)=>{
        const newValue = {
            rid: id,
            sid: statusRole.status
        }
        roleWiseVisaCreate.mutateAsync(newValue)
    }

    const roleWiseVisaCreate = authQueries.useRoleWiseStatusCreateMutation(
        (res)=>{
            roleWiseVisaListing.mutateAsync(res?.data?.sid)
        }
    )


    return (
        <div>

            <MasterHeader handleOpen={handleOpen} header="Status" breadcrumb="Status of Prospects" />

            <div className="add_clients">

                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Status of Prospects</h2>
                    </div>

                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>


                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header">
                            Status of Prospects
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                                <div className="row ">
                                    <div className="col-md-12">

                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6" />
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div className="table-search-wrpr">
                                                            <input style={{ minWidth: '300px' }} onChange={(e) => setName(e.target.value)} className="form-control form-control-sm" type="text" placeholder="Search Status" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            loader ?
                                                <LoaderCommon /> :
                                                <div className="table-wrpr">
                                                    <div className="table-section">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="table prospect-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50">#</th>
                                                                            <th >Name</th>
                                                                            <th width="100">&nbsp;</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody className="ui-sortable">
                                                                        {result?.data?.items?.map((item, index) => (
                                                                            <tr key={index} className="ui-sortable-handle">
                                                                                <th className="">{(index + 1) + result?.data?.currentPage * 25}</th>
                                                                                <td>{item.name}</td>
                                                                                <td className="action-icons flex gap-2 justify-end">
                                                                                    <button onClick={() => { handleVisa(item) }} className="btn-nextrow mb-0 w-[70px]">Visa Type</button>
                                                                                    <button onClick={() => { handleRole(item) }} className="btn-nextrow mb-0">Role</button>
                                                                                    <button onClick={() => { handleOpen(item) }} className="btn-nextrow mb-0">Update</button>
                                                                                    <button onClick={() => { downloadExcel(item.id) }} className="btn-nextrow mb-0">Export</button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                        <MasterModal
                                            grid="6"
                                            width="50%"
                                            header="Status of Prospects"
                                            visible={visible}
                                            handleClose={handleClose}
                                            inputFields={inputFields}
                                            reset={reset}
                                            control={control}
                                            handleSubmit={handleSubmit}
                                            register={register}
                                            errors={errors}
                                            getValues={master?.master}
                                            defaultValues={getValues()}
                                            create={createMaster}
                                            update={updateMaster}
                                        />

                                        <Modal
                                            open={visibleVisa}
                                            onCancel={handleVisaClose}
                                            footer={null}
                                            width="50%"
                                        >
                                            <div className="flex flex-col gap-4 mb-2">
                                                <div className="w-full font-medium text-[18px] text-center">Visa Type</div>
                                                {
                                                    visaListing.isLoading?
                                                    <LoaderCommon />:
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            visa?.map((item, index)=> {
                                                                const exist = statusVisa?.data?.find(element=> element.description === item.description)
                                                                return(
                                                                    <div
                                                                    key={index}
                                                                    onClick={()=>{handleVisaCreate(item.id)}}
                                                                    className={`${exist ? "bg-green-700 text-white" : "bg-gray-300 text-black"} rounded-md px-[8px] py-[2px] text-[11px] cursor-pointer`}
                                                                    >
                                                                        {item?.description}
                                                                    </div>
                                                            )})
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </Modal>

                                        <Modal
                                            open={visibleRole}
                                            onCancel={handleRoleClose}
                                            footer={null}
                                            width="50%"
                                        >
                                            <div className="flex flex-col gap-4 mb-2">
                                                <div className="w-full font-medium text-[18px] text-center">Roles</div>
                                                {
                                                    roleListing.isLoading ?
                                                    <LoaderCommon />:
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            role?.map((item, index)=> {
                                                                const exist = statusRole?.data?.find(element=> element.name === item.name)
                                                                return(
                                                                    <div
                                                                    key={index}
                                                                    onClick={()=>{handleRoleCreate(item.id)}}
                                                                    className={`${exist ? "bg-green-700 text-white" : "bg-gray-300 text-black"} rounded-md px-[8px] py-[2px] text-[11px] cursor-pointer`}
                                                                    >
                                                                        {item?.name}
                                                                    </div>
                                                            )})
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </Modal>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ListProspect