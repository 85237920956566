import { Modal } from '@nextui-org/react'
import React, { Fragment, useEffect, useState } from 'react'
import VisaDatas from '../../Data/VisaDataValues'
import moment from 'moment'
import authQueries from '../../queries/authQueries'
import { userStore } from '../../store/userStore'
import toast from 'react-hot-toast'

const VisaStaffVisaData = ({item}) => {

    const storeData = userStore()

    const [open, setOpen] = useState(false)

    const handleOpen =()=> {
        setOpen(true)
        visaDataAdd()
    }

    const handleClose = () => {
        setOpen(false)
    }

    const [visaData, setVisaData] = useState([])

    const visaDataAdd = async() => {
        let visaDataItem = {}
        VisaDatas.map((vis) => {
            const tempData = vis.id.find((element) => element === item.visa_type_id)
            if(tempData){
                visaDataItem = vis
            }
        })

        let arr = []
        visaDataItem?.items?.map((item) => {
            let data = {
                radio: false,
                date: !item.radio ? moment().format('YYYY-MM-DD') : '',
                name: item.name
            }
            arr.push(data)
        })
        
        storeData.setVisaData(arr)
        setVisaData(visaDataItem?.items)
    }

    const handleSubmit = () => {
        visaDataCreate.mutate({appid: item?.id, data:storeData.visaData})
    }

    const visaDataCreate = authQueries.useVisaDataMutatuionCreate(
        (response) => {
            toast.success("Visa Data Saved Successfully")
            handleClose()
        }
    )

  return (
    <div>
        <button onClick={handleOpen} className='btn-customized'>Visa Data</button>
        <Modal
        scroll
        width="700px"
        open={open}
        onClose={handleClose}
        blur
        closeButton
        >
            <Modal.Header>
                <h4 className='text-center mb-4'>Visa Data</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {
                        visaData?.map((element) => (
                            <Fragment key={item.name}>
                                <VisaDataItems appid={item?.id} item={element} />
                            </Fragment>
                            )
                        )    
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleSubmit} className='btn btn-primary'>Save</button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default VisaStaffVisaData

const VisaDataItems =({appid, item})=> {

    const [radio, setRadio] = useState(false)
    const [date, setDate] = useState('')
    const storeData = userStore()
    let findData = storeData.visaData.find((d) => d.name === item.name)

    useEffect(() => {
        if(!item.radio){
            setDate(moment().format('YYYY-MM-DD'))
        }
        visaView.mutate({appid: appid, name: item.name})
    }, [])

    const visaView = authQueries.useVisaDataMutatuionView(
        async (response) => {
            if(response.data){
                let findData = await storeData.visaData.find((d) => d.name === item.name)
                findData.date = response.data.date
                findData.radio = response.data.radio
                let radio = response.data.radio === "YES" ? true : false
                setRadio(radio)
                setDate(response.data.date)
                storeData.setVisaData(storeData.visaData)
            }
        }
    )

    const handleRadio = async (e) => {
        setRadio(e)
        if(!e){
            setDate('')
            findData.date = ''
            findData.radio = e
            storeData.setVisaData(storeData.visaData)
        }else{
            setDate(moment().format('YYYY-MM-DD'))
            findData.date = moment().format('YYYY-MM-DD')
            findData.radio = e
            storeData.setVisaData(storeData.visaData)

        }
    }

    const handleDate = async (e) => {
        setDate(e.target.value)
        findData.date = e.target.value
        findData.radio = radio
        storeData.setVisaData(storeData.visaData)
    }

    return(
        <Fragment>
            {
                item.radio && (
                    <div className='col-md-6 mb-4'>
                        <label className='label-visa' htmlFor="spouse_name">{item.name} (Yes/No):</label>
                        <div className="profile-status-btn-wrpr">
                            <div id="checkboxes" style={{display:'flex', alignItems:'center'}}>
                                <div className="checkboxgroup visa">
                                    <input checked={findData.radio === "YES" || findData.radio === true ? true : false} onChange={()=> handleRadio(true)} type="radio" name={item.name} id="my_radio_button_id2" />
                                    <label className='label-visa' htmlFor="my_radio_button_id2">Yes</label>
                                </div>
                                <div className="checkboxgroup visa">
                                    <input checked={findData.radio === "YES" || findData.radio === true ? false : true} onChange={()=> handleRadio(false)} type="radio" name={item.name} id="my_radio_button_id3" />
                                    <label className='label-visa' htmlFor="my_radio_button_id3">No</label>
                                </div>
                            </div>
                        </div> 
                    </div>
                )
            }
            {
                item.date && (
                    <div  className='col-md-6 mb-4'>
                        <label className='label-visa' style={{fontWeight:400}}>{item.name}</label>
                        <input value={date} onChange={handleDate} disabled={(!radio && item.radio) ? true : false } className='form-control label-visa' type="date" name={item.name} />
                    </div>
                )
            }
        </Fragment>
    )
}