import React,{useState,useEffect} from 'react'
import * as yup from 'yup';
import { LoginApi } from '../../apis/api';
import axios from "axios";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../store/userStore';
import { message as messages } from 'antd';
import { isAuthenticated } from '../../utils/authenticate';




const ForgotPassword = () => {


  const validationSchema = yup.object({

    email: yup
      .string('⚠ Enter your email')
      .email('⚠ Enter a valid email')
      .required('⚠ Email is required'),
  });


  const user = userStore();

  const userDetails = user.user;

  const navigate = useNavigate();

  const [name, setName] = useState("");



  const { register , control, handleSubmit, formState:{ errors } } = useForm({

    resolver: yupResolver(validationSchema)

  });


//   useEffect(() => {
//     console.log("user", user);
//     if (userDetails?.userId) {
//       user.setUser({ ...userDetails });
//       navigate("/dashboard");
//     }
//   }, []);

//   useEffect(() => {
//     if (isAuthenticated(user?.user)) {
//       navigate("/dashboard");
//     } else {
//       navigate("/");
//     }
//   }, []);


  const { mutateAsync } = authQueries.useForgotMutation(

    (response) => {

      // if(response?.data?.role === 'Admin') {
        if (response?.data) {
          // user.setUser({ ...response?.data });
          // sessionStorage.setItem(
          //   "accessToken",
          //   response.data?.accessToken
          // );
          setTimeout(() => {
            messages.success("Password Link sent to your gmail")
          }, 1500);
          navigate("/");
        }


      }

    // }

  );





  const onSubmit = (data) => {

    const datavalues = {

      user: data.email,

    };

    console.log(datavalues)


    mutateAsync(datavalues);

  }

  return (

    <div>

      <div className="login-wrpr">

        <div className="row">

          <div className="col-md-4 login-left">

            <img src={require('../../assets/images/logo.png')} width="" height="" alt="" />

            <div className="login-form-wrpr">

              <h2 className="">Login to <span>GeeBee</span></h2>

              <p>Please Enter email </p>
                
              <form onSubmit={handleSubmit(onSubmit)}>
                
                <div className="login-form-cntnr">

                  <div className="form-group has-float-label">

                    <input className="form-control" id="" type="email" control={control} {...register("email")} onChange={e => setName(e.target.value)} name="email" placeholder="User email" />
                      
                    <label htmlFor="username">User Email</label>
                        
                    <p className='error'>{errors.email?.message}</p>

                  </div>

                  <div className="form-group login-buttons">

                      {/* <button className="btn btn-sm btn-outline-primary">Register</button> */}

                      <button className="btn btn-sm btn-primary" type="submit">Submit</button>

                  </div>
                    
                
                </div>

              </form>

            </div>

          </div>

          <div className="col-md-8 login-right">
                
          </div>

        </div>

	    </div>
      
    </div>

  )
}

export default ForgotPassword