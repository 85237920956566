import { Pagination, PaginationItem } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { Empty } from 'antd'
import FollowUp from '../../Common/FollowUp'
import LoaderCommon from '../../Common/LoaderCommon'
import ApplicantViewButton from '../../Common/ApplicantViewButton'
import { useNavigate } from 'react-router-dom'
import { useViewProfile, userStore } from '../../../store/userStore'
import AssignModal from '../../Common/AssignModal'
import { useApi } from '../../Common/ApiProvider'
import LeadStatus from '../../Common/LeadStatus'

const SearchAllApplicant = ({handleClose}) => {

    const [result, setResult] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const setApplicationId = useViewProfile((state) => state.setAppId)

    const store = userStore()

    const {apiData} = useApi()

    const applicantList = authQueries.useSearchAllApplicant(
        (response)=> {
            setResult(response)
        }
    )

    const navigate = useNavigate()

    const {register, handleSubmit, getValues} = useForm()

    useEffect(() => {
        const data = getValues()
        applicantList.mutateAsync({
            page: currentPage,
            size: 25,
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            refferal: data.refferal
        })
    }, [currentPage, apiData])

    const onSubmit =(data)=>{
        const dataValues = {
            page: 0,
            size: 25,
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            refferal: data.refferal
        }
        applicantList.mutateAsync(dataValues)
    }

    const inputFieldsArray = [
        {
            name: "name",
            label: "Applicant Name",
            required: false,
            size: 2,
            type:"text",
            input: "input"
        },        
        {
            name: "mobile",
            label: "Contact Number",
            required: false,
            size: 2,
            type:"number",
            input: "input"
        },
        {
            name: "email",
            label: "Email",
            required: false,
            size: 2,
            type:"text",
            input: "input"
        },        
    ]

    const handlePagination = async(e, value) => {
        const data = getValues()
        setCurrentPage(value-1)
        const dataValues = {
            page: value-1,
            size: 25,
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            refferal: data.refferal
        }
        applicantList.mutateAsync(dataValues)
    };
    
	const handleChange = async(item) => {
        await setApplicationId(item?.id)
        handleClose()
		navigate('/applicantdetails')
    }
    

  return (
    <div>

        <div className="table-list-search-wrpr overflow-y-auto max-h-[700px] p-4 text-[12px]">

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-12 gap-4 max-[1214px]:grid-cols-10 max-[958px]:grid-cols-8 max-[824px]:grid-cols-6 max-[622px]:grid-cols-4 max-[374px]:grid-cols-2 !w-full mb-4'>
                    {
                        inputFieldsArray?.map((item, index)=>(
                            <div className='col-span-2' key={index}>
                                <label>{item?.label}</label>
                                <input 
                                type={item?.type} 
                                {...register(item?.name)} 
                                placeholder={item.label} 
                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                />
                            </div>
                        ))
                    }
                    <div className='col-span-1 flex flex-col'>
                        <label>&nbsp;</label>
                        <button type="submit" className="btn-customized-large w-[120px] h-[42px] rounded-md text-[14px]">
                            Search
                        </button>
                    </div>  
                </div>
            </form>

            <div className='text-[12px] font-normal'>
                {
                    result?.data?.totalItems > 0 ?
                    `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                    `O results found`
                }
            </div>

            {
                applicantList?.isLoading ?
                <LoaderCommon />:
                <Fragment>
                    {result?.data?.totalItems > 0 &&
                        <div className="table-wrpr mt-[20px]">
                            <div className="table-section">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table prospect-table">
                                            <thead>
                                                <tr className='alt-colors'>
                                                    <th width="30">#</th>
                                                    <th>Profile Status</th>
                                                    <th>Office</th>
                                                    <th>Date Created</th>
                                                    <th>Applicant Name</th>
                                                    <th>Contact Number</th>
                                                    <th>Visa Type</th>
                                                    <th>Intake</th>
                                                    <th>Counselor</th>
                                                    <th>Reg. Date</th>
                                                    <th>Prospect Status</th>
                                                    <th>Reffered By</th>
                                                    <th width='150'>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody className="ui-sortable">
                                                {result?.data?.items?.map((item, index) => (
                                                    <Fragment key={index}>
                                                        <tr>
                                                            <td>{(index+1) + (result.data?.currentPage * 25)}</td>
                                                            <td><LeadStatus item={item} /></td>
                                                            <td>{item?.office?.name}</td>
                                                            <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                            <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                            <td>
                                                                <div className="email-mobile flex flex-col">
                                                                    <div>{item.mobile_no}</div>
                                                                    <div>{item.landline_no}</div>
                                                                    <div>{item.whatsapp_no}</div>
                                                                </div>
                                                            </td>
                                                            <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                            <td>{item?.intake?.intake}</td>
                                                            <td>{item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                                            <td>
                                                                {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span>:'-'}
                                                            </td>
                                                            <td 
                                                            style={{
                                                            color: (item?.pros_status === 2 || item?.pros_status === 3) ? "#FF004D" : item?.pros_status === 17 && "#006769", 
                                                            fontWeight: (item?.pros_status === 2  || item?.pros_status === 3 || item?.pros_status === 17) && 600}}
                                                            >
                                                            {item?.status_of_prospect?.name}
                                                            </td>
                                                            <td>{item?.refferal?.refferal}</td>
                                                            <td className='flex justify-end'>
                                                                {
                                                                    store?.user?.role === "BDM" ?
                                                                    <div className='flex flex-col gap-1'>
                                                                        <div className='w-[140px]'>
                                                                            <FollowUp item={item} />
                                                                        </div>
                                                                        <div className='flex gap-1 justify-between'>
                                                                            <div className='w-[70px]'>
                                                                                <button className='btn-customized' onClick={()=>{handleChange(item)}}>
                                                                                    View Profile
                                                                                </button>
                                                                            </div>
                                                                            <div className='w-[65px]'>
                                                                            <AssignModal
                                                                            item={item}
                                                                            roles={[3]}
                                                                            selectedRole={3}
                                                                            noOffice={true}
                                                                            label={"Reassign"}
                                                                            officeChange={true}
                                                                            />
                                                                        </div>
                                                                        </div>
                                                                    </div>:
                                                                    <div className='flex gap-1'>
                                                                        <div className='w-[120px]'>
                                                                            <FollowUp item={item} statusHidden={true} />
                                                                        </div>
                                                                        <div className='w-[70px]'>
                                                                            <button className='btn-customized' onClick={()=>{handleChange(item)}}>
                                                                                View Profile
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                ))}												  
                                            </tbody>
                                        </table>
                                        {result?.data?.totalPages > 1 &&
                                            <ul className="pagination pagination-sm justify-content-center table-pagination mt-4 mb-3 custom-pagination">
                                                <Pagination
                                                    count={result?.data?.totalPages}
                                                    nextText="Next"
                                                    prevText="Previous"
                                                    renderItem={
                                                        (item) => (
                                                            <div>
                                                            <PaginationItem 
                                                            component="li"
                                                            {...item}
                                                            className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                                                            />
                                                            </div>
                                                        )
                                                    }
                                                    variant="outlined"
                                                    page={result?.data?.currentPage + 1}
                                                    shape="rounded"                
                                                    onChange={handlePagination}
                                                />
                                            </ul>
                                        }                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        result?.data?.totalItems === 0 &&
                        <div className="text-center flex justify-center mt-[5%] mb-[5%]">
                            <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{ height: 80 }}
                            description={
                                <h6>
                                    Sorry! No Leads found.
                                </h6>
                            }
                            >
                            </Empty>
                        </div>
                    }
                </Fragment>
            }

        </div>
                
    </div>
  )
}

export default SearchAllApplicant