import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { useLoaderStore, usePaginationStore } from '../../store/userStore'
import ProspectStatusList from '../Common/ProspectStatusList'
import LeadStatus from '../Common/LeadStatus'
import PaginationComponent from '../Common/PaginationComponent'
import { useApi } from '../Common/ApiProvider'
import FollowUp from '../Common/FollowUp'
import LoaderCommon from '../Common/LoaderCommon'
import { useAdvancedSearch } from '../../store/advancedSearchStore'
import AdvancedSearch from '../Common/AdvancedSearch'
import { IconClearAll } from '@tabler/icons-react'
import { decode } from 'html-entities'
import { Empty } from 'antd'

const InterviewListing = () => {

    const { apiData } = useApi();
    const [result, setResult] = useState([]);

    const data = useAdvancedSearch((state) => state);
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)

    const listApplicants = authQueries.useExecutiveApplicant(
        (response) => {
            setLoader(false)
            setResult(response)
        }
    );

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);

    useEffect(() => {
        listApplicants.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
            type: "interview"
        })
    }, [data, apiData, currentPage])

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

  return (
    <Fragment>
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Clients</h2>
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header flex justify-between">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            My Clients
                        </a>
                        <div>
                            <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                <IconClearAll size={17} />
                                Clear Filters
                            </button>
                        </div>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                            <AdvancedSearch />

                            <div className="table-list-search-wrpr simple-search">
                                <div className="search-results-count-wrpr">
                                    {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                    }
                                </div>

                                {
                                    loader ? 
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Counselor</th>
                                                                <th>Reg. Date</th>
                                                                <th>Prospect Status</th>
                                                                <th>Reffered By</th>
                                                                <th width='150'>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((item, index) => (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <th className="">{(index + 1) + (result.data?.currentPage * 25)}</th>
                                                                        <td>
                                                                            <LeadStatus item={item} />
                                                                        </td>
                                                                        <td>{item?.office?.name}</td>
                                                                        <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                        <td>
                                                                            {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}
                                                                        </td>
                                                                        <td>
                                                                            <div className="email-mobile flex flex-col">
                                                                                <div>{item.mobile_no}</div>
                                                                                <div>{item.landline_no}</div>
                                                                                <div>{item.whatsapp_no}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item?.visatype?.description}</td>
                                                                        <td>{item?.intake?.intake}</td>
                                                                        <td>{item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                                                        <td>
                                                                            {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                        </td>
                                                                        <td><ProspectStatusList item={item} /></td>
                                                                        <td>{item?.refferal?.refferal}</td>
                                                                        <td className='flex justify-end'>
                                                                            <div className='w-[130px]'>
                                                                                <FollowUp item={item} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                        />
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </Fragment>
  )
}

export default InterviewListing