import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import {  Empty, message, Popconfirm } from 'antd';
import { Badge, Collapse, Grid, Pagination, Text } from '@nextui-org/react';
import { decode } from 'html-entities';
import MasterHeader from "../../Common/MasterHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import InputUseForm from "../../Common/InputUseForm";
import * as yup from 'yup'
import { useForm } from "react-hook-form";
import PaginationComponent from "../../Common/PaginationComponent";
import LoaderCommon from "../../Common/LoaderCommon";
import MasterModal from "../../Common/MasterModal";
import AutoCompleteInput from "../../Common/AutoCompleteInput";
import moment from "moment";
import { generateValidationSchema } from "../../Common/validationSchema";

const IntakeList =()=>{

    const [result, setResult] = useState([])
    const [name, setName] = useState('')
    const [visa, setVisa] = useState([])
    const master = userStore();

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const intakeListing = authQueries.useIntakeMutationList(
        (response) => {
            setResult(response)            
        }   
    );

    const visaListing = authQueries.useVisaTypeMutationList(
        (response) => {
            const data = response?.data
            if(response.message === "Success"){
                const arr = []
                data?.items?.map(item => {
                    const newData = {
                        name: item.description,
                        id: item.id
                    }
                    arr.push(newData)
                })
                setVisa(arr)
            }
        }   
    );

    useEffect(() => {
        visaListing.mutateAsync({size: 500000})
    }, []);

    const inputFields = [
        {
            name: "visa_type_id",
            placeholder: "Visa Type",
            label: "Visa Type",
            size: "6",
            type: AutoCompleteInput,
            required: true,
            options: visa,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "intake",
            label: "Intake",
            size: "6",
            min: 2,
            max: 250,
            type: InputUseForm,
            required: true
        },
        {
            name: "intake_date",
            label: "Intake Date",
            size: "6",
            type: InputUseForm,
            required: true,
            iptype: 'date'
        },
    ]
      
    const { register ,reset, handleSubmit, formState:{ errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })
  
    useEffect(()=>{
        intakeListing.mutateAsync({search: name, page:currentPage, size:25});
    },[name, currentPage])


    const deleteIntake = authQueries.useIntakeMutationDelete(
        (response) => {
            intakeListing.mutateAsync({search: '', status: '', page:0, size:25});
            message.success("successfully deleted")
        }
    );
    
    const deleteMaster = (event, id) => {
        event.preventDefault();
        deleteIntake.mutateAsync(id)
    }

    const [visible, setVisible] = useState(false)

    const handleOpen =async(item)=>{
        if(!item){
            await master.setMaster(null)
            setVisible(true)
            return 
        }else{
            reset(item)
            await master.setMaster(item.id)
            setVisible(true)
        }
    }

    const handleClose =async()=>{
        const getvalue = getValues()
        for(const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
              setValue(key, "")
            }
        }
        clearErrors()
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null)
    }

    const createMaster = authQueries.useIntakeMutationAdd(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                message.success("Successfully created intake")
                intakeListing.mutateAsync({search: name, page:currentPage, size:25});
            }
        }   
    );
        
    const updateMaster = authQueries.useIntakeMutationEdit(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                message.success("Successfully updated intake")
                intakeListing.mutateAsync({search: name, page:currentPage, size:25});
            }
        }   
    );

    
    return(
        <div>

            <MasterHeader handleOpen={handleOpen} header="Intake" breadcrumb="Intakes" />
            		
            
            <div className="add_clients" style={{borderBottom:0}}>

                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Intakes</h2>	
                    </div>
                        
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header">
                            Intakes						
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div className="table-search-wrpr">
                                                            <input style={{minWidth: '300px'}} onChange={(e)=>{setName(e.target.value)}} className="form-control form-control-sm" type="text" placeholder="Search Visa Type" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {intakeListing.isLoading ?
                                            <LoaderCommon />:
                                            <div>
                                                {result?.data?.totalItems > 0 ?
                                                <Grid.Container style={{display:'block'}} gap={2}>
                                                    <Grid>
                                                        <Collapse.Group shadow>
                                                        {
                                                            result?.data?.items?.map((item, index) =>(
                                                                <Collapse 
                                                                key={index} 
                                                                title={<Text h6>{item.description}</Text>}
                                                                subtitle={<span style={{fontSize:'12px'}}>{item?.intakes?.length} Intakes</span>}
                                                                >
                                                                    <div className="table-wrpr">
                                                                        <div className="table-section">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <table className="table prospect-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                            <th width="50">#</th>
                                                                                            <th>Intake</th>
                                                                                            <th>Intake Date</th>
                                                                                            <th width="100">&nbsp;</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        {
                                                                                            item?.intakes?.map((items, indexes) =>(
                                                                                                <tbody key={indexes} className="ui-sortable">
                                                                                                    <tr className="ui-sortable-handle">
                                                                                                        <th className="">{indexes + 1}</th>
                                                                                                        <td>{decode(items.intake)}</td>
                                                                                                        <td>{moment(items.intake_date).format('DD-MM-YYYY')}</td>
                                                                                                        <td className="action-icons flex gap-2 justify-end">
                                                                                                            <button onClick={()=>{handleOpen(items)}} className="btn-nextrow mb-0">Update</button>
                                                                                                            <button onClick={(event) => {deleteMaster(event, item.id)}} className="btn-removerow mb-0">Delete</button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            )
                                                                                        )}
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                            ))
                                                        }
                                                        </Collapse.Group>
                                                        <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                    </Grid>
                                                </Grid.Container>:
                                                <div style={{margin:"5%"}}>
                                                    <Empty description={<div className="">No description found!</div>} />
                                                </div>
                                                }
                                            </div>
                                        }

                                        <MasterModal
                                        width="50%"
                                        header="Intake"
                                        visible={visible} 
                                        handleClose={handleClose} 
                                        inputFields={inputFields} 
                                        reset={reset} 
                                        control={control}
                                        handleSubmit={handleSubmit} 
                                        register={register} 
                                        errors={errors} 
                                        getValues={master?.master}
                                        defaultValues={getValues()}
                                        create={createMaster}
                                        update={updateMaster}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default IntakeList