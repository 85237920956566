import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import {  Alert, message, message as messages, Popconfirm } from 'antd';
import PaginationComponent from "../../Common/PaginationComponent";
import MasterModal from "../../Common/MasterModal";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import MasterHeader from "../../Common/MasterHeader";
import LoaderCommon from "../../Common/LoaderCommon";
import InputUseForm from "../../Common/InputUseForm";
import InputTextArea from "../../Common/InputTextArea";
import { generateValidationSchema } from "../../Common/validationSchema";

const OfficeList =()=>{

    const [result, setResult] = useState([]);
    const [name, setName] = useState('')
    const [loader, setLoader] = useState(true)
    const master = userStore();

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const inputFields = [
        {
            name: "name",
            label: "Office Name",
            placeholder:"Office Name",
            size: "4",
            min: 2,
            max: 250,
            type: InputUseForm,
            required: true
        },
        {
            name: "office_location",
            label: "Office Location",
            placeholder:"Office Location",
            size: "4",
            type: InputUseForm,
            required: true
        },
        {
            name: "email",
            label: "Email",
            placeholder:"Email",
            size: "4",
            type: InputUseForm,
            required: true
        },
        {
            name: "phone",
            label: "Phone",
            placeholder:"Phone",
            iptype: 'number',
            size: "4",
            type: InputUseForm,
            required: true
        },       
        {
            name: "mobile",
            label: "Mobile",
            iptype: 'number',
            placeholder:"Mobile",
            size: "4",
            type: InputUseForm,
            required: true
        },     
        {
            name: "fax",
            label: "Fax",
            placeholder:"Fax",
            size: "4",
            type: InputUseForm,
            required: false
        },        
        {
            name: "registration_no",
            label: "Registration Number",
            placeholder:"Registration Number",
            size: "4",
            type: InputUseForm,
            required: false
        },
        {
            name: "corporation_no",
            label: "Corporation Number",
            placeholder:"Corporation Number",
            size: "4",
            type: InputUseForm,
            required: false
        },
        {
            name: "license_no",
            label: "License Number",
            placeholder:"License Number",
            size: "4",
            type: InputUseForm,
            required: false
        },
        {
            name: "address",
            label: "Address",
            placeholder:"Address",
            size: "6",
            type: InputTextArea,
            required: false
        },
    ]
      
    const { register ,reset, handleSubmit, formState:{ errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    const officeListing = authQueries.useOfficeMutationList(
        async(response) => {
            setResult(response)
            setLoader(false)           
        }   
    );

    useEffect(()=>{
        officeListing.mutateAsync({search: name, page:currentPage, size:25, type: "MASTER"});
    },[name, currentPage])

    const deleteOffice = authQueries.useOfficeMutationDelete(
        (response) => {
            officeListing.mutateAsync({search: name, page:currentPage, size:25, type: "MASTER"});
            messages.success("Successfully deleted")
        }
    );

    const deleteMaster = (event, id) => {
        event.preventDefault();
        deleteOffice.mutateAsync(id)
    }

    const [visible, setVisible] = useState(false)

    const handleOpen =async(item)=>{
        console.log(item)
        if(!item){
            await master.setMaster(null)
            setVisible(true)
            return 
        }else{
            reset(item)
            await master.setMaster(item.id)
            setVisible(true)
        }
    }

    const handleClose =async()=>{
        const getvalue = getValues()
        for(const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
              setValue(key, "")
            }
        }
        clearErrors()
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null)
    }

    const createMaster = authQueries.useOfficeMutationAdd(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                message.success("Successfully created office")
                officeListing.mutateAsync({search: name, page:currentPage, size:25, type: "MASTER"});
            }
        }   
    );
        
    const updateMaster = authQueries.useOfficeMutationEdit(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            message.success("Successfully updated office")
            officeListing.mutateAsync({search: name, page:currentPage, size:25, type: "MASTER"});
        }   
    );


    return(
        <div>
            <div className="">	
                <MasterHeader handleOpen={handleOpen} header="Office" breadcrumb="Offices" />			
				<div className="add_clients">
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span> Offices</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
                            <div className="card-header text-[14px]">
                                Offices
                            </div>
		    				<div id="appointment_any" className="collapse show" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-md-12">
                                        
                                            <div className="table-list-search-wrpr">
                                                <div className="row">
                                                    <div className="col-md-6" />
                                                    <div className="col-md-6 text-right">
                                                        <div className="table-search-filter-wrpr">
                                                            <div className="table-search-wrpr">
                                                                <input style={{minWidth: '300px'}} onChange={(e)=>{setName(e.target.value)}} className="form-control form-control-sm" type="text" placeholder="Search Office" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                          
                                            {
                                                loader ?
                                                <LoaderCommon />:
                                                <div className="table-wrpr">
                                                    <div className="table-section">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="table prospect-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="5%">#</th>
                                                                            <th>Office Name</th>
                                                                            <th>Location</th>
                                                                            <th>Email</th>
                                                                            <th>Phone</th>
                                                                            <th>Address</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="ui-sortable">
                                                                        {
                                                                            result?.data?.items?.map((item, index) =>(
                                                                            <tr className="ui-sortable-handle" key={index} >
                                                                                <th className="">{(index + 1) + result?.data?.currentPage * 25}</th>
                                                                                <td>{item?.name}</td>
                                                                                <td>{item?.office_location}</td>
                                                                                <td>{item?.email}</td>
                                                                                <td>{item?.phone}</td>
                                                                                <td>{item?.address}</td>
                                                                                <td className="action-icons flex gap-2 justify-end">                                                     
                                                                                    <button onClick={()=>{handleOpen(item)}} className="btn-nextrow mb-0">Update</button>
                                                                                    <Popconfirm
                                                                                        placement="left"
                                                                                        title="Are you sure to delete this office?"
                                                                                        onConfirm={(event) => {deleteMaster(event, item.id)}}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button className="btn-removerow mb-0">Delete</button>
                                                                                    </Popconfirm>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <MasterModal
                                            width="60%"
                                            header="Office"
                                            visible={visible} 
                                            handleClose={handleClose} 
                                            inputFields={inputFields} 
                                            reset={reset} 
                                            control={control}
                                            handleSubmit={handleSubmit} 
                                            register={register} 
                                            errors={errors} 
                                            getValues={master?.master}
                                            defaultValues={getValues()}
                                            create={createMaster}
                                            update={updateMaster}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
				</div>
			</div>
        </div>
    )
}

export default OfficeList