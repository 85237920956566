import { Modal } from '@nextui-org/react'
import React, { useState } from 'react'
import authQueries from '../../queries/authQueries'
import LoaderCommon from '../Common/LoaderCommon'

const CountryWiseReportModal = () => {

    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(true)
    const [result, setResult] = useState([])

    const closeHandler = () => {
        setOpen(false)
    }

    const openHandler = () => {
        setOpen(true)
        ceoDashboardCountry.mutateAsync()
    }


	const ceoDashboardCountry = authQueries.useCEODashboardCountryMutation(
		(res) => {
            setLoader(false)
            setResult(res)
		}
	)


  return (
    <div>
        <button onClick={openHandler} className='flex items-center gap-[10px]'>
            <span className="text-[13px] font-medium mt-[3px] text-blue-700">Country Wise</span>	
        </button>
        <Modal
            closeButton
            aria-labelledby="modal-title"
            open={open}
            onClose={closeHandler}
            width='90%'
            >
            <Modal.Header>
                <div className="text-[15px] font-semibold">
                    Country Wise Details
                </div>
            </Modal.Header>
            <Modal.Body>

                {ceoDashboardCountry.isLoading ?
                    <LoaderCommon />:
                    <div className="row">
                        <div className="col-md-12">

                            <div 
                            className="table-country-wise-container" 
                            >
                                <table border={1} className="table table-country-wise alt-colors text-[12px]">
                                    <thead className="fixedHeader table-hdr">
                                        <tr className='ui-sortable-handle font-bold tbl-head'>
                                            <th width='4%'>Sno</th>
                                            <th width="16%">Country</th>
                                            <th width="16%">Follow-up required (Before Reg) <br />- excluding associates files</th>
                                            <th width="16%">Follow-up required (Before Reg) <br />- associates files only</th>
                                            <th width="16%">Awaiting Program<br />Confirmation</th>
                                            <th width="16%">On Hold</th>
                                            <th width="16%">Long Term</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-bdy">
                                        <tr className='ui-sortable-handle font-bold tbl-total'>
                                            <td></td>
                                            <td>Grand Total</td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.followupnonassociates, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.followupassociates, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.awaitingconfirmation, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.hold, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.long, 0)}
                                            </td>
                                        </tr>
                                        { 
                                            result?.data?.map((item, index) => {
                                                return (
                                                    <tr key={index} className="ui-sortable-handle font-bold tbl-content">
                                                        <td>{index+1}</td>
                                                        <td>{item?.name}</td>
                                                        <td className='!bg-[#615EFC] text-white text-[18px]'>{item?.followupnonassociates}</td>
                                                        <td className='!bg-[#FF6969] text-white'>{item?.followupassociates}</td>
                                                        <td className='!bg-[#379777] text-white'>{item?.awaitingconfirmation}</td>
                                                        <td className='!bg-[#FFD700] text-white'>{item?.hold}</td>
                                                        <td className='!bg-[#ED9455] text-white'>{item?.long}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        <tr className='ui-sortable-handle font-bold tbl-total'>
                                            <td></td>
                                            <td>Grand Total</td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.followupnonassociates, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.followupassociates, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.awaitingconfirmation, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.hold, 0)}
                                            </td>
                                            <td>
                                                {result?.data?.reduce((a, b) => a + b.long, 0)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default CountryWiseReportModal