import { persist } from "zustand/middleware";
import create from "zustand";

export const useColorScheme = create(
    persist(
      (set) => ({
        bgColor: '#323a51',
        activeTextColor: '#FC97A4',
        assignBgSelected: '#000',
        assignBgNonSelected: '#323a51',
        fontFamily: 'Poppins',
        setBg: (bg) => set({ bgColor: bg }),
        setActiveTextColor: (color) => set({ activeTextColor: color }),
        setAssignBgSelected: (color) => set({ assignBgSelected: color }),
        setAssignBgNonSelected: (color) => set({ assignBgNonSelected: color }),
        setFontFamily: (font) => set({ fontFamily: font }),
      }),
      { name: "colorScheme", getStorage: () => localStorage }
))