import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { usePaginationStore, userStore } from '../../../store/userStore';
import {  Empty, message as messages, Popconfirm, Button as Buttons, message } from 'antd';
import { Button, Checkbox, Collapse, Grid, Switch, Text } from '@nextui-org/react';
import { decode } from 'html-entities';
import MasterHeader from "../../Common/MasterHeader";
import MasterModal from "../../Common/MasterModal";
import { useForm } from "react-hook-form";
import InputUseForm from "../../Common/InputUseForm";
import { yupResolver } from "@hookform/resolvers/yup";
import PaginationComponent from "../../Common/PaginationComponent";
import AutoCompleteInput from "../../Common/AutoCompleteInput";
import LoaderCommon from "../../Common/LoaderCommon";
import { generateValidationSchema } from "../../Common/validationSchema";

const CollegeList =()=>{

    const [result, setResult] = useState([])
    const [name, setName] = useState('')
    const [country, setCountry] = useState("")
    const [campus, setCampus] = useState(false)
    const [campusName, setCampusName] = useState("")
    const [visible, setVisible] = useState(false)
    const navigate = useNavigate()
    const master = userStore();
    const [edit, setEdit] = useState("")
    const [campusNameEdit, setCampusNameEdit] = useState("")

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const collegeList = authQueries.useCollegeMutationList(
        (response) => {
            setResult(response)
        }   
    ); 

    const countryList = authQueries.useCountryMutationList(
        (response) => {
        }   
    ); 

    const inputFields = [
        {
            name: "country_id",
            placeholder: "Select or Enter Country",
            label: "Country",
            size: "6",
            type: AutoCompleteInput,
            required: true,
            options: countryList?.data?.data?.items,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "name",
            label: "College Name",
            size: "6",
            min: 2,
            max: 250,
            type: InputUseForm,
            required: true
        },
    ]


    const { register ,reset, handleSubmit, formState:{ errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    useEffect(()=>{
        collegeList.mutateAsync({page: currentPage, size:25, campus: campus, search:name, country: country});
    },[name, campus, country, currentPage])

    useEffect(()=>{
        countryList.mutateAsync({page:0, size: 10000})
    },[])

    const deleteCollege = authQueries.useCollegeMutationDelete(
        (response) => {
            collegeList.mutateAsync({search: name, page:currentPage, size:25, campus: campus, country: country});
            messages.success("successfully deleted")
        }
    );

    const deleteCollegeData = (event, id) => {
        event.preventDefault();
        deleteCollege.mutateAsync(id)
    }

    const handleCampus=(e)=> {
        setCampus(e.target.checked)
    }

    const addNewCampus =(id)=> {
        const dataValues ={
            cid: id,
            name: campusName
        }
        AddCampusData.mutateAsync(dataValues)
    }

    const AddCampusData = authQueries.useCampusMutationAdd(
        (response) => {
            if(response?.message === "Success"){
                setCampusName("")
                collegeList.mutateAsync({page: currentPage, size:25, campus: campus, search:name, country: country});
            }
        }
    );

    const deleteCampus = authQueries.useCampusMutationDelete(
        (response) => {
            collegeList.mutateAsync({search: name, page:currentPage, size:25, campus: campus, country: country});
            messages.success("successfully deleted campus")
        }
    );

    const deleteCampusData = (event, id) => {
        event.preventDefault();
        deleteCampus.mutateAsync(id)
    }

    const editCampus =(item)=> {
        setEdit(item?.id)
        setCampusNameEdit(decode(item?.name))
    }

    const updateCampus =(item)=> {
        const dataValues = {
            id: item?.id,
            name: campusNameEdit
        }
        updatingCampus.mutateAsync(dataValues)
    }

    const updatingCampus = authQueries.useCampusMutationEdit(
        async (res)=> {
            await collegeList.mutateAsync({search: name, page:currentPage, size:25, campus: campus, country: country});
            messages.success('Successfully updating campus')
            setEdit("")
            setCampusNameEdit("")
        }
    )

    const handleOpen =async(item)=>{
        if(!item){
            await master.setMaster(null)
            setVisible(true)
            return 
        }else{
            reset(item)
            await master.setMaster(item.id)
            setVisible(true)
        }
    }

    const handleClose =async()=>{
        const getvalue = getValues()
        for(const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
              setValue(key, "")
            }
        }
        clearErrors()
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null)
    }

    const createMaster = authQueries.useCollegeMutationAdd(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                message.success("Successfully created program")
                collegeList.mutateAsync({search: name, page:currentPage, size:25, campus: campus, country: country});
            }
        }   
    );
        
    const updateMaster = authQueries.useCollegeMutationEdit(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                message.success("Successfully updated program")
                collegeList.mutateAsync({search: name, page:currentPage, size:25, campus: campus, country: country});
            }
        }   
    );


    return(
        <div>

            <MasterHeader handleOpen={handleOpen} header="College" breadcrumb="Colleges" />

            <div className="add_clients">
            
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Colleges</h2>	
                    </div>
                        
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                

                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header">
                            Colleges		
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row mb-3">
                                                <div className="col-md-12" style={{display:'flex', justifyContent:'space-between'}}>
                                                    <div style={{display:'flex', gap: 12, alignItems:'center', marginTop:'5px', fontSize:'12px'}}>
                                                        <Switch checked={campus} onChange={handleCampus} />
                                                        <span>Only show campus-included colleges</span>
                                                    </div>
                                                    <div style={{display:'flex', gap:'10px'}}>
                                                        <input style={{minWidth: '200px'}} onChange={(e)=>{setName(e.target.value)}} className="form-control form-control-sm" type="text" placeholder="Search College" />
                                                        <select style={{minWidth: '200px'}} onChange={(e)=>setCountry(e.target.value)} className="form-control form-control-sm" type="text" placeholder="Search College">
                                                            <option value="">Select Country</option>
                                                            {
                                                                countryList?.data?.data?.items?.map((item, index) => (
                                                                    <option key={index} value={item?.id}>{item?.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            collegeList?.isLoading ?
                                            <LoaderCommon /> :                                          
                                            (result?.data?.totalItems > 0 ?
                                            <Grid.Container style={{display:'block'}} gap={2}>
                                                <Grid>
                                                    <Collapse.Group shadow>
                                                    {
                                                        result?.data?.items?.map((item, index) =>(
                                                            <Collapse 
                                                                key={index}
                                                                title={<Text h6>{item?.name}</Text>}
                                                                subtitle={
                                                                <div>
                                                                    <div style={{fontSize:'12px'}}>
                                                                        <div style={{marginTop:'5px'}}>{item?.country?.name} {item?.country?.icon}</div>
                                                                        <div>{item?.campuses?.length} Campus</div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            >
                                                                {master?.user?.role == "ADMIN" &&
                                                                    <div className="mb-3" style={{display:'flex', justifyContent:'space-between'}}>
                                                                        <div style={{display:'flex', gap: "5px"}}>
                                                                            <input value={campusName} onChange={(e)=> {setCampusName(e.target.value)}} style={{minWidth: '300px'}} className="form-control form-control-sm" type="text" placeholder="Enter Campus" />
                                                                            <button
                                                                                onClick={()=> {addNewCampus(item?.id)}}
                                                                                style={{
                                                                                minHeight:'31px', 
                                                                                height:'31px', 
                                                                                border:'none', 
                                                                                background:'#323a51', 
                                                                                color:'#fff', 
                                                                                fontSize:'12px',
                                                                                paddingLeft:"10px",
                                                                                paddingRight:"10px",
                                                                                borderRadius: "5px"
                                                                            }}>Add New Campus</button>
                                                                        </div>  
                                                                        <div style={{display:'flex', gap: "5px"}}>
                                                                            <button
                                                                            onClick={()=>{handleOpen(item)}}
                                                                            style={{
                                                                                display:'flex',
                                                                                gap: "5px",
                                                                                alignItems:'center',
                                                                                minHeight:'31px', 
                                                                                height:'31px', 
                                                                                border:'none', 
                                                                                background:'#323a51', 
                                                                                color:'#fff', 
                                                                                fontSize:'12px',
                                                                                paddingLeft:"10px",
                                                                                paddingRight:"10px",
                                                                                borderRadius: "5px"
                                                                            }}><i className="icon-pencil3"></i> Edit College</button>
                                                                            <Popconfirm
                                                                                    placement="left"
                                                                                    title="Are you sure to delete this college?"
                                                                                    onConfirm={(event) => {deleteCollegeData(event, item.id)}}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                            > 
                                                                                <button 
                                                                                style={{
                                                                                    display:'flex',
                                                                                    gap: "5px",
                                                                                    alignItems:'center',
                                                                                    minHeight:'31px', 
                                                                                    height:'31px', 
                                                                                    border:'none', 
                                                                                    background:'#dc3545', 
                                                                                    color:'#fff', 
                                                                                    fontSize:'12px',
                                                                                    paddingLeft:"10px",
                                                                                    paddingRight:"10px",
                                                                                    borderRadius: "5px"
                                                                                }}><i className="icon-trashcan"></i>Delete College</button>
                                                                            </Popconfirm>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {item?.campuses?.length > 0 ?
                                                                    <div className="table-wrpr">
                                                                        <div className="table-section">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <table className="table prospect-table">
                                                                                        <thead className="text-[12px]">
                                                                                            <tr>
                                                                                            <th width="50">#</th>
                                                                                            <th>Campus Name</th>
                                                                                            {
                                                                                                master?.user?.role == "ADMIN" &&
                                                                                                <th width="300">&nbsp;</th>
                                                                                            }
                                                                                            </tr>
                                                                                        </thead>
                                                                                        {
                                                                                            item?.campuses?.map((items, indexes) =>(
                                                                                            <tbody key={indexes} className="ui-sortable text-[12px]">
                                                                                                <tr className="ui-sortable-handle">
                                                                                                <th className="">{indexes + 1}</th>
                                                                                                <td>
                                                                                                    {
                                                                                                        edit === items?.id ?
                                                                                                        <input value={campusNameEdit} onChange={(e)=> {setCampusNameEdit(e.target.value)}} style={{minWidth: '300px'}} className="form-control form-control-sm" type="text" placeholder="Enter Campus" />:
                                                                                                        <div>{decode(items?.name)}</div>
                                                                                                    }
                                                                                                </td>
                                                                                                    <td className="action-icons flex gap-2 justify-end">
                                                                                                        <div style={{display:'flex', gap: "5px"}}>
                                                                                                            <button 
                                                                                                            onClick={()=>{edit === items?.id ? updateCampus(items):editCampus(items)}}
                                                                                                            className="btn-nextrow mb-0 min-w-[90px] w-[90px]"
                                                                                                            >
                                                                                                            <span>{edit === items?.id ? 'Update Campus' : "Edit Campus"}</span>
                                                                                                            </button>
                                                                                                            <Popconfirm
                                                                                                            placement="left"
                                                                                                            title="Are you sure to delete this college?"
                                                                                                            onConfirm={(event) => {deleteCampusData(event, items?.id)}}
                                                                                                            okText="Yes"
                                                                                                            cancelText="No"
                                                                                                            >
                                                                                                            <button className="btn-removerow mb-0 min-w-[100px] w-[100px]">Delete Campus</button>
                                                                                                            </Popconfirm>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                            ))
                                                                                        }
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>: 
                                                                    <div style={{display:'flex', alignItems:'center', flexDirection:'column', marginBottom:'20px'}}>
                                                                        <Empty description={<div className="">No Campus found!</div>} />
                                                                    </div>
                                                                }
                                                            </Collapse>
                                                    ))}
                                                    </Collapse.Group>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </Grid>
                                            </Grid.Container>:
                                            <div style={{margin:"5%"}}>
                                                <Empty description={<div className="">No data found!</div>} />
                                            </div>)
                                        }   

                                            <MasterModal
                                            width="40%"
                                            header="College"
                                            visible={visible} 
                                            handleClose={handleClose} 
                                            inputFields={inputFields} 
                                            reset={reset}
                                            control={control}
                                            handleSubmit={handleSubmit}
                                            register={register}
                                            errors={errors}
                                            getValues={master?.master}
                                            defaultValues={getValues()}
                                            create={createMaster}
                                            update={updateMaster}
                                            />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollegeList