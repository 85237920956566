import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../../store/userStore";
import { AutoCompleteAntd, AutoCompleteChild } from "../../Clients/AddClients/AutoComplete";
import { Checkbox } from "antd";

const VisaTypeEdit =()=>{

    const master = userStore();

    const validationSchema = yup.object({
        visacode: yup
            .string('⚠ Enter your Visa Code')
            .required('⚠ Visa Code is required')
            .min(2, '⚠ Visa Code should be of Minimum of 2 characters length'),
        country_id: yup
            .string('⚠ Select Country')
            .required('⚠ Country is required'),
        description: yup
            .string('⚠ Enter your Description')
            .required('⚠ Description is required'),
        category: yup
            .string('⚠ Select Category')
            .required('⚠ Category Code is required'),
        isStuVisa: yup
            .string('⚠ Select Student Visa')
            .required('⚠ Student Visa is required'),
        canadaOffice: yup
            .string('⚠ Enter your Canada Office')
            .required('⚠ Canada Office is required'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');
    const [users, setUsers] = useState({fee: '', doc: '', reg: '', loan: '', interview: '', travel: '', gic: ''})

    const navigate = useNavigate();

    useEffect(()=>{
        startData();
    },[])

    const startData = async() => {
        await countryData.mutateAsync({search: '', status: '', page:0, size:500});
        await visaTypeData.mutateAsync(master.visatype)
    }

    const visaTypeData = authQueries.useVisaTypeMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    visacode:response?.data.visacode,
                    country_id:response?.data.country_id,
                    category:response?.data.category,
                    description:response?.data.description,
                    isStuVisa:response?.data.IsStudentVisa,
                    canadaOffice:response?.data.CanadaOffice,
                    display_order:response?.data.display_order,
                    status:response?.data.status
                })
                reset({ ...datas });
                setUsers({
                    fee: response?.data.fee, 
                    doc: response?.data.doc, 
                    reg: response?.data.reg, 
                    loan: response?.data.loan, 
                    interview: response?.data.interview,
                    travel: response?.data.travel,
                    gic: response?.data.gic, 
                })
            }
        }   
    );

    const countryData = authQueries.useCountryMutationList(
        (response) => {
            setResult(response)
            let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setResult(dataArray)             
        }   
    );

    const { register, reset, control, handleSubmit, formState:{ errors }} = useForm({
        resolver: yupResolver(validationSchema)
    });


    const addVisaType = authQueries.useVisaTypeMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/visatype')
            }else if(response?.error[0].visacode){
                setErrName("⚠ "+response?.error[0].visacode)
            }
        }   
    );
        
    
    const onSubmit = (data) => {
        console.log(data)
        const datavalues = {
            id:master.visatype,
            visacode: data.visacode,
            country_id:data.country_id,
            category:data.category,
            IsStudentVisa:data.isStuVisa,
            CanadaOffice:data.canadaOffice,
            description:data.description,
            display_order:data.display_order,
            status:data.status,
            fee: users.fee,
            doc: users.doc,
            reg: users.reg,
            loan: users.loan,
            interview: users.interview,
            travel: users.travel,
            gic: users.gic,
        };
        
        addVisaType.mutateAsync(datavalues);
    }
    

    
    return(
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/visatype">Visa Type List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Visa Type</li>
                </ol>
            </nav>

            <div className="add_clients">
                <div className="card">
                        <div className="card-header">
                            Edit Visa Type
                        </div>
                        <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group"> 
                                            <div id="parent" className="form-group">
                                            <label htmlFor="">Country :</label>

                                            <AutoCompleteAntd
                                                control={control} 
                                                data={result} 
                                                name={"country_id"} 
                                                value={visaTypeData?.data?.data?.country_id}
                                            />
                                            <div className='error-msg'>{errors.country_id?.message}</div>
                                            </div>
                                            <div id="output"></div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                                <div id="parent" className="form-group">
                                                    <label >Visa Code</label>
                                                    <input 
                                                        {...register("visacode")}
                                                        type="text" 
                                                        className="form-control"  
                                                        name="visacode"/>
                                                    <p className='error'>{errors.visacode?.message}{errName}</p>
                                                </div>
                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Is Student Visa</label>
                                                    <select  {...register("isStuVisa")}  className="form-control"  name="isStuVisa">
                                                        <option value="">Select</option>
                                                        <option value="YES">Yes</option>
                                                        <option value="NO">No</option>
                                                    </select>       
                                                    {/* <p className='error'>{errors.status?.message}</p> */}
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Canada Office</label>
                                                    <select  {...register("canadaOffice")}  className="form-control"  name="canadaOffice">
                                                        <option value="">Select</option>
                                                        <option value="YES">Yes</option>
                                                        <option value="NO">No</option>
                                                    </select>       
                                                    {/* <p className='error'>{errors.status?.message}</p> */}
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Category</label>
                                                    <select  {...register("category")}  className="form-control"  name="category">
                                                        <option value="">Select</option>
                                                        <option value="Immigration">Immigration</option>
                                                        <option value="StudentVisa">Student Visa</option>
                                                    </select>       
                                                    <div className='error-msg'>{errors.category?.message}</div>
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>   
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Description</label>
                                                    <textarea {...register("description")} name="description" className="form-control"></textarea>
                                                    <div className='error-msg'>{errors.description?.message}</div>
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div> 

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <Checkbox checked={users?.fee === 'YES' ?true: false} onChange={(e) => setUsers({...users, fee: e.target.checked ? 'YES' : 'NO'}) }>
                                                Fee Coordinator
                                            </Checkbox>   
                                            <Checkbox checked={users?.doc === 'YES' ?true: false} onChange={(e) => setUsers({...users, doc: e.target.checked ? 'YES' : 'NO'}) }>
                                                Document Staff
                                            </Checkbox>   
                                            <Checkbox checked={users?.reg === 'YES' ?true: false} onChange={(e) => setUsers({...users, reg: e.target.checked ? 'YES' : 'NO'}) }>
                                                Mark Registration
                                            </Checkbox>     
                                            <Checkbox checked={users?.loan === 'YES' ?true: false} onChange={(e) => setUsers({...users, loan: e.target.checked ? 'YES' : 'NO'}) }>
                                                Loan Executive
                                            </Checkbox>     
                                            <Checkbox checked={users?.travel === 'YES' ?true: false} onChange={(e) => setUsers({...users, travel: e.target.checked ? 'YES' : 'NO'}) }>
                                                Travel Executive
                                            </Checkbox>     
                                            <Checkbox checked={users?.gic === 'YES' ?true: false} onChange={(e) => setUsers({...users, gic: e.target.checked ? 'YES' : 'NO'}) }>
                                                GIC Executive
                                            </Checkbox>     
                                            <Checkbox checked={users?.interview === 'YES' ?true: false} onChange={(e) => setUsers({...users, interview: e.target.checked ? 'YES' : 'NO'}) }>
                                                Interview Trainer
                                            </Checkbox>     
                                            
                                        </div>
                                    </div>                                               
                                    
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btn btn-default btn-save">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default VisaTypeEdit