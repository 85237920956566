import React, { Fragment, useEffect, useState } from 'react'
import { useViewProfile, userStore } from '../../store/userStore'
import authQueries from '../../queries/authQueries'
import { Avatar, Drawer, Popover } from 'antd'
import moment from 'moment'
import { Loading } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../../Constants/RouteConstans'
import FollowUp from './FollowUp'
import { useExistModal } from '../../store/existModalStore'
import { IconExclamationCircle } from '@tabler/icons-react'

const NotificationDrawer = ({ open, setOpen }) => {

	const notificationData = userStore()

	const important = useExistModal((state) => state.important)

	const [limit, setLimit] = useState(10)
	const onClose = () => {
		setOpen(false);
		setLimit(10)
	};

	const readAll = () => {
		notificationData.notificationData?.data?.items?.map((item, index) => {
			item.status = "READ"
		})
		notificationData.setNotification(notificationData.notificationData)
		readAllNotification.mutateAsync()
	}

	const readAllNotification = authQueries.useNotificationReadAllMutation(
		(response) => {
		}
	)

	return (
		<Drawer
			title={<div style={{fontFamily: "var(--primary-font)"}}>Notifications</div>}
			placement='right'
			width={600}
			onClose={onClose}
			open={open}
			extra={
				<button
				onClick={readAll}
				style={{
					background:'#28a745', 
					border:'none', 
					padding: "5px", 
					paddingLeft:"20px", 
					paddingRight:"20px", 
					color:'#fff', 
					borderRadius: "5px",
					fontFamily: "var(--primary-font)",
					fontSize:'14px',
					fontWeight: 400

				}}
				>Read All</button>
			}
				
		>
			<NotificationComponent open={open} limit={limit} setLimit={setLimit} onClose={onClose} important={important} />
		</Drawer>
	)
}

export default NotificationDrawer

const NotificationComponent = ({ open, limit, setLimit, onClose, important }) => {

	const notificationData = userStore()
	const navigate = useNavigate()

	const [notiType, setNotiType] = useState('')

	useEffect(() => {
		if(open){
			notificationList.mutateAsync({ type: notiType, page: 0, size: limit, important: important });
		}
	}, [notiType, limit, open])

	const notificationList = authQueries.useNotificationList(
		async (response) => {
			await notificationData.setNotification(response)
		}
	);

	const readMore = () => {
		setLimit(limit + 10)
		navigate(RouteConstants.NOTIFICATIONS)
		onClose()
	}

	return (
		<div>
			<div className='tab-bar notification' style={{ marginTop: "10px", marginBottom: '20px' }} />
			<div className='notification-list'>
				{
					notificationList.isLoading ?
					<div style={{ textAlign: 'center' }}>
						<Loading />
					</div>
					:
					<TabContents onClose={onClose} />
				}
			</div>
			{notificationData?.notificationData?.data?.totalPages > 1 &&
				<div 
				style={{ textAlign: 'right', cursor: 'pointer' }}
				onClick={readMore} 
				className='notification-readmore notification-item-content-time'>
					Read More
				</div>
			}
		</div>
	)
}


const TabContents = ({onClose}) => {

	const notificationData = userStore()

	const setApplicationId = useViewProfile((state) => state.setAppId)
    const navigate = useNavigate()
	
	const readNotification = (id) => {
		const clickItem = notificationData.notificationData?.data?.items?.find(item => item.id === id)
		if (clickItem) {
			clickItem.status = "READ"
			notificationData.setNotification(notificationData.notificationData)
			readAsNotification.mutateAsync(id)
		}
	}

	const readAsNotification = authQueries.useNotificationReadMutation(
		(response) => {
		}
	)

	return (
		<Fragment>
			{notificationData.notificationData?.data?.items?.map((item, index) => (
				<div
				className="notification-item"
				key={index}
				style={{ 
					marginBottom: 10, 
					background: item?.visaapproved === "YES" ? '#9DBDFF' : item?.status === "NEW" ? '#C7F270' : '#f0f0f0' 
				}}
				>
					<div className="notification-item-content-inner flex gap-4">
						<div className='flex items-center'>
							<Avatar src={item?.send?.userdetail?.image ? item?.send?.userdetail?.image : `url(${require('../../assets/images/profile-avatar.jpg')})`} />
						</div>
						<div className='w-full'>
							<div className="notification-item-content-title">
								<div className='row relative'>
									<div className='col-md-12' style={{ display: 'flex', alignItems: 'flex-start' }}>
										<div className=' text-[13px] font-semibold' style={{fontFamily: "var(--primary-font)"}}>{item?.title}</div> {item?.status === "NEW" && <span style={{ marginLeft: '10px', fontFamily: "var(--primary-font)" }} className='badge badge-success'>New</span>}
									</div>
										{
											item?.status === "NEW" &&
											<div
												style={{ cursor: 'pointer' }}
												className='notification-item-close'
												onClick={() => readNotification(item?.id)}
											>
												<i className="icon-close"></i>
											</div>
										}
								</div>
							</div>
							<div className="notification-item-content-description">
								{item?.description?.length > 90 ? item?.description?.substring(0, 90) + "..." : item?.description}
							</div>
							<div className="notification-item-content-time">
								<div className='row'>
									<div className='col-md-12 text-[10px] font-medium'>
										{
											moment(item?.createdAt).format('DD MMM YYYY hh:mm') === moment().format('DD MMM YYYY hh:mm') ?
											"Just Now" :
											moment(item?.createdAt).format('DD MMM YYYY') === moment().format('DD MMM YYYY') ?
												"Today" :
												moment(item?.createdAt).format('DD MMM YYYY') === moment().subtract(1, 'days').format('DD MMM YYYY') ?
													"Yesterday" :
													moment(item?.createdAt).format('DD MMM YYYY') < moment().format('DD MMM YYYY') ?
														moment(item?.createdAt).format('DD MMM YYYY') :
														moment(item?.createdAt).format('hh:mm A')
										}
									</div>
									<div className='col-md-12 flex justify-end'>
										<NotificationPopover item={item} onClose={onClose} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</Fragment>
	)
}


const NotificationPopover = ({ item, onClose }) => {

	const [open, setOpen] = useState(false);

	const setAppid = useExistModal((state) => state.setAppid)
	const setExistOpen = useExistModal((state) => state.setOpen)

	const hide = () => {
	  setOpen(false);
	};

	const handleOpenChange = (newOpen) => {
	  setOpen(newOpen);
	};

	const handleDetails =()=>{
		setExistOpen(true)
		hide()
		onClose()
		setAppid(item?.application?.id)
	}

	return(
		<Popover
		open={open}
		trigger="click"
		onOpenChange={handleOpenChange}
		content={
			<div className='w-[400px]'>
				<div>
					<span style={{ fontWeight: 'bold' }}>Title: </span>
					<span>{item?.title}</span>
				</div>
				<div>
					<span style={{ fontWeight: 'bold' }}>Description: </span>
					<span>{item?.description}</span>
				</div>
				{item?.applicantId &&
				<Fragment>
					<div>
						<span style={{ fontWeight: 'bold' }}>Applicant Name: </span>
						<span>{item?.application?.applicant_name} {item?.application?.middlename} {item?.application?.lastname}</span>
					</div>
					<div>
						<span style={{ fontWeight: 'bold' }}>Mobile No: </span>
						<span>{item?.application?.mobile_no}</span>
					</div>
					<div className='w-full flex justify-end'>
						{
							item?.exist_notification === "YES" ?
							<button className='text-[12px]' onClick={handleDetails}>
								<span className='cursor-pointer'>View Details</span>
							</button>:
							<div className='w-[130px]'>
								<FollowUp item={item?.application} closePopover={hide} />
							</div>
						}
					</div>
				</Fragment>
				}
			</div>
		}>
			<span className='cursor-pointer'>View Details</span>
		</Popover>
	)
}