import React, { Fragment, useRef } from 'react';

const EmailInput = ({ appStore, register, emailValidation, visaid, setEmail }) => {

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedValidation = useRef(debounce((e, visaid) => {
    emailValidation({ email: e.target.value, visa: visaid });
  }, 500)).current;

  const onChange = (e) => {
    setEmail(e.target.value);
    debouncedValidation(e, visaid);
  };

  return (
    <Fragment>
      <input
        disabled={(appStore?.user?.role === "REGISTRATION" || appStore?.user?.role === "ADMISSIONSTAFF" || appStore?.user?.role === "VISASTAFF")}
        {...register('email', {
          onChange: onChange,
          onBlur: () => {}
        })}
        id="email"
        type="email"
        className="form-control"
        name="email"
      />
    </Fragment>
  );
};

export default EmailInput;
