import React, { Fragment } from 'react'
import { useViewProfile, userStore } from '../../store/userStore'
import { useNavigate } from 'react-router-dom'

const ApplicantViewButton = ({item, closable, setOpen}) => {

    const setApplicationId = useViewProfile((state) => state.setAppId)
    const navigate = useNavigate()
    
	const handleChange = async() => {
		setApplicationId(item?.id)
		navigate('/applicantdetails')
    if(closable){
      setOpen(false)
    }
  }

  return (
    <Fragment>
        <button 
        onClick={handleChange} 
        className='btn-customized'
        >
        View
        </button>
    </Fragment>
  )
}

export default ApplicantViewButton
