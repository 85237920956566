import React,{useState,useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Controller, Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../../queries/authQueries';
import { Select } from 'antd';
import { MultipleSelect } from '../../Common/MultupleAutoComplete';



const  AddProspect = () => {


    const validationSchema = yup.object({
        name: yup
            .string('⚠ Please Select ')
            .required('⚠ Name is required'),   
        visa: yup
            .array()
            .test('len', '⚠ Visa Type is required', (val) => val?.length > 0)
            .required('⚠ Visa Type is required'),
        roles: yup
            .array()
            .test('len', '⚠ Role is required', (val) => val?.length > 0)
            .required('⚠ Roles is required'),
    });





    const [errName, setErrName] = useState('')

    const [result, setResult] = useState([]);

    const [visaResult, setVisaResult] = useState([]);

    const [roleResult, setRoleResult] = useState([]);

    const navigate = useNavigate();

    const { register, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
    });

    useEffect(()=>{
        VisaList.mutateAsync({search: '', status: '', page:0, size:500});
        RoleList.mutateAsync({search: '', status: '', page:0, size:500});
    },[])

    const VisaList = authQueries.useVisaTypeMutationList(
        (response) => {

            let visaArray = [];
            response?.data?.items?.map((item) => { 
                
                let visa = {
                    value: item.id,
                    label: item.description
                }

                visaArray = [...visaArray, visa]  
            });

            setVisaResult(visaArray);


        }   
    );

    const RoleList = authQueries.useRoleMutationList(
        (response) => {

            let roleArray = [];
            response?.data?.items?.map((item) => { 
                
                let visa = {
                    value: item.id,
                    label: item.label
                }

                roleArray = [...roleArray, visa]  
            });

            setRoleResult(roleArray);


        }   
    );




    const AddProspectdata = 

    authQueries.useProspectAdd(

        (response) => {

            if(response?.message === "Success"){
                navigate('/listprospect')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }

        }
    );


    const onSubmit = (data) => {

    
        const datavalues = {
            visa: data.visa,
            name: data.name,
            status: "ACTIVE",
            role: data.roles
    
        };
    
        AddProspectdata.mutateAsync(datavalues);    
    
      }


  return (

    <div>

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/listprospect">Status of Prospects</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Add Status of Prospects</li>
            </ol>
        </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Add Status of Prospects
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-4">
                                            <div className='form-group'>
                                                <div id='parent' className='form-group'>
                                                    <label htmlFor="">Status Name</label>
                                                    <input className="form-control"  {...register("name")} type="text" name='name' />
                                                    <div className='error-msg'>{errors.name?.message} {errName}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <div id='parent' className='form-group'>
                                                    <label htmlFor="">Visa Type</label>

                                                    <MultipleSelect options={visaResult} name="visa" control={control} />

                                                    <div className='error-msg'>{errors.visa?.message}</div>
                                                </div>
                                            </div>
                                        </div> 
                                        
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <div id='parent' className='form-group'>
                                                    <label htmlFor="">Roles</label>
                                                        <MultipleSelect options={roleResult} name="roles" control={control} />
                                                    <div className='error-msg'>{errors.roles?.message}</div>
                                                </div>
                                            </div>
                                        </div>     
                                    </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

    </div>

  )

}

export default AddProspect