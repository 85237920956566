import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApplicationFee, useLoaderStore, usePaginationStore } from '../../store/userStore'
import { useAdvancedSearch } from '../../store/advancedSearchStore'
import { useApi } from '../Common/ApiProvider'
import authQueries from '../../queries/authQueries'
import RouteConstants from '../../Constants/RouteConstans'
import toast from 'react-hot-toast'
import LeadStatus from '../Common/LeadStatus'
import ProspectStatusList from '../Common/ProspectStatusList'
import moment from 'moment'
import AdvancedSearch from '../Common/AdvancedSearch'
import { IconClearAll, IconClipboardList } from '@tabler/icons-react'
import ApplicationStatusModal from '../Common/ApplicationStatusModal'
import PaginationComponent from '../Common/PaginationComponent'
import { Empty } from 'antd'
import LoaderCommon from '../Common/LoaderCommon'
import ConfirmProgram from '../Common/ConfirmProgram'
import FollowUp from '../Common/FollowUp'
import ApplicantProgramConfirmation from '../Common/ApplicantProgramConfirmation'
import ApplicantViewButton from '../Common/ApplicantViewButton'
import RejectProgram from '../Common/RejectProgram'

const ProgramCoordinatorMyClient = () => {


    const navigate = useNavigate()

    const [result, setResult] = useState([])
    const [active, setActive] = useState({ index: null, status: false })
    const loader = useLoaderStore((state) => state.loader)
    const setLoader = useLoaderStore((state) => state.setLoader)
    const setProgram = useApplicationFee((state) => state.setProgramId)
    const currentPage = usePaginationStore((state) => state.currentPage)
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage)
	const [gicEnable, setGicEnable] = useState(null)
    const data = useAdvancedSearch((state) => state);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)
    const { apiData, setApiDataAndNotify } = useApi()

    const appStatusList = authQueries.useAppStaffStatusMutationList(
        (res)=>{
        }
    )

    const listApplicants = authQueries.useApplicantAssignProgramMutationList(
        (response) => {
            setResult(response)
            setLoader(false)
        }   
    )

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);

    useEffect(() => {
        appStatusList.mutateAsync({page: 0, size: 10000})
    }, [])

    useEffect(() => {
        listApplicants.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
        })
    }, [data, apiData, currentPage])

    const gicAssign = authQueries.useGicAssignMutation(
		async (res)=> {
            toast.success('GIC Assign Successfully');
			setApiDataAndNotify(res)
			setGicEnable(null)
		}
	)

	const handleGIC =(id)=> {
		gicAssign.mutateAsync(id)
	}

    const expantButton =(index)=> {
        if(active.index === index){
            setActive({
                index: null,
                status: false
            })
        }else{
            setActive({
                index: index,
                status: true
            })
        }
    }

	const counselorName =(item)=> {
		const name = item?.firstname +" "+ item?.lastname 
		return name
	}

	const handleAdmissionFee =(id)=> {
		navigate(RouteConstants.ADMISSIONFEEORM)
		setProgram(id)
	}

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

  return (
    <Fragment>
                <div>
            
            <div className="col-md-12 flex items-center gap-2 p-0">
                <i className="followup-icon">
                    <IconClipboardList size="30" className='text-[#7D6FAB]' />
                </i>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                        <span className="text-[16px] font-medium">My Clients</span>	
                    </div>
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form mt-4">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header flex justify-between">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            My Clients
                        </a>
                        <div>
                            <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                <IconClearAll size={17} />
                                Clear Filters
                            </button>
                        </div>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                            <AdvancedSearch />

                            <div className="table-list-search-wrpr simple-search">

                                <div className="search-results-count-wrpr">
                                    {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                    }
                                </div>

                                {loader? 
                                    <LoaderCommon />:	
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div>
                                                        <table className="table prospect-table">
                                                            <thead>
                                                                <tr>
                                                                    <th width="30">#</th>
                                                                    <th>Profile Status</th>
                                                                    <th>Office</th>
                                                                    <th>Date Created</th>
                                                                    <th>Applicant Name</th>
                                                                    <th>Contact Number</th>
                                                                    <th>Visa Type</th>
                                                                    <th>Intake</th>
                                                                    <th>Counselor</th>
                                                                    <th>Reg. Date</th>
                                                                    <th>Prospect Status</th>
                                                                    <th>Reffered By</th>
                                                                    <th width='150'>&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {result?.data?.items?.map((item, index) => {
                                                                    const application = item?.application
                                                                    return(
                                                                    <Fragment>
                                                                        <tr key={index}>
                                                                            <td>{(index + 1) + (result?.data?.currentPage * 25)}</td>
                                                                            <td>
                                                                                <LeadStatus item={application} />
                                                                            </td>
                                                                            <td>{application?.office?.name}</td>
                                                                            <td>{moment(application?.createdAt).format('DD-MM-YYYY')}</td>
                                                                            <td>
                                                                                <a style={{color:'blue', cursor:'pointer'}} onClick={()=>{expantButton(index)}}>
                                                                                    {application?.applicant_name} {application?.middlename} {application?.lastname}
                                                                                </a>
                                                                            </td>
                                                                            <td>
                                                                                <div className="email-mobile flex flex-col">
                                                                                    <div>{application?.mobile_no}</div>
                                                                                    <div>{application?.landline_no}</div>
                                                                                    <div>{application?.whatsapp_no}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{application?.visatype?.country?.icon} {application?.visatype?.description}</td>
                                                                            <td>{application?.intake?.intake}</td>
                                                                            <td>
                                                                                {counselorName(application?.assignUser?.CounselorAssign?.userdetail)}
                                                                            </td>
                                                                            <td>
                                                                                {application?.registered_date ? <span className="registered">{moment(application?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                            </td>
                                                                            <td>
                                                                                <ProspectStatusList item={application} />
                                                                            </td>
                                                                            <td>{application?.refferal?.refferal}</td>
                                                                            <td>
                                                                                <div className='grid grid-cols-12 min-w-[150px] gap-1'>
                                                                                    <div className='col-span-12'>
                                                                                        <FollowUp item={application} />
                                                                                    </div>
                                                                                    <div className='col-span-8'>
                                                                                        <ApplicantProgramConfirmation item={application} />
                                                                                    </div>
                                                                                    <div className='col-span-4'>
                                                                                        <ApplicantViewButton item={application} />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="13" className={active.index === index && active.status?"dropdown-table-wrpr active": "dropdown-table-wrpr"}>
                                                                                <table style={{width:'100%'}}  className="table dropdown-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th width="20">Prioriy</th>
                                                                                            <th>College / University</th>
                                                                                            <th>Program Name</th>
                                                                                            <th>Intake</th>
                                                                                            <th>Campus</th>
                                                                                            <th>Program Code</th>
                                                                                            <th>Application Status</th>
                                                                                            <th>Status Updated</th>
                                                                                            <th></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {application?.intended_programs?.sort((a, b) => a.priority - b.priority)?.map((items, indexes) => (
                                                                                            <tr key={indexes}>
                                                                                                <td width="15">
                                                                                                    <div
                                                                                                    className='font-[600] flex items-center justify-center w-[25px] h-[25px] rounded-full text-white'
                                                                                                    style={{background: items?.status === "CONFIRMED" ? 'green' : 'orange'}}>
                                                                                                        {items?.priority}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{items?.college?.name}</td>
                                                                                                <td>
                                                                                                    {items?.program?.programlink ?
                                                                                                        <a className='text-blue-700' href={items?.program?.programlink} target='_blank'>
                                                                                                            {items?.program?.name}
                                                                                                        </a>:
                                                                                                        <div>{items?.program?.name}</div>
                                                                                                    }
                                                                                                </td>
                                                                                                <td>{items?.intake?.intake}</td>
                                                                                                <td>{items?.campus? items?.campus?.name : 'Not Applicable' }</td>
                                                                                                <td>{items?.program?.program_code}</td>
                                                                                                <td>
                                                                                                    <ApplicationStatusModal item={items} />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div>
                                                                                                        {AdmissionUpdated(items)}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className='flex justify-end'>
                                                                                                    <div className='flex gap-2 items-center justify-center'>
                                                                                                        <div className='w-[80px]'>
                                                                                                            <ConfirmProgram item={items} />
                                                                                                        </div>
                                                                                                        <div className='w-[60px]'>
                                                                                                            <RejectProgram item={items} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )})}
                                                            </tbody>
                                                        </table>
                                                        <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} fixed={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>:
                                    <div className='flex justify-center mt-16 mb-16 w-full'>
                                        <Empty description={<div className=''> No applicants found!</div>} />
                                    </div>
                                
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Fragment>
  )
}

export default ProgramCoordinatorMyClient

const AdmissionUpdated =(item)=>{
	if(item?.admissions?.length === 0){
		return
	}
	const exist = item?.admissions?.find(element=> element.statusid === item?.appstaffstatuss?.id)
	if(!exist){
		return
	}

	return moment(exist?.updatedAt).format("DD-MM-YYYY")
}