import { persist } from "zustand/middleware";
import create from "zustand";

export const useRmStore = create(
    persist(
        (set) => ({
            office: '',
            setOffice: (element) => set({ office: element }),
        }),
        { name: "rm", getStorage: () => localStorage }
))