import React, { useEffect, useState } from 'react';
import { userStore } from '../../store/userStore';
import { useNavigate } from 'react-router-dom';

const IDLE_TIMEOUT = 7200000; // 2 hour in millisecond
function IdleTimeoutHandler() {

    const { accessToken } = userStore((state) => state.user);
    const setUser = userStore((state) => state.setUser);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const navigate = useNavigate()

    const logOutMutation =()=>{
		setUser({})
		navigate("/")
	}

    useEffect(() => {
        let logoutTimer;

        const handleActivity = () => {
            setLastActivityTime(Date.now()); // Update last activity time
            clearTimeout(logoutTimer);
            logoutTimer = setTimeout(() => {
                logOutMutation()
                console.log('User logged out due to inactivity.');
            }, IDLE_TIMEOUT);
        };

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                const currentTime = Date.now();
                const timeSinceLastActivity = currentTime - lastActivityTime;
                if (timeSinceLastActivity >= IDLE_TIMEOUT) {
                    logOutMutation();
                } else {
                    // User became active again during tab switch, reset logout timer
                    clearTimeout(logoutTimer);
                    logoutTimer = setTimeout(() => {
                        logOutMutation();
                    }, IDLE_TIMEOUT - timeSinceLastActivity);
                }
                setLastActivityTime(currentTime); // Update last activity time
            }
        };

        const handleUserActivity = () => {
            if (accessToken) {
                handleActivity();
            }
        };

        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('mousedown', handleUserActivity);
        document.addEventListener('keypress', handleUserActivity);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        handleActivity(); // Initial setup

        return () => {
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('mousedown', handleUserActivity);
            document.removeEventListener('keypress', handleUserActivity);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearTimeout(logoutTimer);
        };
    }, [accessToken, lastActivityTime]);

    return null; // This component doesn't render anything
}

export default IdleTimeoutHandler;
