import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react";
import { userStore } from '../../../store/userStore';
import LoaderCommon from "../../Common/LoaderCommon";
import RouteConstants from "../../../Constants/RouteConstans";
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../Common/financialYear';
import { useAdvancedSearch } from '../../../store/advancedSearchStore';
import SelectSearch from "../../Common/SelectSearch";

const CountryPerformance = () => {
    const user = userStore();
    const currentFY = getFinancialYear('CURRENT');
    const officeData = user?.user?.office;
    console.log("uservisa", user);
    const [result, setResult] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [loader, setLoader] = useState(false);
    const navigation = useNavigate();
    const [from, setFrom] = useState(currentFY.startDate);
    const [to, setTo] = useState(currentFY.endDate);
    const [expandedCountries, setExpandedCountries] = useState({});
    const currentFinancialYear = getCurrentFinancialYear();
    const nextFinancialYear = getNextFinancialYear();
    const previousFinancialYear = getPreviousFinancialYear();
    const [dateRange, setDateRange] = useState("CURRENT");
    let TotalExpectedVisaCount = 0;
    const [masterData, setMasterData] = useState({})
    const office = useAdvancedSearch((state) => state.office);
    const setOffice = useAdvancedSearch((state) => state.setOffice);
    const counselor = useAdvancedSearch((state) => state.counselor);
    const setCounselor = useAdvancedSearch((state) => state.setCounselor);
    const store = userStore();

    const bmOffice = store?.user?.office?.map(item=>{
        return item?.office
    })

    const advancedMasterList = authQueries.useAdvancedSearchMutation(
        (response) => {
            setMasterData(response?.data)
        }
    );



    useEffect(() => {
        advancedMasterList.mutateAsync({visa: ''})
    }, [])



    useEffect(() => {
        
        countryPerformanceApi.mutateAsync({ from: currentFY.startDate, to: currentFY.endDate,office:office, counselor:counselor  });
    }, []);



    const countryPerformanceApi = authQueries.useCountryTopPerfomanceReport(
        (response) => {
            setResult(response.data[0]);
            console.log("response", response.data[0]);
        }
    );

    // Group results by country
    const groupedResults = result.reduce((acc, item) => {
        if (!acc[item.country]) {
            acc[item.country] = [];
        }
        acc[item.country].push(item);
        return acc;
    }, {});

    const handleLoadMore = (country) => {
        setExpandedCountries(prevState => ({
            ...prevState,
            [country]: groupedResults[country].length
        }));
    };

    const handleViewLess = (country) => {
        setExpandedCountries(prevState => ({
            ...prevState,
            [country]: 5
        }));
    };

    const selectDate = (e) => {
        const dateValue = getFinancialYear(e.target.value);
        setDateRange(e.target.value);
        setFrom(dateValue.startDate);
        setTo(dateValue.endDate);
        countryPerformanceApi.mutateAsync({ from: currentFY.startDate, to: dateValue.endDate, office: office, counselor: counselor});
    };

    useEffect(() => {
        countryPerformanceApi.mutateAsync({ from: from, to: to, office: office, counselor: counselor });
    }, [office, counselor]);

    function getFinancialYear(type) {
        const today = new Date();
        let currentYear = today.getFullYear();
        const currentMonth = today.getMonth();

        // If the current month is before April, the financial year is from the previous year
        if (currentMonth < 3) {
            currentYear--;
        }

        const getFinancialYearDates = (startYear) => {
            const startDate = `${startYear}-04-01`;
            const endDate = `${startYear + 1}-03-31`;
            return { startDate, endDate };
        };

        let financialYear;

        switch (type) {
            case 'CURRENT':
                financialYear = getFinancialYearDates(currentYear);
                break;
            case 'PREVIOUS':
                financialYear = getFinancialYearDates(currentYear - 1);
                break;
            case 'FUTURE':
                financialYear = getFinancialYearDates(currentYear + 1);
                break;
            default:
                throw new Error("Invalid type. Please use 'CURRENT', 'PREVIOUS', or 'FUTURE'.");
        }

        return financialYear;
    }
    const grandTotal = result.reduce((total, item) => total + (item.expected_visa || 0), 0);

    const inputSearchArry = [
        {
            label: "Branch Office",
            size: 2,            
            state: office,
            setState: setOffice,
            input: SelectSearch,
            value: (store?.user?.role === "BMANAGER" || store?.user?.role === "SENIORPROGRAMMANAGER") ? bmOffice : masterData?.office,
            hidden: store?.user?.role === "RM" ? true : false,
            name: 'name',
            simple: true,
        }

    ]

    const userSearchArry = [
        {
            label: "Counselor",
            size: 2,            
            state: counselor,
            setState: setCounselor,
            input: SelectSearch,
            value: store.user.role === "SENIORPROGRAMMANAGER" ? masterData?.seniorpgmusers : masterData?.user?.filter(user => user.roles.includes(3)),
            hidden: false,
            name: 'name',
            simple: true,
        }
    ]



    return (
        <Fragment>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Country – Wise Top Performers Report</li>
                    </ol>
                </nav>
            </div>

            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Country – Wise Top Performers Report</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr_employee">
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                                    <div className='col-span-3'>
                                        <label>Select Date</label>
                                        <select value={dateRange} className='form-control form-control-sm !w-full !min-h-[38px]' onChange={selectDate}>
                                            <option value="PREVIOUS">Previous Financial Year ({previousFinancialYear})</option>
                                            <option value="CURRENT">Current Financial Year ({currentFinancialYear})</option>
                                            <option value="FUTURE">Next Financial Year ({nextFinancialYear})</option>
                                        </select>
                                    </div>
                                    {
                                        inputSearchArry?.map((item, index)=> {
                                            if(item?.blank && !item?.hidden){
                                                return(
                                                <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                                )
                                            }
                                            if(item?.hidden){
                                                return
                                            }
                                            return(
                                            <Fragment key={index}>
                                                {
                                                    (item?.simple ) &&
                                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                        <item.input item={item} />
                                                    </div>
                                                }
                                            </Fragment>
                                        )})
                                    }

                                    {
                                    userSearchArry?.map((item, index)=> {
                                        if(item?.blank && !item?.hidden){
                                            return(
                                            <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                            )
                                        }
                                        if(item?.hidden){
                                            return
                                        }
                                        return(
                                        <Fragment key={index}>
                                            {
                                                (item?.simple) &&
                                                <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                    <item.input item={item} />
                                                </div>
                                            }
                                        </Fragment>
                                    )})
                                }
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-head-wrpr">
                                            <table className="table collageprogram alt-colors">
                                                <thead className="fixedHeader table-hdr">
                                                    <tr>
                                                        <th rowSpan={2}>Country</th>
                                                        <th rowSpan={2}>Visa Type</th>
                                                        <th rowSpan={2}>Branch</th>
                                                        <th rowSpan={2}>Counselor</th>
                                                        <th rowSpan={2}>Email</th>
                                                        <th rowSpan={2}>Phone</th>
                                                        <th rowSpan={2}>Active Leads</th>
                                                        <th rowSpan={2}>Status</th>
                                                        <th rowSpan={2}>Expected Visa Count(preparing visa+visa applied+visa approved)</th>
                                                        <th >Total Expected Visa Count</th>
                                                    </tr>
                                                    <tr>

                                                        <th style={{backgroundColor:'rgb(71, 165, 36)'}}>{grandTotal}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        {countryPerformanceApi.isLoading ?
                                <LoaderCommon />:
                                        <div className="table-body-wrpr" style={{ height: "550px" }}>
                                            <div style={{ position: "relative" }}>
                                                <table border={1} className="table" style={{ fontSize: "12px" }}>
                                                    <tbody className="table-bdy">
                                                        {Object.keys(groupedResults).length > 0 ? (
                                                            Object.keys(groupedResults).map(country => {
                                                                const countryData = groupedResults[country];
                                                                const countryTotal = countryData.reduce((total, item) => total + (item.expected_visa || 0), 0);
                                                                const displayCount = expandedCountries[country] || 5;

                                                                return (
                                                                    <Fragment key={country}>
                                                                        {countryData.slice(0, displayCount).map((item, index) => (
                                                                            <tr className="ui-sortable-handle" key={`${country}-${index}`}>
                                                                                {index === 0 && <td rowSpan={Math.min(displayCount, countryData.length)}>{country}</td>}
                                                                                <td>{item.visa}</td>
                                                                                <td>{item.office}</td>
                                                                                <td>{item.username}</td>
                                                                                <td>{item?.email}</td>
                                                                                <td>{item?.phone}</td>
                                                                                <td className="topperformactivecount">{item.active_count}</td>
                                                                                <td>{item.status}</td>
                                                                                <td>{item.expected_visa}</td>
                                                                                {index === 0 && <td rowSpan={Math.min(displayCount, countryData.length)}>{countryTotal}</td>}
                                                                            </tr>
                                                                        ))}
                                                                        {countryData.length > displayCount && (
                                                                            <tr key={`${country}-load-more`}>
                                                                                <td colSpan="10" className="text-center">
                                                                                    <button className="btn btn-link" onClick={() => handleLoadMore(country)}>View More</button>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        {countryData.length > 5 && displayCount > 5 && (
                                                                            <tr key={`${country}-view-less`}>
                                                                                <td colSpan="10" className="text-center">
                                                                                    <button className="btn btn-link" onClick={() => handleViewLess(country)}>View Less</button>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </Fragment>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="7" className="text-center">No data found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
}
                                        <div className="table-list-search-wrpr table-container tableContainer">
                                            <div className="row">
                                                <div className="col-md-6">
                                                </div>
                                                <div className="col-md-6 text-right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CountryPerformance;
