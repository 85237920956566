import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { generateValidationSchema } from './validationSchema';
import { Modal } from '@nextui-org/react';
import moment from 'moment';
import { useApi } from './ApiProvider';

const RegistrationModal = ({item}) => {

    const inputFields = [
        {
            name: 'comment',
            required: true,
            min: 10,
            max: 256,
            label: 'Comment'
        },
        {
            name: 'date',
            required: true,
            label: 'Date'
        }
    ]

    const [open, setOpen] = useState(false)

    const { setApiDataAndNotify } = useApi()

    const { register, setValue, handleSubmit, formState:{ errors }} = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen =()=> {
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
    }

    const onSubmit = (data) => {
        const dataValues = {    
            id: item?.id,
            comment: data.comment,
            date: data.date
        }

        const statusData = {
            applicant_id: item?.id,
            prospects_id: 33,
            comments: data.comment,
            status: "ACTIVE"
        }

        addProspect.mutateAsync(statusData);
        markRegistration.mutateAsync(dataValues)
    }

    const addProspect = authQueries.useProStatusAdd(
        (response) => {
            setValue('comment', '')
            setValue('date', moment('yyyy-mm-dd'))
        }
    );

    const markRegistration = authQueries.useApplicationMutationMarkRegistration(
        (response) => {
            setApiDataAndNotify(response?.data)
        }   
    )

  return (
    <Fragment>
        <button onClick={handleOpen} 
        className="btn-customized">Mark Registration</button>
        <Modal
        scroll
        blur
        closeButton
        width="550px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={handleClose}
        >
            <Modal.Body>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>                      
                        <div className="modal-body p-3">
                            <div className="form-group">
                                <label>Registration Date:</label>
                                <input
                                max={moment().format('YYYY-MM-DD')}
                                style={{height:'44px'}} 
                                {...register('date')} 
                                name="date" 
                                type="date" 
                                className="form-control"
                                />
                                <div style={{marginTop:'5px'}}>{errors.comment && <span style={{color:"red", fontSize:'13px'}}>{errors?.date?.message}</span>}</div>
                            </div>
                            <div className="form-group">
                                <label >Registration Comment:</label>
                                <textarea {...register('comment')} name="comment" className="form-control" placeholder="Enter Comment" style={{ minHeight: '100px' }}></textarea>
                                <div style={{marginTop:'5px'}}>{errors.comment && <span style={{color:"red", fontSize:'13px'}}>{errors?.comment?.message}</span>}</div>
                            </div>
                            <button className='btn-customized-large w-full' type="submit">
                                Mark Registration
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>

        </Modal>
    </Fragment>
  )
}

export default RegistrationModal
