import React,{useState} from 'react'
import authQueries from '../../../queries/authQueries'
import { Link, useNavigate } from "react-router-dom";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = yup.object({

    name: yup
      .string('⚠ Enter Name')
      .required('⚠ Name is required')
      .min(2,'⚠ Name must be more than 2 characters '),
    officeloc: yup
      .string('⚠ Enter Office Location')
      .required('⚠ Office Location is required')
      .min(2,'⚠ Office Location must be more than 2 characters '),
    email: yup
      .string('⚠ Enter your email')
      .email('⚠ Enter a valid email')
      .required('⚠ Email is required'),
    phone: yup
      .string('⚠ Enter Phone')
      .required('⚠ Phone is required')
      .min(2,'⚠ Phone must be more than 2 characters '),
    mobile: yup
      .string('⚠ Enter Mobile')
      .required('⚠ Mobile is required')
      .max(10,'⚠ Mobile must be 10 numbers ')
      .min(10,'⚠ Mobile must be 10 numbers '),
    fax: yup
      .string('⚠ Enter Fax Number')
      .required('⚠ Fax is required'),
    address: yup
      .string('⚠ Enter Address')
      .required('⚠ Address is required')
      .min(2,'⚠ Address must be more than 2 characters '),
    regno: yup
      .string('⚠ Enter Registration Number')
      .required('⚠ Registration Number is required')
      .min(2,'⚠ Registration Number must be more than 2 characters '),
    corpno: yup
      .string('⚠ Enter Corporation Number')
      .required('⚠ Corporation Number is required'),
    liceno: yup
      .string('⚠ Enter License Number')
      .required('⚠ License Number is required')
      .min(2,'⚠ License Number must be more than 2 characters '),
    loc: yup
      .string('⚠ Enter Location')
      .required('⚠ Location is required')
      .min(2,'⚠ Location must be more than 2 characters '),
    // reviewGB: yup
    //   .string('⚠ Enter Review GB')
    //   .required('⚠ Review GB is required'),
    // reviewMAG: yup
    //   .string('⚠ Enter Review MAG')
    //   .required('⚠ Review MAG is required')
    //   .min(2,'⚠ Review MAG must be more than 2 characters '),
    order: yup
      .string('⚠ Enter Display Order')
      .required('⚠ Display Order is required'),
  });

const  AddOffice = () => {

    const [errName, setErrName] = useState('')

    const navigate = useNavigate();

    const { register, handleSubmit, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
    });

    const AddOfficedata = 

    authQueries.useOfficeMutationAdd(

        (response) => {

            if(response?.message === "Success"){
                navigate('/office')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }
    );

    const onSubmit = (data) => {

    
        const datavalues = {
            name: data.name,
            office_location: data.officeloc,
            email: data.email,
            phone: data.phone,
            mobile: data.mobile,
            fax: data.fax,
            address: data.address,
            registration_no: data.regno,
            corporation_no: data.corpno,
            license_no: data.liceno,
            location: data.loc,
            review_GB: data.reviewGB,
            review_MAG: data.reviewMAG,
            display_order: data.order,
            status: "ACTIVE",
    
        };
    
        AddOfficedata.mutateAsync(datavalues);    
    
      }


  return (

    <div>

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/office">Offices</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Add Office</li>
            </ol>
        </nav>


        <div className="add_clients">

            <div className="card">
                <div className="card-header">
                    Add Office
                </div>
                <div id="travel_history" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Office Name</label>
                                            <input className="form-control"  {...register("name")} type="text" name='name' />
                                            <div className='error-msg'>{errors.name?.message} {errName}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Office Location</label>
                                            <input className="form-control"  {...register("officeloc")} type="text" name='officeloc' />
                                            <div className='error-msg'>{errors.officeloc?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Email</label>
                                            <input className="form-control"  {...register("email")} type="email" name='email' />
                                            <div className='error-msg'>{errors.email?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Phone</label>
                                            <input  className="form-control"  {...register("phone")} type="number" name='phone' />
                                            <div className='error-msg'>{errors.phone?.message} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Mobile</label>
                                            <input className="form-control"  {...register("mobile")} type="number" name='mobile' />
                                            <div className='error-msg'>{errors.mobile?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Fax</label>
                                            <input className="form-control"  {...register("fax")} type="number" name='fax' />
                                            <div className='error-msg'>{errors.fax?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Address</label>
                                            <input className="form-control"  {...register("address")} type="text" name='address' />
                                            <div className='error-msg'>{errors.address?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Registration Number</label>
                                            <input className="form-control"  {...register("regno")} type="text" name='regno' />
                                            <div className='error-msg'>{errors.regno?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Corporation Number</label>
                                            <input className="form-control"  {...register("corpno")} type="text" name='corpno' />
                                            <div className='error-msg'>{errors.corpno?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">License Number</label>
                                            <input className="form-control"  {...register("liceno")} type="text" name='liceno' />
                                            <div className='error-msg'>{errors.liceno?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Location</label>
                                            <input className="form-control"  {...register("loc")} type="text" name='loc' />
                                            <div className='error-msg'>{errors.loc?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Review GB</label>
                                            <input className="form-control"  {...register("reviewGB")} type="number" name='reviewGB' />
                                            <div className='error-msg'>{errors.reviewGB?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Review MAG</label>
                                            <input className="form-control"  {...register("reviewMAG")} type="number" name='reviewMAG' />
                                            <div className='error-msg'>{errors.reviewMAG?.message}</div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Display Order</label>
                                            <input className="form-control"  {...register("order")} type="number" name='order' />
                                            <div className='error-msg'>{errors.order?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Status</label>
                                            <select className="form-control" name='status'  {...register("status")}>
                                                <option value=''>Select</option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="INACTIVE">INACTIVE</option>
                                            </select>
                                            <div className='error-msg'>{errors.status?.message}</div>
                                        </div>
                                    </div>
                                </div>      */}
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button type='submit' className="btn btn-default btn-save">Submit</button>
                                </div>
                            </div>
                        </form>                            
                    </div>
                </div>
            </div>

        </div>


    </div>

  )
}

export default AddOffice