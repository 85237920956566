import { Checkbox, Radio } from '@nextui-org/react'
import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'

const InputRadio = ({item, control}) => {
  return (
    <Fragment>
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <Radio.Group
            orientation='horizontal'
            value={field.value}
            className="flex bg-white w-full h-10 text-[12px]" 
            name={item?.name}
            onChange={(value, option) => {
                field.onChange(value);
            }}   
            >
                {
                    item?.options?.map((item, index)=> {
                        return(
                            <Radio
                            key={index}
                            value={item?.value}
                            >
                                <div className='text-[12px] text-poppins'>
                                    {item?.label}
                                </div>
                            </Radio>
                        )
                    })
                }
            </Radio.Group>
            )}
        />
    </Fragment>
  )
}

export default InputRadio