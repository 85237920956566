import React, { useState, useEffect } from 'react';
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import LoaderCommon from '../../Common/LoaderCommon';
import PaginationComponent from '../../Common/PaginationComponent';
import InputSearch from '../../Common/InputSearch';
import SelectSearch from '../../Common/SelectSearch';
import USAProgramModal from './UsaAddingModal';
import { useApi } from '../../Common/ApiProvider';

const UsaProgram = () => {
    const master = userStore();
    let userRole = master.user.role
    const [result, setResult] = useState({ data: { items: [] } });
    const [loader, setLoader] = useState(true);
    const [program, setProgram] = useState([]);
    const [university, setUniversity] = useState([]);
    const [location, setLocation] = useState([]);
    const [city, setCity] = useState([]);
    const [level, setLevel] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);


    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const {apiData} = useApi()

    const setCurrentPageBypass = (state) => {
        setCurrentPage(state)
        Listing.mutateAsync({
            page: state,
            size: 25,
            program: program, 
            university: university,
            location:location, 
            level: level,
            city: city,
        });
    }

    
    const Listing = authQueries.useUsaProgramListing(
        async (response) => {
            setResult(response);
            setLoader(false);
        }
    );

    const ListingLocation =authQueries.useUsaProgramLocationListing(
        async (response) => {
            // setLocation(response);
            // console.log("listinglocation>>>>>>.",ListingLocation)
        }
    );

    const ListingCity = authQueries.useUsaCityForStateListing(
        async (response) => {
            setFilteredCities(response);
        }
    );
   

    
    useEffect(() => {
        Listing.mutateAsync({
            page: 0,
            size: 25,
            program: program, 
            university: university,
            location:location, 
            level: level,
            city: city,
        });
    }, [program, university, level, location , city, apiData]);

    useEffect(() => {
        ListingLocation.mutateAsync();
        // ListingAllCity.mutateAsync();
    }, []);

   useEffect(() => {
    setCity([]);
    setFilteredCities([]);
    if(location)
        ListingCity.mutateAsync({state_id:location})
    else
        setCity([]);
        // ListingAllCity.mutateAsync();
    },[location])

    
    const listingFilterArry = [
            {
            label: 'PROGRAM',
            type: 'text',
            size: 2,
            setState: setProgram,
            input: InputSearch,
        },
        {
            label: 'UNIVERSITY',
            type: 'text',
            size: 2,
            setState: setUniversity,
            input: InputSearch,
        },
        {
            label: "STATE",
            size: 2,            
            state: location,
            setState: setLocation,
            input: SelectSearch,
            value: ListingLocation?.data?.data?.items,
            name: 'location',
            simple: true,
        },
        {
            label: "CITY",
            size: 2,            
            state: city,
            setState: setCity,
            input: SelectSearch,
            value: filteredCities?.data?.items,
            name: 'city',
            simple: true
        },
        
        {
            label: 'LEVEL',
            type: 'text',
            size: 2,
            setState: setLevel,
            input: InputSearch,
        },
    ];
    return (
        <div className=''>
            <div className="card-body">                 
                <div className="flex justify-between">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} alt="" />
                        </i>
                        <h2><span>&nbsp;</span>USA Program List</h2>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                    {userRole === 'PROGRAMCOORDINATOR' && <USAProgramModal/>}
                </div>
                </div>
                 <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        {/* <div className="card-header text-[14px]">USA Program List</div> */}
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6" />
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div className="table-search-wrpr" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                                            {
                                                listingFilterArry?.map((item, index)=> {
                                                    if(item?.blank && !item?.hidden){
                                                        return(
                                                        <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                                        )
                                                    }
                                                    if(item?.hidden){
                                                        return
                                                    }
                                                    return(
                                                            <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                                <item.input item={item}/>
                                                            </div>
                                                )})
                                            }
                                        </div>
                                        <div className="total-data-count">
                                            Total records : {result?.data?.totalItems || 0}
                                        </div>
                                        {
                                        loader ? <LoaderCommon /> :
                                            result?.data?.totalItems > 0 ?
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="5%">SR.No</th>
                                                                        <th>Program name</th>
                                                                        <th>University</th>
                                                                        <th>State</th>
                                                                        <th>City</th>
                                                                        <th>Level</th>
                                                                        <th>Remarks</th>
                                                                        <th>Links</th>
                                                                        <th>Stem/NonStem</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items.map((item, index) => (
                                                                        <tr className="ui-sortable-handle" key={index}>
                                                                            <td>{(index + 1) + (result?.data?.currentPage * 25)}</td>
                                                                            <td>{item?.program_name}</td>
                                                                            <td>{item?.university}</td>
                                                                            <td>{item?.usaprogramlocation?.location}</td>
                                                                            <td>{item?.usaprogramcity?.city}</td>
                                                                            <td>{item?.level}</td>
                                                                            <td>{item?.remarks}</td>
                                                                            <td ><div className='w-40'>
                                                                            <a href={item?.links} target="_blank" rel="noopener noreferrer" className=" w-15 text-blue-500 hover:underline">
                                                                                    {item?.links}
                                                                                </a></div>
                                                                                </td>
                                                                            <td>{item?.stem_nonstem}</td>
                                                                            <td className="action-icons flex gap-2 justify-end">
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPageBypass} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             : <p>No data available.</p>
                                        }
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsaProgram;
