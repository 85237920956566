/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import authQueries from '../../../queries/authQueries';
import { userStore } from '../../../store/userStore';
import { Popconfirm, message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from 'moment';
import { Checkbox, Radio } from '@nextui-org/react';

const EnglishProficiency = ({spouseData}) => {

	const appStore = userStore()

	const [result, setResult] = useState([])
	const [spouse, setSpouse] = useState('NO')
	const [dataReload, setDataReload] = useState('')
	const [completed, setCompleted] = useState(appStore?.appAllData?.applicant?.ielts_status === "COMPLETED" ? true : false)

    const validationSchema = yup.object({
        exam_type: yup
			.string()
			.required('Exam Type is required'),
		exam_date: yup
			.string()
			.required('Exam Date is required'),
		expiry_date: yup
			.string()
			.required('Expiry Date is required'),
		}
	)

	const { register, handleSubmit, formState:{ errors }, reset } = useForm({
		resolver: yupResolver(validationSchema)
	})


	const EnglishProAdd = authQueries.useEnglishProAdd(
        (response) => {
			reset();
			message.success(`${response?.data?.exam_type} Added Successfully`);
			EnglishProficiencyList.mutateAsync(appStore.appid);
        }
    );

	const onSubmit = (data) => {
		const dataValues = {
			...data,
			spouse_enabled: spouse,
			applicant_id: appStore.appid
		}
		EnglishProAdd.mutate(dataValues);
	}
	
	useEffect(() => {
		EnglishProficiencyList.mutateAsync(appStore.appid);
	}, [dataReload]);

	const EnglishProficiencyList = authQueries.useEnglishProList(
		(response) => {
			setResult(response?.data);
		}
	)


	const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )

	const englishProfiencyStatus = authQueries.useEnglishProfiencyStatusChangeMutation(
		(res)=>{
			viewAllApplicationEditData.mutateAsync(appStore.appid) 
		}
	)

	const statusChange =(e)=> {
		englishProfiencyStatus.mutateAsync({id: appStore.appid, status: e})
	}

	const handleDocumentStatus =(status)=>{
        setCompleted(status)
        const data = {
            status: status ? "COMPLETED" : "PENDING",
            id: appStore.appid,
            type: "ielts"
        }
        changeStatus.mutateAsync(data)
    }

    const changeStatus = authQueries.useChangeDocumentOrIeltsStatus(
        (response) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    )

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )


  return (
    <div>
		<div className="card">
			<div className="card-header">
				<a className="collapsed card-link" data-toggle="collapse" href="#english_proficiency_test">
				English Proficiency Test Details, If Any
				</a>
				<div className={result?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                	<span className={result?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
            	</div>
			</div>

			<div id="english_proficiency_test" className="collapse" data-parent="#accordion">
				<div className="card-body">

					<div className='my-3 flex justify-end'>
                        
                        <Popconfirm
                            placement="topRight"
                            title={!completed ? "Are you sure you want to mark as completed?" : "Are you sure you want to mark as incomplete?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDocumentStatus(!completed)}
                        >
                            <Checkbox isSelected={completed}>
                                <span className='text-[13px] font-normal mt-1'>IELTS Completed</span>
                            </Checkbox>
                        </Popconfirm>

                    </div>

					<ul className="nav nav-tabs">
						<li className="nav-item">
							<a onClick={()=> {setSpouse('NO')}} className="nav-link active" data-toggle="tab" href="#englishapplicant">Primary Applicant</a>
						</li>
						{
							spouseData &&
							<li className="nav-item">
								<a onClick={()=> {setSpouse('YES')}} className="nav-link" data-toggle="tab" href="#englishspouse">Spouse</a>
							</li>
						}
					</ul>
                    <div className="tab-content">
						<div className='mt-4 mb-3'>
							<span className='text-sm'>Have you appeared for an English Proficiency Test?</span>
							<div className='mt-3'>
								<Radio.Group defaultValue={appStore?.appAllData?.englishpro} onChange={(e)=> statusChange(e)} orientation='horizontal'>
									<Radio value='YES'>
										<span className='text-sm font-normal'>Yes</span>
									</Radio>
									<Radio value='NO'>
										<span className='text-sm font-normal'>No</span>
									</Radio>
								</Radio.Group>
							</div>
						</div>
						{
							appStore?.appAllData?.applicant?.englishpro === "YES" &&
							<div className="row">
								<div className="col-md-12" style={{marginBottom:'20px'}}>
									<form onSubmit={handleSubmit(onSubmit)}>
										<div className="row">
											<div className="col-md-2">
												<div className="form-group">
													<label htmlFor="englishTest">Name of Exam</label>
													<select {...register('exam_type')} className="form-control form-control-sm english" name="exam_type">
														<option value="">Select</option>
														<option value="IELTS">IELTS</option>
														<option value="OET">OET</option>
														<option value="PTE">PTE</option>
														<option value="DUO">Duolingo English Test</option>
														<option value="TOEFL">TOEFL</option>
													</select>
													<div style={{fontSize:'12px', color:'red', marginTop:'5px'}}>
														{errors?.exam_type?.message}
													</div>
												</div>
											</div>
											<div className="col-md-2">
												<div className="form-group">
													<label htmlFor="englishDate">Date of exam</label>
													<input {...register('exam_date')} type="date" className="form-control form-control-sm english" name="exam_date" placeholder="Enter Date" />
													<div style={{fontSize:'12px', color:'red', marginTop:'5px'}}>
														{errors?.exam_date?.message}
													</div>
												</div>
											</div>
											<div className="col-md-2">
												<div className="form-group">
													<label htmlFor="englishDate">Date of expiry</label>
													<input {...register('expiry_date')} type="date" className="form-control form-control-sm english" name="expiry_date" placeholder="Enter Date" />
													<div style={{fontSize:'12px', color:'red', marginTop:'5px'}}>
														{errors?.expiry_date?.message}
													</div>
												</div>
											</div>
											<div className="col-md-2">
												<div className="form-group">
													<label htmlFor="spouse_name">&nbsp;</label><br/>
													<button 
													type='submit' 
													className="btn btn-default btn-nextrow text-white"
													>Create</button>
												</div>	
											</div>    
										</div>
									</form>
								</div>

							{result?.find((item) => item?.spouse_enabled === spouse) &&
								<div className="col-md-12">
									<div className="" style={{overflow:'auto'}}>
											<table className="table collageprogram ">
												<thead>
													<tr style={{fontSize:'15px'}}>
														<th width="12%">Name of Exam</th>
														<th width="12%">Score</th>
														<th width="12%">Listening</th>
														<th width="12%">Reading</th>
														<th width="12%">Writing</th>
														<th width="12%">Speaking</th>
														<th width="12%">Date of exam</th>
														<th width="12%">Date of expiry</th>
														<th width="8%">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{
														result?.map((item, index) => (
															<Fragment>
																{item.spouse_enabled === spouse &&
																	<ViewEnglishProficiency item={item} reloadData={setDataReload} />
																}
															</Fragment>
															)
														)
													}
												</tbody>
											</table>
									</div>				
								</div>		
							}

							</div>
						}
					</div>
				</div>
			</div>
		</div>
    </div>
  )
}

export default EnglishProficiency


const ViewEnglishProficiency = ({item, reloadData}) => {

	const [edit, setEdit] = useState(false);

	useEffect(() => {
		setValue('overall', item.overall);
		setValue('listing', item.listing);
		setValue('reading', item.reading);
		setValue('writing', item.writing);
		setValue('speaking', item.speaking);
		setValue('exam_date', item.exam_date);
		setValue('expiry_date', item.expiry_date);
	}, [item]);

	const { register, handleSubmit, formState:{ errors }, setValue } = useForm({});

	const onSubmit = (data) => {
		const dataValues = {
			id: item.id,
			overall: data.overall,
			listing: data.listing,
			reading: data.reading,
			writing: data.writing,
			speaking: data.speaking,
		}
		EnglishProficiencyEdit.mutateAsync(dataValues);
		setEdit(false);
	}

	const EnglishProficiencyEdit = authQueries.useEnglishProEdit(
		(response)	=> {
			reloadData(response)
		},
	);

	const EnglishProficiencyDelete = authQueries.useEnglishProDelete(
		(response)	=> {
			reloadData(response)
		},
	);

	return(
		<tr>
			{
				!edit ?
					<Fragment>
						<td className="left-header table-english">{item.exam_type === "DUO" ? "Duolingo English Test" : item.exam_type}</td>
						<td className='table-english'>{item.overall ? item.overall : "0"}</td>
						<td className='table-english'>{item.listing ? item.listing: "0" }</td>
						<td className='table-english'>{item.reading ? item.reading: "0"}</td>
						<td className='table-english'>{item.writing ? item.writing: "0"}</td>
						<td className='table-english'>{item.speaking ? item.speaking: "0"}</td>
						<td className='table-english'>{moment(item.exam_date).format('DD-MM-YYYY')}</td>
						<td className='table-english'>{moment(item.expiry_date).format('DD-MM-YYYY')}</td>
						<td style={{display:'flex'}}>
							<button onClick={()=> setEdit(true)} style={{background:'#FFC75E'}} className="btn btn-default btn-sm">
								<i style={{color:'#212529'}} className="icon-edit"></i>
							</button>
							<span style={{paddingLeft:'5px'}}></span>
							<button onClick={()=> EnglishProficiencyDelete.mutateAsync(item.id)} style={{background:'#FFC75E'}} className="btn btn-default btn-sm">
								<i style={{color:'#212529'}} className="icon-delete"></i>
							</button>
						</td>
					</Fragment>
					:
					<Fragment>
						<td className="left-header table-english">{item.exam_type === "DUO" ? "Duolingo English Test" : item.exam_type}</td>
						<td className='table-english'><input type="number" className="form-control form-control-sm english" {...register('overall')} name="overall" /></td>
						<td className='table-english'><input type="number" className="form-control form-control-sm english" {...register('listing')} name="listing" /></td>
						<td className='table-english'><input type="number" className="form-control form-control-sm english" {...register('reading')} name="reading" /></td>
						<td className='table-english'><input type="number" className="form-control form-control-sm english" {...register('writing')} name="writing" /></td>
						<td className='table-english'><input type="number" className="form-control form-control-sm english" {...register('speaking')} name="speaking" /></td>
						<td className='table-english'><input  disabled type="date" className="form-control form-control-sm english" {...register('exam_date')} name="exam_date" /></td>
						<td className='table-english'><input  disabled type="date" className="form-control form-control-sm english" {...register('expiry_date')} name="expiry_date" /></td>
						<td>
							<button onClick={handleSubmit(onSubmit)} style={{background:'#FFC75E'}} className="btn btn-default btn-sm">
								<i style={{color:'#212529'}} className="icon-check"></i>
							</button>
						</td>
					</Fragment>
			}
		</tr>
	)
}