
import React from "react";
// import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { userStore } from '../../../store/userStore';
import AdmissionDashboard from '../../Dashboard/AdmissionDashboard';

const PageLayout = () => {
  const user = userStore()
  return (
    <div>
        <Sidebar />    
    </div>
  );
}

export default PageLayout;
