/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';

const DocumentDashboard = () => {

  return (
    <div>	
        <div className="prg-coordinator">

            <FollowupToday />
            <FollowupPending />

        </div>
    </div>
  )
}

export default DocumentDashboard