import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useApi } from './ApiProvider'
import authQueries from '../../queries/authQueries'

const FollowupPendingLead = () => {

    const { setApiDataAndNotify } = useApi()

    const pendingList = authQueries.useCounselorDashboardAutoFollowupMutation(
		async (response) => {
			setApiDataAndNotify(response)
		}
	)

    useEffect(() => {
		pendingList.mutateAsync()
    }, []);

}

export default FollowupPendingLead

