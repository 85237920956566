import React,{useState,useEffect, Fragment} from 'react'
import authQueries from '../../queries/authQueries';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Breadcrumb, Select, Upload } from 'antd';
import { userStore } from '../../store/userStore';
import { Avatar, Card, Input, Loading, Modal, Text } from '@nextui-org/react';
import LoaderCommon from '../Common/LoaderCommon';

const MultipleRole = ({selectRole, control, rolesData}) => {

    const user = userStore();

    let users =  [] 
    user?.rid?.map(item => {
        if(selectRole !== item){
            users.push(item)
        }
    })
    
    return (
        <Fragment>
            <Controller 
                name={"secondaryrole"}
                control={control}
                render={({ field: { onChange } }) => (
                    <Select
                        defaultValue={users}
                        mode="multiple"
                        allowClear
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(val) => {onChange(val)}}
                        options={rolesData}
                    />
                )}
            />
        </Fragment>
    )
}

const MultipleVisa = ({control, visaData}) => {

    const user = userStore();

    return (
        <Fragment>
            <Controller
                name={"visatype"}
                control={control}
                render={({ field: { onChange } }) => (
                    <Select
                        defaultValue={user.uvid}
                        mode="multiple"
                        allowClear
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(val) => {onChange(val)}}
                        options={visaData}
                    />
                )}
            />
        </Fragment>
    )
}


const MultipleOffice = ({control, officeData}) => {

    const user = userStore();

    return (
        <Fragment>
            <Controller
                name={"office"}
                control={control}
                render={({ field: { onChange } }) => (
                    <Select
                        defaultValue={user.uoid}
                        mode="multiple"
                        allowClear
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(val) => {onChange(val)}}
                        options={officeData}
                    />
                )}
            />
        </Fragment>
    )
}




const UserEdit = () => {

    const validationSchema = yup.object({
        username: yup
          .string('⚠ Enter User Name')
          .required('⚠ User Name is required')
          .min(2,'⚠ User Name must be more than 2 characters '),
        firstname: yup
          .string('⚠ Enter First Name')
          .required('⚠ First Name is required')
          .min(2,'⚠ First Name must be more than 2 characters '),
        lastname: yup
          .string('⚠ Enter Last Name')
          .required('⚠ Last Name is required')
          .min(2,'⚠ Last Name must be more than 2 characters '),
        role: yup
          .string('⚠ Enter Role')
          .required('⚠ Role is required'),
        email: yup
          .string('⚠ Enter your email')
          .email('⚠ Enter a valid email')
          .required('⚠ Email is required'),
        phone: yup
          .string('⚠ Enter Phone')
          .required('⚠ Phone is required')
          .min(10,'⚠ Phone must be 10 Numbers '),
        visatype: yup
            .array()
            .test('len', '⚠ Visa Type is required', (val) => val?.length > 0)
            .required('⚠ Visa Type is required'),
        office: yup
            .array()
            .test('len', '⚠ Office is required', (val) => val?.length > 0)
            .required('⚠ Visa Type is required'),
        address: yup
          .string('⚠ Enter Address')
          .required('⚠ Address is required'),
      });
  
      const MarketingSchema = yup.object({
        username: yup
          .string('⚠ Enter User Name')
          .required('⚠ User Name is required')
          .min(2,'⚠ User Name must be more than 2 characters '),
        firstname: yup
          .string('⚠ Enter First Name')
          .required('⚠ First Name is required')
          .min(2,'⚠ First Name must be more than 2 characters '),
        lastname: yup
          .string('⚠ Enter Last Name')
          .required('⚠ Last Name is required')
          .min(2,'⚠ Last Name must be more than 2 characters '),
        role: yup
          .string('⚠ Enter Role')
          .required('⚠ Role is required'),
        email: yup
          .string('⚠ Enter your email')
          .email('⚠ Enter a valid email')
          .required('⚠ Email is required'),
        phone: yup
          .string('⚠ Enter Phone')
          .required('⚠ Phone is required')
          .min(10,'⚠ Phone must be 10 Numbers '),
        address: yup
          .string('⚠ Enter Address')
          .required('⚠ Address is required'),
      });
  
  
  
      const VisaManager = yup.object({
        username: yup
          .string('⚠ Enter User Name')
          .required('⚠ User Name is required')
          .min(2,'⚠ User Name must be more than 2 characters '),
        firstname: yup
          .string('⚠ Enter First Name')
          .required('⚠ First Name is required')
          .min(2,'⚠ First Name must be more than 2 characters '),
        lastname: yup
          .string('⚠ Enter Last Name')
          .required('⚠ Last Name is required')
          .min(2,'⚠ Last Name must be more than 2 characters '),
        role: yup
          .string('⚠ Enter Role')
          .required('⚠ Role is required'),
        email: yup
          .string('⚠ Enter your email')
          .email('⚠ Enter a valid email')
          .required('⚠ Email is required'),
        phone: yup
          .string('⚠ Enter Phone')
          .required('⚠ Phone is required')
          .min(10,'⚠ Phone must be 10 Numbers '),
        address: yup
          .string('⚠ Enter Address')
          .required('⚠ Address is required'),
        visatype: yup
          .array()
          .test('len', '⚠ Visa Type is required', (val) => val?.length > 0)
          .required('⚠ Visa Type is required'),
      });
  
      const BranchManager = yup.object({
        username: yup
          .string('⚠ Enter User Name')
          .required('⚠ User Name is required')
          .min(2,'⚠ User Name must be more than 2 characters '),
        firstname: yup
          .string('⚠ Enter First Name')
          .required('⚠ First Name is required')
          .min(2,'⚠ First Name must be more than 2 characters '),
        lastname: yup
          .string('⚠ Enter Last Name')
          .required('⚠ Last Name is required')
          .min(2,'⚠ Last Name must be more than 2 characters '),
        role: yup
          .string('⚠ Enter Role')
          .required('⚠ Role is required'),
        email: yup
          .string('⚠ Enter your email')
          .email('⚠ Enter a valid email')
          .required('⚠ Email is required'),
        phone: yup
          .string('⚠ Enter Phone')
          .required('⚠ Phone is required')
          .min(10,'⚠ Phone must be 10 Numbers '),
        address: yup
          .string('⚠ Enter Address')
          .required('⚠ Address is required'),
        office: yup
          .array()
          .test('len', '⚠ Office is required', (val) => val?.length > 0)
          .required('⚠ Office is required'),
      });
  
      const Executive = yup.object({
        username: yup
          .string('⚠ Enter User Name')
          .required('⚠ User Name is required')
          .min(2,'⚠ User Name must be more than 2 characters '),
        firstname: yup
          .string('⚠ Enter First Name')
          .required('⚠ First Name is required')
          .min(2,'⚠ First Name must be more than 2 characters '),
        lastname: yup
          .string('⚠ Enter Last Name')
          .required('⚠ Last Name is required')
          .min(2,'⚠ Last Name must be more than 2 characters '),
        role: yup
          .string('⚠ Enter Role')
          .required('⚠ Role is required'),
        email: yup
          .string('⚠ Enter your email')
          .email('⚠ Enter a valid email')
          .required('⚠ Email is required'),
        phone: yup
          .string('⚠ Enter Phone')
          .required('⚠ Phone is required')
          .min(10,'⚠ Phone must be 10 Numbers '),
        address: yup
          .string('⚠ Enter Address')
          .required('⚠ Address is required'),
      });


  const [errName, setErrName] = useState('')
  const [visaResult, setVisaResult] = useState([])
  const [roleResult, setRoleResult] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [selectRole, setSelectRole] = useState(null);
  const [image, setImage] = useState('')
  const [officeResult, setOfficeResult] = useState([]);

    const navigate = useNavigate();
    const {rid,uid} = useParams();
    const user = userStore();

    const [loader, setLoader] = useState(true)

    const { register, reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(
          parseInt(selectRole) === 11 ? MarketingSchema : 
          (parseInt(selectRole) === 2 || parseInt(selectRole) === 20 || parseInt(selectRole) === 22) ? VisaManager :
          (parseInt(selectRole) === 12 || parseInt(selectRole) === 23) ? BranchManager :
          (parseInt(selectRole) === 15 || parseInt(selectRole) === 16 || parseInt(selectRole) === 17 || parseInt(selectRole) === 18 || parseInt(selectRole) === 19 || parseInt(selectRole) === 21) ? Executive : 
          validationSchema)
    });

    useEffect(() => {
        startData();
    }, []);

    const startData = async() => {
        await RoleList.mutateAsync({search: '', status: '', page:0, size:500});
        await VisaList.mutateAsync({search: '', status: '', page:0, size:500});
        await OfficeList.mutateAsync({search: '', status: '', page:0, size:500});
        await ViewUserData.mutateAsync(uid);
    }

    const VisaList = authQueries.useVisaTypeMutationList(
         (response) => {
             setVisaResult(response)
             let dataArray = []
             for(let datas of response?.data?.items){
                 const dataValues = {
                     label:datas?.description,
                     value:datas?.id
                 }
                 dataArray = [...dataArray, dataValues]
             }
             setVisaResult(dataArray)
         }
 );

    const RoleList = authQueries.useRoleMutationList(
        (response) => {
            setRoleResult(response)
            let roleArray = [];
            response?.data?.items?.map((item) => { 
                let role = {
                    value: item.id,
                    label: item.label
                }
                if(item.name !== 'ADMIN' && item.name !== 'CEO' ){
                    roleArray = [...roleArray, role]  
                }
            });
            setRolesData(roleArray);
        }
    ); 

    const OfficeList = authQueries.useOfficeMutationList(
        (response) => {
            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }
                dataArray = [...dataArray, dataValues]
            }
            setOfficeResult(dataArray)
        }
    );

    const ViewUserData = authQueries.useUserRoleMutationView(
        (response) => {
            if(response?.data){
                setLoader(false)
                let datas = ({
                    username:response?.data?.username,
                    role: response?.data.role_id,
                    firstname: response?.data?.userdetail.firstname,
                    lastname: response?.data?.userdetail.lastname,
                    email: response?.data?.userdetail.email,
                    secondaryrole: response?.data?.rolemultiples.map((item) => { return item.rid }),
                    phone: response?.data?.userdetail.phone,
                    visatype: response?.data?.uservisas.map((item) => { return item.vid }),
                    office: response?.data?.useroffices.map((item) => { return item.oid }),
                    address: response?.data?.userdetail?.address? response.data?.userdetail?.address : "",
                    status: response?.data?.status
                })
                setSelectRole(response?.data.role_id)
                setImage(response?.data?.userdetail?.image)
                reset({ ...datas });
            }
        }   
    );


    const EditUserdata = authQueries.useUserRoleMutationEdit(
        (response) => {
          if(response?.message === "Success"){
              navigate('/userlist')
          }else if(response?.error[0].username){
              setErrName("⚠ "+response?.error[0].username)
          }
        }
    );


    const onSubmit = (data) => {
      const datavalues = {
          id: uid,
          srole: data.secondaryrole,
          username: data.username,
          role_id: data.role,
          firstname: data.firstname,
          lastname: data.lastname,
          phone: data.phone,
          image: image,
          email: data.email,
          visatype: data.visatype || null,
          office: data.office,
          address: data.address,
          status: data.status,
      };
      EditUserdata.mutateAsync(datavalues);
    }

    const handleChange =(e)=>{
        const data = new FormData();
        data.append('file', e)
        imageUploadCreate.mutateAsync(data)
    }

    const imageUploadCreate = authQueries.useFileUploadCommon(
        (response) => {
            setImage(response?.file)
        }   
    );


  return (

    <div>


        {
            loader ? 
            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'15%'}}>
				<LoaderCommon />
			</div>:


            <div>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{width:'98%'}}>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={"/userlist/"}>Users</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Update User</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className='flex flex-col justify-center bg-white rounded-xl'>
                    <div className='mt-8 ml-3' style={{display:'flex', justifyContent:'flex-start', gap:'3px', alignItems:'center'}}>
                        <i onClick={()=> navigate('/userlist')} className='icon-keyboard_arrow_left' style={{fontSize:'24px', color:'#00f', cursor:'pointer'}}></i>
                        <span>Update User</span>
                    </div>
                    <div className="profile-wrpr">
                        <div id="image-preview" className='profile-img' style={{backgroundSize:"cover", 
                            backgroundImage:image?
                            `url(${image})`:`url(${require('../../assets/images/profile-avatar.jpg')})`
                            }}>
                            <label htmlFor="image-upload" id="image-label">Choose File</label>
                            <input onChange={(e)=>{handleChange(e.target.files[0])}} type="file" name="image" id="image-upload" /> 
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row p-5 !text-[12px]">
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">User Name</label>
                                        <input className="form-control min-h-[40px] !text-[12px]"  {...register("username")} type="text" name='username' />
                                        <p className='error'>{errors.username?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">First Name</label>
                                        <input className="form-control min-h-[40px] !text-[12px]"  {...register("firstname")} type="text" name='firstname' />
                                        <p className='error'>{errors.firstname?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Last Name</label>
                                        <input className="form-control min-h-[40px] !text-[12px]"  {...register("lastname")} type="text" name='lastname' />
                                        <p className='error'>{errors.lastname?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Phone</label>
                                        <input className="form-control min-h-[40px] !text-[12px]"  {...register("phone")} type="number" name='phone' />
                                        <p className='error'>{errors.phone?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Email</label>
                                        <input className="form-control min-h-[40px] !text-[12px]"  {...register("email")} type="email" name='email' />
                                        <p className='error'>{errors.email?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-2">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Primary Role</label>
                                            <select 
                                                className="form-control min-h-[40px] !text-[12px]"
                                                {...register('role', {
                                                    onChange: (e) => {setSelectRole(parseInt(e.target.value))},
                                                    onBlur: (e) => {},
                                                })}
                                                name='role'>
                                                <option value=''>Select</option>
                                                {roleResult.data?.items?.map((item,index) => (
                                                    (item.name !== "ADMIN" && item.name !== "CEO") &&
                                                    <option key={index} value={item.id}>
                                                        {item.label}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <p className='error'>{errors.role?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Secondary Role</label>
                                        <MultipleRole selectRole={selectRole} rolesData={rolesData} control={control} />
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Visa Type</label>

                                        <MultipleVisa visaData={visaResult} control={control} />
                                        <div className='error-msg'>{errors.visatype?.message}</div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Office</label>

                                        <MultipleOffice officeData={officeResult} control={control} />
                                        <div className='error-msg'>{errors.office?.message}</div>


                                    </div>
                                </div>
                            </div>  
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Address</label>
                                        <input className="form-control min-h-[40px] !text-[12px]"  {...register("address")} type="text" name='address' />
                                        <p className='error'>{errors.address?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>                            

                            <div className="col-md-2">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Status</label>
                                        <select className="form-control min-h-[40px] !text-[12px]" name='status'  {...register("status")}>
                                            <option value=''>Select</option>
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="INACTIVE">INACTIVE</option>
                                        </select>
                                        <p className='error'>{errors.status?.message}</p>
                                    </div>
                                </div>
                            </div>     
                            <div className="row col-md-12">
                                <div className="col-md-12 text-center">
                                    <button type='submit' className="btn btn-default btn-save">Update</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        }

    </div>

  )
}

export default UserEdit