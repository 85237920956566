
import { Select } from 'antd'
import React from 'react'
import { Controller } from 'react-hook-form'


export const MultipleSelect = ({control, name, options, value}) => {
    return (
    <div>
        { value &&
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange } }) => (
                    <Select
                        mode="multiple"
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue={value}
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(val) => {
                            onChange(val)
                        }}
                        options={options}
                    />
                )}
            />
        }
        { !value &&
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange } }) => (
                    <Select
                        mode="multiple"
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(val) => {
                            onChange(val)
                        }}
                        options={options}
                    />
                )}
            />
        }
    </div>
  )
}


