import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react"
import { userStore } from '../../../store/userStore'
import LoaderCommon from "../../Common/LoaderCommon"
import RouteConstants from "../../../Constants/RouteConstans";

const CounselorRegistration =()=>{
    const user = userStore();

    const officeData= user?.user?.office;
    console.log("uservisa",user)
    const [result, setResult] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [status, setStatus] = useState('');
    const [visibleStatus, setVisibleStatus] = useState(false)
    const [applicantresult, setApplicantresult] = useState([])
    const [type, setType] = useState(0);
    const [loader, setLoader] = useState(false)
    const navigation = useNavigate();


    const [countryapplicantresult, setCountryapplicantresult] = useState([])
    let staffTotal=0;
    let office='';
    let serialNo=0;
    let totalRegPrevFinancialYr=0;
    let totalRegCurrFinancialYr=0;
    let totalRegPrevMonth=0;
    let totalRegCurrMonth=0;
    let totalInactive30Day=0;
    let totalInactive60Day=0;
    let totalInactive90Day=0;
    let totalRegPrevMonthastoday=0;
    let totalRegyeartilldate=0;
    let totalfeepaidpryr=0;
    let totalfeepaidcryr=0;
    let totalnotinterestedcf=0;
    let totalnotinterestedcmonth=0;
    
    let grandTotalRegPrevFinancialYr=0;
    let grandTotalRegCurrFinancialYr=0;
    let grandtotalRegPrevMonth=0;
    let grandtotalRegPrevMonthastoday=0;
    let grandtotalRegCurrMonth=0;
    let grandtotalInactive30Day=0;
    let grandtotalInactive60Day=0;
    let grandtotalRegyeartilldate=0
    let grandtotalfeepaidpryr=0;
    let grandtotalfeepaidcryr=0;
    let grandtotalnotinterestedcf=0;
    let grandtotalnotinterestedcmonth=0;
    const role = user?.user?.rid;
    const userId =  user?.user?.id;
    const initcall = async function () {
        await counselorRegistrationReportApi.mutateAsync();
        await counselorCountryRegistrationReportApi.mutateAsync();
    }

    useEffect(()=>{
        user.setToggleMenu(false);
        initcall()
        //counselorRegistrationReportApi.mutateAsync();
    },[]);

    function removeDuplicates(arr) {
        return arr.filter((obj, index, self) => {
            return index === self.findIndex((t) => (
                t.user_id === obj.user_id
            ));
        });
    }


    const counselorRegistrationReportApi = authQueries.useCounselorRegistrationReport(

        (response) => {
            console.log("response",response.data[0])
            let uniqueDataArray = removeDuplicates(response.data[0])
            setResult(uniqueDataArray);
        }   
    
    );

    const counselorCountryRegistrationReportApi = authQueries.useCounselorRegistrationCountryReport(

        (response) => {
            console.log("response",response.data[0])
            setCountryList(response.data[0]);        
        }   
    
    );

    const handleCloseStatus = () => {
        setVisibleStatus(false)
    }

    const statusModalOpen =(type, uid)=>{
        setLoader(true)
        setVisibleStatus(true)
        setType(type)
        if(type===1)
        {
            setStatus("Registered moved to not Interested within 30 days")
        }else if(type===2)
        {
            setStatus("Registered moved to not Interested within 31 - 60 days")
        }
        else if(type===3)
        {
            setStatus("Registered moved to not Interested within 31 - 60 days")
        }
        else if(type===4)
        {
            setStatus("Registered moved to not interested year till date")
        }
        else if(type===5)
        {
            setStatus("Previous Financial Year Registrations")
        }
        else if(type===6)
        {
            setStatus("Previous Financial Year Fee Paid")
        }
        else if(type===7)
            {
                setStatus("Registrations Current Month as of Current Date")
            }
            else if(type===8)
            {
                setStatus("Current Financial Year Registrations")
            }
            else if(type===9)
            {
                setStatus("Current Financial Year Registrations Fee Paid")
            }
            else if(type===10)
            {
                setStatus("Registrations Previous Month")
            }
            else if(type===11)
            {
                setStatus("Registrations Previous Month as of Current Date")
            }
            else if(type===12)
                {
                    setStatus("Registered Moved to not Interested this year")
                }
                else if(type===13)
                {
                    setStatus("Registered Moved to not Interested this month")
                }
    

        
       listApplicant.mutateAsync({type:type, uid: uid})
    }

    const Countrylist =(country)=>{
        setLoader(true)
        setType(11)
        setVisibleStatus(true)
        setStatus("Registered Applicant List")
        listCountryApplicant.mutateAsync({country:country})
    }

    const listApplicant = authQueries.useCounselorApplicantList(
        (response) => {
            setLoader(false)
            setApplicantresult(response.data[0])
            
        }
    )

    
    const listCountryApplicant = authQueries.useCounselorCountryApplicantlist(
        (response) => {
            setLoader(false)
            setApplicantresult(response.data[0])
            
        }
    )

    let grandTotalRegPrevFinancialYrfirstRow=0;
    let grandTotalRegCurrFinancialYrfirstRow=0;
    let grandtotalRegPrevMonthfirstRow=0;
    let grandtotalRegPrevMonthastodayfirstRow=0;
    let grandtotalRegCurrMonthfirstRow=0;
    let grandtotalInactive30DayfirstRow=0;
    let grandtotalInactive60DayfirstRow=0;
    let grandtotalInactive90DayfirstRow=0;
    let grandtotalRegyeartilldatefirstrow=0;
    let grandtotalfeepaidpryrfirstrow=0;
    let grandtotalfeepaidcryrfirstrow=0;
    let grandtotalnotinterestedcffirstrow=0;
    let grandtotalnotinterestedcmonthfirstrow=0;


    for(let i=0;i<result.length;i++)
    {
        grandTotalRegPrevFinancialYrfirstRow+=result[i].registered_leads_prev_yr;
        grandTotalRegCurrFinancialYrfirstRow+=result[i].registered_leads_curr_yr;
        grandtotalRegPrevMonthfirstRow+=result[i].prev_month_registrations;
        grandtotalRegPrevMonthastodayfirstRow+=result[i].prev_month_registrationastoday;
        grandtotalRegCurrMonthfirstRow+=result[i].curr_month_registrations;
        grandtotalInactive30DayfirstRow+=result[i].registered_inactve_30_days;
        grandtotalInactive60DayfirstRow+=result[i].registered_inactve_60_days;
        grandtotalInactive90DayfirstRow+=result[i].registered_inactve_90_days;
        grandtotalRegyeartilldatefirstrow+=result[i].registered_inactive_financialyear_days;
        grandtotalfeepaidpryrfirstrow+=result[i].feepaid_prev_yr;
        grandtotalfeepaidcryrfirstrow+=result[i].feepaid_curr_yr;
        grandtotalnotinterestedcf+=result[i].registered_all_inactive_financialyear_days;
        grandtotalnotinterestedcffirstrow+=result[i].registered_all_inactive_financialyear_days;
        grandtotalnotinterestedcmonthfirstrow+=result[i].registered_nis_current_month;

    }

    function formatDateToDDMMYYYY(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    let countrytotal=0;
    return(
        <Fragment>
            <div className="breadcrumb-and-otherbuttons">
                {/* <!-- breadcrumb --> */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Counselor Registration Report</li>
                    </ol>
                </nav>
            </div>
    
            <div className="add_clients">
                    <div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Counselor Registration Report</h2>	
                        </div>
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    
                    <div id="accordion" className="add_clients_accordion_form">
                        <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            {/* <a className="collapsed card-link"  href="" style="pointer-events:none;"> */}
                            Country wise month to date student registrations
                            <a onClick={()=>{ navigation(RouteConstants.COUNSELORNIS) }}  style={{float:'right',  textDecoration: 'underline', cursor: "pointer", color: "blue" }} href="#"><span>Counselors with Highest Registered Clients Moved to Not Interested</span></a>
                            {/* </a> */}
                            <table border={1} className="table report collageprogram">
                            <tbody>
                                {countryList?.map((item, index) => (
                                    <React.Fragment key={index}>
                                            <td>{item.country}</td>
                                    </React.Fragment>
                                ))}
                                   <td><b>Total</b></td>
                            </tbody>
                            <tbody> 
                                {
                               
                                countryList?.map((item, index) => {
                                    countrytotal+=item.curr_month_reg;
                                    return(
                                    <React.Fragment key={index}>
                                        {
                                            role===14 || role===2?(
                                                <td> <a className="text-[blue] underline" href="javascript:void(0)" onClick={() =>{ Countrylist(item.id)} }>{item.curr_month_reg}</a></td>
                                            ):  <td> <a href="javascript:void(0)" >{item.curr_month_reg}</a></td>
                                        }
                                          
                                    </React.Fragment>
                               ) } )}
                                <td><b>{countrytotal}</b></td>
                            </tbody>
                        </table>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                               <span>Counselor Registration Report</span>
                              
                                <div className="row ">
                                    <div className="col-md-12">
                                        
                                    <div class="table-head-wrpr" >
                                                    <table class="table collageprogram alt-colors" >
                                                        <thead class="fixedHeader table-hdr">
                                                            {/* <tr >
                                                                <th colSpan={9}></th>
                                                                <th  colSpan={3}>Data from April 1st 2022</th>
                                                                <th></th>
                                                            </tr> */}
                                                            <tr>
                                                                <th rowSpan={2}>Branch</th>
                                                                <th rowSpan={2}>Counselor</th>
                                                                <th colSpan={2}>Previous Financial Year</th>
                                                                <th colSpan={2}>Current Financial Year</th>
                                                                <th rowSpan={2}>Registrations Previous Month</th>
                                                                <th rowSpan={2}>Registrations Previous Month as of Current Date</th>
                                                                <th rowSpan={2}>Registrations Current Month as of Current Date</th>
                                                                <th style={{textAlign:'center'}} colSpan={5}>Data from April 1st 2022</th>
                                                                <th rowSpan={2}>This year Registered Moved to Not Interested this year</th>
                                                                
                                                            </tr>

                                                            <tr>
                                                                <th>Registrations</th>
                                                                <th>Fee Paid</th>
                                                                <th>Registrations</th>
                                                                <th>Fee Paid</th>
                                                                <th>Registered moved to not Interested within 30 days</th>
                                                                <th>Registered moved to not Interested within 31 - 60 days</th>
                                                                <th>Registered moved to not Interested within 61 - 90 days</th>
                                                                <th>Registered Moved to not Interested this year</th>
                                                                <th>Registered Moved to not Interested this month</th>
                                                            </tr>

                                                            
                                                            
                                                            <tr style={{backgroundColor: "rgb(109, 174, 230)"}}>
                                                                <th>Grand total</th>
                                                                <th></th>
                                                                <th>{grandTotalRegPrevFinancialYrfirstRow}</th>
                                                                <th>{grandtotalfeepaidpryrfirstrow}</th>
                                                                <th>{grandTotalRegCurrFinancialYrfirstRow}</th>
                                                                <th>{grandtotalfeepaidcryrfirstrow}</th>
                                                                <th>{grandtotalRegPrevMonthfirstRow}</th>
                                                                <th>{grandtotalRegPrevMonthastodayfirstRow}</th>
                                                                <th style={{ backgroundColor: '#47a524' }}>{grandtotalRegCurrMonthfirstRow}</th>
                                                                <th>{grandtotalInactive30DayfirstRow}</th>
                                                                <th>{grandtotalInactive60DayfirstRow}</th>
                                                                <th>{grandtotalInactive90DayfirstRow}</th>
                                                                <th>{grandtotalnotinterestedcffirstrow}</th>
                                                                <th>{grandtotalnotinterestedcmonthfirstrow}</th>
                                                                <th>{grandtotalRegyeartilldatefirstrow}</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                    </table>
                                        </div>

                                        <div class="table-body-wrpr" style={{ height:"450px"}} >
											<div style={{position:"relative"}}>
                                                <table border={1} className="table" style={{ fontSize:"12px"}}>
                                                <tbody className="table-bdy">
                                                    { counselorRegistrationReportApi.isLoading && "Loading" }
                                                    {result?.map((item, index) =>{
                                                        let offices = result?.filter(function(office){
                                                            return  office.office === item.office;
                                                    });
                                                    
                                                   
                                                    const exists = officeData.some(offc => offc.oid === item?.office_id);
                                                   
                                                    
                                                    if(office!==item.office){
                                                        serialNo=0
                                                        totalRegPrevFinancialYr=0;
                                                        totalRegCurrFinancialYr=0;
                                                        totalRegPrevMonth=0;
                                                        totalRegPrevMonthastoday=0;
                                                        totalRegCurrMonth=0;
                                                        totalInactive30Day=0;
                                                        totalInactive60Day=0;
                                                        totalInactive90Day=0;
                                                        totalRegyeartilldate=0;
                                                        totalfeepaidpryr=0;
                                                        totalfeepaidcryr=0;
                                                        totalnotinterestedcf=0;
                                                        totalnotinterestedcmonth=0;

                                                        //serialNo=serialNo+1;
                                                        totalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        totalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        totalRegPrevMonth+=item.prev_month_registrations
                                                        totalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0;
                                                        totalRegCurrMonth+=item.curr_month_registrations
                                                        totalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        totalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        totalInactive90Day+=item.registered_inactve_90_days?item.registered_inactve_90_days:0
                                                        totalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        totalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        totalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0
                                                        totalnotinterestedcf+=item.registered_all_inactive_financialyear_days?item.registered_all_inactive_financialyear_days:0;
                                                        totalnotinterestedcmonth+=item.registered_nis_current_month?item.registered_nis_current_month:0;

                                                        grandTotalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        grandTotalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        grandtotalRegPrevMonth+=item.prev_month_registrations
                                                        grandtotalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0
                                                        grandtotalRegCurrMonth+=item.curr_month_registrations
                                                        grandtotalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        grandtotalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        grandtotalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        grandtotalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        grandtotalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0
                                                        grandtotalnotinterestedcf+=item.registered_all_inactive_financialyear_days?item.registered_all_inactive_financialyear_days:0;
                                                        grandtotalnotinterestedcmonth+=item.registered_nis_current_month?item.registered_nis_current_month:0;

                                                        
                                                    }else{
                                                        serialNo=serialNo+1;
                                                        totalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        totalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        totalRegPrevMonth+=item.prev_month_registrations?item?.prev_month_registrations:0
                                                        totalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0
                                                        totalRegCurrMonth+=item.curr_month_registrations
                                                        totalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        totalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        totalInactive90Day+=item.registered_inactve_90_days?item.registered_inactve_90_days:0
                                                        totalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        totalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        totalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0
                                                        totalnotinterestedcf+=item.registered_all_inactive_financialyear_days?item.registered_all_inactive_financialyear_days:0;
                                                        totalnotinterestedcmonth+=item.registered_nis_current_month?item.registered_nis_current_month:0;

                                                        grandTotalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        grandTotalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        grandtotalRegPrevMonth+=item.prev_month_registrations
                                                        grandtotalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0
                                                        grandtotalRegCurrMonth+=item.curr_month_registrations
                                                        grandtotalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        grandtotalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        grandtotalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        grandtotalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        grandtotalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0
                                                        grandtotalnotinterestedcf+=item.registered_all_inactive_financialyear_days?item.registered_all_inactive_financialyear_days:0;

                                                    }
                                                    return(
                                                    <Fragment>
                                                        
                                                        <tr className="ui-sortable-handle">
                                                            {office!==item.office?<td style={{fontSize:"10px !important"}} rowSpan={offices.length}><b>{office=item.office }</b></td>: null}
                                                            <td>{item.username}</td>
                                                            
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                            <td><a data-value={ item?.user_id} style={{ textDecoration: 'underline', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(5, item?.user_id )}}  href="javascript:void(0)"> {item.registered_leads_prev_yr?item.registered_leads_prev_yr:0}</a></td>
                                                                ):(
                                                                    <td><a data-value={ item?.user_id}  href="javascript:void(0)"> {item.registered_leads_prev_yr?item.registered_leads_prev_yr:0}</a></td>
                                                                )
                                                            }
                                                            
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                <td><a data-value={ item?.user_id} style={{ textDecoration: 'underline' , cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(6, item?.user_id )}}  href="javascript:void(0)">{item.feepaid_prev_yr?item.feepaid_prev_yr:0}</a></td>
                                                            ):(
                                                                <td><a data-value={ item?.user_id}  href="javascript:void(0)">{item.feepaid_prev_yr?item.feepaid_prev_yr:0}</a></td>
                                                            )
                                                            }
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                <td><a data-value={ item?.user_id} style={{ textDecoration:  'underline', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(8, item?.user_id )}}  href="javascript:void(0)">{item.registered_leads_curr_yr?item.registered_leads_curr_yr:0}</a></td>
                                                            ):(
                                                                <td><a data-value={ item?.user_id}  href="javascript:void(0)">{item.registered_leads_curr_yr?item.registered_leads_curr_yr:0}</a></td>

                                                            )
                                                            }
                                                            
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                <td><a data-value={ item?.user_id} style={{ textDecoration:  'underline' , cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(9, item?.user_id )}}  href="javascript:void(0)">{item.feepaid_curr_yr?item.feepaid_curr_yr:0}</a></td>
                                                            ):(
                                                                
                                                                <td><a data-value={ item?.user_id}  href="javascript:void(0)">{item.feepaid_curr_yr?item.feepaid_curr_yr:0}</a></td>
                                                            )
                                                            }
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                    <td><a data-value={ item?.user_id} style={{ textDecoration:  'underline' , cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(10, item?.user_id )}}  href="javascript:void(0)">{item.prev_month_registrations?item.prev_month_registrations:0}</a></td>
                                                                ):(
                                                                    <td><a data-value={ item?.user_id}  href="javascript:void(0)">{item.prev_month_registrations?item.prev_month_registrations:0}</a></td>

                                                                )
                                                            }
                                                            
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                    <td><a data-value={ item?.user_id} style={{ textDecoration:  'underline' , cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(11, item?.user_id )}}  href="javascript:void(0)">{item.prev_month_registrationastoday?item.prev_month_registrationastoday:0}</a></td>
                                                                ):(
                                                                    <td><a data-value={ item?.user_id}  href="javascript:void(0)">{item.prev_month_registrationastoday?item.prev_month_registrationastoday:0}</a></td>

                                                                )
                                                            }

                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                    <td><a data-value={ item?.user_id} style={{ textDecoration:  'underline' , cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(7, item?.user_id )}}  href="javascript:void(0)">{item.curr_month_registrations?item.curr_month_registrations:0}</a></td>
                                                                ):(
                                                                    <td><a data-value={ item?.user_id}  href="javascript:void(0)">{item.curr_month_registrations?item.curr_month_registrations:0}</a></td>

                                                                )
                                                            }
                                                        
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                <td style={{  backgroundColor: item && item.registered_inactve_30_days >= 2 ? 'yellow' : '' }}>
                                                                    <a data-value={ item?.user_id}  style={{ textDecoration: item && item.registered_inactve_30_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}  onClick={() => statusModalOpen(1, item?.user_id)} href="javascript:void(0)">{item.registered_inactve_30_days ? item.registered_inactve_30_days : 0}</a>
                                                                </td>
                                                                ):(
                                                                    <td style={{  backgroundColor: item && item.registered_inactve_30_days >= 2 ? 'yellow' : '' }}>
                                                                        <a data-value={ item?.user_id}    href="javascript:void(0)">{item.registered_inactve_30_days ? item.registered_inactve_30_days : 0}</a>
                                                                    </td>
                                                                )
                                                            }
                                                          
                                                            {
                                                                ( userId===item.user_id) || (role===14) || (role===12 && exists) || role===2?(
                                                                    <td style={{ backgroundColor: item && item.registered_inactve_60_days >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_inactve_60_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(2, item?.user_id )}}  href="javascript:void(0)">{item.registered_inactve_60_days?item.registered_inactve_60_days:0}</a>
                                                                    </td>
                                                                 ):(
                                                                    <td style={{ backgroundColor: item && item.registered_inactve_60_days >= 4 ? 'yellow' : '' }} >
                                                                    <a data-value={ item?.user_id}  href="javascript:void(0)">{item.registered_inactve_60_days?item.registered_inactve_60_days:0}</a>
                                                                    </td>
                                                                 )
                                                            }

                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                    <td style={{ backgroundColor: item && item.registered_inactve_90_days >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_inactve_90_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(3, item?.user_id )}}  href="javascript:void(0)">{item.registered_inactve_90_days?item.registered_inactve_90_days:0}</a>
                                                                    </td>
                                                                ):(
                                                                    <td style={{ backgroundColor: item && item.registered_inactve_90_days >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id}   href="javascript:void(0)">{item.registered_inactve_90_days?item.registered_inactve_90_days:0}</a>
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                    <td style={{ backgroundColor: item && item.registered_all_inactive_financialyear_days >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_all_inactive_financialyear_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(12, item?.user_id )}}  href="javascript:void(0)">{item.registered_all_inactive_financialyear_days?item.registered_all_inactive_financialyear_days:0}</a>
                                                                    </td>
                                                                ):(
                                                                    <td style={{ backgroundColor: item && item.registered_all_inactive_financialyear_days >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id}   href="javascript:void(0)">{item.registered_all_inactive_financialyear_days?item.registered_all_inactive_financialyear_days:0}</a>
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                    <td style={{ backgroundColor: item && item.registered_nis_current_month >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_nis_current_month > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(13, item?.user_id )}}  href="javascript:void(0)">{item.registered_nis_current_month?item.registered_nis_current_month:0}</a>
                                                                    </td>
                                                                ):(
                                                                    <td style={{ backgroundColor: item && item.registered_nis_current_month >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id}   href="javascript:void(0)">{item.registered_nis_current_month?item.registered_nis_current_month:0}</a>
                                                                    </td>
                                                                )
                                                            }


                                                            {
                                                                userId===item.user_id || role===14 || (role===12 && exists) || role===2?(
                                                                    <td style={{ backgroundColor: item && item.registered_inactive_financialyear_days >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_inactive_financialyear_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(4, item?.user_id )}}  href="javascript:void(0)">{item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0}</a>
                                                                    </td>
                                                                ):(
                                                                    <td style={{ backgroundColor: item && item.registered_inactive_financialyear_days >= 4 ? 'yellow' : '' }} >
                                                                        <a data-value={ item?.user_id}   href="javascript:void(0)">{item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0}</a>
                                                                    </td>
                                                                )
                                                            }

                                                        </tr>
                                                        {offices.length-1 === serialNo ? (
                                                            <tr style={{backgroundColor: "#6daee6"}}>
                                                                <td colSpan={2}><b>Total</b></td>
                                                                <td><b>{totalRegPrevFinancialYr}</b></td>
                                                                <td><b>{totalfeepaidpryr}</b></td>
                                                                <td><b>{totalRegCurrFinancialYr}</b></td>
                                                                <td><b>{totalfeepaidcryr}</b></td>
                                                                <td><b>{totalRegPrevMonth}</b></td>
                                                                <td><b>{totalRegPrevMonthastoday}</b></td>
                                                                <td><b>{totalRegCurrMonth}</b></td>
                                                                <td><b>{totalInactive30Day}</b></td>
                                                                <td><b>{totalInactive60Day}</b></td>
                                                                <td><b>{totalInactive90Day}</b></td>
                                                                <td><b>{totalnotinterestedcf}</b></td>
                                                                <td><b>{totalnotinterestedcmonth}</b></td>
                                                                <td><b>{totalRegyeartilldate}</b></td>
                                                            </tr>
                                                        ):null}
                                                    </Fragment>
                                                    )}
                                                )}
                                                    <tr>
                                                        <td ><b>Grand Total</b></td>
                                                        <td></td>
                                                        <td><b>{grandTotalRegPrevFinancialYr}</b></td>
                                                        <td><b>{grandtotalfeepaidpryr}</b></td>
                                                        <td><b>{grandTotalRegCurrFinancialYr}</b></td>
                                                        <td><b>{grandtotalfeepaidcryr}</b></td>
                                                        <td><b>{grandtotalRegPrevMonth}</b></td>
                                                        <td><b>{grandtotalRegPrevMonthastoday}</b></td>
                                                        <td><b>{grandtotalRegCurrMonth}</b></td>
                                                        <td><b>{grandtotalInactive30Day}</b></td>
                                                        <td><b>{grandtotalInactive60Day}</b></td>
                                                        <td><b>{grandtotalInactive90DayfirstRow}</b></td>
                                                        <td><b>{grandtotalnotinterestedcffirstrow}</b></td>
                                                        <td><b>{grandtotalnotinterestedcmonthfirstrow}</b></td>
                                                        <td><b>{grandtotalRegyeartilldate}</b></td>
                                                    </tr>
                                            </tbody>

                                            </table>
                                                
                                            </div>
                                        </div>
                                    
                                        <div className="table-list-search-wrpr  table-container tableContainer">
                                            <div className="row">
                                                <div className="col-md-6">
                                                </div>
                                            
                                                <div className="col-md-6 text-right">
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>

                
        <Modal
            scroll
            blur
            closeButton
            width="70%"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={visibleStatus}
            onClose={handleCloseStatus}
            size="sm"
        >
            <Modal.Header>
                <Text b size="16px" className="text-center">{status}</Text>
            </Modal.Header>
            <Modal.Body>
            {loader ? (
                <div className="loader" >
                <LoaderCommon />
                </div>
            ) : (
                <table className="table report collageprogram alt-colors">
                    <thead>
            
                        <tr style={{background:'rgb(50 58 81 )'}}>
                            <th>Applicant Name</th>
                            <th>VisaType</th>
                            <th>Phone</th>  
                            <th>Email</th>
                            { type===11 &&<th>Counselor</th> }
                            { type!==11 &&<th>Registered date</th> }
                            { type===1 || type===2 || type===3 || type===4 &&<th>Not Interested date</th> }
                            
                        </tr>
                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        {
                                applicantresult?.map((item, index) => (
                                    <tr>
                                        {type !== 11 ? (
                                            <td>{item.applicant_name}</td>
                                        ) : <td>{item.full_name}</td>}
                                        <td>{item?.description}</td>
                                        <td>{item?.mobile_no}</td>
                                        <td>{item?.email}</td>
                                        {type === 11 ? (
                                            <td>{item.counselor}</td>
                                        ) : null}
                                        { type!==11 && <td>{formatDateToDDMMYYYY(item?.registered_date)}</td> }
                                        { type===1 || type===2 || type===3 || type===4 && <td>{formatDateToDDMMYYYY(item?.notinteresteddate)}</td> }
                                    </tr>

                                ))
                        }
                   
                    </tbody>
                </table> )}
            </Modal.Body>
        </Modal>
                
            </div>
        </Fragment>
    )

}
export default CounselorRegistration;