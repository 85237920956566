import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../../store/userStore';
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../Common/financialYear';
import LoaderCommon from "../../Common/LoaderCommon";
import OfficeDetailedReport from "./OfficeDetailedReport";


const Officevisa = () => {
    const user = userStore();
    const [result, setResult] = useState([]);
    const [loader, setLoader] = useState(false);
    const navigation = useNavigate();
    const currentFinancialYear = getCurrentFinancialYear();
    const nextFinancialYear = getNextFinancialYear();
    const previousFinancialYear = getPreviousFinancialYear();
    const [dateRange, setDateRange] = useState("CURRENT");
    const [detailed, setDetailed]= useState(false)
    const [datevalue, setDatevalue]= useState({});
    const [detailedResult, setDetailedResult]= useState([]);

    useEffect(() => {
        const currentFY = getFinancialYear('CURRENT');
        officevisaapi.mutateAsync({ from: currentFY.startDate, to: currentFY.endDate });
    }, []);

    const officevisaapi = authQueries.useOfficevisaReport(
        (response) => {
            console.log("directQueryResults",response.data?.directQueryResults)
            setResult(response.data?.directQueryResults);
            setDetailedResult(response.data?.storedProcedureResults)
        }
    );

    const selectDate = (e) => {
        setDateRange(e.target.value);
        const dateValue = getFinancialYear(e.target.value);
        setDatevalue(dateValue)
        officevisaapi.mutateAsync({ from: dateValue.startDate, to: dateValue.endDate });
    };

    function getFinancialYear(type) {
        const today = new Date();
        let currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
        if (currentMonth < 3) {
            currentYear--;
        }
        const getFinancialYearDates = (startYear) => {
            const startDate = `${startYear}-04-01`;
            const endDate = `${startYear + 1}-03-31`;
            return { startDate, endDate };
        };
        let financialYear;
        switch (type) {
            case 'CURRENT':
                financialYear = getFinancialYearDates(currentYear);
                break;
            case 'PREVIOUS':
                financialYear = getFinancialYearDates(currentYear - 1);
                break;
            case 'FUTURE':
                financialYear = getFinancialYearDates(currentYear + 1);
                break;
            default:
                throw new Error("Invalid type. Please use 'CURRENT', 'PREVIOUS', or 'FUTURE'.");
        }
        return financialYear;
    }
    const officeTotal = result.reduce((total, item) => total + (item.visacount || 0), 0);
    return (
        <Fragment>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Top performing Offices</li>
                    </ol>
                </nav>
            </div>

            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <h2>Top performing Offices</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr_employee">
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                                    <div className='col-span-3'>
                                        <label>Select Date</label>
                                        <select value={dateRange} className='form-control form-control-sm !w-full !min-h-[38px]' onChange={selectDate}>
                                            <option value="PREVIOUS">Previous Financial Year ({previousFinancialYear})</option>
                                            <option value="CURRENT">Current Financial Year ({currentFinancialYear})</option>
                                            <option value="FUTURE">Next Financial Year ({nextFinancialYear})</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-head-wrpr">
                                            <table className="table collageprogram alt-colors">
                                                <thead className="fixedHeader table-hdr">
                                                    <tr>
                                                        <th style={{ textAlign: "center" }}>Sno</th>
                                                        <th style={{ textAlign: "center" }}>Office</th>
                                                        <th style={{ textAlign: "center" }}>Total for office</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ fontWeight: "bold", backgroundColor:'rgb(109, 174, 230)' }}>
                                                        <td></td>
                                                        <td style={{ textAlign: "center" }}>Grand Total</td>
                                                        <td style={{ textAlign: "center" }}>{officeTotal}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {officevisaapi.isLoading ?
                                        <LoaderCommon />:
                                        <div className="table-body-wrpr" style={{ height: "550px" }}>
                                            <div style={{ position: "relative" }}>
                                                <table border={1} className="table" style={{ fontSize: "12px" }}>
                                                    <tbody className="table-bdy">
                                                    { 
                                                        result.map((item, itemIndex) => {
                                                         
                                                            return (
                                                                <tr key={`-${itemIndex}`} className={`ui-sortable-handle`}>
                                                                    <td style={{ textAlign: "center" }}>{itemIndex+1}</td>
                                                                    <td  style={{ textAlign: "center", fontWeight: "bold" }}>{item?.office}</td>
                                                                    <td  style={{ textAlign: "center", fontWeight: "bold" }}>{item?.visacount}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr style={{ fontWeight: "bold", backgroundColor:'rgb(109, 174, 230)' }}>
                                                            <td></td>
                                                            <td style={{ textAlign: "center" }}>Grand Total</td>
                                                            <td style={{ textAlign: "center" }}>{officeTotal}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                        

                                        }
                                    </div>
                                </div>    
                                {
                                    detailed &&
                                    <OfficeDetailedReport datevalue={datevalue} result={detailedResult} />
                                }
                                {
                                    detailed? <a style={{float:'right',  textDecoration: 'underline', cursor: "pointer", color: "blue" }} href="javascript:void(0)" onClick={()=>{setDetailed(false)}}> Hide Detailed Report</a>:<a style={{float:'right',  textDecoration: 'underline', cursor: "pointer", color: "blue" }} href="javascript:void(0)" onClick={()=>{setDetailed(true)}}> View Detailed Report</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Officevisa;
