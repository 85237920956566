import React,{useState,useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../../queries/authQueries';
import { userStore } from "../../../store/userStore";


const validationSchema = yup.object({

    question: yup
      .string('⚠ Enter Question')
      .required('⚠ Question is required')
      .min(2,'⚠ Question must be more than 2 characters '),
    status: yup
      .string('⚠ Enter your status')
      .required('⚠ Status is required'),
  });


const  EditAddInfo = () => {

    const [errName, setErrName] = useState('')

    const navigate = useNavigate();

    const master = userStore();

    useEffect(()=>{
        InfoData.mutateAsync(master.info);
    },[])

    const InfoData = authQueries.useInfoMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    question:response?.data.question,
                    status:response?.data.status
                })
                reset({ ...datas });
            }
        }   
    );


    const { register, reset, handleSubmit, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const editInfo = authQueries.useInfoMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listinfo')
            }else if(response?.error[0].question){
                setErrName("⚠ "+response?.error[0].question)
            }
        }   
    );
        


    const onSubmit = (data) => {

    
        const datavalues = {
            id:master.info,
            question: data.question,
            status: data.status,
    
        };
    
        editInfo.mutateAsync(datavalues);    
    
      }


  return (

    <div>

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/listinfo">Additional Information</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Edit Information</li>
            </ol>
        </nav>

        <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Edit Information
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Question</label>
                                                <input className="form-control"  {...register("question")} type="text" name='question' />
                                                <p className='error'>{errors.question?.message} {errName}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Status</label>
                                            <select  {...register("status")}  className="form-control"  name="status">
                                                <option value="">Select</option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="INACTIVE">INACTIVE</option>
                                            </select>       
                                            <p className='error'>{errors.status?.message}</p>
                                        </div>

                                        <div id="output"></div>
                                    
                                </div>
                            </div>  

                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Update</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
        </div>

    </div>

  )

}

export default EditAddInfo