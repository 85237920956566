import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { Link } from 'react-router-dom'
import LoaderCommon from '../../Common/LoaderCommon'
import moment from 'moment';
import { Empty } from 'antd';
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../Common/financialYear';
import { Modal } from '@nextui-org/react';
import LeadStatus from '../../Common/LeadStatus';
import { Pagination, PaginationItem } from '@mui/material';


const AssociateReport = () => {

    const currentFinancialYear = getCurrentFinancialYear()
    const nextFinancialYear = getNextFinancialYear()
    const previousFinancialYear = getPreviousFinancialYear()

    const today = new Date()
    const [result, setResult] = useState([])
    const [dateRange, setDateRange] = useState("CURRENT")
    const [fromDate, setFromDate] = useState(today)
    const [toDate, setToDate]= useState(today)

    const associatesReport = authQueries.useAssociatesReportMutation(
        (res)=>{
            setResult(res)
        }
    )

    useEffect(()=>{
        associatesReport.mutateAsync({from: fromDate, to: toDate, type: dateRange })
    },[])


    const onSearch =()=>{
        associatesReport.mutateAsync({from: fromDate, to: toDate, type: dateRange })
    }

    const selectDate=(e)=>{
        setDateRange(e.target.value)
    }


  return (
    <div>

        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Associates Report</li>
                </ol>
            </nav>
        </div>
            
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                    </i>
                    <h2><span>&nbsp;</span>Associates Report</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        Associates Report
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="table-list-search-wrpr table-report-ceo overflow-auto">
                                        <div className="row">
                                            <div className="col-md-6">
                                            </div>
                                            <div className="col-md-6 text-right">
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                                        <div className='col-span-3'>
                                            <label>Select Date</label>
                                            <select value={dateRange} className='form-control form-control-sm !w-full !min-h-[38px]' onChange={selectDate}>
                                                <option value="PREVIOUS">Previous Financial Year ({previousFinancialYear})</option>
                                                <option value="CURRENT">Current Financial Year ({currentFinancialYear})</option>
                                                <option value="FUTURE">Next Financial Year ({nextFinancialYear})</option>
                                                <option value="CUSTOM">Custom Date</option>
                                            </select>
                                        </div>
                                        {dateRange === "CUSTOM" &&
                                            <Fragment>
                                                <div className='col-span-2'>
                                                    <label>From Date</label>
                                                    <input 
                                                    max={moment(toDate).format("YYYY-MM-DD")} 
                                                    value={moment(fromDate).format('YYYY-MM-DD')} 
                                                    type='date' onChange={(e)=> {setFromDate(e.target.value)}} 
                                                    className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                    />
                                                </div>
                                                <div className='col-span-2'>
                                                    <label>To Date</label>
                                                    <input 
                                                    min={moment(fromDate).format("YYYY-MM-DD")} 
                                                    value={moment(toDate).format('YYYY-MM-DD')} 
                                                    type='date' onChange={(e)=> {setToDate(e.target.value)}} 
                                                    className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                    />
                                                </div>
                                            </Fragment>
                                        }
                                        <div className='col-span-1 flex flex-col'>
                                            <label>&nbsp;</label>
                                            <button onClick={onSearch} className='mt-[3px] rounded-md text-[12px] !w-full !min-h-[38px] bg-[#323a51] border-none text-white'>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    {associatesReport?.isLoading ?
                                        <LoaderCommon />:
                                        <div className='overflow-x-auto max-h-[600px]'>
                                            {
                                                result?.data?.length > 0 ?
                                                <table border={1} className="table report associate-report collageprogram overflow-auto">
                                                    <thead className="sticky top-0">
                                                        <tr className='text-center'>
                                                            <th className='text-left'>Sl No</th>
                                                            <th className='text-left'>Associates Name</th>
                                                            <th>Active Clients</th>
                                                            <th>Registered Client</th>
                                                            <th>Preparing Visa</th>
                                                            <th>Visa Applied</th>
                                                            <th>Visa Approved</th>
                                                            <th>Expected Student Visas</th>
                                                            <th>Not Interested</th>
                                                            <th>Not Qualified</th>
                                                        </tr>
                                                        <AssociateReportTable data={result.grandTotal} />
                                                    </thead>
                                                    <tbody className="h-4 overflow-y-auto">
                                                        {
                                                            result?.data?.map((item, index)=>(
                                                                <tr className='text-center' key={index}>
                                                                    <td className='font-normal text-left'>{index+1}</td>
                                                                    <td className='font-normal text-left'>
                                                                        <div className='flex flex-col'>
                                                                            <div className='font-medium'>{item?.name}</div>
                                                                            <div>{item?.phone}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td><AssociatesCountModal item={item} count={item?.activeCount} type="active" to={toDate} from={fromDate} dateRange={dateRange} /></td>
                                                                    <td><AssociatesCountModal item={item} count={item?.registeredCount} type="registered" to={toDate} from={fromDate} dateRange={dateRange} /></td>
                                                                    <td><AssociatesCountModal item={item} count={item?.visaPrepareCount} type="prepare" to={toDate} from={fromDate} dateRange={dateRange} /></td>
                                                                    <td><AssociatesCountModal item={item} count={item?.visaAppliedCount} type="applied" to={toDate} from={fromDate} dateRange={dateRange} /></td>
                                                                    <td><AssociatesCountModal item={item} count={item?.visaApprovedCount} type="approved" to={toDate} from={fromDate} dateRange={dateRange} /></td>
                                                                    <td className='bg-cyan-700 text-white'>
                                                                        <AssociatesCountModal item={item} count={item?.expectVisa} type="expectVisa" to={toDate} from={fromDate} dateRange={dateRange} />
                                                                    </td>
                                                                    <td><AssociatesCountModal item={item} count={item?.notInterestedCount} type="notinterested" to={toDate} from={fromDate} dateRange={dateRange} /></td>
                                                                    <td><AssociatesCountModal item={item} count={item?.notQualifiedCount} type="notqualified" to={toDate} from={fromDate} dateRange={dateRange} /></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                    <tbody>
                                                        <AssociateReportTable data={result.grandTotal} />
                                                    </tbody>

                                                </table>:
                                                <div className='mt-28 mb-28'>
                                                    <Empty description={<div className="text-sm">No data found!</div>} />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      
    </div>
  )
}

export default AssociateReport

const AssociateReportTable = ({data})=>{
    return(
        <Fragment>
            <tr className='text-[13px] !font-bold text-center bg-[#6daee6]'>
                <th></th>
                <th className="text-left">Grand Total</th>
                <th>{data?.activeCount}</th>
                <th>{data?.registeredCount}</th>
                <th>{data?.visaPrepareCount}</th>
                <th>{data?.visaAppliedCount}</th>
                <th>{data?.visaApprovedCount}</th>
                <th>{data?.expectVisa}</th>
                <th>{data?.notInterestedCount}</th>
                <th>{data?.notQualifiedCount}</th>
            </tr>
        </Fragment>
    )
}

const AssociatesCountModal =({count, item, type, to, from, dateRange})=>{

    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])

    const applicantList = authQueries.useAssociatesReportListMutation(
        (res)=>{
            setResult(res?.data)
        }
    )

    const handleOpen = () => {
        loadingData()
        setOpen(true)
    }

    const loadingData =(page)=>{
        applicantList.mutateAsync({
            page: page ? page : 0,
            size: 25,
            type: type,
            to: to,
            from: from,
            dateRange: dateRange,
            id: item?.id
        })
    }

    const handlePageChange = (e, value) => {
        loadingData(value-1)
    }

    const handleClose = () => setOpen(false)

    return(
        <Fragment>
            {
                count == 0 ? 0 :
                <button className={`${type === "expectVisa" ? "text-white" : "text-[blue]"} underline`} onClick={handleOpen}>{count}</button>
            }

        <Modal
        open={open}
        closeButton={true}
        onClose={handleClose}
        scroll
        blur
        width="90%"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        >
            <Modal.Header>
                <h3 className="font-medium text-[16px]">Associates Report</h3>
            </Modal.Header>
            <Modal.Body>
            <div className="table-wrpr !mb-10">
                <div className="table-section">
                    <table className="table prospect-table">
                        <thead>
                            <tr>
                                <th width="30">#</th>
                                <th>Profile Status</th>
                                <th>Office</th>
                                <th>Date Created</th>
                                <th>Applicant Name</th>
                                <th>Contact Number</th>
                                <th>Visa Type</th>
                                <th>Intake</th>
                                <th>Counselor</th>
                                <th>Reg. Date</th>
                                <th>Prospect Status</th>
                            </tr>
                        </thead>
                        <tbody className="ui-sortable">
                            {result?.items?.map((app, index) => (
                                <Fragment key={index}>
                                    <tr className="ui-sortable-handle" key={index}>
                                        <th className="">{(index+1) + (result?.currentPage * 25)}</th>
                                        <td>
                                            <LeadStatus item={app} />
                                        </td>
                                        <td>{app?.office?.name}</td>
                                        <td>{moment(app?.createdAt).format('DD-MM-YYYY')}</td>
                                        <td>
                                            {app?.applicant_name} {app?.middlename} {app?.lastname} 
                                        </td>
                                        <td>
                                            <div className="email-mobile flex flex-col">
                                                <div>{app?.mobile_no}</div>
                                                <div>{app?.landline_no}</div>
                                                <div>{app?.whatsapp_no}</div>
                                            </div>                                                                    
                                        </td>
                                        <td>{app?.visatype?.description}</td>
                                        <td>{app?.intake?.intake}</td>
                                        <td>{app?.assignUser?.CounselorAssign?.userdetail?.firstname} {app?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                        <td>
                                            {app?.registered_date ? <span className="registered">{moment(app?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                        </td>
                                        <td>
                                            {app?.status_of_prospect?.name}
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}												  
                        </tbody>
                    </table>
                    {result?.totalPages > 1 &&
                        <ul className="pagination pagination-sm justify-content-center table-pagination mt-4 mb-3 custom-pagination">
                            <Pagination
                                count={result?.totalPages}
                                nextText="Next"
                                prevText="Previous"
                                renderItem={
                                    (element) => (
                                        <div>
                                        <PaginationItem
                                        component="li"
                                        {...element}
                                        className={`!bg-white !text-[12px] ${element.selected && '!border-rose-500'}`}
                                        />
                                        </div>
                                    )
                                }
                                variant="outlined"
                                page={result?.currentPage + 1}
                                shape="rounded"                
                                onChange={handlePageChange}
                            />
                        </ul>
                    }
                </div>
            </div>
            </Modal.Body>
        </Modal>

        </Fragment>
    )
}