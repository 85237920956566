import { Checkbox, Loading, Avatar } from '@nextui-org/react'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import moment from 'moment'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { userStore } from '../../store/userStore'
import { AutoComplete, Button, Empty, Modal, Popconfirm, Tooltip } from 'antd'
import { decode } from 'html-entities'
import { useApi } from './ApiProvider'
import LeadStatus from './LeadStatus'
import { IconDeviceFloppy, IconMessage, IconMessagePlus } from '@tabler/icons-react'
import VisaStaffStatusOptions from './VisaStaffStatusOptions'
import HOT from '../../assets/images/hot-icon-2.png'
import WARM from '../../assets/images/warm-icon-2.png'
import COLD from '../../assets/images/cold-icon-2.png'
import LONG from '../../assets/images/longterm-icon-3.png'
import HOLD from '../../assets/images/on-hold-icon.png'
import AutoCompleteInput from './AutoCompleteInput'
import Swal from 'sweetalert2'
import { InactiveStatus } from '../../Data/InactiveStatus'
import { useFollowupStore } from '../../store/followupStore'
import TooltipError from './TooltipError'

const FollowUp = ({ item, statusHidden, label, historyOnly, closePopover }) => {

    const store = userStore()
    const {setApiDataAndNotify} = useApi()

    const validationRules = yup.object().shape({
		date: yup
			.string('⚠ Please Select Date')
			.required('⚠ Date is required'),
	});

	const commentOnlyValid = yup.object().shape({
		comment: yup
			.string('⚠ Please enter comments')
			.required('⚠ comments is required'),
	});

    const prospectStatusValid = yup.object().shape({
		prospect: yup
			.string('⚠ Please Select Prospect Status')
			.required('⚠ Prospect Status is required'),
	});

    const applicant = useFollowupStore(state=> state.application)
    const setApplicant = useFollowupStore(state=> state.setApplication)

    const [visible, setVisible] = useState(false)
    const [loader, setLoader] = useState(true)
    const [activeFollow, setActiveFollow] = useState(null)
	const [follow, setFollow] = useState("FOLLOW")
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
    const [followApp, setFollowApp] = useState([])
    const [followHistory, setFollowHistory] = useState([])
    const [status, setStatus] = useState([])
    const [followupComment, setFollowupComment] = useState("")
    const [users, setUsers] = useState([])
    const [assignedDetails, setAssignedDetails]= useState([]);

    const [showStatus, setShowStatus] = useState(false)
    const [followDone, setFollowDone] = useState(false)

    const followList = authQueries.useAppointClientList(
		async (response) => {
			setFollowApp(response)
		}
	)

    const followHistoryList = authQueries.useFollowupHistoryList(
		async (response) => {
			setFollowHistory(response)
            setLoader(false)
		}
	)

    const listStatus = authQueries.useStatusOfProspectsRoleList(
        (response) => {
            setStatus(response)
            setLoader(false)
        }
    );

    const assignedList = authQueries.useApplicantAssignedUsersDetails(
        (res)=>{
            setAssignedDetails(res)
        }
    )

    const handleOpen =()=>{
        setVisible(true)
        setApplicant(item)
        if(closePopover){
            closePopover()
        }
        userList.mutateAsync(item?.id)
        assignedList.mutateAsync(item?.id)
        followList.mutateAsync(item?.id)
        followHistoryList.mutateAsync(item?.id)
        listStatus.mutateAsync({role: store?.user?.rid, visa: item?.visa_type_id, showstatus: item?.registered_status})
    }

    const {register, handleSubmit, reset, formState: { errors }, control, setValue} = useForm({
		resolver: yupResolver(follow === "FOLLOW" ? validationRules : follow === "GENERAL" ? commentOnlyValid : prospectStatusValid)
	})

    const handleClose =()=> {
		setVisible(false)
		setActiveFollow(null)
        setFollow("FOLLOW")
        setShowStatus(false)
        if(followDone){
            setApiDataAndNotify(followApp)
            setFollowDone(false)
        }
	}

    
    const followEnable =(value)=> {
		setFollow(value)
	}

    const onSubmit =(data) => {
		const datavalues = {
			applicant_id: applicant?.id,
			assign: data.assignee ? data.assignee : store?.user?.id,
			comments: data.comment,
			type: follow === "FOLLOW" ? "FOLLOW-UP" : "GENERAL",
			followupdate: follow === "FOLLOW" ? data.date + " " + data?.time  : null,
            date_enable: data?.time ? "YES" : "NO",
			status: "ACTIVE"
		}
		createFollow.mutateAsync(datavalues);
	}

    const onSubmitProspect =(data) => {
        if(parseInt(data?.prospect) === 18){
            Swal.fire({
                text: "Are you sure you want to update the status to Visa Refusal",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonColor: 'blue',
                cancelButtonColor: 'gray'
              }).then((result) => {
                if(result.isConfirmed) {
                    const datavalues = {
                        applicant_id: applicant?.id,
                        visa: applicant?.visa_type_id,
                        prospects_id: data.prospect,
                        comments: data.comment,
                        status: "ACTIVE"
                    }
                    addProspect.mutateAsync(datavalues);
                    Swal.fire("Saved!", "", "success");
                } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                    return
                }
            })
        }else{
            const datavalues = {
                applicant_id: applicant?.id,
                visa: applicant?.visa_type_id,
                prospects_id: data.prospect,
                comments: data.comment,
                status: "ACTIVE"
            }
            addProspect.mutateAsync(datavalues);
        }
    }

    const addProspect = authQueries.useProStatusAdd(
        async (response) => {
            const dataValues = {
                prospect: '',
                comment: ''
            }
            reset(dataValues)
            await setApiDataAndNotify(response)
            const inactive = InactiveStatus?.find(item => item === parseInt(response?.data?.sid))
            if(inactive){
                Swal.fire({
                    text: "Prospect Status Changed To Inactive",
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Close",
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleClose()
                    }
                })
            }
            await followHistoryList.mutateAsync(applicant?.id)
        }
    );

    const userList = authQueries.useUserFollowMutationList(
        (res) => {
            let dataArr = []
            res?.data?.forEach(element => {
                const data = {
                    id: element?.id,
                    name: element?.userdetail?.firstname + " " + element?.userdetail?.lastname
                }
                dataArr.push(data)
                if(element.id === store.user.id){
                    setValue('assignee', element.id)
                }
            });
            setUsers(dataArr)
        }
    )

    const createFollow = authQueries.useAppointClientAdd(
		async (response) => {
            setApiDataAndNotify(response)
			followList.mutateAsync(applicant?.id)
			followHistoryList.mutateAsync(applicant?.id)
			setValue('comment', '')
            setValue('date', '')
            setValue("time", '')
		}
	)

    const createComment = authQueries.useFollowupCommentCreateMutation(
		(res)=>{
			setFollowupComment("")
			setActiveFollow(null)
			followList.mutateAsync(applicant?.id)
            followHistoryList.mutateAsync(applicant?.id)
		}
	)

    const commentCreate =(id)=> {
		const dataValues = {
			fid: id,
			comments: followupComment
		}
		createComment.mutateAsync(dataValues)
	}   
    
	const confirmCheck = authQueries.useCounselorDashboardConfirmMutation(
		(res)=> {
            setFollowDone(true)
			followList.mutateAsync(applicant?.id)
            followHistoryList.mutateAsync(applicant?.id)
		}
	)
    
    const commentCreateAndClose =async(id)=> {
		const dataValues = {
			fid: id,
			comments: followupComment
		}
		await createComment.mutateAsync(dataValues)
        confirmCheck.mutateAsync({id: id})
	}

    const listProspect = authQueries.useProStatusList(
        (response) => {
        }
    );

    const handleChangeStatus =(e)=>{
        setShowStatus(!showStatus)
        if(e){
            listProspect.mutateAsync(applicant?.id)
        }
    }

    const leadTypes = [
        {name: "Hot", value: "HOT", img: HOT},
        {name: "Warm", value: "WARM", img: WARM},
        {name: "Cold", value: "COLD", img: COLD}
    ]

    const handleLeadStatus = (value) => {
        const dataValues = {
            ...applicant,
            lead_status: value
        }
        setApplicant(dataValues)
        changeLeadStatus.mutateAsync({id: applicant?.id, status: value})
    }

    const changeLeadStatus = authQueries.useChangeLeadStatus(
        (response) => {
            setApiDataAndNotify(response)
        }
    )

    const offerAccepted = () => {
        const accepted = item?.intended_programs?.find(item => item?.sid === 7)
        if(accepted){
            return true
        }else{
            return false
        }
    }

    const accepted = offerAccepted()

  return (
    <div>
        <button onClick={handleOpen} className='btn-customized'>{label ? label : "Followup/comments"}</button>
        <Modal
        open={visible}
        closable
        closeIcon={<button className='text-black' onClick={handleClose}>Close</button>}
        onCancel={handleClose}
        width="100%"
        wrapClassName='modal-customized followup-modal'
        centered
        style={{maxWidth:'100%', minHeight:'100%', height:'100%'}}
        footer={null}
        >
            <div className='mb-4 flex items-center justify-center gap-2'>
                <h5 className="modal-title text-center">Follow Up / Comments - {applicant?.applicant_name} {applicant?.middlename} {applicant?.lastname}</h5>
                {
                    showStatus ? 
                    <div style={{fontSize:'16px', cursor:'pointer', color:'#00f'}} onClick={()=> handleChangeStatus(false)}>&nbsp; ( {applicant?.status_of_prospect?.name} )</div>:
                    <div style={{fontSize:'16px', cursor:'pointer', color:'#00f'}} onClick={()=> handleChangeStatus(true)}>&nbsp; ( {applicant?.status_of_prospect?.name} )</div>
                }
            </div>
            
            <Fragment>
                {
                    showStatus ?
                    <Fragment>
                        <div className="modal-body">
                            <div className='mb-4'>
                                <div onClick={()=> {setShowStatus(false)}} style={{display:'flex', alignItems:'center', fontSize:'16px', gap:4, cursor:'pointer'}}><i className='icon-keyboard_arrow_left' style={{fontSize:'22px'}}></i> Back</div>
                            </div>
                            <div className="table-wrpr">
                                <div className="table-section">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table className="table prospect-table">
                                                <thead>
                                                <tr>
                                                    <th width="8%">Date</th>
                                                    <th width="20%">Status</th>
                                                    <th width="62%">Comment</th>
                                                    <th width="10%">Commented By</th>
                                                </tr>
                                                </thead>
                                                <tbody >
                                                {
                                                    listProspect?.data?.data?.map((prostatus, index) => (
                                                    <tr className="ui-sortable-handle" key={index}>
                                                        <td>{moment(prostatus?.createdAt).format("DD-MM-YYYY")}</td>
                                                        <td>{decode(prostatus?.status_of_prospect?.name)}</td>
                                                        <td>{prostatus?.comments}</td>
                                                        <td>{prostatus?.createdBy?.userdetail?.firstname} {prostatus?.createdBy?.userdetail?.lastname}</td>
                                                    </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        {
                            loader ?
                            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', height:'100%'}}>
                                <Loading size='lg' />
                                <span style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
                            </div>
                            :
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-3 max-[1500px]:col-span-4 max-[916px]:col-span-12">
                                    {
                                        // ((!historyOnly && item?.email) || (item?.pros_status === 2 || item?.pros_status === 3)) &&
                                        !historyOnly &&
                                        <div className='add-general-comment-wrpr card mb-4'>
                                            <div className="card-body">

                                                <div className='bg-white text-[12px] grid grid-cols-12 max-[1065px]:grid-cols-4 max-[916px]:grid-cols-12 p-[5px] rounded-md text-center mb-3 shadow-md border border-slate-100' >
                                                    <div
                                                    onClick={()=>followEnable("FOLLOW")}
                                                    className={`${statusHidden ? "col-span-6" : "col-span-4"} ${follow === "FOLLOW" && 'custom-color text-white'} p-[7px] rounded-lg cursor-pointer`}
                                                    >Followup</div>
                                                    <div
                                                    onClick={()=>followEnable("GENERAL")}
                                                    className={`${statusHidden ? "col-span-6" : "col-span-4"} ${follow === "GENERAL" && 'custom-color text-white'} p-[7px] rounded-lg cursor-pointer`}
                                                    >General</div>
                                                    {
                                                        !statusHidden &&
                                                        <div
                                                        onClick={()=>followEnable("PROSPECT")}
                                                        className={`col-span-4 ${follow === "PROSPECT" && 'custom-color text-white'} p-[7px] rounded-lg cursor-pointer`}
                                                        >Status</div>
                                                    }
                                                </div>

                                                <form onSubmit={handleSubmit(follow === "PROSPECT" ? onSubmitProspect:onSubmit)}>
                                                    <div className="row setfollowup">
                                                        {
                                                            follow === "FOLLOW" &&
                                                            <Fragment>
                                                                <div className="col-md-12">
                                                                    <div className={`grid grid-cols-12 gap-x-6 gap-y-3`}>
                                                                        <div className={`col-span-12 flex flex-col gap-2`}>
                                                                            <AutoCompleteInput
                                                                            item={{
                                                                            name: "assignee",
                                                                            placeholder: "Select User",
                                                                            label: "Assigned To",
                                                                            required: true
                                                                            }}
                                                                            register={register}
                                                                            errors={errors}
                                                                            control={control}
                                                                            options={users}
                                                                            optionProps={{name: "name", name2: "", value: "id", value2: ""}}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label for="">Date:</label><br />
                                                                        <input 
                                                                        {...register('date')} 
                                                                        min={today}
                                                                        name="date" 
                                                                        id="arrival_date3" 
                                                                        type="date" 
                                                                        className="form-control applicant-date" 
                                                                        />
                                                                        <div className="mt-2" style={{color:'#f00', fontSize:"14px"}}>{errors.date?.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label for="">Time:</label><br />
                                                                        <input 
                                                                        {...register('time')} 
                                                                        name="time" 
                                                                        id="arrival_date3" 
                                                                        type="time" 
                                                                        className="form-control applicant-date" 
                                                                        />
                                                                        <div className="mt-2" style={{color:'#f00', fontSize:"14px"}}>{errors.time?.message}</div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                        {
                                                            follow === "PROSPECT" &&
                                                            <div className="col-md-12">
                                                                <div className="flex flex-col gap-1">
                                                                    <div className='text-[12px] h-4 font-[500]'>Prospect Status</div>
                                                                        <select
                                                                        disabled={(!accepted && store?.user?.role === "VISASTAFF" && follow === "PROSPECT") ? true : false}
                                                                        {...register('prospect')} 
                                                                        name="prospect" 
                                                                        className="bg-white mb-0 border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                                                        >
                                                                        <option value="">Select Status</option>
                                                                            {status?.data?.map((pros, index) => {
                                                                                if(pros?.id === 2 && applicant?.registered_status === "YES" && store?.user?.role === "COUNSELOR"){
                                                                                    return
                                                                                }
                                                                                return(
                                                                                    <option value={pros?.id} key={index}>{decode(pros?.name)}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    <div style={{color:'#f00', fontSize:"13px"}} className='mb-2'>{errors?.prospect?.message}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="col-md-12">
                                                            <textarea 
                                                            disabled={(!accepted && store?.user?.role === "VISASTAFF" && follow === "PROSPECT") ? true : false}
                                                            {...register('comment')} name="comment" className="form-control" placeholder="Enter comments" style={{ minHeight: "160px" }}></textarea>
                                                            <div className="mt-1 mb-2" style={{color:'#f00', fontSize:"14px"}}>{errors.comment?.message}</div>
                                                        </div>
                                                        {
                                                            (store?.user?.role === "VISASTAFF" && !accepted && follow === "PROSPECT") && <div className="col-md-12 text-[11px] text-justify italic text-[red] my-2">You must have at least one offer accepted in your application to change the visa status. Please ensure you have accepted an offer before proceeding.</div>
                                                        }
                                                        <div className="col-md-12">
                                                        {
                                                            (follow === "PROSPECT" && store?.user?.role === 'COUNSELOR' && applicant?.assignUser?.counselor !== store?.user?.id) ?
                                                            <button
                                                            type="button"
                                                            disabled
                                                            className='btn-customized-large opacity-70 cursor-not-allowed'
                                                            >Add Prospect Status
                                                            </button>:
                                                            <button 
                                                            type="submit"
                                                            disabled={(!accepted && store?.user?.role === "VISASTAFF" && follow === "PROSPECT") ? true : false}
                                                            className="btn-customized-large w-full gap-2">
                                                                <IconMessagePlus size="16" />
                                                                {
                                                                    follow === "GENERAL" ?
                                                                    "Add General Comment" : 
                                                                    follow === "FOLLOW" ? 
                                                                    "Add Followup":
                                                                    "Add Prospect Status"
                                                                }
                                                            </button>
                                                        }
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (store?.user?.role === "COUNSELOR" || store?.user?.role === "MANAGER" || store?.user?.role === "BMANAGER")&&
                                        <div className="add-lead-status-wrpr grid grid-cols-9 max-[1388px]:grid-cols-2 max-[916px]:grid-cols-10 max-[546px]:grid-cols-2 w-full justify-between p-2">
                                            {
                                                leadTypes?.map((lead, index) => {
                                                    return(
                                                        <div
                                                        onClick={() => handleLeadStatus(lead?.value)}
                                                        className={`col-span-3 text-[12px] ${applicant.lead_status === lead?.value ? 'bg-[#EF0]':'bg-white'} py-2 rounded-[5px] flex items-center justify-center gap-1`} key={index} >
                                                            <img src={lead?.img} width="12px" />
                                                            {lead?.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>	
                                    }
                                    <div className='add-general-comment-wrpr card mt-4'>
                                        <div className="card-body">
                                            <div style={{display:'flex', flexDirection:'column', gap:10}}>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'flex-start', marginBottom: '10px'}}>
                                                    <div style={{fontWeight: 700, fontSize:'14px', textDecoration:'underline'}}>Applicant Details</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Profile Status</div>
                                                    <LeadStatus item={applicant} />		
                                                   								
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div></div>
                                                    <LeadStatus item={applicant} link={true} />
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Applicant Name</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.applicant_name} {applicant?.middlename} {applicant?.lastname}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Email</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.email}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Mobile</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.mobile_no}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Whatsapp</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.whatsapp_no}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Landline</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.landline_no}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Visa Type</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.visatype?.description}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Office</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.office?.name}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Intake</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.intake?.intake}</div>
                                                </div>
                                                <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                    <div>Status</div>
                                                    <div style={{fontWeight: 700}}>{applicant?.status_of_prospect?.name}</div>
                                                </div>
                                                {applicant?.registered_status === "YES" &&
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Registered Date</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.registered_date && moment(applicant?.registered_date).format('DD-MM-YYYY') }</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='add-general-comment-wrpr card mt-4' style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <div className="card-body">
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                                <div style={{ display: 'flex', fontSize: '12px', justifyContent: 'flex-start', marginBottom: '10px' }}>
                                                    <div style={{ fontWeight: 700, fontSize: '14px', textDecoration: 'underline' }}>Assigned Details</div>
                                                </div>

                                                {
                                                    assignedDetails?.data?.map((data, index) => {
                                                        const details = data?.user?.userdetail;
                                                        const role = data?.role;
                                                        return (
                                                            <div className='col-span-6 bg-lime-200 shadow-md rounded-xl px-3 py-2 font-medium' key={index}>
                                                                <div className='flex gap-3 items-center'>
                                                                    <div>
                                                                        <Avatar rounded src={details?.image ? details?.image : require('../../assets/images/profile-avatar.jpg')} />
                                                                    </div>
                                                                    <div>
                                                                        <div>{details?.firstname} {details?.lastname}</div>
                                                                        <div>{role?.label}</div>
                                                                        <div>
                                                                            <div className='text-[12px] font-normal'>Email: {details?.email}</div>
                                                                            <div className='text-[12px] font-normal'>Mobile: {details?.phone}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-span-9 max-[1500px]:col-span-8 max-[916px]:col-span-12">
                                    <Fragment>
                                    {followApp?.data?.map((element, index) => {
                                        if(element?.type === "FOLLOW-UP" && element?.followStatus === "PENDING")
                                        return(
                                            <div className="card followup-card" key={index}>
                                                <div className="card-body text-[12px]">

                                                    <div className="grid grid-cols-10 max-[1382px]:grid-cols-8 max-[972px]:grid-cols-6 max-[558px]:grid-cols-4 max-[404px]:grid-cols-2 max-[404px]:text-center gap-2">
                                                        <div className="col-span-2">
                                                        <label>Visa Type</label>
                                                            <div className='font-semibold'>
                                                            {applicant?.visatype?.description}
                                                            </div>
                                                            <label>Created Date</label>
                                                            <div className='font-semibold'>
                                                                {element?.createdAt && moment(element?.createdAt).format('DD-MM-YYYY')} &nbsp;
                                                                {element?.createdAt && moment(element?.createdAt).format('hh:mm A')}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label>Followup Date</label>
                                                            <div className='font-semibold'>
                                                                {element?.followupdate && moment(element?.followupdate).format('DD-MM-YYYY')} &nbsp;
                                                                {(element?.followupdate && element?.date_enable === "YES") && moment(element?.followupdate).format('hh:mm A')}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label>Type</label>
                                                            <div className='font-semibold'>{element?.type}</div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label>Created By</label>
                                                            <div className='font-semibold'>{element?.createdBy?.userdetail?.firstname} {element?.createdBy?.userdetail?.lastname}</div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label>Assign to</label>
                                                            <div className='font-semibold'>
                                                                {element?.assignId?.userdetail?.firstname} {element?.assignId?.userdetail?.lastname}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        activeFollow === element.id && 
                                                        <div className="flex flex-col items-end mt-4 gap-1">
                                                            <div className='w-full'>
                                                                <textarea
                                                                value={followupComment}
                                                                onChange={(e)=> {setFollowupComment(e.target.value)}}
                                                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 min-h-[100px]" 
                                                                />
                                                                <div className='text-[10px] italic text-[#f00]'>Comment minimum 10 characters needed</div>
                                                            </div>
                                                            <div className='flex gap-2'>
                                                                <button
                                                                disabled={followupComment?.length < 10 ? true:false} 
                                                                style={{opacity: followupComment?.length < 10 ? 0.7:1}}
                                                                onClick={()=> {commentCreate(element?.id)}}  
                                                                className="flex items-center gap-1 btn-customized-large h-8 w-[100px]">
                                                                <IconDeviceFloppy size="16" />
                                                                Save</button> 
                                                                <button
                                                                disabled={followupComment?.length < 10 ? true:false} 
                                                                style={{opacity: followupComment?.length < 10 ? 0.7:1}}
                                                                onClick={()=> {commentCreateAndClose(element?.id)}}  
                                                                className="flex items-center gap-1 btn-customized-large h-8 w-[180px]">
                                                                <IconDeviceFloppy size="16" />
                                                                Save & Close Comment</button>
                                                            </div>
                                                        </div>
                                                    }


                                                    {
                                                        activeFollow !== element.id && 
                                                        <div className='flex justify-between mt-4'>
                                                            <div className='font-semibold'>
                                                                {
                                                                    (element?.assignid === store?.user?.id && element?.followupComments?.find((f)=> f.primary === "NO")) ?
                                                                    <ConfirmCheck data={element} setFollowApp={setFollowApp} setFollowDone={setFollowDone} setFollowHistory={setFollowHistory} /> :
                                                                    <div>
                                                                        <Checkbox aria-label='' isDisabled size="lg">
                                                                            <div className='text-[12px] font-normal'>
                                                                                Followup Done
                                                                            </div>
                                                                        </Checkbox>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div>
                                                                <button 
                                                                disabled={element?.assignid !== store?.user?.id}
                                                                style={{cursor: element?.assignid !== store?.user?.id && 'not-allowed', opacity:element?.assignid !== store?.user?.id && 0.7}}
                                                                onClick={()=>setActiveFollow(element.id)} 
                                                                className="btn-customized-large w-[130px] gap-2 h-8">
                                                                    <IconMessage size="16" /> 
                                                                    Add Comment
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }


                                                    {element?.followupComments?.length > 0 &&
                                                        <div className='mt-3'>
                                                            <div className="table-wrpr mb-0">
                                                                <div className="table-section">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <table className="table prospect-table">
                                                                                <thead>
                                                                                    <tr className="ui-sortable-handle follow-comment">
                                                                                        <th width="200">Commented Date</th>
                                                                                        <th>Comment</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody style={{background:'#fff'}} className="ui-sortable">
                                                                                    {element?.followupComments?.map((items, indexes) => (
                                                                                        <tr key={index} className={`ui-sortable-handle ${(items?.primary === "YES" && element.type === "FOLLOW-UP") && "general-comment"} follow-comment`}>
                                                                                            <th className="">{moment(items?.createdAt).format('DD-MM-YYYY hh:mm A')}</th>
                                                                                            <td>{items?.comments}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })}
                                    </Fragment>
                                    
                                    <div className='mt-4'>
                                        <FollowupHistory data={followHistory?.data} />
                                    </div>
                                </div>

                            </div>
                        }
                    </Fragment>
                }
            </Fragment>
        </Modal>
    </div>
  )
}

export default FollowUp



const FollowupHistory =({data})=> {
	return(
		<Fragment>
			{data?.length > 0 &&
			<Fragment>
                <div className='mb-3 flex justify-between max-[1331px]:flex-col items-center'>
                    <div>Comment history</div>
                    <div className='text-[12px] flex max-[1151px]:flex-col max-[1151px]:w-full max-[910px]:flex-row gap-2'>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#BBE2EC]'/>Prospect Comment</div>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#FFB0B0]'/>Followup Create Comment</div>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#F3CCF3]'/>Followup Comments</div>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#F2F1EB]'/>General Comment</div>
                    </div>
                </div>
                <div className="table-wrpr">
                    <div className="table-section">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table prospect-table">
                                    <thead>
                                        <tr className="ui-sortable-handle follow-comment">
                                            <th width="12%">Commented Date</th>
                                            <th width="10%">Followup Date</th>
                                            <th width="8%">Type</th>
                                            <th width="10%">Assign to</th>
                                            <th width="10%">Created By</th>
                                            <th width="13%">Status</th>
                                            <th width="37%">Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{background:'#fff'}} className="ui-sortable">
                                        {data?.map((item, index) => (
                                            <tr key={index} className="ui-sortable-handle"
                                            style={{
                                            backgroundColor: item?.type === "PROSPECT" ? "#BBE2EC" :
                                            (item?.type === "FOLLOW-UP" && item?.primary === "YES") ? "#FFB0B0" :
                                            (item?.type === "FOLLOW-UP" && item?.primary === "NO") ? "#F3CCF3" :
                                            "#F2F1EB"
                                            }}>
                                                <td>
                                                    {moment(item?.createdAt).format('DD-MM-YYYY')}
                                                    <div>{moment(item?.createdAt).format('hh:mm A')}</div>
                                                </td>
                                                <td>
                                                    {item?.followupdate && moment(item?.followupdate).format('DD-MM-YYYY')}
                                                    {
                                                        item?.date_enable === "YES" &&
                                                        <div>{item?.followupdate && moment(item?.followupdate).format('hh:mm A')}</div>
                                                    }
                                                </td>
                                                <td>{item?.type}</td>
                                                <td>{item?.assign?.userdetail?.firstname} {item?.assign?.userdetail?.lastname}</td>
                                                <td>{item?.commentedBy?.userdetail?.firstname} {item?.commentedBy?.userdetail?.lastname}</td>
                                                <td>{item?.status?.name}</td>
                                                <td>{item?.comment}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
			</Fragment>
			}
		</Fragment>
	)
}


const ConfirmCheck = ({data, setFollowApp, setFollowDone, setFollowHistory}) => {

	const [checked, setChecked] = useState(false)

	useEffect(()=>{
		setChecked(data?.followStatus === "DONE" ? true : false)
	},[])

	const handleChange = (e) => {
		confirmCheck.mutateAsync({id: data?.id})
		setChecked(!checked)
	}

	const confirmCheck = authQueries.useCounselorDashboardConfirmMutation(
		(res)=> {
            setFollowDone(true)
			followList.mutateAsync(data.applicant_id)
            followHistoryList.mutateAsync(data.applicant_id)
		}
	)

	const followList = authQueries.useAppointClientList(
		async (response) => {
			setFollowApp(response)
		}
	)

    const followHistoryList = authQueries.useFollowupHistoryList(
		async (response) => {
			setFollowHistory(response)
		}
	)

	return(
		<div>
			<Checkbox aria-label='' onChange={handleChange} isSelected={checked} size="lg">
                <div className='text-[12px] font-normal'>
                    Followup Done
                </div>
            </Checkbox>
		</div>
	)
}
