import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../../store/userStore";

const EditDistrict =()=>{

    const master = userStore();

    const validationSchema = yup.object({
        name: yup
          .string('⚠ Enter District Name')
          .required('⚠ District Name is required')
          .min(2, '⚠ Name should be of Minimum of 2 characters length'),
        order: yup
          .string('⚠ Enter Display Order')
          .required('⚠ Display Order is required'),
        status: yup
          .string('⚠ Enter your status')
          .required('⚠ Status is required'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');

    const navigate = useNavigate();

    useEffect(()=>{
        DistrictData.mutateAsync(master.district);
    },[])

    const DistrictData = authQueries.useDistrictMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    name:response?.data.name,
                    order:response?.data.display_order,
                    status:response?.data.status
                })
                reset({ ...datas });
            }
        }   
    );


    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const editDistrict = authQueries.useDistrictMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/district')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );
        
    
    const onSubmit = (data) => {
        const datavalues = {
            id:master.district,
            name: data.name,
            display_order: data.order,
            status: data.status,
        };
        
        editDistrict.mutateAsync(datavalues);
    }
    

    
    return(
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/district">District List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit District</li>
                </ol>
            </nav>

            <div className="card">
                <div className="card-header">
                    Edit District
                <div className="tab-info">
                    <span>1 Field Pending</span>
                </div>
                </div>
                <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Name</label>
                                            <input 
                                                {...register("name")}
                                                type="text" 
                                                className="form-control"  
                                                name="name"/>
                                            <p className='error'>{errors.name?.message}{errName}</p>
                                        </div>
                                        <div id="output"></div> 
                                    
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <div id='parent' className='form-group'>
                                        <label htmlFor="">Display Order</label>
                                        <input className="form-control"  {...register("order")} type="text" name='order' />
                                        <p className='error'>{errors.order?.message} {errName}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Status</label>
                                            <select  {...register("status")}  className="form-control"  name="status">
                                                <option value="">Select</option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="INACTIVE">INACTIVE</option>
                                            </select>       
                                            <p className='error'>{errors.status?.message}</p>
                                        </div>

                                        <div id="output"></div>
                                    
                                </div>
                            </div>                                                       
                            
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-default btn-save">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        </div>
    )
}

export default EditDistrict