const UserData = [
    {role: "COUNSELOR", name: "counselor"},
    {role: "PROGRAMCOORDINATOR", name: "program"},
    {role: "FEECOORDINATOR", name: "fee"},
    {role: "ADMISSIONSTAFF", name: "admission"},
    {role: "VISASTAFF", name: "visa"},
    {role: "DOCUMENTSTAFF", name: "document"},
    {role: "SOPSTAFF", name: "sop"},
]

export default UserData