import { Modal } from '@nextui-org/react'
import { decode } from 'html-entities'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'

const ApplicationStatusModal = ({item}) => {

    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])

    const handleOpen =()=>{
        setOpen(true)
        programAdmissionHistory.mutateAsync(item?.id)
    }

    const handleClose =()=>{
        setOpen(false)
    }

    const programAdmissionHistory = authQueries.useAdmissionProgramWiseMutationList(
        async (response) => {
            setResult(response)
        }
    );


  return (
    <Fragment>
        {
            item?.appstaffstatuss &&
            <div onClick={handleOpen} style={{color:'#00f'}} className="btn-fee cursor-pointer">
                {item?.appstaffstatuss?.name}<i className="icon-arrow-down-thick"></i>
            </div>
        }
        <Modal
        open={open}
        closeButton
        onClose={handleClose}
        className="followup-modal"
        centered
        blur
        animationDuration={1000}
        overlayId="modal-overlay"
        width='90%'
        scroll
        >
            <Modal.Header>
                <h5 className="modal-title">Status History</h5>
            </Modal.Header>
            <Modal.Body>
                <StatusCreate result={result} />
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default ApplicationStatusModal


const StatusCreate =({result})=> {  
	return(
        <div className="table-wrpr">
            <div className="table-section">
                <div className="row">
                    <div className='col-md-12'>
                        <table className="table prospect-table">
                            <thead>
                                <tr>
                                    <th width="90">Date Updated</th>
                                    <th width="300">Status</th>
                                    <th width="400">Notes</th>
                                </tr>
                            </thead>
                            <tbody >
                                {result?.data?.admissions?.map((item, index) => (
                                    <tr key={index} className="ui-sortable-handle">
                                        <td width="100">{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                                        <td width="130">{decode(item?.appstaffstatuss?.name)}</td>
                                        <td width="40">{item?.notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
	)
}
