import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoCompleteAntd, AutoCompleteChild, AutoCompleteChilds } from '../../Clients/AddClients/AutoComplete';


const validationSchema = yup.object({

    country: yup
      .string('⚠ Enter Country Name')
      .required('⚠ Coutry Name is required'),
    name: yup
      .string('⚠ Enter College Name')
      .required('⚠ College Name is required')
      .min(2,'⚠ College Name must be more than 2 characters '),
  });
  

const AddCollege = () => {

    const navigate = useNavigate();

    const [countryResult, setCountryResult] = useState([])
    const [countryId, setCountryId] = useState('')
    const [errName, setErrName] = useState('')

    useEffect(()=>{
        CountryList.mutateAsync({search:'', status: '', page: 0, size: 500});
    },[])


    const { register, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const CountryList = 
    
        authQueries.useCountryMutationList(

            (response) => {

                setCountryResult(response)

                let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setCountryResult(dataArray)

            }
        );

    const AddCollegedata = 

    authQueries.useCollegeMutationAdd(

        (response) => {

            if(response?.message === "Success"){
                navigate('/college')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }

        }
    );

    const onSubmit = (data) => {

    
        const datavalues = {
            country_id: data.country,
            name: data.name,
            IsAffiliate: "TRUE",
            status: "ACTIVE",
        };
    
        // e.preventDefault()

        console.log(data)
    
        AddCollegedata.mutateAsync(datavalues);    
    
      }
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/college">Colleges</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add College</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Add College
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                
                                                <label htmlFor="">Country :</label>
                                            

                                                <AutoCompleteAntd 
                                                    control={control} 
                                                    data={countryResult} 
                                                    name={"country"} 
                                                    value={{label: '', value: null}}
                                                />
                                                    
                                                    <div className='error-msg'>{errors.country?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">

                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">College Name</label>
                                                <input className="form-control"  {...register("name")} type="text" name='name' />
                                                <div className='error-msg'>{errors.name?.message} {errName}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                
                                {/* <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                      
                                            <div className="col-md-6">
                                            <label htmlFor="">Status</label>
                                                <select className="form-control" name='status'  {...register("status")}>
                                                    <option value=''>Select</option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="INACTIVE">INACTIVE</option>
                                                </select>
                                                <p className='error'>{errors.status?.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default AddCollege;