import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { userStore } from "../../../store/userStore";
import { decode } from 'html-entities';
import { AutoCompleteAntd, AutoCompleteChild } from '../../Clients/AddClients/AutoComplete';


const validationSchema = yup.object({

    country: yup
      .string('⚠ Enter Country Name')
      .required('⚠ Coutry Name is required'),
    name: yup
      .string('⚠ Enter College Name')
      .required('⚠ College Name is required')
      .min(2,'⚠ College Name must be more than 2 characters '),
    status: yup
      .string('⚠ Enter your status')
      .required('⚠ Status is required'),
  });
  

const EditCollege = () => {

    const navigate = useNavigate();
    const master = userStore();

    const [countryResult, setCountryResult] = useState([])

    const [errName, setErrName] = useState('')

    useEffect(()=>{
        startData();
    },[])

    const startData = async() => {
       await CountryList.mutateAsync({search: '', status: '', page: 0, size: 500});
       await CollegeData.mutateAsync(master.college);
    }

    const CollegeData = authQueries.useCollegeMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    country:response?.data.country_id,
                    name:decode(response?.data.name),
                    affliation:"TRUE",
                    status:response?.data.status
                })
                
                reset({ ...datas });
            }
        }   
    );


    const { register, reset, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const CountryList = 
    
        authQueries.useCountryMutationList(

            (response) => {

                setCountryResult(response)

                let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setCountryResult(dataArray)

            }
        );


    const editCollege = authQueries.useCollegeMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/college')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );

    const onSubmit = (data) => {

    
        const datavalues = {
            id:master.college,
            country_id: data.country,
            name: data.name,
            IsAffiliate: data.affliation,
            status: data.status,
        };
    
        // e.preventDefault()
    
        editCollege.mutateAsync(datavalues);    
    
      }
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/college">Colleges</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit College</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Edit College
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Country :</label>
                                            
                                                <AutoCompleteAntd 
                                                    control={control} 
                                                    data={countryResult} 
                                                    name={"country"} 
                                                    value={CollegeData.data?.data?.country_id}
                                                />
                                                    
                                                    <div className='error-msg'>{errors.country?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">

                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">College Name</label>
                                                <input className="form-control"  {...register("name")} type="text" name='name' />
                                                <div className='error-msg'>{errors.name?.message} {errName}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="">Status</label>
                                        <select className="form-control" name='status'  {...register("status")}>
                                            <option value=''>Select</option>
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="INACTIVE">INACTIVE</option>
                                        </select>
                                        <div className='error-msg'>{errors.status?.message}</div>
                                    </div>

                                </div>
                                
                                
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Update</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default EditCollege;