import React,{useState,useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../../queries/authQueries';


const validationSchema = yup.object({

    name: yup
      .string('⚠ Enter Fee paid type')
      .required('⚠ Fee paid type is required')
      .min(2,'⚠ Fee paid type must be more than 2 characters '),
    days: yup
      .string('⚠ Enter days')
      .required('⚠ Days is required'),
  });


const  AddLeadType = () => {

    const [errName, setErrName] = useState('')

    const navigate = useNavigate();

    const { register, handleSubmit, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const Addleadtypedata = 

    authQueries.useLeadTypeMutationAdd(

        (response) => {

            if(response?.message === "Success"){
                navigate('/listleadtype')
            }else if(response?.error[0].typename){
                setErrName("⚠ "+response?.error[0].typename)
            }


        }
    );


    const onSubmit = (data) => {

    
        const datavalues = {
            typename: data.name,
            days: data.days,
            status: "ACTIVE",
    
        };
    
        Addleadtypedata.mutateAsync(datavalues);    
    
      }


  return (

    <div>

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/listleadtype">Lead Types</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Add Lead Type</li>
            </ol>
        </nav>

        <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Add Lead Type
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Name</label>
                                                <input className="form-control"  {...register("name")} type="text" name='name' />
                                                <div className='error-msg'>{errors.name?.message} {errName}</div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Follow up Pending Days</label>
                                                <input className="form-control"  {...register("days")} type="number" name='days' />
                                                <div className='error-msg'>{errors.days?.message} {errName}</div>
                                            </div>
                                        </div>
                                    </div>       
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
        </div>

    </div>

  )

}

export default AddLeadType