import { Modal } from '@nextui-org/react';
import moment from 'moment';
import React, { Fragment, useState } from 'react'

const FeePaidSplitupModal = ({item}) => {

    const [open, setOpen] = useState(false)

    const handleOpen =()=>{
        setOpen(true)
    }

    const handleClose =()=>{
        setOpen(false)
    }

    const feePaid =()=>{
        const total = item?.feepaids?.reduce((accumulator, item) => {
            return accumulator + parseFloat(item.amount || 0);
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        let formattedNumber = roundedTotal.toFixed(2);
        return formattedNumber
    }

  return (
    <Fragment>
        <button onClick={handleOpen}>
            {feePaid()}
        </button>
        <Modal
        closeButton
        blur
        scroll
        width="800px"
        aria-labelledby="modal-title"
        open={open}
        onClose={handleClose}
        >
            <Modal.Header>
                <div className='text-black text-[15px] font-semibold'>Payment Details</div>
            </Modal.Header>
            <Modal.Body>
                <div className="w-full grid grid-cols-4 border-1 border-b pb-1 font-semibold text-[14px]">
                    <p className="col-span-1">Payment Date</p>
                    <p className="col-span-2">Payment Through</p>
                    <p className="col-span-1 text-right">Amount</p>
                </div>
                {
                    item?.feepaids?.map((item, index)=>{
                        return (
                            <div key={index}>
                                <div className="w-full grid grid-cols-4 border-1 border-b pb-1 font-normal text-[14px]">
                                    <p className="col-span-1">{moment(item?.createdAt).format('DD-MM-YYYY')}</p>
                                    <p className="col-span-2">{item?.method}</p>
                                    <p className="col-span-1 text-right">{item?.amount}</p>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="w-full grid grid-cols-4 font-semibold text-[14px]">
                    <p className="col-span-1">Total</p>
                    <p className="col-span-2"></p>
                    <p className="col-span-1 text-right">{feePaid()}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default FeePaidSplitupModal