import { Modal, Text } from '@nextui-org/react'
import moment from 'moment'
import React from 'react'
import { Fragment } from 'react'
import LeadStatus from '../Common/LeadStatus'
import { Pagination, PaginationItem } from '@mui/material'

const AssociatesTileModal = ({visible, handleClose, result, handlePageChange}) => {
  return (
    <Fragment>
        <Modal
        onClose={handleClose}
        open={visible}
        closeButton
        scroll
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        width="90%">
            <Modal.Header>
                <div className='font-[600] text-[16px]'>{result?.status}</div>
            </Modal.Header>
            <Modal.Body>
            <div className="table-wrpr !mb-10">
                <div className="table-section">
                    <table className="table prospect-table">
                        <thead>
                            <tr>
                                <th width="30">#</th>
                                <th>Profile Status</th>
                                <th>Office</th>
                                <th>Date Created</th>
                                <th>Applicant Name</th>
                                <th>Contact Number</th>
                                <th>Visa Type</th>
                                <th>Intake</th>
                                <th>Counselor</th>
                                <th>Reg. Date</th>
                                <th>Prospect Status</th>
                            </tr>
                        </thead>
                        <tbody className="ui-sortable">
                            {result?.data?.items?.map((item, index) => (
                                <Fragment key={index}>
                                    <tr className="ui-sortable-handle" key={index}>
                                        <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                        <td>
                                            <LeadStatus item={item} />
                                        </td>
                                        <td>{item?.office?.name}</td>
                                        <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                        <td>
                                            {item?.applicant_name} {item?.middlename} {item?.lastname} 
                                        </td>
                                        <td>
                                            <div className="email-mobile flex flex-col">
                                                <div>{item.mobile_no}</div>
                                                <div>{item.landline_no}</div>
                                                <div>{item.whatsapp_no}</div>
                                            </div>                                                                    
                                        </td>
                                        <td>{item?.visatype?.description}</td>
                                        <td>{item?.intake?.intake}</td>
                                        <td>{item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                        <td>
                                            {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                        </td>
                                        <td>
                                            {item?.status_of_prospect?.name}
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}												  
                        </tbody>
                    </table>
                    {result?.data?.totalPages > 1 &&
                        <ul className="pagination pagination-sm justify-content-center table-pagination mt-4 mb-3 custom-pagination">
                            <Pagination
                                count={result?.data?.totalPages}
                                nextText="Next"
                                prevText="Previous"
                                renderItem={
                                    (item) => (
                                        <div>
                                        <PaginationItem
                                        component="li"
                                        {...item}
                                        className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                                        />
                                        </div>
                                    )
                                }
                                variant="outlined"
                                page={result?.data?.currentPage + 1}
                                shape="rounded"                
                                onChange={handlePageChange}
                            />
                        </ul>
                    }
                </div>
            </div>
            </Modal.Body>
        </Modal>
    </Fragment>
  )
}

export default AssociatesTileModal
