const VisaDatas = [
    {
        // CANADA
        id: [1,17,18,19,22,23,24,25,26,28,29,30,31,32,33,34,48,52],
        items: [
            {name: "Biometric Done", radio: true, date: true},
            {name: "Medicals Done", radio: true, date: true},
            {name: "Visa Applied Date", radio: false, date: true},
            {name: "Visa Received Date", radio: false, date: true},
            {name: "Deferred Y/N Deferred Intake", radio: true, date: false},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true}
        ]
    },
    {
        // USA
        id: [10],
        items: [
            {name: "VFS Date", radio: false, date: true},
            {name: "Consulate Interview Date", radio: false, date: true},
            {name: "Visa Approved Date", radio: false, date: true},
            {name: "Visa Received Date", radio: false, date: true},
            {name: "Deferred Y/N Deferred Intake", radio: true, date: false},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true}
        ]
    },
    {
        // IRELAND
        id: [6],
        items: [
            {name: "Appointment Date", radio: false, date: true},
            {name: "Visa Approved Date", radio: false, date: true},
            {name: "Deferred Y/N Deferred Intake", radio: true, date: false},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true}
        ]
    },
    {
        // GERMANY
        id: [8,35,42,45],
        items: [
            {name: "Living Cost Payment Paid", radio: true, date: true},
            {name: "Visa Appointment Date", radio: false, date: true},
            {name: "Visa Approved Date", radio: false, date: true},
            {name: "Deferred Y/N Deferred Intake", radio: true, date: false},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true}
        ]
    },
    {
        // NEW ZEALAND
        id: [3],
        items: [
            {name: "Visa Submitted Date", radio: false, date: true},
            {name: "AIP Received Date", radio: false, date: true},
            {name: "Tuition Fee Paid Date", radio: false, date: true},
            {name: "Visa Approved Date", radio: false, date: false},
            {name: "Deferred Y/N Deferred Intake", radio: true, date: false},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true}
        ]
    },
    {
        // NEWZELAND CAP
        id: [16],
        items: [
            {name: "CGFNS Application Date", radio: false, date: true},
            {name: "IQN Application Date", radio: false, date: true},
            {name: "Decision Letter Received Date", radio: false, date: true},
            {name: "Offer Applied Date", radio: false, date: true},
            {name: "Offer Received Date", radio: false, date: true},
            {name: "Fee Payment Due Date", radio: false, date: true},
            {name: "Employer Interview Date", radio: false, date: true},
            {name: "Visa Submission Date", radio: false, date: true},
            {name: "Visa Approved Date", radio: true, date: true},
        ]
    },
    {
        // AUSTRALIA
        id: [2,51],
        items: [
            {name: "Offer Acceptance Deadline Date", radio: false, date: true},
            {name: "Orientation Date", radio: false, date: true},
            {name: "Course Commencement Date", radio: false, date: true},
            {name: "Visa Submission Date", radio: false, date: false},
            {name: "Visa Received Date", radio: false, date: true},
            {name: "Extension Received Y/N Date", radio: true, date: true},
            {name: "Deferred Y/N Deferred Intake", radio: true, date: false},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true},
        ]
    },
    {
        // UK
        id: [4,5,20,27,36,38,50],
        items: [
            {name: "CAS Received", radio: true, date: true},
            {name: "Visa Submission Date", radio: false, date: true},
            {name: "Visa Received Date", radio: false, date: true},
            {name: "Deferred Y/N Deferred Intake", radio: true, date: false},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true},
        ]
    },
    {
        // EUROPE
        id: [7,9,12,13,14,37,39,40,41,43,44,46,47,49],
        items: [
            {name: "Visa Submission Date", radio: false, date: true},
            {name: "College Interview Date", radio: false, date: true},
            {name: "VFS Appointment Date", radio: false, date: true},
            {name: "Visa Approved Date", radio: false, date: false},
            {name: "Deferred Y/N Deferred Intake", radio: false, date: true},
            {name: "Travel Date", radio: false, date: true},
            {name: "Enrolled", radio: true, date: true},
        ]
    },
    {
        // OTHER COUNTRIES
        id: [11,15,21],
        items: [
            {name: "Visa Submission Date", radio: false, date: true},
            {name: "Visa Received Date", radio: false, date: true},
            {name: "Deferred Y/N Deferred Intake", radio: false, date: true},
            {name: "Travel Date", radio: true, date: false},
            {name: "Enrolled", radio: true, date: true}
        ]
    }
]

export default VisaDatas