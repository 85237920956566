import { Checkbox } from '@nextui-org/react'
import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'

const InputCheckBox = ({item, control}) => {
  return (
    <Fragment>
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <Checkbox
            className="bg-white w-full h-10 text-[12px]" 
            name={item?.name}
            isSelected={field.value === "YES" ? true : false}
            onChange={(value, option) => {
                field.onChange(value ? "YES" : "NO");
            }}            
            >
                <div className='text-[12px] text-poppins'>
                    {item?.label}
                </div>
            </Checkbox>
            )}
        />
    </Fragment>
  )
}

export default InputCheckBox