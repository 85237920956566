import create from "zustand";

export const useUserSearch = create((set) => {
    const initialState = {
        name: "",
        role: null,
        status: "ACTIVE",
        visatype: null,
        office: null,
    }
    return{
      ...initialState,
      setName: (element) => set({ name: element }),
      setRole: (element) => set({ role: element }),
      setStatus: (element) => set({ status: element }),
      setVisatype: (element) => set({ visatype: element }),
      setOffice: (element) => set({ office: element }),
      resetAll: () => set(initialState)
    }
  });