import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { userStore } from '../../../store/userStore';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { DatePicker, message as messages, Popconfirm } from 'antd'

const TravelHistory = () => {

    const appStore = userStore();

    useEffect(() => {
        countryData.mutateAsync({search:'', status:'',page:0, size:1000000}); 
    },[])

    const [result, setResult] = useState(appStore?.appAllData?.travelhistories)

    const [edit, setEdit] = useState(0)
    const [country, setCountry] = useState([])

    const editValidationSchema = yup.object({
        countrys: yup
            .string('⚠ Please select country')
            .required('⚠ Country is required'),
        froms: yup
            .string('⚠ Please enter duration from')
            .required('⚠ Duration from is required'),
        tos: yup
            .string('⚠ Please enter duration to')
            .required('⚠ Duration to is required'),
        purposes: yup
            .string('⚠ Please enter purpose')
            .required('⚠ Purpose is required'),
        visas: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
    });


    const addValidationSchema = yup.object({
        country: yup
            .string('⚠ Please select country')
            .required('⚠ Country is required'),
        from: yup
            .string('⚠ Please enter duration from')
            .required('⚠ Duration from is required'),
        to: yup
            .string('⚠ Please enter duration to')
            .required('⚠ Duration to is required'),
        purpose: yup
            .string('⚠ Please enter Purpose')
            .required('⚠ Purpose is required'),
        visa: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
    });


    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(edit === 0 ? addValidationSchema : editValidationSchema)
    });

    const addTravelHistory = authQueries.useTravelHistoryAdd(
        (response) => {
            let datas = ({
                country: "",
                from: "",
                to: "",
                purpose: "",
                visa: "",
            })
            reset({ ...datas });
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const onSubmit = (data) => {
        const datavalues = {
            applicant_id: appStore.appid,
            country_id:data.country,
            dur_from:data.from,
            dur_to:data.to,
            purpose:data.purpose,
            visa_type:data.visa,
            status: "ACTIVE"
        }
        addTravelHistory.mutateAsync(datavalues);
    }

    const countryData = authQueries.useCountryMutationList(
        (response) => {
            setCountry(response)            
        }   
    );

    const viewTravelHistory = (event, item) => {
        event.preventDefault();
        setEdit(item?.id)
        let datas = ({
            countrys: item?.country_id,
            froms: item?.dur_from,
            tos: item?.dur_to,
            purposes: item?.purpose,
            visas: item?.visa_type,
        })
        reset({ ...datas });
    }

    const editTravelHistory = authQueries.useTravelHistoryEdit(
        (response) => {
            setEdit(0)
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const onEdit = (data) => {
        const datavalues = {
            id:edit,
            applicant_id: appStore.appid,
            country_id:data.countrys,
            dur_from:data.froms,
            dur_to:data.tos,
            purpose:data.purposes,
            visa_type:data.visas,
            status: "ACTIVE"
        }
        editTravelHistory.mutateAsync(datavalues)
    }

    const deleteTravelHistory = authQueries.useTravelHistoryDelete(
        (response) => {
            messages.success("successfully deleted")
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const deleteHistory =(e, id)=> {
        e.preventDefault()
        deleteTravelHistory.mutateAsync(id)
    }

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 30;
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
                setResult(response?.data?.travelhistories)
            }
        }
    )
  

  return (

    <div>
        <div className="card">
            <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#travel_history">
                Travel History, If Any
                </a>
                <div className={result?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                	<span className={result?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
            	</div>
                </div>
                <div id="travel_history" className="collapse " data-parent="#accordion">
                <div className="card-body">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-12 max-[1629px]:grid-cols-10 max-[1036px]:grid-cols-6 gap-x-6">
                            <div className="col-span-2 max-[1036px]:col-span-3 max-[626px]:col-span-6">
                                <label htmlFor="">Country</label>
                                <select {...register("country")} name="country" className="form-control form-control-sm !w-full">
                                    <option value="">Select</option>
                                    {country?.data?.items?.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>  
                                    ))}
                                </select>
                                <div className="error-msg">{errors.country?.message}</div>
                            </div>
                            <div className="col-span-2 max-[1480px]:col-span-3  max-[626px]:col-span-6">
                                <label htmlFor="">Duration</label>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="col-span-1">                                               
                                        <select {...register("from")} name="from" className="form-control form-control-sm !w-full">
                                            <option value="" hidden>From Date</option>
                                            {years.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="error-msg">{errors.from?.message}</div>
                                    </div>
                                    <div className="col-span-1">
                                        <select {...register("to")} name="to" className="form-control form-control-sm !w-full">
                                            <option value="" hidden>To Date</option>
                                            {years.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="error-msg">{errors.to?.message}</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="">Purpose</label>
                                <input {...register("purpose")} name="purpose" className="form-control form-control-sm !w-full" type="text" />
                                <div className="error-msg">{errors.purpose?.message}</div>
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="">Type of Visa</label>
                                <select {...register("visa")} name="visa" className="form-control form-control-sm !w-full">
                                    <option value="">Select</option>
                                    <option value="STUDENT">Student</option>
                                    <option value="IMMIGRATION">Immigration</option>
                                </select> 
                                <div className="error-msg">{errors.visa?.message}</div>
                            </div>
                            <div className="col-span-1">
                                <div className="form-group">
                                    <label htmlFor="">&nbsp;</label><br/>
                                    <button 
                                        type='submit' 
                                        className="btn btn-default btn-nextrow text-white"
                                        >Create</button>
                                </div>
                            </div>
                        </div>
                    </form>
                                      
                    <div className="row">
                        <div className="col-md-12 mt-2">
                            <form>
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table prospect-table">
                                                    <thead>
                                                        <tr>
                                                        <th width="2%">#</th>
                                                        <th width="10%">Country</th>
                                                        <th width="10%">Duration From</th>
                                                        <th width="10%">Duration To</th>
                                                        <th width="30%">Purpose</th>
                                                        <th width="30%">Nature/Type of Visa</th>
                                                        <th width="8%">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="sortable7" className="ui-sortable">
                                                        {result?.map((item, index) =>(
                                                            <Fragment key={index}>
                                                                {
                                                                    edit !== item.id?
                                                                    <tr className="ui-sortable-handle" key={index}>
                                                                        <th>{index+1}</th>
                                                                        <td>{item?.country?.name}</td>
                                                                        <td>{item?.dur_from}</td>
                                                                        <td>{item?.dur_to}</td>
                                                                        <td>{item?.purpose}</td>
                                                                        <td>{item?.visa_type}</td>
                                                                        <td className="action-icons flex gap-1 justify-end">
                                                                            <button onClick={event => viewTravelHistory(event, item)} className="btn-nextrow !mb-0">Edit</button>
                                                                            <Popconfirm
                                                                                placement="left"
                                                                                title="Are you sure to delete this Details ?"
                                                                                onConfirm={(event) => {deleteHistory(event, item.id)}}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <button type='button' style={{ color: '#fff' }} className="btn-removerow !mb-0">Remove</button>
                                                                            </Popconfirm>
                                                                        </td>
                                                                    </tr>:
                                                                    <tr className="ui-sortable-handle" key={index}>
                                                                        <th>{index+1}</th>
                                                                        <td>
                                                                            <select {...register("countrys")} name="countrys" className="form-control form-control-sm !w-full">
                                                                                <option value="">Select</option>
                                                                                {country?.data?.items?.map((item, index) => (
                                                                                    <option key={index} value={item.id}>{item.name}</option>  
                                                                                ))}
                                                                            </select>
                                                                            <div className="error-msg">{errors.countrys?.message}</div>
                                                                        </td>
                                                                        <td>
                                                                            <select {...register("froms")} name="froms" className="form-control form-control-sm !w-full">
                                                                                <option value="" hidden>From Date</option>
                                                                                {years.map((year) => (
                                                                                    <option key={year} value={year}>
                                                                                        {year}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            <div className="error-msg">{errors.froms?.message}</div>
                                                                        </td>
                                                                        <td>
                                                                            <select {...register("tos")} name="tos" className="form-control form-control-sm !w-full">
                                                                                <option value="" hidden>To Date</option>
                                                                                {years.map((year) => (
                                                                                    <option key={year} value={year}>
                                                                                        {year}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            <div className="error-msg">{errors.tos?.message}</div>
                                                                        </td>
                                                                        <td>
                                                                            <input {...register("purposes")} name="purposes" className="form-control form-control-sm !w-full" type="text" placeholder="From" />
                                                                            <div className="error-msg">{errors.purposes?.message}</div>
                                                                        </td>
                                                                        <td>
                                                                            <select {...register("visas")} name="visas" className="form-control form-control-sm !w-full">
                                                                                <option value="">Select</option>
                                                                                <option value="STUDENT">Student</option>
                                                                                <option value="IMMIGRATION">Immigration</option>
                                                                            </select>                                                         
                                                                            <div className="error-msg">{errors.visas?.message}</div>
                                                                        </td>
                                                                        <td className="action-icons flex gap-1">
                                                                            <button style={{ color: '#fff' }} onClick={handleSubmit(onEdit)} className="btn-nextrow !mb-0">Update</button>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </Fragment>
                                                        ))}                                    
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>                        
                    </div>                 
                </div>
            </div>
        </div>
    </div>

  )
}

export default TravelHistory