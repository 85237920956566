import { persist } from "zustand/middleware";
import create from "zustand";

export const useFollowupStore = create(
    persist(
        (set) => ({
            application: {},
            setApplication: (element) => set({ application: element }),
        }),
        { name: "followup", getStorage: () => localStorage }
))