import React,{useState,useEffect, Fragment} from 'react'
import * as yup from 'yup';
import { LoginApi } from '../../apis/api';
import axios from "axios";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../store/userStore';
import { isAuthenticated } from '../../utils/authenticate';
import { TextField } from '@mui/material';
import { useRmStore } from '../../store/rmRoleStore';
import { useDelegateStore } from '../../store/delegateStore';


const validationSchema = yup.object({
  username: yup
    .string('⚠ Enter your Name')
    .required('⚠ Name is required')
    .max(20, '⚠ Name should be of Maximum of 20 characters length'),
  password: yup
    .string('⚠ Enter your password')
    .required('⚠ Password is required')
    .min(4, '⚠ At least 4 characters Long'),
})

const LoginPage = () => {

  const store = userStore();
  const navigate = useNavigate();
  const [isError, setIsError] = useState("");

  const setVisible = useDelegateStore((state) => state.setVisible);

  const setOffice = useRmStore((state) => state.setOffice);

  useEffect(() => {
    if (store?.user?.accessToken) {
      navigate("/dashboard");
    }
  }, []);



  const login = authQueries.useLoginMutation(
    async(response) => {

        if(response?.message === "Username or password is incorrect."){
          return setIsError(response?.message);
        }

        if(response?.message === 'Login successfull') {
          if(response?.data?.role){
            if(response?.data?.office?.length > 0){
              setOffice(response?.data?.office[0]?.oid)
            }
          }
          if (response.data?.id) {
            await store.setUser({ ...response?.data });
            // window.location.reload()
            await window.open('/dashboard', '_self')
            if(response?.data?.role !== "MARKETINGASSOCIATE"){
              setVisible(true)
            }
          }
        } 

    }
  );


  const { register , control, handleSubmit, formState:{ errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {

    store.setActiveTab('/dashboard')

    const datavalues = {
      username: data.username,
      password: data.password,
    };

    login.mutateAsync(datavalues);

  }

  return (
    <Fragment>
        <div className="login-wrpr">
            <div className="row">
                <div className="col-md-4 login-left">
                    <img src={require('../../assets/images/logo.png')} width="" height="" alt="" />
                    <div className="login-form-wrpr">
                        <h2 className="">Login to <span>GeeBee</span></h2>
                        <p>Please login with your username </p>
                        <div className="login-form-cntnr">
                            <form onSubmit={handleSubmit(onSubmit)}>         
                                <div style={{width: '100%',}}>
                                  <TextField 
                                    id="username" 
                                    label="Username" 
                                    variant="outlined"
                                    {...register("username")}
                                    name="username"
                                  />
                                  <div  className="error-msg">{errors.username?.message}</div>
                                </div>
                                <div style={{marginTop: '15px'}}>
                                  <TextField 
                                      id="password" 
                                      label="Password" 
                                      variant="outlined"
                                      type="password" 
                                      control={control}
                                      name="password"
                                      {...register("password")}
                                    />
                                    <div className="error-msg">{errors.password?.message}</div>
                                </div>
                                <div className='login-error-msg'>
                                  {isError ? isError : ""}
                                </div>
                                <div className="form-group forgot-remember">
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="remember" name="remember" />
                                    <label className="custom-control-label" htmlFor="remember">Remember</label>
                                    </div>
                                    <div className="custom-control custom-checkbox mb-3 text-right">
                                      <Link to="forgot_password">Forgot Password?</Link>
                                    </div>
                                </div>
                                <div className="form-group login-buttons">
                                    <button className="btn btn-sm btn-primary" type="submit">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 login-right" />
            </div>
	      </div>
    </Fragment>
  )
}

export default LoginPage
