/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import authQueries from '../../queries/authQueries';
import Swiper from 'swiper';
import 'swiper/css';
import $ from 'jquery'
import Charts from '../Charts/Charts';
import { CheckCircle, CloudSun, Flame, HelpingHandIcon } from 'lucide-react';
import { IconCreativeCommonsSa, IconSnowflake } from '@tabler/icons-react';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import FollowupPendingLead from '../Common/FollowupPendingLead';
import LoaderCommon from '../Common/LoaderCommon';
import CountUp from 'react-countup';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import FollowupPendingLeadTypeWise from '../Common/FollowupPendingLeadTypeWise';
import RouteConstants from '../../Constants/RouteConstans';
import { userStore } from '../../store/userStore';

const CounselorDashboard = () => {

	const navigate = useNavigate()
	const [reg, setReg] = useState({})
	const [loader, setLoader] = useState(true);
	const setRegistered = useAdvancedSearch(state=> state.setRegistered)
	const onlyHot = useAdvancedSearch(state=> state.onlyHot)
	const onlyWarm = useAdvancedSearch(state=> state.onlyWarm)
	const onlyCold = useAdvancedSearch(state=> state.onlyCold)
	const setStatus = useAdvancedSearch(state=> state.setStatus)
	const resetAll = useAdvancedSearch(state=> state.resetAll)
	const setActiveTab = userStore((state)=> state.setActiveTab)

	const [countNotReg, setCountNotReg] = useState({
		hot: 0,
		warm: 0,
		cold: 0,
		longTerm: 0,
		hold: 0,
		regClient: 0,
		graph: []
	})

	const [total, setTotal] = useState({
		non: 0,
		reg: 0,
		notI: 0,
		notq: 0,
		visaA: 0,
		visaR: 0,
		graph: []
	})

	const [active, setActive] = useState({
		appPending: 0,
		offerAccepted: 0,
		preparingVisa: 0,
		appliedVisa: 0,
		visaApproved: 0,
		visaRefused: 0,
		graph: []
	})

	const [feePaid, setFeePaid] = useState({
		previous: 0,
		current: 0,
		future: 0,
	})

	const [visa, setVisa] = useState({
		previous: {
			count: 0,
			target: 0,
		},
		current: {
			count: 0,
			target: 0,
		},
		future: {
			count: 0,
			target: 0,
		},
	})

	const activeAssign = authQueries.useCounselorDashboardActiveMutation(
		async (response) => {
		setActive({
			appPending: response?.appPending ? response?.appPending : 0,
			offerAccepted: response?.offAccept ? response?.offAccept : 0,
			preparingVisa: response?.preVisa ? response?.preVisa : 0,
			appliedVisa: response?.appliedVisa ? response?.appliedVisa : 0,
			visaApproved: response?.approvedVisa ? response?.approvedVisa : 0,
			visaRefused: response?.refusedVisa ? response?.refusedVisa : 0,
			graph: response?.graph ? response?.graph : []
		})
    });

  	const totalAssign = authQueries.useCounselorDashboardTotalMutation(
		async (response) => {
		setTotal({
			non: response?.nonRegistered ? response?.nonRegistered : 0,
			reg: response?.registered ? response?.registered : 0,
			notI: response?.notInterested ? response?.notInterested : 0,
			notq: response?.notQualified ? response?.notQualified : 0,
			visaA: response?.visaApproved ? response?.visaApproved : 0,
			visaR: response?.visaRefused ? response?.visaRefused : 0,
			graph: response?.graph ? response?.graph : []
		})
    });

	const CountDashboardNotReg = authQueries.useCounselorDashboardCountMutation(
		async (response) => {
			setCountNotReg({
				hot: response?.hotClient ? response?.hotClient : 0,
				warm: response?.warmClient ? response?.warmClient : 0,
				cold: response?.coldClient ? response?.coldClient : 0,
				longTerm: response?.longTerm ? response?.longTerm : 0,
				hold: response?.onHold ? response?.onHold : 0,
				regClient: response?.regClient ? response?.regClient : 0,
				graph: response?.graph
			})
			setLoader(false)
		}
	);

  	const feeDashboard = authQueries.useCounselorDashboardFeePaidMutation(
		async (response) => {
		setFeePaid(
			{
			previous: response?.previous ? response?.previous : 0,
			current: response?.current ? response?.current : 0,
			future: response?.future ? response?.future : 0,
			}
		)
    });

  	const visaDashboard = authQueries.useCounselorDashboardVisaMutation(
		async (response) => {
		setVisa(
			{
			previous: {
				count: response?.previous?.count ? response?.previous?.count : 0,
				target: response?.previous?.target ? response?.previous?.target : 0,
			},
			current: {
				count: response?.current?.count ? response?.current?.count : 0,
				target: response?.current?.target ? response?.current?.target : 0,
			},
			future: {
				count: response?.future?.count ? response?.future?.count : 0,
				target: response?.future?.target ? response?.future?.target : 0,
			},
			}
		)
    });

  	const regDashboard = authQueries.useCounselorDashboardRegMutation(
		async (response) => {
		setReg(response?.data)
    });

	const fetchData = async () => {
        try {
            await Promise.all([
				CountDashboardNotReg.mutateAsync({type: 'NO'}),
				feeDashboard.mutateAsync({assign:"YES"}),
				visaDashboard.mutateAsync(),
				regDashboard.mutateAsync(),
				activeAssign.mutateAsync(),
				totalAssign.mutateAsync(),
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


	useEffect(() => {
		fetchData();
	}, [])

	const categories = ['App Pending', 'Offer Accepted', 'Preparing Visa', 'Visa Applied', 'Visa Approved', 'Visa Refused']
	const categories2 = ['Hot Clients', 'Warm Clients', 'Cold Clients', 'On Hold', 'Long Term']
	const categories3 = ['Non Registered', 'Registered', 'Not Interested', 'Not Qualified', 'Visa Approved', 'Visa Refused']
	const colors = {color1: '#009688', color2: '#FF0000', color3: '#8bc34a'}

	const slideArr = [
		{
			color: '#508D69',
			title: 'Hot',
			name: 'Hot',
			value: "HOT",
			icon: <Flame color='#508D69' size="20" />,
			count: countNotReg.hot
		},
		{
			color: '#29ADB2',
			title: 'Warm',
			name: 'Warm',
			value: "WARM",
			icon: <CloudSun color='#29ADB2' size="20" />,
			count: countNotReg.warm
		},
		{
			color: '#FA7070',
			title: 'Cold',
			name: 'Cold',
			value: "COLD",
			icon: <IconSnowflake color='#FA7070' size="20" />,
			count: countNotReg.cold
		},
		{
			color: '#FFB000',
			title: 'On Hold',
			name: 'Hold',
			value: "HOLD",
			icon: <HelpingHandIcon color='#FFB000' size="20" />,
			count: countNotReg.hold
		},
		{
			color: '#DAC0A3',
			title: 'Long Term',
			name: 'Long',
			value: "LONG",
			icon: <IconCreativeCommonsSa color='#EAD196' size="20" />,
			count: countNotReg.longTerm
		},
		{
			color: '#5F0F40',
			title: 'Registered',
			name: 'REG',
			value: "REG",
			icon: <CheckCircle color='#5F0F40' size="20" />,
			count: countNotReg.regClient
		}
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 6,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 6,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [countNotReg]);

	const tileClick = (value) => {
		resetAll()
		if(value === "HOT"){
			onlyHot()
		}
		if(value === "WARM"){
			onlyWarm()
		}
		if(value === "COLD"){
			onlyCold()
		}
		if(value === "HOLD"){
			setStatus(7)
			setRegistered("NO")
		}
		if(value === "LONG"){
			setStatus(8)
			setRegistered("NO")
		}
		if(value === "REG"){
			setRegistered("YES")
		}
		setActiveTab(RouteConstants.APPLICANTASSIGNLIST)
		navigate(RouteConstants.APPLICANTASSIGNLIST)
	}


	return ( 
	<div>
		{
			loader ?
			<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
				<LoaderCommon  />
			</div>:
			<div>
				<div className="carousel-section mb-5">
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{slideArr.map((item, index) => (
										<div key={index} className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && tileClick(item.value)}>
											<div className='flex flex-col p-3 justify-between h-full'>
												<div className='flex justify-between'>
													<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
													<div className='text-4xl'>
														<CountUp end={item?.count} duration={1} />
													</div>
												</div>
												<div>
													<div className='text-[14px]'>{item.title}</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="performance-report-section mb-4">
					<div className="grid grid-cols-12 max-xl:grid-cols-6 gap-6">
						<div className="col-span-6">
							<div className="card border-0">
								<div className="grid grid-cols-12 max-sm:grid-cols-6 gap-5 justify-center p-6">
									<div className="col-span-6">
										<div className="pr-boxes-wrpr p-0 myallocatedClients">
											<h2>Fee Paid</h2>
											<div className="flex flex-col gap-2 fee-paid-list">
												<div className="visa-column previous mb-0 w-full">
													<div className="list-item list-none">Previous Financial Year</div>
													<div className="visa-count"><span>{feePaid.previous}</span></div>
												</div>
												<div className="visa-column current mb-0 w-full">
													<div className="list-item list-none">Current Financial Year</div>
													<div className="visa-count"><span>{feePaid.current}</span></div>
												</div>
												<div className="visa-column future mb-0 w-full">
													<div className="list-item list-none">Future Financial Year</div>
													<div className="visa-count"><span>{feePaid.future}</span></div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-span-6">
										<div className="pr-boxes-wrpr p-0">
											<h2>Visa Approved Client</h2>
											<div className="flex flex-col gap-2 visa-approved-list">
												<div className="visa-column previous mb-0  w-full">
													<div className="list-item list-none">Previous Financial Year</div>
													<div className="taget-and-count">
														<div className="visa-count"><span>{visa?.previous?.count}</span></div>
														<div className="target-count"><span>{visa?.previous?.target}</span></div>
													</div>
												</div>
												<div className="visa-column current mb-0  w-full">
													<div className="list-item list-none">Current Financial Year</div>
													<div className="taget-and-count">
														<div className="visa-count"><span>{visa?.current?.count}</span></div>
														<div className="target-count"><span>{visa?.current?.target}</span></div>
													</div>
												</div>
												<div className="visa-column future mb-0  w-full">
													<div className="list-item list-none">Future Financial Year</div>
													<div className="taget-and-count">
														<div className="visa-count"><span>{visa?.future?.count}</span></div>
														<div className="target-count"><span>{visa?.future?.target}</span></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>	
						</div>

						<div className="col-span-6">
							<div className="pr-boxes-wrpr">
								<h2>Registration Target for the Current Financial Year</h2>
								<div className="pr-boxes">
									<div className="cylinder-graph-wrpr">
										<div className="reg-target" data-regtrgt={reg?.target}>Registration Target</div>
										<div className="gray-cylinder"></div>
										<div className="color-cylinder-wrpr orange" data-visatrgt={reg?.count} 
											style={{width: `${reg?.count / reg?.target * 100 > 100 ? '100' : reg?.count / reg?.target * 100}%` }}
										>
											<div className="color-cylinder "></div>
										</div>
										<div className="conv-ratio">Pending Target <span className="conv-ratio-count">{reg?.target - reg?.count > 0 ? reg?.target - reg?.count : 0 }</span></div>
										<div className="pending-target">Conversion Ratio <span className="pending-target-count">{(reg?.count / reg?.target * 100 > 100 ? "100" : (reg?.count / reg?.target * 100).toFixed(2))}%</span></div>
									</div>
								</div>
							</div>
						</div>

					</div>	
				</div>
					<FollowupToday />
					<FollowupPending />
					{/* <FollowupPendingLeadTypeWise /> */}
					<FollowupPendingLead />
				<div className="performance-report-section mt-4">

					<div className='grid grid-cols-12'>
						<div className="col-span-12">
							<div className="sectiontitle">
								<h2><i className="icon-calendar1"></i> Performance Report <span>Year to Date</span></h2>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-12 gap-4">
						
						<div className="col-span-6">
							<div className="echart-wrpr">
								<div className="echart-hding row">
									<div className="col-md-10">
										<h2>Current Active Non Registered Clients</h2>
									</div>
									<div className="col-md-2 text-right">
										<div className="echart-count"></div>
									</div>
								</div>
								{
									countNotReg?.graph?.length > 0 &&
									<Charts categories={categories2} data={countNotReg.graph} colors={colors.color2} />
								}
							</div>
						</div>

						<div className="col-span-6">
							<div className="echart-wrpr">
								<div className="echart-hding row">
									<div className="col-md-10">
										<h2>Current Active Registered Clients</h2>
									</div>
									<div className="col-md-2 text-right">
										<div className="echart-count"></div>
									</div>
								</div>
								{
									active?.graph?.length > 0 &&
									<Charts data={active?.graph} categories={categories} colors={colors.color1} />
								}
							</div>
						</div>

						<div className="col-span-6">
							<div className="echart-wrpr">
								<div className="echart-hding row">
									<div className="col-md-10">
										<h2>Total Assigned Clients from the date of Joining</h2>
									</div>
									<div className="col-md-2 text-right">
										<div className="echart-count"></div>
									</div>
								</div>
								{
									total?.graph?.length > 0 &&
									<Charts categories={categories3} data={total?.graph} colors={colors.color3} />
								}

							</div>
						</div>

					</div>

				</div>
			</div>
		}

	</div>
	)
}

export default CounselorDashboard