  import { AutoComplete } from 'antd'
  import React, { Fragment } from 'react'
  import { Controller } from 'react-hook-form'

  const AutoCompleteInput = ({item, errors, options, control, optionProps, disabled}) => {
    return (
      <Fragment>
          <div className="text-[12px] h-4 font-[500]">{item?.label} {item?.required &&<span className="text-[red]">*</span>}</div>
          <Controller
            name={item.name}
            control={control}
            render={({ field }) => (
              <AutoComplete
              disabled={disabled}
              className="custom-auto-complete"
              options={options?.map(option => (!optionProps?.advanced ? {
                  label: option.name,
                  value: option.id
                }: {
                  label: `${option?.[optionProps?.name]}${optionProps?.name2 && ` ${option?.[optionProps?.name2]}`}`,
                  value: `${option?.[optionProps?.value]}${optionProps?.value2 && ` ${option?.[optionProps?.value2]}`}`
                }
              ))}
              placeholder={item.placeholder}
              filterOption={(inputValue, option) =>
                option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              value={options?.find(option => option?.[optionProps?.value] === field.value)?.[optionProps?.name]}
              onChange={(value, option) => {
                field.onChange(option?.value || null);
                if(item?.setState){
                  item?.setState(option?.value)
                }
              }}
              />
            )}
          />
          <div className='text-[12px] text-red-600'>{errors?.[item?.name]?.message}</div>
      </Fragment>
    )
  }

export default AutoCompleteInput