import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import Swiper from 'swiper';
import $ from 'jquery'
import authQueries from '../../queries/authQueries';
import LoaderCommon from '../Common/LoaderCommon';
import { IconAnchor, IconCalendarEvent, IconCalendarMinus, IconCalendarPlus, IconCircleDashed, IconCoinPound, IconCoinRupee, IconDiscountCheck, IconHourglass, IconLocationOff, IconProgressCheck, IconRosette } from '@tabler/icons-react';
import { BadgeCheck, CheckCircle, Vote } from 'lucide-react';
import FollowupTodayBranchManager from '../Common/FollowupTodayBranchManager';
import FollowupPendingBranchManager from '../Common/FollowupPendingBranchManager';
import { IconCircleCheck } from '@tabler/icons-react';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import { IconMoneybag } from '@tabler/icons-react';
import RouteConstants from '../../Constants/RouteConstans';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store/userStore';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { IconArrowsMaximize } from '@tabler/icons-react';

const SopStaffDashboard = () => {

	const navigate = useNavigate()

	const [loader, setLoader] = useState(true)

	const resetAll = useAdvancedSearch(state=> state.resetAll)
	const setActiveTab  = userStore((state)=> state.setActiveTab)
	const setFinancialYear = useAdvancedSearch(state=> state.setFinancialYear)
	const setIncluded = useAdvancedSearch(state=> state.setIncluded)
	const setSopCompleted = useAdvancedSearch(state=> state.setSopCompleted)

	const dashboardCount = authQueries.useSopStaffDashboardCounts(
		(response) => {
			setLoader(false)
		}
	)

	useEffect(() => {
		dashboardCount.mutateAsync()
	}, [])

	const slideArrTotal = [
		{
			color: '#508D69',
			title: 'Total Assigned',
			value: "TOTAL",
			icon: <IconArrowsMaximize color='#508D69' size="20" />,
			count: dashboardCount?.data?.totalAssigned
		},
		{
			color: '#FA7070',
			title: 'Pending Files',
			value: "PENDING",
			icon: <IconCircleDashed color='#FA7070' size="20" />,
			count: dashboardCount?.data?.totalPending,
			included: dashboardCount?.data?.totalPendingIds
		},
		{
			color: '#29ADB2',
			title: 'Progress Files',
			value: "PROGRESS",
			icon: <IconProgressCheck color='#29ADB2' size="20" />,
			count: dashboardCount?.data?.totalProgress,
			included: dashboardCount?.data?.totalProgressIds
		},
		{
			color: '#EAD01F',
			title: 'Completed Files',
			value: "COMPLETED",
			icon: <BadgeCheck color='#EAD01F' size="20" />,
			count: dashboardCount?.data?.totalCompleted
		}
	];

	const slideArrCurrent = [
		{
			color: '#9195F6',
			title: 'Total Assigned Current FY',
			value: "TOTAL",
			icon: <IconArrowsMaximize color='#9195F6' size="20" />,
			count: dashboardCount?.data?.currentAssigned
		},
		{
			color: '#B19470',
			title: 'Pending Files Current FY',
			value: "PENDING",
			icon: <IconCircleDashed color='#B19470' size="20" />,
			count: dashboardCount?.data?.currentPending,
			included: dashboardCount?.data?.currentPendingIds
		},
		{
			color: '#222831',
			title: 'Progress Files Current FY',
			value: "PROGRESS",
			icon: <IconProgressCheck color='#222831' size="20" />,
			count: dashboardCount?.data?.currentProgress,
			included: dashboardCount?.data?.currentProgressIds
		},
		{
			color: '#A5DD9B',
			title: 'Completed Files Current FY',
			value: "COMPLETED",
			icon: <BadgeCheck color='#A5DD9B' size="20" />,
			count: dashboardCount?.data?.currentCompleted
		}
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 6,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	const tileClick = (item, duration) => {
		resetAll()
		if(duration === "CURRENT"){
			setFinancialYear("CURRENT")
		}
		if(item?.value === "PENDING"){
			setIncluded(item?.included)
		}
		if(item?.value === "PROGRESS"){
			setIncluded(item?.included)
		}
		if(item?.value === "COMPLETED"){
			setSopCompleted(true)
		}
		setActiveTab(RouteConstants.SOPSTAFFMYCLIENTS)
		navigate(RouteConstants.SOPSTAFFMYCLIENTS)
	}

	return (
		<div>
			{loader ? 
				<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
					<LoaderCommon  />
				</div>:
				<div>
					<div className="carousel-section mb-3">
						<div className="row">
							<div className="col-md-12">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArrTotal.map((item, index) => (
											<div key={index} className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && tileClick(item, "TOTAL")}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="col-md-12 mt-4">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArrCurrent.map((item, index) => (
											<div key={index} className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && tileClick(item, "CURRENT")}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<FollowupToday />
					<FollowupPending />

				</div>
			}
		</div>
	)
}

export default SopStaffDashboard