import { Modal } from '@nextui-org/react'
import { IconUsersGroup } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useDelegateStore } from '../../store/delegateStore'

const DelegateListModal = () => {

  const visible = useDelegateStore((state) => state.visible);
  const setVisible = useDelegateStore((state) => state.setVisible);

  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={() => setVisible(false)}
        width='80%'
      >
        <Modal.Header>
          <p className='text-[18px] font-medium'>Delegate Visits</p>
        </Modal.Header>
        <Modal.Body>
          <img src={`${process.env.REACT_APP_S3}delegate/delegate.png`} className='w-full' />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default DelegateListModal