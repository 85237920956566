import React, { useRef } from 'react'
import html2pdf from 'html2pdf.js';
import GeebeeIcon from '../../assets/images/gblogo';
import { IconPrinter } from '@tabler/icons-react';

const PdfGenerate = ({item}) => {

    const options = {
        filename: 'my-document.pdf',
        margin: 0.4,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };

    const contentRef = useRef(null);

    const convertToPdf = async() => {
        const content = contentRef.current;
        // html2pdf().set(options).from(content).save();
        const pdfBlob = await html2pdf().set(options).from(content).outputPdf('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
    };

  return (
    <div>
        <div style={{ display: 'none' }}>
            <div ref={contentRef} className='mb-2 w-full h-full font-montserrat'>
                <div className='flex w-full justify-center mt-6'>
                    <GeebeeIcon />
                </div>
                <div className='text-center text-[14px] font-semibold my-4'>Registration Acknowledgement</div>
                <div className='flex justify-center gap-20 font-semibold'>
                    <div className='text-[13px] flex flex-col gap-2'>
                        <div>Acknowledgement No</div>
                        <div>Applicant Name</div>
                        <div>Email</div>
                        <div>Mobile Number</div>
                        <div>Branch Office</div>
                        <div>Place</div>
                        <div>Reference</div>
                    </div>
                    <div className='text-[13px] flex flex-col gap-2'>
                        <div className='flex gap-4'><div>:</div> <div className='text-[red]'>GB0{item?.id}</div></div>
                        <div className='flex gap-4'><div>:</div> <div>{item?.name}</div></div>
                        <div className='flex gap-4'><div>:</div> <div>{item?.email}</div></div>
                        <div className='flex gap-4'><div>:</div> <div>{item?.mobile}</div></div>
                        <div className='flex gap-4'><div>:</div> <div>{item?.office?.name}</div></div>
                        <div className='flex gap-4'><div>:</div> <div>{item?.place}</div></div>
                        <div className='flex gap-4'><div>:</div> <div>{item?.reference}</div></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-[100px] h-8'>
		    <button className='btn-customized-large h-8 flex gap-2 items-center' onClick={convertToPdf}><IconPrinter size="16" /> Print</button>
        </div>
    </div>
  )
}

export default PdfGenerate