import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { AutoCompleteAntd, AutoCompleteChild } from "../../Clients/AddClients/AutoComplete";
import { Checkbox } from "antd";

const VisaTypeAdd =()=>{

    const validationSchema = yup.object({
        visacode: yup
            .string('⚠ Enter your Visa Code')
            .required('⚠ Visa Code is required')
            .min(2, '⚠ Visa Code should be of Minimum of 2 characters length'),
        country: yup
            .string('⚠ Select Country')
            .required('⚠ Country is required'),
        description: yup
            .string('⚠ Enter your Description')
            .required('⚠ Description is required'),
        category: yup
            .string('⚠ Select Category')
            .required('⚠ Category Code is required'),
        isStuVisa: yup
            .string('⚠ Select Student Visa')
            .required('⚠ Student Visa is required'),
        CanadaOffice: yup
            .string('⚠ Enter your Canada Office')
            .required('⚠ Canada Office is required'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');
    const [users, setUsers] = useState({fee: 'NO', doc: 'NO', reg: 'NO', loan: 'YES', interview: 'NO', travel: 'YES', gic: 'NO'})

    const navigate = useNavigate();

    useEffect(()=>{
        countryData.mutateAsync({search: '', status: '', page:0, size:10});
    },[])

    const countryData = authQueries.useCountryMutationList(
        (response) => {
            setResult(response)  
            let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setResult(dataArray)          
        }   
    );

    const { register , control, handleSubmit, formState:{ errors }} = useForm({
        resolver: yupResolver(validationSchema)
    });


    const addVisaType = authQueries.useVisaTypeMutationAdd(
        (response) => {
            if(response?.message === "Success"){
                navigate('/visatype')
            }else if(response?.error[0].visacode){
                setErrName("⚠ "+response?.error[0].visacode)
            }
        }   
    );
        
    
    const onSubmit = (data) => {
        console.log(data)
        const datavalues = {
            visacode: data.visacode,
            country_id:data.country,
            category:data.category,
            description:data.description,
            IsStudentVisa:data.isStuVisa,
            CanadaOffice: data.CanadaOffice,
            display_order:data.display_order,
            fee: users.fee,
            doc: users.doc,
            reg: users.reg,
            status:"ACTIVE",
        };
        
        addVisaType.mutateAsync(datavalues);
    }
    

    
    return(
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/visatype">Visa Type List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add Visa Type</li>
                </ol>
            </nav>

            <div className="add_clients">
                <div className="card">
                        <div className="card-header">
                            Add Visa Type
                        </div>
                        <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group"> 
                                            <div id="parent" className="form-group">
                                            <label htmlFor="">Country :</label>

                                                <AutoCompleteAntd
                                                    control={control} 
                                                    data={result} 
                                                    name={"country"} 
                                                    value={null}
                                                />
                                                <div className='error-msg'>{errors.country?.message}</div>
                                            </div>
                                            <div id="output"></div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                                <div id="parent" className="form-group">
                                                    <label >Visa Code</label>
                                                    <input 
                                                        {...register("visacode")}
                                                        type="text" 
                                                        className="form-control"  
                                                        name="visacode"/>
                                                    <div className="error-msg">{errors.visacode?.message}{errName}</div>
                                                </div>
                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Is Student Visa</label>
                                                    <select  {...register("isStuVisa")}  className="form-control"  name="isStuVisa">
                                                        <option value="">Select</option>
                                                        <option value="YES">Yes</option>
                                                        <option value="NO">No</option>
                                                    </select>       
                                                    <div className="error-msg">{errors.isStuVisa?.message}</div>
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Canada Office</label>
                                                    <select  {...register("CanadaOffice")}  className="form-control"  name="CanadaOffice">
                                                        <option value="">Select</option>
                                                        <option value="YES">Yes</option>
                                                        <option value="NO">No</option>
                                                    </select>       
                                                    <div className="error-msg">{errors.CanadaOffice?.message}</div>
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Category</label>
                                                    <select  {...register("category")}  className="form-control"  name="category">
                                                        <option value="">Select</option>
                                                        <option value="Immigration">Immigration</option>
                                                        <option value="StudentVisa">Student Visa</option>
                                                    </select>       
                                                    <div className="error-msg">{errors.category?.message}</div>
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>   
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            
                                                <div id="parent" className="form-group">
                                                    <label >Description</label>
                                                    <textarea {...register("description")} name="description" className="form-control"></textarea>
                                                    <div className="error-msg">{errors.description?.message}</div>
                                                </div>

                                                <div id="output"></div>
                                            
                                        </div>
                                    </div>   

                                    <div className="col-md-12 mt-2">
                                        <div className="form-group">
                                            <Checkbox checked={users?.fee === 'YES' ?true: false} onChange={(e) => setUsers({...users, fee: e.target.checked ? 'YES' : 'NO'}) }>
                                                Fee Coordinator
                                            </Checkbox>   
                                            <Checkbox checked={users?.doc === 'YES' ?true: false} onChange={(e) => setUsers({...users, doc: e.target.checked ? 'YES' : 'NO'}) }>
                                                Document Staff
                                            </Checkbox>   
                                            <Checkbox checked={users?.reg === 'YES' ?true: false} onChange={(e) => setUsers({...users, reg: e.target.checked ? 'YES' : 'NO'}) }>
                                                Mark Registration
                                            </Checkbox>     
                                            <Checkbox checked={users?.loan === 'YES' ?true: false} onChange={(e) => setUsers({...users, loan: e.target.checked ? 'YES' : 'NO'}) }>
                                                Loan Executive
                                            </Checkbox>     
                                            <Checkbox checked={users?.travel === 'YES' ?true: false} onChange={(e) => setUsers({...users, travel: e.target.checked ? 'YES' : 'NO'}) }>
                                                Travel Executive
                                            </Checkbox>     
                                            <Checkbox checked={users?.gic === 'YES' ?true: false} onChange={(e) => setUsers({...users, gic: e.target.checked ? 'YES' : 'NO'}) }>
                                                GIC Executive
                                            </Checkbox>     
                                            <Checkbox checked={users?.interview === 'YES' ?true: false} onChange={(e) => setUsers({...users, interview: e.target.checked ? 'YES' : 'NO'}) }>
                                                Interview Trainer
                                            </Checkbox>     
                                        </div>
                                    </div>                                                       
                                    
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default VisaTypeAdd