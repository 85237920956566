import { Modal } from '@nextui-org/react'
import React from 'react'

const HotConfirmModal = ({open, handleClose, onConfirm, onCancel}) => {
  return (
    <Modal
    open={open}
    onClose={handleClose}
    width='450px'>
        <Modal.Header>
            <div className='text-[14px] font-semibold'>
                Confirmation
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className='text-[13px]'>
                Are you sure you want to keep the client as HOT
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className='flex justify-between w-full gap-2'>
                <button
                onClick={onCancel} 
                className="bg-gray-100 w-full p-[10px] text-black rounded-md text-[13px] font-medium">No</button>
                <button
                onClick={onConfirm} 
                className="bg-orange-500 w-full p-[10px] text-white rounded-md text-[13px] font-medium">Yes</button>
            </div>
        </Modal.Footer>
    </Modal>
  )
}

export default HotConfirmModal
