import { Pagination, PaginationItem } from '@mui/material'
import React, { Fragment } from 'react'

const PaginationComponent = ({url, result, setCurrentPage, fixed}) => {

    const handleChange = (e, value) => {

        if(!fixed){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
      
        if(value === 1){
            setCurrentPage(0)
        }else{
            setCurrentPage(value-1)
        }
    }

  return (
    <Fragment>
        {result?.data?.totalPages > 1 &&
            <ul className="pagination pagination-sm justify-content-center table-pagination mt-4 mb-3 custom-pagination">
                <Pagination
                    count={result?.data?.totalPages}
                    nextText="Next"
                    prevText="Previous"
                    renderItem={
                        (item) => (
                            <div>
                            <PaginationItem 
                            component="li"
                            {...item}
                            className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                            />
                            </div>
                        )
                    }
                    variant="outlined"
                    page={result?.data?.currentPage + 1}
                    shape="rounded"                
                    onChange={handleChange}
                />
            </ul>
        }
    </Fragment>
  )
}

export default PaginationComponent