import { Checkbox } from '@nextui-org/react'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useNavigate } from 'react-router-dom'
import authQueries from '../../queries/authQueries'

const StudentConfirmation = () => {

    const [confirmed, setConfirmed] = useState(false)
    const [result, setResult] = useState({})
    const [select, setSelect] = useState([])
    const {data} = useParams()

    const navigate = useNavigate()

    const handleSubmit =()=> {
        if(select?.length < 1){
            return message.error("Please select atleast one.")
        }

        programUpdate.mutateAsync({data: {id: select}, token: data})
    }
    
    const programUpdate = authQueries.useApplicantProgramPublicUpdateMutation(
        (res)=>{
            if(res?.message === "success"){
                setConfirmed(true)
            }
        }
    )

    const programList = authQueries.useApplicantProgramPublicListMutation(
        (res)=>{
            if(res?.response?.data?.message === "URL Expired" || res?.response?.data?.message === "Invalid URL"){
                navigate('/404')
            }

            if(res?.message === "success"){
                setResult(res?.data)
            }
        }
    )

    useEffect(()=>{
        programList.mutateAsync(data)
    },[])

  return (
    <div className={`flex min-h-[100vh] justify-center ${confirmed ? "items-center bg-[black]" : "bg-white"}`}>
        {!confirmed ?
            <div className='p-4 w-[50%] max-xl:w-[80%] max-md:w-[90%] max-sm:w-full'>
                <div className="mb-4 flex justify-center">
                    <img className='w-20' src={require('../../assets/images/pdfform.png')} />
                </div>
                <div className='text-[12px] mb-4 italic '>
                    <div>Greetings {result?.application?.applicant_name} {result?.application?.middlename} {result?.application?.lastname},</div>
                    <div className='text-justify pt-2'>Please check the programs you would like to confirm and submit by clicking the confirm button.</div>
                </div>
                <div className='mb-3 text-[14px] text-[blue] font-semibold'>
                    Program Confirmation
                </div>
                <div className='mb-3'>
                    {
                        result?.intendedMailPrograms?.map((item, index)=> (
                            <ProgramCard select={select} setSelect={setSelect} key={index} item={item} />
                        ))
                    }
                </div>
                <div className='flex justify-end'>
                    <button onClick={handleSubmit} className='bg-[blue] px-4 py-[8px] rounded-md text-white text-[12px]'>Confirm</button>
                </div>
            </div>:
            <div className='flex h-full flex-col items-center justify-center'>
                <img className='w-[60%] max-xl:w-[65%] max-lg:w-[70%] max-md:w-[75%] max-sm:w-[80%]' src={require('../../assets/images/success-ani.gif')} />
                <div className='text-md text-white text-center'>
                    Thank you <br />for your confirmation
                </div>
            </div>
        }
    </div>
  )
}

export default StudentConfirmation

const ProgramCard =({item, select, setSelect})=> {

    const [checked, setChecked] = useState(false)

    const selectedItem =()=> {
        if(checked){
            setSelect(select.filter((e) => e !== item.intpro));
        }else{
            setSelect([...select, item.intpro])
        }
        setChecked(!checked)
    }

    return(
        <div className={`bg-[#F4F4F5] ${checked && "outline outline-2 outline-offset-0 outline-blue-600"} rounded-xl text-[10px] p-3 mb-3 cursor-pointer`} onClick={selectedItem}>
            <div className='flex items-center gap-3'>
                <div className='flex w-[25px]'>
                    <Checkbox aria-label={item} onClick={selectedItem} isSelected={checked} className='mb-0'></Checkbox>
                </div>
                <div className='flex flex-col gap-1'>
                    <div className='font-semibold text-[blue]'>
                        {item?.intended_program?.program?.name}
                    </div>
                    <div>
                        {item?.intended_program?.college?.name}
                    </div>
                </div>
            </div>
        </div>
    )
}