import { io } from "socket.io-client";
import { userStore } from "../store/userStore";

const URL = process.env.REACT_APP_BASE_URL;
const accessToken = userStore.getState().user.accessToken

export const socket = io(URL, {
    extraHeaders: {
        Authorization: accessToken,
    },
});