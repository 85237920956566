import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react";
import CountryWise from "./CountryWise";
import Uniquecountrywise from "./Uniquecountrywise";
import { userStore } from '../../../store/userStore'


const Countrywisenew =()=>{

    function getCurrentFinancialYearDates() {
        var today = new Date();
        var currentYear = today.getFullYear();
        var financialYearStart = new Date(currentYear, 3, 1); // April 1st is month 3 (0-indexed)
        if (today < financialYearStart) {
            financialYearStart = new Date(currentYear - 1, 3, 1); // April 1st is month 3 (0-indexed)
        }
        var nextYear = currentYear + 1;
        var financialYearEnd = new Date(nextYear, 2, 31); // March 31st is month 2 (0-indexed)
        if (financialYearEnd < today) {
            financialYearEnd = new Date(currentYear, 2, 31); // March 31st is month 2 (0-indexed)
        }
        var formattedStartDate = formatDate(financialYearStart);
        var formattedEndDate = formatDate(financialYearEnd);
        return {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        };
    }
    
    function formatDate(date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2); 
        var day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    }
    
    var financialYearDates = getCurrentFinancialYearDates();

    const [fromDate, setfromDate] = useState(financialYearDates.startDate);
    const [toDate, settoDate] = useState(financialYearDates.endDate);

    const [fromDateapi, setfromDateapi] = useState( financialYearDates.startDate);
    const [toDateapi, settoDateapi] = useState(financialYearDates.endDate);
    const user = userStore();
    const role = user?.user?.rid;
    const [selectedSemester, setSelectedSemester] = useState('ALL');

    const currentYear = new Date().getFullYear();
    const academicYears = [
      `${currentYear - 1}-${currentYear.toString().slice(-2)}`,
      `${currentYear}-${(currentYear + 1).toString().slice(-2)}`,
      `${currentYear + 1}-${(currentYear + 2).toString().slice(-2)}`
    ];
    const semesters = ['Summer', 'Winter', 'Fall'];


    
    const handlefromDate = (event) => {
        const value = event.target.value;
        setfromDate(value);
    };

    const handletoDate = (event) => {
        const value = event.target.value;
        settoDate(value);
    };

    const handleSearch = () => {
        setfromDateapi(fromDate)
        settoDateapi(toDate)
      };

      
      const getDateRangeForSemester = (semester, academicYear) => {
        const [startYear, endYear] = academicYear.split('-').map(year => parseInt(year, 10));
        const fullStartYear = startYear < 100 ? 2000 + startYear : startYear;
        const fullEndYear = endYear < 100 ? 2000 + endYear : endYear;

        switch (semester) {
          case 'SUMMER':
            return {
              start: `${fullStartYear}-04-01`,
              end: `${fullStartYear}-06-30`
            };
          case 'WINTER':
            return {
              start: `${fullStartYear}-07-01`,
              end: `${fullStartYear}-12-31`
            };
          case 'FALL':
            return {
              start: `${fullEndYear}-01-01`,
              end: `${fullEndYear}-03-31`
            };
          default:
            return null;
        }
    };




    const options = [
        ...academicYears.flatMap(academicYear =>
          semesters.map(semester => {
            const [startYear] = academicYear.split('-');
            const semesterValue = `${semester.toUpperCase()}${startYear}`;
            const dateRange = getDateRangeForSemester(semester.toUpperCase(), academicYear);
            return {
              value: semesterValue,
              label: `${semester} ${academicYear}`,
              dateRange
            };
          })
        )
    ];

    const handleSemesterChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSemester(selectedValue);
        const selectedOption = options.find(option => option.value === selectedValue);
        if (selectedOption && selectedOption.dateRange) {
            setfromDate(selectedOption.dateRange.start);
            settoDate(selectedOption.dateRange.end);
        } else {
            setfromDate(financialYearDates.startDate);
            settoDate(financialYearDates.endDate);
        }
    };


    useEffect(() => {
        // Set initial date range based on current semester
        const currentSemesterOption = options.find(option => option.value === selectedSemester);
        if (currentSemesterOption && currentSemesterOption.dateRange) {
            setfromDate(currentSemesterOption.dateRange.start);
            settoDate(currentSemesterOption.dateRange.end);
            setfromDateapi(currentSemesterOption.dateRange.start);
            settoDateapi(currentSemesterOption.dateRange.end);
        }
    }, []);

    
    

    return(
        <div>
            <div className="add_clients">			
                    <div className="row">
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    

                    <div id="accordion" className="add_clients_accordion_form" style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">

                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-8 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div style={{display: "-webkit-inline-box" }} className="table-search-wrpr">
                                                            <label style={{marginRight:7}}>From</label>
                                                            <input style={{minWidth: '100px'}} value={fromDate} onChange={handlefromDate}  className="form-control form-control-sm" type="date" placeholder="From" />
                                                            <label style={{marginRight:7}}>To</label>
                                                            <input style={{minWidth: '100px'}} value={toDate} onChange={handletoDate}  className="form-control form-control-sm" type="date" placeholder="TO" />
                                                           
                                                                
                                                            <select
                            className="form-control form-control-sm"
                            value={selectedSemester}
                            onChange={handleSemesterChange}
                        >
                            {options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                                                          
                                                            <button style={{background: '#323a51'}} onClick={handleSearch}  className="btn btn-info">Search</button>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>                           
                                           
                                        <CountryWise fromDate={fromDateapi} toDate={toDateapi} />
                                       
                                        <Uniquecountrywise fromDate={fromDateapi} toDate={toDateapi}/>
                                    

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
    </div>
    ) 
}
export default Countrywisenew;