import React, { Fragment, useState } from 'react'
import HOT from '../../assets/images/hot-icon-2.png'
import WARM from '../../assets/images/warm-icon-2.png'
import COLD from '../../assets/images/cold-icon-2.png'
import REG from '../../assets/images/registered-icon-2.png'
import { Modal, Avatar } from  '@nextui-org/react'
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore'

const LeadStatus = ({item, link}) => {

    const store = userStore((state) => state.user)

    const [visible, setVisible] = useState(false);
    const [result, setResult] = useState([])

    const handleOpen = () => {
        setVisible(true);
        usersList.mutateAsync(item?.id)
    };

    const handleClose = () => {
        setVisible(false);
    };

    const usersList = authQueries.useApplicantAssignedUsersDetails(
        (res)=>{
            setResult(res)
        }
    )


  return (
    <Fragment>
    {link?
        <div onClick={store?.role !== "MARKETINGASSOCIATE" && handleOpen} style={{ textDecoration: 'underline', cursor: "pointer", color: "blue" }}>View assigned staff details</div>:
        <div
        onClick={store?.role !== "MARKETINGASSOCIATE" && handleOpen}
        className={`type-wrpr ${store?.role !== "MARKETINGASSOCIATE" && "cursor-pointer"}`}>
            <img
            className="type-icon hot" 
                src={
                    item?.registered_status === "YES" ? REG : 
                    item?.lead_status === "HOT" ? HOT : 
                    item?.lead_status === "WARM" ? WARM :
                    item?.lead_status === "COLD" && COLD
                }
                width="" 
                height="" 
                alt="" 
            />
            <span 
            style={{
            marginLeft:'5px',
            color: item?.registered_status === "YES" && "#1C4C3B",
            fontWeight: 600
            }}
            >
            {
                item?.lead_status === "LONG" ? "LONG TERM" :
                item?.registered_status === "YES" ? "REGISTERED" : 
                item?.lead_status === "HOT" ? "HOT" : 
                item?.lead_status === "WARM" ? "WARM" : 
                item?.lead_status === "COLD" && "COLD"
            }
            </span>
        </div>
    }

        <Modal
        closeButton
        blur
        width="800px"
        aria-labelledby="modal-title"
        open={visible}
        onClose={handleClose} 
        >
            <Modal.Header>
                <h2 className="font-semibold text-lg">Assigned Details</h2>
            </Modal.Header>
            <Modal.Body>
                <div className='bg-blue-200 shadow-md rounded-xl px-3 py-2 font-medium flex flex-col gap-2'>
                    <div className='text-[12px] font-medium flex gap-3'><div className='w-[150px]'>Applicant Name</div>: <div>{item?.applicant_name} {item?.middlename} {item?.lastname}</div></div>
                    <div className='text-[12px] font-medium flex gap-3'><div className='w-[150px]'>Contact Number</div>: <div>{item?.mobile_no} {item?.landline_no} {item?.whatsapp_no}</div></div>
                    <div className='text-[12px] font-medium flex gap-3'><div className='w-[150px]'>Visa Type</div>: <div>{item?.visatype?.description}</div></div>
                    <div className='text-[12px] font-medium flex gap-3'><div className='w-[150px]'>Intake</div>: <div>{item?.intake?.intake}</div></div>
                    <div className='text-[12px] font-medium flex gap-3'><div className='w-[150px]'>Prospect Status</div>: <div>{item?.status_of_prospect?.name}</div></div>
                </div>
                <div className="grid grid-cols-12 text-[13px] gap-3">
                    {
                        result?.data?.map((data, index) => {
                            const details = data?.user?.userdetail
                            const role = data?.role
                            return(                     
                            <div className='col-span-6 bg-lime-200 shadow-md rounded-xl px-3 py-2 font-medium' key={index}>
                                <div className='flex gap-3 items-center'>
                                    <div>
                                        <Avatar rounded src={details?.image ? details?.image:require('../../assets/images/profile-avatar.jpg')} />
                                    </div>
                                    <div>
                                        <div>{details?.firstname} {details?.lastname}</div>
                                        <div>{role?.label}</div>
                                        <div>
                                            <div className='text-[12px] font-normal'>Email: {details?.email}</div>
                                            <div className='text-[12px] font-normal'>Mobile: {details?.phone}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )})
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>

    </Fragment>
  )
}

export default LeadStatus
