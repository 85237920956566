import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../../store/userStore";

const EditAppointment =()=>{

    const master = userStore();

    const validationSchema = yup.object({
        name: yup
          .string('⚠ Enter Appoint Type')
          .required('⚠ Appoint Type is required')
          .min(2, '⚠ Type should be of Minimum of 2 characters length'),
        status: yup
          .string('⚠ Enter your status')
          .required('⚠ Status is required'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');

    const navigate = useNavigate();

    useEffect(()=>{
        AppointData.mutateAsync(master.appoint);
    },[])

    const AppointData = authQueries.useAppointmentMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    name:response?.data.name,
                    order:response?.data.display_order,
                    status:response?.data.status
                })
                reset({ ...datas });
            }
        }   
    );


    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const editAppoint = authQueries.useAppointmentMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listappoint')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );
        
    
    const onSubmit = (data) => {
        const datavalues = {
            id:master.appoint,
            name: data.name,
            display_order: data.order,
            status: data.status,
        };
        
        editAppoint.mutateAsync(datavalues);
    }
    

    
    return(
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/listappoint">Appointment Types List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Appointment Type</li>
                </ol>
            </nav>

            <div className="card">
                <div className="card-header">
                    Edit Appointment Type
              
                </div>
                <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Name</label>
                                            <input 
                                                {...register("name")}
                                                type="text" 
                                                className="form-control"  
                                                name="name"/>
                                            <div className='error-msg'>{errors.name?.message}{errName}</div>
                                        </div>
                                        <div id="output"></div> 
                                    
                                </div>
                            </div>

                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Status</label>
                                            <select  {...register("status")}  className="form-control"  name="status">
                                                <option value="">Select</option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="INACTIVE">INACTIVE</option>
                                            </select>       
                                            <div className='error-msg'>{errors.status?.message}</div>
                                        </div>

                                        <div id="output"></div>
                                    
                                </div>
                            </div>                                                       
                            
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-default btn-save">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        </div>
    )
}

export default EditAppointment