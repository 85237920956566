import { yupResolver } from '@hookform/resolvers/yup'
import { Modal, Textarea } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { message } from 'antd'
import { useApi } from './ApiProvider'
import toast from 'react-hot-toast'

const LoginDetailsModal = ({item}) => {

    const [open, setOpen] = useState(false)
    const [login, setLogin] = useState("")

    const { setApiDataAndNotify } = useApi()

    const onSubmit = () => {
        let dataValue = {
            login: login,
            intid: item?.id
        }
        programLoginCreate.mutateAsync(dataValue)
    }

    const programLoginCreate = authQueries.useNotesUpdateMutation(
        (response) => {
            setApiDataAndNotify(response)
            handleClose()
            setLogin('')
            toast.success('Program Login Created Successfully');
        }
    )

    const handleOpen =()=> {
        setLogin(item?.login ? item?.login : '')
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
    }

  return (
    <Fragment>
        <button onClick={handleOpen} className='btn-customized'>Login Details</button>
        <Modal
            closeButton
            blur
            scroll
            width="600px"
            aria-labelledby="modal-title"
            open={open}
            onClose={handleClose}
            >
                <Modal.Header>
                    Program Login Details
                </Modal.Header>
                    <Modal.Body>
                        <Textarea
                        label="Enter Login Details"
                        initialValue={login}
                        onChange={(e)=>setLogin(e.target.value)}
                        />
                        <button onClick={onSubmit} className='btn-customized-large min-h-[40px]'>
                            {item?.login ?'Update':'Save'}
                        </button>
                    </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default LoginDetailsModal
