import RouteConstants from "../Constants/RouteConstans";

const program_finderlink = "https://www.unisetu.com/course-finder"
// const program_finderlink = program_finderlink


export const NavigationMaster = [
    {label: "Additional Information", url: RouteConstants.LISTINFO, icon: "icon-info3", masterItem: true},
    {label: "Office", url: RouteConstants.OFFICE, icon: "icon-domain", masterItem: true},
    {label: "Counselor Associates", url: RouteConstants.COUNSELORASSOCIATES, icon: "icon-user-add", masterItem: true},
    {label: "College", url: RouteConstants.COLLEGE, icon: "icon-office", masterItem: true},
    {label: "Country", url: RouteConstants.COUTRY, icon: "icon-earth", masterItem: true},
    {label: "District", url: RouteConstants.DISTRICT, icon: "icon-room", masterItem: true},
    {label: "Application Status", url: RouteConstants.LISTAPPSTAFFSTATUS, icon: "icon-settings", masterItem: true},
    {label: "Document Details", url: RouteConstants.LISTDOC, icon: "icon-file3", masterItem: true},
    {label: "Intake", url: RouteConstants.INTAKE, icon: "icon-download2", masterItem: true},
    {label: "Prospect Status", url: RouteConstants.LISTPROSPECT, icon: "icon-user3", masterItem: true},
    {label: "Visa Type", url: RouteConstants.VISATYPE, icon: "icon-airplane", masterItem: true},
    {label: "Program", url: RouteConstants.PROGRAM, icon: "icon-window", masterItem: true},
    {label: "Referral", url: RouteConstants.REFFERAL, icon: "icon-contacts", masterItem: true},
    {label: "Role", url: RouteConstants.ROLE, icon: "icon-stars", masterItem: true},
    {label: "Qualification", url: RouteConstants.QUALIFICATION, icon: "icon-graduate", masterItem: true},
    {label: "University", url: RouteConstants.UNIVERSITY, icon: "icon-library", masterItem: true},
    {label: "Senior Program Coordinator", url: RouteConstants.SENIORMANAGERPROGRAMMASTER, icon: "icon-users1", masterItem: true},
    {label: "Counselor Score", url: RouteConstants.COUNSELORSCORE, icon: "icon-users2", masterItem: true},
    {label: "USA Program State", url: RouteConstants.USALOCATION, icon: "icon-chart7", masterItem: true},
    {label: "USA Program City", url: RouteConstants.USACITY, icon: "icon-chart7", masterItem: true},
    {label: "USA Program Level", url: RouteConstants.USALEVEL, icon: "icon-chart7", masterItem: true},
]

export const NavigationReport = [
    {label: "Counselor Registration Report", url: RouteConstants.COUNSELOREGISTRATION, icon: "icon-chart7 !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Top performing Offices", url: RouteConstants.OFFICEVISA, icon: "icon-bars !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Top Performing Counselors", url: RouteConstants.TOPPERFORMER, icon: "icon-bubble_chart !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Country wise unique Report", url: RouteConstants.UNIQUECOUNTRYWISE, icon: "icon-graph !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Country Wise Top Performers", url: RouteConstants.COUNTRYPERFOMANCE, icon: "icon-graph !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Fee Paid Report", url: RouteConstants.FEEPAID, icon: "icon-pie_chart !text-[20px]", reportItem: true, logins: [20]},
    {label: "On Hold Report", url: RouteConstants.ONHOLDREPORT, icon: "icon-graphic_eq !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Visa Approved Report", url: RouteConstants.VISAAPPROVED, icon: "icon-graphic_eq !text-[20px]", reportItem: true, logins: []},
    {label: "Associates Report", url: RouteConstants.ASSOCIATEREPORT, icon: "icon-multiline_chart !text-[20px]", reportItem: true, logins: [2,12,20]},
    {label: "Admission Staff Perfomance", url: RouteConstants.ADMISSIONSTAFFREPORT, icon: "icon-chart7 !text-[20px]", reportItem: true, logins: [2,14]},
    {label: "Ready to Mark Registered", url: RouteConstants.READYTOMARK, icon: "icon-graph !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Employee Details Report", url: RouteConstants.EMPLOYEE, icon: "icon-show_chart !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Not Interested Report", url: RouteConstants.NOTINTEREST, icon: "icon-chart2 !text-[20px]", reportItem: true, logins: []},
    {label: "Not Qualified Report", url: RouteConstants.NOTQUALIFIED, icon: "icon-bars !text-[20px]", reportItem: true, logins: []},
    {label: "Not Proceeding with any offer", url: RouteConstants.NOTPROCEEDING, icon: "icon-graph !text-[20px]", reportItem: true, logins: [], allLogins: true},
    {label: "Client Export Report", url: RouteConstants.CLIENTEXPORT, icon: "icon-export !text-[20px]", reportItem: true, logins: [14]},
    {label: "Country wise unique Report new", url: RouteConstants.COUNTRYWISENEW, icon: "icon-graph !text-[20px]", reportItem: true, logins: [14]},
]


export const NavigationRoleWise = {
    MANAGER: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.MARKREGISTRATIONMYCLIENTS, icon: "icon-account_circle"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Visa Approved", url: RouteConstants.VISAFINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    BMANAGER: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.BMLIST, icon: "icon-account_circle"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Visa Approved", url: RouteConstants.OFFICEFINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    RM: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.RMMYCLIENTS, icon: "icon-account_circle"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    SENIORPROGRAMMANAGER: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.SENIORPROGRAMMANAGERMYCLIENT, icon: "icon-account_circle"},
        {label: "My Assigned Clients", url: RouteConstants.MYCLIENTSSENIORPROGRAMCOORDINATOR, icon: "icon-th-large"},
        {label: "Users List", url: RouteConstants.SENIORMANAGERUSERLIST, icon: "icon-user2"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],    
    REGISTRATION: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.MARKREGISTRATIONMYCLIENTS, icon: "icon-account_circle"},
        {label: "Visa Approved", url: RouteConstants.VISAFINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    ADMIN: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "Master", icon: "icon-stack3", master: true},
        {label: "Target", url: RouteConstants.TARGET, icon: "icon-target"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    COUNSELOR: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.APPLICANTASSIGNLIST, icon: "icon-account_circle"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Visa Approved", url: RouteConstants.FINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Loan Assistance", url: RouteConstants.LOAN, icon: "icon-dollar1"},    
        {label: "Travel Assistance", url: RouteConstants.TRAVEL, icon: "icon-airplane"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "USA Program Suggestion", url: RouteConstants.USAPROGRAM, icon: "icon-chart7 !text-[20px]", logins: [6,3], usaProgram: true},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    TELECALLER: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.TELECALLERMYCLIENTS, icon: "icon-account_circle"},
        {label: "Imported List", url: RouteConstants.IMPORT, icon: "icon-upload6"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 !text-[22px]"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    ADMISSIONSTAFF: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.ADMISSIONSTAFFMYCLIENTS, icon: "icon-account_circle"},
        {label: "Fee Paid", url: RouteConstants.FEECOORD, icon: "icon-moneybag !text-[20px]"},
        {label: "Visa Approved", url: RouteConstants.FINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    PROGRAMCOORDINATOR: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.PROGRAMMYCLIENTS, icon: "icon-account_circle"},
        // {label: "Pending Programs", url: RouteConstants.PENDINGPROGRAMLIST, icon: " icon-spinner"},
        {label: "Visa Approved", url: RouteConstants.FINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "USA Program Suggestion", url: RouteConstants.USAPROGRAM, icon: "icon-chart7 !text-[20px]", logins: [6,3], usaProgram: true},
        {label: "Report", report: true, icon: "icon-show_chart"},
        
    ],
    VISASTAFF: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.VISASTAFFMYCLIENTS, icon: "icon-account_circle"},
        {label: "Fee Paid", url: RouteConstants.FEECOORD, icon: "icon-moneybag !text-[20px]"},
        {label: "Visa Approved", url: RouteConstants.FINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    DOCUMENTSTAFF: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.DOCUMENTSTAFFMYCLIENTS, icon: "icon-account_circle"},
        {label: "Visa Approved", url: RouteConstants.FINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    FEECOORDINATOR: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.FEECOORDINATORMYCLIENTS, icon: "icon-account_circle"},
        {label: "Visa Approved", url: RouteConstants.FINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student Wise)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],    
    CEO: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.USERSLIST, icon: "icon-account_circle fontSize18"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Program Finder", url: program_finderlink, icon: "icon-window", anchor: true},
        {label: "Program Suggestion (Student)", url: RouteConstants.PROGRAMSUGGESTION, icon: "icon-lightbulb_outline !text-[20px]"},
        {label: "Program Suggestion (U/C)", url: RouteConstants.PGMSUGGESTIONREPORT, icon: "icon-chart7 !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "USA Program Suggestion", url: RouteConstants.USAPROGRAM, icon: "icon-chart7 !text-[20px]", logins: [6,3,14], usaProgram: true},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
    FRONTOFFICE: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "Employee Report", url: RouteConstants.EMPLOYEE, icon: "icon-show_chart !text-[20px]"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
    ],
    GIC: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Not Interested Clients", url: RouteConstants.NOTINTEREST, icon: "icon-thumb_down"},
        {label: "Not Qualified Clients", url: RouteConstants.NOTQUALIFIED, icon: "icon-forbid"},
        {label: "Report", report: true, icon: "icon-show_chart"}
    ],
    SOPSTAFF: [
        {label: "Dashboard", url: RouteConstants.DASHBOARD, icon: "icon-dashboard1"},
        {label: "My Clients", url: RouteConstants.SOPSTAFFMYCLIENTS, icon: "icon-account_circle"},
        {label: "Visa Approved", url: RouteConstants.FINISHEDCLIENTS, icon: "icon-checkmark3 !text-[20px]"},
        {label: "Daily Report", url: RouteConstants.DAILYREPORT, icon: "icon-time1 fontSize18"},
        {label: "Report", report: true, icon: "icon-show_chart"},
    ],
}

