import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { userStore } from "../../../store/userStore";
import { AutoCompleteAntd, AutoCompleteChild } from '../../Clients/AddClients/AutoComplete';
import { decode } from 'html-entities';


const validationSchema = yup.object({

    visa: yup
      .string('⚠ Select Visa Type')
      .required('⚠ Visa Type is required'),
    name: yup
      .string('⚠ Enter Document Name')
      .min(2,'⚠ Document Name must be more than 2 characters ')
      .required('⚠ Document Name is required'),
    status: yup
      .string('⚠ Enter your status')
      .required('⚠ Status is required'),
  });
  

const EditDoc = () => {

    const navigate = useNavigate();
    const master = userStore();

    const [visaResult, setVisaResult] = useState([])

    const [cdata, setCdata] = useState(null)

    const [errName, setErrName] = useState('')
    const [eer, setEer] = useState('')

    useEffect(()=>{
        startData();
    },[])

    const startData = async() => {
      await VisaList.mutateAsync({search: '', status: '', page: 0, size: 500});
      await DocData.mutateAsync(master.doc);
    }

    const DocData = authQueries.useDocDetailMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    vid:response?.data?.vid,
                    name:decode(response?.data.document_name),
                    status:response?.data.status
                })
                setCdata(response?.data.vid)
                reset({ ...datas });
            }
        }   
    );


    const { register, reset, handleSubmit, control, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const VisaList = authQueries.useVisaTypeMutationList(
        (response) => {

            setVisaResult(response)

            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.description,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setVisaResult(dataArray)

        }
    );


    const editDoc = authQueries.useDocDetailMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listdoc')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );

    const onSubmit = (data) => {

    
        const datavalues = {
            id:master.doc,
            vid: data.visa,
            document_name: data.name,
            status: data.status,
        };
    
        // e.preventDefault()
    
        editDoc.mutateAsync(datavalues);    
    
      }
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/listdoc">Document Details</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Document</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Edit Document Details
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Visa Type :</label>
                                            
                                                <AutoCompleteAntd 
                                                    control={control} 
                                                    data={visaResult} 
                                                    name={"visa"} 
                                                    value={cdata}
                                                />
                                                
                                                <div className='error-msg'>{errors.visa?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">

                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Document Name</label>
                                                <input className="form-control"  {...register("name")} type="text" name='name' />
                                                <div className='error-msg'>{errors.name?.message} {errName}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="">Status</label>
                                        <select className="form-control" name='status'  {...register("status")}>
                                            <option value=''>Select</option>
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="INACTIVE">INACTIVE</option>
                                        </select>
                                        <div className='error-msg'>{errors.status?.message}</div>
                                    </div>

                                </div>
                                
                                
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Update</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default EditDoc;