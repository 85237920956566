import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";

const FeepaidStatus =()=>{

    const [result, setResult] = useState([]);
    const [status, setStatus] = useState('');
    const [cid, setCid]= useState('');
    const [countries, setCountries] = useState([])
    let PrevCount=0;
    let CurrCount=0;
    let FutCount=0;



    useEffect(()=>{
        countryListing.mutateAsync({page:0, size:100})
      },[])
      
      
      const countryListing = authQueries.useCountryMutationList(
          (response) => {
            setCountries(response)
          }   
      ); 
    
    const FeeStatusApi = authQueries.useFeeStatusReport(

        (response) => {
            console.log("response",response)
            setResult(response);        
        }   
    
    );

    const onCountry =(e)=> {
        setCid(e)
        FeeStatusApi.mutateAsync({cid:e})
    
    }
    

    return(
        <div>
            <div className="content-area">
				<div className="breadcrumb-and-otherbuttons">
					{/* <!-- breadcrumb --> */}
					<nav aria-label="breadcrumb">
					  <ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
						<li className="breadcrumb-item active" aria-current="page">Fee Paid Status Report</li>
					  </ol>
					</nav>
				</div>
				
				{/* <!-- addclients --> */}
				<div className="add_clients">
				
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span> Fee Paid Status Report</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					

					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
						  <div className="card-header">
							
                          Fee Paid Status Report
							
							
						  </div>
						  <div id="appointment_any" className="collapse show" data-parent="#accordion">
							<div className="card-body">
									
                                <div className="row ">
                                    <div className="col-md-12">
                                    
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    
                                                </div>
                                                <div className="col-md-6 text-right">
                                                <div className="table-search-filter-wrpr">
                                                    <div className="table-search-wrpr">
                                                            <select onChange={(e)=>{onCountry(e.target.value)}} className="form-control  form-control-sm" >
                                                                <option value="">Select Country</option>
                                                                {
                                                                    countries?.data?.items?.map((item, index) =>(
                                                                        <option key={index} value={item.id}>{item.name}</option>
                                                                ))}
                                                                
                                                            </select>
                                                            </div>
                                                        </div>
                                                </div>
                                        </div>

                                        
                                        
                                            <table border={1} className="table report collageprogram">
                                                <thead>
                                                    <tr>
                                      
                                                  
                                                    <th>Intake</th>
                                                    <th width="200">Previous Financial Year</th>
                                                    <th width="200">Current Financial Year</th>
                                                    <th width="200">Future Financial Year</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { FeeStatusApi.isLoading && "Loading" }
                                                {   
                                                
                                                    FeeStatusApi?.data?.data[0]?.map((item, index) =>
                                                    {  PrevCount+=item.prev_yr
                                                       CurrCount+=item.curr_yr
                                                       FutCount+=item.fut_yr

                                                    return(
                                                        <tr className="ui-sortable-handle">
                     
                                                       
                                                        <td>{item.intake}</td>
                                                        <td>{item.prev_yr}</td>
                                                        <td>{item.curr_yr}</td>
                                                        <td>{item.fut_yr}</td>

                                                        </tr>
                                                    )})
                                                }
                                                <tr>
                                                    <td><b>Total</b></td>
                                                    <td><b>{PrevCount}</b></td>
                                                    <td><b>{CurrCount}</b></td>
                                                    <td><b>{FutCount}</b></td>
                                                    </tr>
                                               </tbody>

                                            </table>
                                    </div>
                                </div>
								
								</div>
							</div>
						</div>
						
						
					  </div>
					
				</div>
				
			</div>

        </div>

    </div>
    )

    


    
}
export default FeepaidStatus;