import { Pagination, Spin } from 'antd'
import { decode } from 'html-entities'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore';

const ApplicantStaffDashboard =()=> {

  const [result, setResult] = useState([]);

  const navigate = useNavigate();

  useEffect(()=>{
    ApplicantListing.mutateAsync({page:0, size:10})
  },[])

  const appStore = userStore();

  const ApplicantListing = authQueries.useApplicantMutationList(
    (response) => {
        setResult(response?.data)
        // console.log(response?.data)
    }   

); 

const editApplicant =(id, geebee)=>{
  appStore.setApplication(id)
  viewApplication.mutateAsync(id)
}

const addApplicant =(id, geebee)=>{
  appStore.setApplication('')
  navigate('/addclient')
}



const viewApplication = authQueries.useApplicantMutationView(
  (response) => {
      // console.log(response?.data)
      let val = response?.data

      const dataValues = {
          appName: val.applicant_name,
          marital: val.marital_status,
          appEmail: val.email,
          mobile:val.mobile_no,
          whtsapp: val.whatsapp_no,
          landline: val.landline_no,
          appOccu:val.occupation,
          appDob:val.dob,
          age:val.age,
          appQualification:val.qualification,
          noofChild:val.no_of_children,
          passno:val.passport_no,
          aadhno:val.aadhar_no,
          visatype:val.visa_type_id,
          intake:val.intake_id,
          reffered:val.referral_id,
          office:val.office_id,
          spoName: val?.spouse?.spouse_name,
          lead_status: val?.lead_status,
          // spoEmail: val?.spouse?.spouse_email,
          spouse_status:val?.spouse_status,
          spoAge: val?.spouse?.age,
          spoDob: val?.spouse?.dob,
          spoOccu: val?.spouse?.occupation,
          spoQualification: val?.spouse?.qualification,
      }

      appStore.setApplicantData(dataValues)

      navigate('/addclient')

  }   
);      



  return (

    <div className="content-area">
    <div className="breadcrumb-and-otherbuttons">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Applicant Details</li>
        </ol>
      </nav>
      <button className="icon-plus delete" onClick={addApplicant}></button>
    </div>
    

    <div className="add_clients">
    
      <div className="row">
        <div className="col-md-6 appointments-hing-wrpr">
            <i className="appointments-icon">
                <img src={require("../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
            </i>
            <h2><span>&nbsp;</span>Applicant Details</h2>	
        </div>

        <div className="col-md-12">
            <hr className="progress-hr" />
        </div>
      </div>
      

      <div id="accordion" className="add_clients_accordion_form">
        <div className="card" id="appointments_wrpr">
          <div className="card-header">
            Applicant Details
          </div>
          <div id="appointment_any" className="collapse show" data-parent="#accordion">
          <div className="card-body">
              
                <div className="row ">
                    <div className="col-md-12">
                    
                        <div className="table-list-search-wrpr">
                            <div className="row">
                                <div className="col-md-6">
                                    
                                </div>
                                <div className="col-md-6 text-right">
                                    <div className="table-search-filter-wrpr">
                                        <div className="table-search-wrpr">
                                            <input style={{minWidth: '300px'}} 
                                              // onChange={searchData} 
                                              className="form-control form-control-sm" type="text" placeholder="Search" />
                                        </div>
                                        <div className="table-filter-wrpr">
                                            <select 
                                            // onChange={filterData} 
                                            className="form-control  form-control-sm" >
                                                <option value=''>Select</option>
                                                <option value='ACTIVE'>Active</option>
                                                <option value='INACTIVE'>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        
                            <table className="table collageprogram">
                                <thead>
                                    <tr>
                                    <th width="50">#</th>
                                    <th >Applicant Name</th>
                                    <th width="150">Gender</th>
                                    <th width="150">Email</th>
                                    <th width="150">Mobile</th>
                                    <th width="150">Qualification</th>
                                    <th width="150"> GEEBEE ID</th>
                                    <th width="150">Status</th>
                                    <th>&nbsp;</th>
                                    </tr>
                                </thead>

                                {
                                    result?.items?.map((item, index) =>(

                                    <tbody key={index} className="ui-sortable">
                                        <tr className="ui-sortable-handle">
                                        <th className="">{(index + 1) + result?.currentPage * 10}</th>
                                        <td>{decode(item.applicant_name)}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile_no}</td>
                                        <td>{item.qualification}</td>
                                        <td>{item.geebee_student_id}</td>
                                        <td>
                                            <span>{item.lead_status}</span>
                                        </td>
                                        <td className="action-icons">
                                            <button 
                                              onClick={()=>{editApplicant(item.id, item.geebee_student_id)}} 
                                              className="icon-pencil3"></button>
                                            <button 
                                              // onClick={event => deleteCollegeData(event, item.id)} 
                                              className="icon-trashcan delete"></button>
                                            
                                        </td>
                                        </tr>

                                    </tbody>

                                ))
                                }
                            </table>
                            
                          <ul className="pagination pagination-sm justify-content-center table-pagination mt-5">

                          <Pagination
                              defaultCurrent={1}
                              // total={result?.totalItems}
                              // onChange={(e) => {mutateAsync({search: name, status: status, page:e-1, size:pageSize})}}
                          />
                          </ul>
                    
                    </div>
                </div>
            
            </div>
          </div>
        </div>
        
        
      </div>
      
    </div>
    
  </div>
  )
}

export default ApplicantStaffDashboard