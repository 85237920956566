import React from 'react'
import { Link } from 'react-router-dom'

const MasterHeader = ({header, breadcrumb, handleOpen}) => {
  return (
    <div>
        <div className="breadcrumb-and-otherbuttons mb-2">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{breadcrumb ? breadcrumb : header}</li>
                </ol>
            </nav>
            <div className="other-buttons">
                <button className="bg-[#323a51] text-[12px] text-white px-4 py-[10px] rounded-lg" onClick={()=>handleOpen("")}>Create {header}</button>
            </div>
        </div>
    </div>
  )
}

export default MasterHeader