import React from 'react'

const ErrorPage = () => {
  return (
    <div className='bg-white flex justify-center items-center min-h-[100vh]'>
       <img className='w-[30%] max-2xl:w-[35%] max-xl:w-[40%] max-lg:w-[45%] max-md:w-[60%] max-sm:w-[70%] max-xs:w-[80%]' src={require('../../assets/images/error.jpg')} />
    </div>
  )
}

export default ErrorPage