import React, { Fragment } from 'react';

const UsaInputSelectForm = ({ item, register, errors, options, optionProps, onChange }) => {
  return (
    <Fragment>
      <div className="text-[12px] h-4 font-[500]">
        {item?.label} {item?.required && <span className="text-[red]">*</span>}
      </div>
      <select
        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
        name={item?.name}
        {...register(`${item.name}`)}
        onChange={onChange} 
      >
        <option value="">Select {item?.nameShow && item?.label}</option>
        {options?.map((option, index) => (
          <option key={index} value={option?.[optionProps?.value]}>
            {option?.[optionProps?.name]}
          </option>
        ))}
      </select>
      <div className="text-[12px] text-red-600">
        {errors?.[item?.name]?.message}
      </div>
    </Fragment>
  );
};

export default UsaInputSelectForm;
