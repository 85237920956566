import React from 'react'
import { useNavigate } from 'react-router-dom';

const AddButton = ({url, name}) => {
    const navigation = useNavigate();
  return (
    <div>
        <button onClick={()=>{navigation(url)}} className="btn btn-md btn-dark btn-add-new-client btn-add-new-client2">
          <i className="icon-plus" style={{fontSize:'12px'}}></i> 
          Add {name}
        </button>
    </div>
  )
}

export default AddButton