import React, { useEffect, useRef, useState } from "react";
import { socket } from "./socket";
import { Button, Space, message as messages, notification } from "antd";
import { useViewProfile, userStore } from '../store/userStore';
import authQueries from "../queries/authQueries";
import { useNavigate } from "react-router-dom";
import ValidationListingModal from "../components/Common/ValidationListingModal";
import { useExistModal } from "../store/existModalStore";


const SocketNotification =()=> {

    const storeData = userStore()
    const navigate = useNavigate()
    const setApplicationId = useViewProfile((state) => state.setAppId)

    const setAppid = useExistModal((state) => state.setAppid)
    const setOpen = useExistModal((state) => state.setOpen)

    useEffect(()=>{
      socket.on('profile_update', (message) => {
         if(message.id === storeData.user.id){
          const user = storeData?.user
          // user.name = message?.name
          const data = {
            ...user,
            image: message?.image,
            name: message?.name
          }
          
          storeData.setUser(data)
          
          openProfileUpdate(message)
         }
      });
    },[])

    useEffect(()=> {
      notify()
    },[])

    const notify = async()=> {
      socket.on('notification', (message) => {
        if(message?.rid){
            if(message.uid === storeData?.user?.id && message.rid === storeData?.user?.rid){
              if(message?.important){
                openHighNotification(message?.appid, message?.message, message?.details)
              }else{
                openNotification(message?.appid, message?.message, message?.details, message?.visaapproved)
              }
            }
        }else{
          if(message.uid === storeData?.user?.id){
            if(message?.important){
              openHighNotification(message?.appid, message?.message, message?.details)
            }else{
              openNotification(message?.appid, message?.message, message?.details, message?.visaapproved)
            }
          }
        }
      });

      socket.on('notificationCount', (message) => {

        let data = storeData.notificationCount
        if(!data){
          data = []
        }
        let alreadyExist = data?.find(item => item.id === message.id)

        
        if(alreadyExist){
          if(message.count){
            alreadyExist.count = message.count
          }else{
            alreadyExist.count = 0
          }
          if(message.count2){
            alreadyExist.count2 = message.count2
          }else{
            alreadyExist.count2 = 0
          }
          storeData.setNotificationCount(data)
        }else{
          data.push({id: message.id, count:message.count, count2: message.count2})
          storeData.setNotificationCount(data)
        }
      })
    }

    const viewData = async(id) => {
      await setApplicationId(id)  
      navigate('/applicantdetails')
    }
  
    const [api, contextHolder] = notification.useNotification()
    const [profile, contextProfile] = notification.useNotification()

    const audioRef = useRef(null);
    
    const openNotification = (appid, message, details, visaapproved) => { 
        const btn = (
            <Space>
              {
                appid &&
                <Button type="primary" size="small" onClick={()=>{viewData(appid)}}>
                    View Applicant
                </Button>
              }
            </Space>
        );
        api.open({
              key: appid,
              duration: '12',
              placement: 'topRight',
              message: <span style={{fontSize:'13px', fontWeight:600, color: visaapproved ? '#fff' : '#1269fd', fontFamily: "var(--primary-font)"}}>{message}</span>,
              description: <span style={{fontSize:'13px', fontWeight:'normal', color: visaapproved ? '#fff' : '#434242'}}>{details}</span>,
              btn,
              className: 'custom-class',
              style: {
                width: 400,
                background: visaapproved ? '#9DBDFF' : "#fff",
              },
        });
    };

    const handleOpenExist =(appid)=>{
      setAppid(appid)
      setOpen(true)
      api.destroy()
    }

    const openHighNotification = (appid, message, details) => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      const btn = (
          <Space>
            {
              appid &&
              <button className="bg-[#f00] px-2 py-1 text-[12px] rounded-lg text-white font-medium" type="primary" size="small" onClick={()=> {handleOpenExist(appid)}} >
                View Details
              </button>
            }
          </Space>
      );
      api.open({
            duration: '12',
            placement: 'topRight',
            message: <span style={{fontSize:'14px', fontWeight:600, color:'#f00', fontFamily: "var(--primary-font)"}}>{message}</span>,
            description: <span style={{fontSize:'13px', fontWeight:'lighter', color:'#1269fd', color:'#434242'}}>{details}</span>,
            btn,
            className: 'custom-class',
            style: {
              width: 400
            },
      });
    };


    const openProfileUpdate = (message) => { 
      profile.open({  
            duration: '12',
            placement: 'topRight',
            message: <span style={{fontSize:'13px', fontWeight:600, color:'#1269fd', fontFamily: "var(--primary-font)"}}>{message?.message}</span>,
            description: <span style={{fontSize:'13px', fontWeight:'lighter', color:'#1269fd', color:'#434242'}}>{message?.description}</span>,
            className: 'custom-class',
            style: {
            width: 400,
          },
      })
  }


  // const uid = storeData.user

  // useEffect(() => {
  //   socket.emit('login', uid.username);
  //   socket.on('updateActiveUsers', (users) => {
  //     storeData.setActiveUsers(users)
  //   });

  //   const tabActivityInterval = setInterval(() => {
  //     socket.emit('tabActivity', !document.hidden);
  //   }, 5000);

  //   return () => {
  //     clearInterval(tabActivityInterval);
  //     socket.emit('logout');
  //   };
  // }, [uid.username]);

  return (
    <div>
      {contextHolder}
      {contextProfile}
      <ValidationListingModal />
      <audio ref={audioRef}>
          <source src="https://new.themagweb.com/assets/error.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
      </audio>
    </div>
  )
}

export default SocketNotification
