import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../../store/userStore';
import { AutoCompleteAntd, AutoCompleteChild } from "../../Clients/AddClients/AutoComplete";

const ProgramEdit =()=>{

    const master = userStore();

    const validationSchema = yup.object({
        cid: yup
          .string('⚠ Enter your country')
          .required('⚠ country is required'),
        name: yup
          .string('⚠ Enter your Name')
          .required('⚠ Name is required')
          .min(2, '⚠ Name should be of Minimum of 2 characters length'),
        code: yup
          .string('⚠ Enter your program code')
          .min(2, '⚠ At least 2 characters Long')
          .required('⚠ Program code is required'),
        link: yup
          .string('⚠ Enter your program link')
          .min(2, '⚠ At least 2 characters Long')
          .required('⚠ Program link is required'),
        status: yup
          .string('⚠ Enter your status')
          .required('⚠ Status is required'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');
    const [cdata, setCdata] = useState(null)
    const [dataResult, setDataResult] = useState([]);

    const navigate = useNavigate();


    useEffect(()=>{
        startData();
    },[])

    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const startData = async() => {
        await countryData.mutateAsync({search:'', status: '', page:0, size:500});
        await programData.mutateAsync(master.program);
    }


    const countryData = authQueries.useCollegeMutationList(
        (response) => {
            setResult(response) 
            let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setResult(dataArray)
        }   
    );

    const programData = authQueries.useProgramMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    cid:response?.data.cid,
                    name:response?.data.name,
                    code:response?.data.program_code,
                    link:response?.data.programlink,
                    status:response?.data.status
                })
                setCdata(response?.data.cid)
                reset({ ...datas });
            }
        }   
    );


 


    const editProgram = authQueries.useProgramMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/program')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );
        
    const onSubmit = (data) => {
        const datavalues = {
            id: master.program,
            cid: data.cid,
            name: data.name,
            program_code:data.code,
            programlink: data.link,
            status: data.status,
        };
        
        editProgram.mutateAsync(datavalues);
    }
    

    
    return(
        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/program">Program List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Program</li>
                </ol>
            </nav>
            <div className="card">
                <div className="card-header">
                {/* <a className="collapsed card-link" data-toggle="collapse" href="#intended_college_program"> */}
                    Edit Program
                {/* </a> */}
                
                </div>
                <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                    {programData &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group"> 
                                    <div id="parent" className="form-group">
                                    <label htmlFor="">Country :</label>

                                        <AutoCompleteAntd
                                            control={control} 
                                            data={result} 
                                            name={"cid"} 
                                            value={cdata}
                                        />

                                        <div className='error-msg'>{errors.cid?.message}</div>
                                    </div>
                                    <div id="output"></div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Name</label>
                                            <input 
                                                {...register("name")}
                                                type="text" 
                                                className="form-control"  
                                                name="name"/>
                                            <div className='error-msg'>{errors.name?.message}{errName}</div>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Program Code</label>
                                            <input
                                                {...register("code")} 
                                                type="text" 
                                                className="form-control"  
                                                name="code"/>
                                            <div className='error-msg'>{errors.code?.message}</div>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Program Link</label>
                                            <input 
                                                {...register("link")} 
                                                type="text" 
                                                className="form-control"  
                                                name="link"/>
                                            <div className='error-msg'>{errors.link?.message}</div>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Status</label>
                                            <select {...register("status")} className="form-control"  name="status">
                                                <option value="">Select</option>
                                                <option
                                                    // selected={dataResult.status === "ACTIVE"} 
                                                    value="ACTIVE">ACTIVE</option>
                                                <option
                                                    // selected={dataResult.status === "INACTIVE"} 
                                                    value="INACTIVE">INACTIVE</option>
                                            </select>       
                                            <div className='error-msg'>{errors.status?.message}</div>
                                        </div>

                                        <div id="output"></div>
                                    
                                </div>
                            </div>                                                       
                            
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-default btn-save">Update</button>
                            </div>
                        </div>
                    </form>
                    }
                </div>
            </div>
        </div>


        </div>
    )
}

export default ProgramEdit