import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, Space } from 'antd';
import { AutoCompleteAntd, AutoCompleteChild } from '../../Clients/AddClients/AutoComplete';


const validationSchema = yup.object({

    country: yup
      .string('⚠ Enter Country Name')
      .required('⚠ Coutry Name is required'),
    intake: yup
      .string('⚠ Enter Intake Name')
      .required('⚠ Intake Name is required')
      .min(2,'⚠ Intake Name must be more than 2 characters '),
    visacategory: yup
      .string('⚠ Enter Visa Category')
      .required('⚠ Visa Category is required')
      .min(2,'⚠ Visa Category must be more than 2 characters '),
    visa: yup
      .string('⚠ Enter Visa Type')
      .required('⚠ Visa Type is required'),
    date: yup
        .string('⚠ Enter Date')
      .required('⚠ Date is required'),
  });
  

const AddIntake = () => {

    const navigate = useNavigate();

    const [countryResult, setCountryResult] = useState([])
    const [visaResult, setVisaResult] = useState([])
    const [errName, setErrName] = useState('')

    // const onChange = (date, dateString) => {
    //     console.log(date, dateString);
    //   };

    useEffect(()=>{
        CountryList.mutateAsync({search: '', status: '', page:0, size:500});
        VisaList.mutateAsync({search: '', status: '', page:0, size:500});
    },[])


    const { register, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const CountryList = authQueries.useCountryMutationList(
        (response) => {

            setCountryResult(response)

            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setCountryResult(dataArray)

        }
    );

    const VisaList = authQueries.useVisaTypeMutationList(
        (response) => {
            setVisaResult(response)
            let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.description,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }

            setVisaResult(dataArray)
        }
    );

    const AddIntakedata = authQueries.useIntakeMutationAdd(
        (response) => {
            if(response?.message === "Success"){
                navigate('/intake')
            }else if(response?.error[0].intake){
                setErrName("⚠ "+response?.error[0].intake)
            }

        }
    );

    const onSubmit = (data) => {
    
        const datavalues = {
            visa_category: data.visacategory,
            visa_type_id: data.visa,
            country_id: data.country,
            intake: data.intake,
            intake_date: data.date,
            status: "ACTIVE",
        };
    
        // e.preventDefault()
    
        AddIntakedata.mutateAsync(datavalues);    
    
      }
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/intake">Intakes</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add Intake</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Add Intake
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Visa Category</label>
                                                <select  className="form-control" name='visacategory'  {...register("visacategory")}>
                                                    <option value=''>Select</option>
                                                    <option value='STUDENT'>STUDENT</option>
                                                    <option value='IMMIGRATION'>IMMIGRATION</option>
                                                    
                                                </select>
                                                <div className='error-msg'>{errors.visacategory?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                {/* <label htmlFor="">Visa Type</label>
                                                <select className="form-control"  {...register("visa")} name='visa'>
                                                    <option value=''>Select</option>
                                                    {
                                                        visaResult?.data?.map((item, index) =>(
                                                            <option key={index} value={item.id}>
                                                                {item.visacode}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                <p className='error'>{errors.visa?.message} {errName}</p> */}
                                                <label htmlFor="">Visa Type :</label>

                                                <AutoCompleteAntd
                                                    control={control} 
                                                    data={visaResult} 
                                                    name={"visa"} 
                                                    value={{label: '', value: null}}
                                                />
                                                <div className='error-msg'>{errors.visa?.message}</div>
                                            </div>
                                        </div>
                                    </div>  

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                            <label htmlFor="">Country :</label>

                                                <AutoCompleteAntd
                                                    control={control} 
                                                    data={countryResult} 
                                                    name={"country"} 
                                                    value={{label: '', value: null}}
                                                    // value={visaData} 
                                                    // error={errors.country}
                                                />
                                                <div className='error-msg'>{errors.country?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Intake</label>
                                                <input className="form-control"  {...register("intake")} type="text" name='intake' />
                                                <div className='error-msg'>{errors.intake?.message} {errName}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Date</label>
                                                <input type='date' {...register("date")} name='date' className="form-control" />
                                                <div className='error-msg'>{errors.date?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default AddIntake;