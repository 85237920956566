import React,{useState, useEffect} from 'react'
import authQueries from '../../../queries/authQueries'
import { Link, useNavigate } from "react-router-dom";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { userStore } from "../../../store/userStore";

const validationSchema = yup.object({

    code: yup
      .string('⚠ Enter Code')
      .required('⚠ Code is required')
      .min(2,'⚠ Code must be more than 2 characters '),
    description: yup
      .string('⚠ Enter Description')
      .required('⚠ Description is required')
      .min(2,'⚠ Description must be more than 2 characters '),
    level: yup
      .string('⚠ Enter Level')
      .required('⚠ Level is required'),
    status: yup
      .string('⚠ Enter your status')
      .required('⚠ Status is required'),
  });

const  EditNoc = () => {

    const [errName, setErrName] = useState('')

    const navigate = useNavigate();

    const master = userStore();

    useEffect(() => {
        NocData.mutateAsync(master.noc);
    },[])

    const { register, reset, handleSubmit, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
    });


    const editNoc = authQueries.useNocMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/noc')
            }else if(response?.error[0].code){
                setErrName("⚠ "+response?.error[0].code)
            }
        }   
    );

    const NocData = authQueries.useNocMutationView(
        (response) => {
            console.log(response)
            if(response?.data){
                let datas = ({
                    code: response?.data.code,
                    description: response?.data.description,
                    level: response?.data.level,
                    status:response?.data.status,
                })
                reset({ ...datas });
            }
        }   
    );

    const onSubmit = (data) => {

    
        const datavalues = {
            id: master.noc,
            code: data.code,
            description: data.description,
            level: data.level,
            status: data.status,
    
        };
    
        editNoc.mutateAsync(datavalues);    
    
      }


  return (

    <div>

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/noc">Noc</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Edit Noc</li>
            </ol>
        </nav>


        <div className="add_clients">

            <div className="card">
                <div className="card-header">
                    Edit Noc
                </div>
                <div id="travel_history" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">NOC Code</label>
                                            <input className="form-control"  {...register("code")} type="text" name='code' />
                                            <div className='error-msg'>{errors.code?.message} {errName}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Description</label>
                                            <input className="form-control"  {...register("description")} type="text" name='description' />
                                            <div className='error-msg'>{errors.description?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Level</label>
                                            <select className="form-control" {...register("level")} name='level'>
                                                <option value=''>Select</option>
                                                <option value="O">O</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                            </select>
                                            <div className='error-msg'>{errors.level?.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Status</label>
                                            <select className="form-control" name='status'  {...register("status")}>
                                                <option value=''>Select</option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="INACTIVE">INACTIVE</option>
                                            </select>
                                            <div className='error-msg'>{errors.status?.message}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button type='submit' className="btn btn-default btn-save">Update</button>
                                </div>
                            </div>
                        </form>                            
                    </div>
                </div>
            </div>

        </div>


    </div>

  )
}

export default EditNoc