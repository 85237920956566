import React, { Fragment, useEffect } from 'react'
import { useColorScheme } from '../../store/colorSchemeStore';

const ColorSheme = () => {

    const color = useColorScheme((state)=> state.bgColor)
    const setColor = useColorScheme((state)=> state.setBg)
    const activeTextColor = useColorScheme((state)=> state.activeTextColor)
    const setActiveTextColor = useColorScheme((state)=> state.setActiveTextColor)
    const assignBgSelected = useColorScheme((state)=> state.assignBgSelected)
    const assignBgNonSelected = useColorScheme((state)=> state.assignBgNonSelected)
    const setAssignBgSelected = useColorScheme((state)=> state.setAssignBgSelected)
    const setAssignBgNonSelected = useColorScheme((state)=> state.setAssignBgNonSelected)
    const fontFamily = useColorScheme((state)=> state.fontFamily)
    const setFontFamily = useColorScheme((state)=> state.setFontFamily)

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', color);
        document.documentElement.style.setProperty('--active-sidebar', activeTextColor)
        document.documentElement.style.setProperty('--assign-bg-selected', assignBgSelected)
        document.documentElement.style.setProperty('--assign-bg-non-selected', assignBgNonSelected)
        document.documentElement.style.setProperty('--primary-font', fontFamily)
    }, [color, activeTextColor, assignBgSelected, assignBgNonSelected, fontFamily]);
    
    const colors = [
        '#323a51', '#186F65', '#FE7A36', 
        '#FF004D', '#AAD9BB', '#4942E4', 
        '#756AB6', '#88AB8E', '#FF0303', 
        '#FC6736', '#5F0F40', '#D0A2F7',
        '#F4CE14', '#008170', '#D2DE32',
        '#E90064', '#16FF00', '#A084DC',
        '#FF0032', '#EAC7C7', '#FF7B54',
        '#A31ACB', '#A555EC', '#E5BA73',
        '#3CCF4E', '#231955', '#06283D',
        '#94B49F', '#FFA500', '#FF06B7',
        '#354259', '#00FFAB', '#15133C',
        '#001D6E', '#383838', '#0E3EDA',
        '#019267', '#5463FF', '#084594',
        '#000000',
    ]

    const fontFamilyArray = [
        {font: "Poppins", value: "Poppins", id: 1},
        {font: "Lato", value: "Lato", id: 2},
        {font: "Roboto", value: "Roboto", id: 3},
        {font: "Montserrat", value: "Montserrat", id: 4},
        {font: "Soleil", value: "Soleil", id: 5},
        {font: "Open Sans", value: "Open Sans", id: 6},
        {font: "Bungee Tint", value: "Bungee Tint", id: 7},
        {font: "Matemasie", value: "Matemasie", id: 8},
        {font: "Kalnia Glaze", value: "Kalnia Glaze", id: 9},
        {font: "Sacramento", value: "Sacramento", id: 10},
        {font: "Sofia", value: "Sofia", id: 11},
        {font: "Nabla", value: "Nabla", id: 12},
        {font: "Raleway", value: "Raleway", id: 13},
        {font: "ProductSans", value: "ProductSans", id: 14},
    ]

    const handleChange =async(item)=>{
       setColor(item)
    }

    const handleActiveTextChange =async(item)=>{
        setActiveTextColor(item)
    }

    const handleNonSelectedAssign =async(item)=>{
        setAssignBgNonSelected(item)
    }

    const handleSelectedAssign =async(item)=>{
        setAssignBgSelected(item)
    }

    const handleFontFamily =async(item)=>{
        setFontFamily(item)
    }

    return(
        <Fragment>
            <div className='text-[14px] font-semibold mb-2'>
                Background Colors
            </div>
            <div className='flex gap-4 flex-wrap'>
                {
                    colors?.map((item, index)=> (
                        <div
                        key={index}
                        style={{background:item}}
                        className='w-10 min-w-10 h-10 rounded-full cursor-pointer' onClick={()=>{handleChange(item)}} />
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                Sidebar Active Text Colors
            </div>
            <div className='flex gap-4 flex-wrap'>
                {
                    colors?.map((item, index)=> (
                        <div
                        key={index}
                        style={{background:item}}
                        className='w-10 min-w-10 h-10 rounded-full cursor-pointer' onClick={()=>{handleActiveTextChange(item)}} />
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                Assign Modal Non Selected Colors
            </div>
            <div className='flex gap-4 flex-wrap'>
                {
                    colors?.map((item, index)=> (
                        <div
                        key={index}
                        style={{background:item}}
                        className='w-10 min-w-10 h-10 rounded-full cursor-pointer' onClick={()=>{handleNonSelectedAssign(item)}} />
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                Assign Modal Selected Colors
            </div>
            <div className='flex gap-4 flex-wrap'>
                {
                    colors?.map((item, index)=> (
                        <div
                        key={index}
                        style={{background:item}}
                        className='w-10 min-w-10 h-10 rounded-full cursor-pointer' onClick={()=>{handleSelectedAssign(item)}} />
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                <div className='text-[14px] font-semibold my-4'>
                    Select Font
                </div>
                <div className='grid grid-cols-12 gap-3'>
                    {
                        fontFamilyArray?.map((item, index)=> (
                            <div
                            onClick={()=>{handleFontFamily(item.value)}}
                            key={index}
                            style={{fontFamily: item?.value}}
                            className='text-[20px] col-span-2 bg-white shadow-lg h-20 rounded-[10px] flex justify-center items-center'>
                                {item?.font}
                            </div>
                        ))
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default ColorSheme
