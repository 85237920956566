import { decode } from 'html-entities'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import authQueries from '../../queries/authQueries';
import { usePaginationStore } from '../../store/userStore';
import PaginationComponent from '../Common/PaginationComponent';


const UserBranch =()=> {
    
    const [result, setResult] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('ACTIVE');
    
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const navigate = useNavigate();

    const {rid} = useParams();

    useEffect(()=>{
        userListing.mutateAsync({
            role: 3,
            page:currentPage, 
            size:25, 
            status:status,
            search:search
        })
    },[currentPage, status, search])

    const userListing = authQueries.useUserBranchList(
        (response) => {
            setResult(response)
        }   
    );

  return (
    <div>

        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Users</li>
                </ol>
            </nav>
        </div>

        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Users</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            My Users
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                                <div className='flex justify-between'>

                                    <div className="search-results-count-wrpr">
                                        Search Results {result?.data?.items?.length} of {result?.data?.totalItems}
                                    </div>

                                    <div className="table-search-filter-wrpr">
                                        <div className="table-search-wrpr">
                                            <input onChange={(e)=>{setSearch(e.target.value)}} style={{minWidth: '300px'}} className="form-control form-control-sm" type="text" placeholder="Search" />
                                        </div>
                                        <div className="table-filter-wrpr" style={{marginLeft:10}}>
                                            <select onChange={(e)=>{setStatus(e.target.value)}} className="form-control  form-control-sm" >
                                                <option value="">Select Status</option>
                                                <option value="ALL">All</option>
                                                <option value="ACTIVE">Active</option>
                                                <option value="INACTIVE">Inactive</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
        
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table prospect-table">
                                                    <thead>
                                                        <tr>
                                                            <th width="30">#</th>
                                                            <th>User Name</th>
                                                            <th>Name</th>
                                                            <th>Phone</th>
                                                            <th>Email</th>
                                                            <th>Primary Roles</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ui-sortable">
                                                        {result?.data?.items.map((item, index) => (
                                                            <tr className="ui-sortable-handle" key={index}>
                                                                <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                <td>
                                                                    {item.username}
                                                                </td>
                                                                <td>{decode(item?.userdetail?.firstname)} {decode(item?.userdetail?.lastname)}</td>
                                                                <td>{item?.userdetail?.phone}</td>
                                                                <td>{item?.userdetail?.email}</td>
                                                                <td>{item?.role?.label}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} result={result} />
                                </div>
                                

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
  )
}

export default UserBranch