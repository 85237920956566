import React, { Fragment, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

const InputUseForm = ({item, errors, control, disabled}) => { 
  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label} {item?.required &&<span className="text-[red]">*</span>}</div>
        <Controller
          name={item?.name}
          control={control}
          render={({ field }) => (
        <input
        {...field}
        disabled={disabled}
        style={{opacity: disabled ? "0.5" : "1", cursor: disabled ? "not-allowed" : "auto", backgroundColor: disabled ? "#f0f0f0" : "white"}}
        className="border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
        type={!item?.iptype ? "text": item?.iptype}
        placeholder={item?.label}
        name={item?.name}
        />
        )}
      />
      <div className='text-[12px] text-red-600'>{errors?.[item?.name]?.message}</div>
    </Fragment>
  )
}

export default InputUseForm