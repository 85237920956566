import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { userStore } from "../../../store/userStore";
import { decode } from 'html-entities';
import { AutoCompleteAntd, AutoCompleteChild } from '../../Clients/AddClients/AutoComplete';


const validationSchema = yup.object({

    college: yup
      .string('⚠ Enter College / University Name')
      .required('⚠ College / University Name is required'),
    name: yup
      .string('⚠ Enter Campus Name')
      .required('⚠ Campus Name is required')
      .min(2,'⚠ Campus Name must be more than 2 characters '),
    status: yup
      .string('⚠ Enter your status')
      .required('⚠ Status is required'),
  });


const EditCampus = () => {

    const navigate = useNavigate();

    const master = userStore();

    const [result, setResult] = useState([])

    const [errName, setErrName] = useState('')

    useEffect(()=>{
        startData();
    },[])

    const startData = async() => {
       await CollegeList.mutateAsync({search: '', status: '', page: 0, size: 500});
       await CampusData.mutateAsync(master.campus);
    }

    const CampusData = authQueries.useCampusMutationView(
        (response) => {
            
            if(response?.data){
                    let datas = ({
                        college:response?.data.cid,
                        name:decode(response?.data.name),
                        type:response?.data.type,
                        status:response?.data.status
                    })

                    reset({ ...datas });

            }
        }   
    );


    const { register, reset, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const CollegeList = authQueries.useCollegeMutationList(
        (response) => {

            let dataArray = []
            for(let datas of response?.data){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setResult(dataArray)
        }
    );

    const editCampus = authQueries.useCampusMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listcampus')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );

    const onSubmit = (data) => {
        const datavalues = {
            id:master.campus,
            cid: data.college,
            name: data.name,
            status: data.status,
        };

        editCampus.mutateAsync(datavalues);

      }



  return (

    <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/listcampus">Campus</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Campus</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Edit Campus
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <div className="col-md-6">

                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Campus Name :</label>
                                                <input style={{height:'44px'}} className="form-control"  {...register("name")} type="text" name='name' />
                                                <div className='error-msg'>{errors.name?.message} {errName}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                
                                                <label htmlFor="">College / University</label>

                                                <AutoCompleteAntd 
                                                    control={control} 
                                                    data={result} 
                                                    name={"college"} 
                                                    value={CampusData.data?.data?.college?.name}
                                                />
                                                    
                                                <div className='error-msg'>{errors.college?.message}</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Status</label>
                                                <select style={{height:'44px'}} className="form-control" name='status'  {...register("status")}>
                                                    <option value=''>Select</option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="INACTIVE">INACTIVE</option>
                                                </select>
                                                <div className='error-msg'>{errors.status?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                               
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Update</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>
    
  )
}

export default EditCampus