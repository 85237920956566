import React,{useState,useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../../queries/authQueries';


const validationSchema = yup.object({

    name: yup
      .string('⚠ Enter Status')
      .required('⚠ Status is required')
      .min(2,'⚠ Status must be more than 2 characters '),
  });


const  AddAppStaffStatus = () => {

    const [errName, setErrName] = useState('')

    const navigate = useNavigate();

    const { register, handleSubmit, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const AddStatusdata = 

    authQueries.useAppStaffStatusMutationAdd(

        (response) => {

            if(response?.message === "Success"){
                navigate('/listappstaffstatus')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }


        }
    );


    const onSubmit = (data) => {

    
        const datavalues = {
            name: data.name,
            status: "ACTIVE",
    
        };
    
        AddStatusdata.mutateAsync(datavalues);    
    
      }


  return (

    <div>

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/listappstaffstatus">Admission Status</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Add Admission Status</li>
            </ol>
        </nav>

        <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Add Admission Status
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Name</label>
                                                <input className="form-control"  {...register("name")} type="text" name='name' />
                                                <div className='error-msg'>{errors.name?.message} {errName}</div>
                                            </div>
                                        </div>
                                    </div>        
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
        </div>

    </div>

  )

}

export default AddAppStaffStatus