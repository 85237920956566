import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateValidationSchema } from './validationSchema'
import { useApi } from './ApiProvider'
import { Modal, Textarea } from '@nextui-org/react'
import { message } from 'antd'

const ApplicantProgramConfirmation = ({item}) => {

    const inputFields = [
        {
            name: "comment",
            label: "Comment",
            min: 10,
            max: 255,
            required: true
        }
    ]

    const [open, setOpen] = useState(false)

    const { setApiDataAndNotify } = useApi()

    const { handleSubmit, register, formState: {errors}, setValue } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    const handleOpen =()=> {
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
    }

    const onSubmit =(data)=> {
        const dataValues = {
            applicant_id: item?.id,
            prospects_id: 11,
            comments: data?.comment,
            status:"ACTIVE"
        }
		handleClose()
        addProspect.mutateAsync(dataValues)
    }

    const addProspect = authQueries.useProStatusAdd(
        (response) => {
            setApiDataAndNotify(response?.data)
            setValue('comment', '');
            message.success('Status changed successfully')
        }
    );

  return (
    <Fragment>
      <button 
        disabled={item?.pros_status  === 5 ? false:true}
        onClick={handleOpen} 
        className="btn-customized"
        style={{opacity : item?.pros_status !== 5 && "0.7"}}
        >
        Notify Counselor
        </button>
        <Modal
            open={open}
            closeButton
            onClose={handleClose}
            className="followup-modal"
            centered
            blur
            animationDuration={1000}
            overlayId="modal-overlay"
            width='60%'
        >
            <Modal.Header>
                <h5 className="modal-title">Send to Counselor</h5>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='w-full mb-2'>
                        <textarea
                        {...register('comment')}
                        name='comment'
                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 min-h-[100px]" 
                        rows={6} 
                        placeholder='Enter comment' 
                        />
                        {errors?.comment && <div className='text-red-500 text-[14px]'> {errors?.comment?.message}</div>}
                    </div>
                    <button 
                    type='submit' 
                    className="btn-customized-large"
                    >Send to Counselor</button>
                </form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default ApplicantProgramConfirmation
