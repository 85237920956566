
import { Modal } from 'antd';
import React, { useState, useEffect  } from 'react';
import InputUseForm from '../../Common/InputUseForm';
import { generateValidationSchema } from '../../Common/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import authQueries from '../../../queries/authQueries';
import { useApi } from '../../Common/ApiProvider';
import { message } from 'antd';
import InputSelectForm from '../../Common/InputSelectForm';
import UsaInputSelectForm from './UsaInputSelectForm';
// import { response } from 'express';

const USAProgramModal = () => {
    const [open, setOpen] = useState(false);
    const [location, setLocation] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const {setApiDataAndNotify} = useApi()

    useEffect(() => {
        ListingLocation.mutateAsync();
        // ListingCity.mutateAsync();
    }, []);

    const ListingLocation =authQueries.useUsaProgramLocationListing(
        async (response) => {
            setLocation(response);
            
        }
    );

    const ListingCity = authQueries.useUsaCityForStateListing(
        async (response) => {
            setFilteredCities(response);
        }
    );

    const handleStateChange = async (event) => {
        const stateId = event.target.value;
        setLocation(stateId);
        // Fetch cities for the selected state
        try {
            const citiesForState = ListingCity.mutateAsync({state_id:stateId});
         
    } catch (error) {
        setFilteredCities([]);
    }
};

    const inputFields = [
        
        {
            name: "program_name",
            label: "Program name",
            placeholder: "Program name",
            size: "6",
            type: InputUseForm,
            required: true,
            min: 2,
            max: 250
        },
        {
            name: "university",
            label: "University",
            placeholder: "University",
            size: "6",
            type: InputUseForm,
            required: true,
            min: 2,
            max: 250
        },
        {
            name: "state_id",
            label: "State",
            placeholder: "State",
            size: "6",
            type: UsaInputSelectForm,
            options: ListingLocation?.data?.data?.items,
            optionProps: { name: "location", value: "id" },
            onChange: (e) => handleStateChange(e),
            required: true,
        },
        {
            name: "city_id",
            label: "City",
            placeholder: "City",
            size: "6",
            type: UsaInputSelectForm,
            options: filteredCities?.data?.items,
            optionProps: { name: "city", value: "id" },
            required: true,
        },
        {
            name: "level",
            label: "Level",
            placeholder: "Level",
            size: "6",
            type: InputUseForm,
            required: false,
            max: 250
        },
        {
            name: "remarks",
            label: "Remarks",
            placeholder: "Remarks",
            size: "6",
            type: InputUseForm,
            required: false
        },
        {
            name: "links",
            label: "Links",
            placeholder: "Links",
            size: "6",
            type: InputUseForm,
            required: false
        },
        {
            name: "stem_nonstem",
            label: "Stem/NonStem",
            placeholder: "Stem/NonStem",
            size: "6",
            type: InputSelectForm,
            options:[
                { value: 'STEM', name: 'STEM' },
                { value: 'NonSTEM', name: 'NonSTEM' }
            ],
            optionProps: { name: "name", value: "value" },
            required: false
        }
    ];  

    const { register, reset, handleSubmit, formState: { errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen = () => {
        setOpen(true);
        reset();
    };

    const handleClose = () => {
        setOpen(false);
        reset(null);
    };

    const Createusapro = authQueries.useUsaProgramCreating(
        (res)=>{

        }
    );

    const onSubmit = async (data) => {
        data= data
       
        try {
            const response = await Createusapro.mutateAsync(data);
            handleClose();
            setApiDataAndNotify(response);
            if (response?.message === "Success") {
                message.success("Successfully updated data"); 
            } else {
                message.error("Failed to update data"); 
            }
            
        } catch (error) {
            message.error("An error occurred while updating data");
        }
    };
    
   

    return (
        <div>
            <button 
            className='btn-customized-large h-[35px] w-[120px]'
            onClick={handleOpen}>
                Add New Program
            </button>
            <Modal
                open={open}
                onCancel={handleClose}
                footer={null}
                width="400px"
            >
                <div className='flex flex-col gap-6'>
                    <div>
                        <div className='text-[14px] font-medium'></div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col justify-center gap-6'>
                            
                            <div className={`raw grid grid-cols-6 gap-x-6 gap-y-3`}>
                                {inputFields.map((input, index) => (
                                    <div key={index} className={`col-span-${input.size} flex flex-col gap-2`}>
                                        <input.type item={input} register={register} errors={errors} control={control} options={input.options} optionProps={input.optionProps} onChange={input.onChange}/>
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-end gap-5'>
                                <button
                                    onClick={handleClose}
                                    className='bg-red-500 text-white px-8 py-[8px] rounded-md text-[12px] font-semibold'
                                    type='button'
                                >
                                    Cancel
                                </button>
                                <button
                                    className='bg-primary text-white px-8 py-[8px] rounded-md text-[12px] font-semibold'
                                    type='submit'
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default USAProgramModal;
