import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../../store/userStore';
import {  Empty, message as messages, Popconfirm, Button as Buttons } from 'antd';
import { Button, Checkbox, Collapse, Grid, Switch, Text } from '@nextui-org/react';
import { decode } from 'html-entities';
import AddButton from '../../Common/AddButton';
import { Pagination, PaginationItem } from "@mui/material";


const ListCampus =()=>{

    const [result, setResult] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [name, setName] = useState('')
    const [country, setCountry] = useState("")
    const [pageSize, setPageSize] = useState(25)
    const [campus, setCampus] = useState(true)
    const [campusName, setCampusName] = useState("")
    const navigate = useNavigate()
    const master = userStore();

    const collegeList = authQueries.useCollegeMutationList(
        (response) => {
            setResult(response)
        }   
    ); 

    const countryList = authQueries.useCountryMutationList(
        (response) => {
        }   
    ); 


    useEffect(()=>{
        collegeList.mutateAsync({page: currentPage, size:25, campus: campus, search:name, country: country});
        console.log(name)
    },[name, campus, country])

    useEffect(()=>{
        countryList.mutateAsync({page:0, size: 10000})
    },[])

    const editSelect =(id)=>{
        master.setMaster({collegeDetail:id})
        navigate('/editcollege')
    }

    const deleteCollege = authQueries.useCollegeMutationDelete(
        (response) => {
            collegeList.mutateAsync({search: name, page:currentPage, size:25, campus: campus, country: country});
            messages.success("successfully deleted")
        }
    );

    const deleteCollegeData = (event, id) => {
        event.preventDefault();
        deleteCollege.mutateAsync(id)
    }


    const searchData = (e) => {
        setName(e.target.value)
    }

    const handleCampus=(e)=> {
        setCampus(e.target.checked)
    }

    const handleChange =(e, value)=> {
        setCurrentPage(value-1)
        collegeList.mutateAsync({search: name, page: value-1 , size:pageSize, campus: campus, country: country})
    }

    const addNewCampus =(id)=> {
        const dataValues ={
            cid: id,
            name: campusName
        }
        AddCampusData.mutateAsync(dataValues)
    }

    const AddCampusData = authQueries.useCampusMutationAdd(
        (response) => {
            if(response?.message === "Success"){
                setCampusName("")
                collegeList.mutateAsync({page: currentPage, size:25, campus: campus, search:name, country: country});
            }
        }
    );

    return(
        <div>
            <div className="content-area">
				<div className="breadcrumb-and-otherbuttons">
					<nav aria-label="breadcrumb">
					  <ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
						<li className="breadcrumb-item active" aria-current="page">Colleges</li>
					  </ol>
					</nav>
                    {
                        master?.user?.role == "ADMIN" &&
                        <div className="other-buttons">
                            <AddButton url={"/addcollege"} name={"College"} />
                        </div>
                    }
				</div>

				<div className="add_clients">
				
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Colleges</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					

					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
                            <div className="card-header">
                                Colleges		
                            </div>
                            <div id="appointment_any" className="collapse show" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <div className="table-list-search-wrpr">
                                                <div className="row mb-3">
                                                    <div className="col-md-12" style={{display:'flex', justifyContent:'space-between'}}>
                                                        <div style={{display:'flex', gap: 12, alignItems:'center', marginTop:'5px', fontSize:'12px'}}>
                                                            <Switch checked={campus} onChange={handleCampus} />
                                                            <span>Only show campus-included colleges</span>
                                                        </div>
                                                        <div style={{display:'flex', gap:'10px'}}>
                                                            <input style={{minWidth: '200px'}} onChange={searchData} className="form-control form-control-sm" type="text" placeholder="Search College" />
                                                            <select style={{minWidth: '200px'}} onChange={(e)=>setCountry(e.target.value)} className="form-control form-control-sm" type="text" placeholder="Search College">
                                                                <option value="">Select Country</option>
                                                                {
                                                                    countryList?.data?.data?.items?.map((item, index) => (
                                                                        <option key={index} value={item?.id}>{item?.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {result?.data?.totalItems > 0 ?
                                                <Grid.Container style={{display:'block'}} gap={2}>
                                                    <Grid>
                                                        <Collapse.Group shadow>
                                                        {
                                                            result?.data?.items?.map((item, index) =>(
                                                                    <Collapse 
                                                                        key={index}
                                                                        title={<Text h6>{item?.name}</Text>}
                                                                        subtitle={
                                                                        <div>
                                                                            <div style={{fontSize:'12px'}}>
                                                                                <div style={{marginTop:'5px'}}>{item?.country?.name} {item?.country?.icon}</div>
                                                                                <div>{item?.campuses?.length} Campus</div>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    >
                                                                        <div className="mb-3" style={{display:'flex', gap: "5px"}}>
                                                                            <input value={campusName} onChange={(e)=> {setCampusName(e.target.value)}} style={{minWidth: '300px'}} className="form-control form-control-sm" type="text" placeholder="Enter Campus" />
                                                                            <button
                                                                                onClick={()=> {addNewCampus(item?.id)}}
                                                                                style={{
                                                                                minHeight:'31px', 
                                                                                height:'31px', 
                                                                                border:'none', 
                                                                                background:'#323a51', 
                                                                                color:'#fff', 
                                                                                fontSize:'12px',
                                                                                paddingLeft:"10px",
                                                                                paddingRight:"10px",
                                                                                borderRadius: "5px"
                                                                                }}>Add New Campus</button>
                                                                        </div>

                                                                        {item?.campuses?.length > 0 ?
                                                                            <table className="table collageprogram">
                                                                                <thead>
                                                                                    <tr>
                                                                                    <th width="50">#</th>
                                                                                    <th>Campus Name</th>
                                                                                    <th width="100">&nbsp;</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                {
                                                                                    item?.campuses?.map((items, indexes) =>(
                                                                                    <tbody key={indexes} className="ui-sortable">
                                                                                        <tr className="ui-sortable-handle">
                                                                                        <th className="">{indexes + 1}</th>
                                                                                        <td>{decode(items.name)}</td>
                                                                                        <td className="action-icons">
                                                                                            <Popconfirm
                                                                                                placement="left"
                                                                                                title="Are you sure to delete this Details ?"
                                                                                                okText="Yes"
                                                                                                cancelText="No"
                                                                                            >
                                                                                                <button className="icon-trashcan delete"></button>
                                                                                            </Popconfirm>
                                                                                        </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    ))
                                                                                }
                                                                            </table>: 
                                                                            <div style={{display:'flex', alignItems:'center', flexDirection:'column', marginBottom:'20px'}}>
                                                                                <Empty description={"No Campus found!"} />
                                                                            </div>
                                                                        }
                                                                    </Collapse>
                                                            ))
                                                        }
                                                        </Collapse.Group>
                                                        <ul className="pagination pagination-sm justify-content-center table-pagination mt-5">
                                                            {result?.data?.totalPages > 1 &&
                                                            <Pagination
                                                                    count={result?.data?.totalPages} 
                                                                    nextText="Next"
                                                                    prevText="Previous"
                                                                    renderItem={
                                                                        (item) => (     
                                                                            <PaginationItem component="li" {...item} />
                                                                        )
                                                                    }
                                                                    variant="outlined"
                                                                    page={result?.data?.currentPage+1}
                                                                    shape="rounded"
                                                                    onChange={handleChange}
                                                                />
                                                            }
                                                        </ul>
                                                    </Grid>
                                                </Grid.Container>:
                                                <div style={{margin:"5%"}}>
                                                    <Empty />
                                                </div>
                                            }   
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
				</div>
			</div>
        </div>
    )
}

export default ListCampus