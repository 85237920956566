import React, { useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { userStore } from '../../../store/userStore';
import * as yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { message as messages, Popconfirm } from 'antd';


const AcademicView =({item})=>{

    const appStore = userStore();
    const [edit, setEdit] = useState(0)

    useEffect(()=> {
        setValue('comments', item?.reason_for_the_gap)
    },[])

    const addValidationSchema = yup.object({
        comments: yup
        .string('⚠ Please enter reason for the gap')
        .required('⚠ Reason for the gap is required'),
    });

    const { register, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(addValidationSchema)
    })

    const academicGapEdit = authQueries.useAcademicEdit(
        (response) => {
            setEdit(0)
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const onEdit = (data) => {

        const datavalues = {
            id:item?.id,
            applicant_id: appStore.appid,
            reason_for_the_gap: data.comments,
            status:"ACTIVE"
        }

        academicGapEdit.mutateAsync(datavalues);
    }

    const editButton =(e)=>{
        e.preventDefault();
        setEdit(item?.id)
    }

    const academicGapDelete = authQueries.useAcademicDelete(
        (response) => {
            messages.success("successfully deleted")
            setEdit(0)
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const deleteButton =(e)=>{
        e.preventDefault();
        academicGapDelete.mutateAsync(item?.id)
    }

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )


    return(
        <form>
            <div className="row">
                <div className="col-md-10">
                    <label>State the period and reason for the gap</label>
                    <textarea disabled={edit===item?.id?false:true} {...register('comments')} name="comments" className="form-control"></textarea>
                    <div className="error-msg">{errors.comments?.message}</div>
                </div>
                <div className="form-group">
                    <label htmlFor="">&nbsp;</label><br/>
                    <Popconfirm
                        placement="left"
                        title="Are you sure to delete this Details ?"
                        onConfirm={(e) => {deleteButton(e)}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button  type='button' className="btn btn-danger btn-removerow">Delete</button>
                    </Popconfirm>
                    {edit !== item?.id?
                    <button type='submit' onClick={(e)=>editButton(e)} className="btn btn-default btn-nextrow" style={{marginLeft:"15px"}}>
                        Edit
                    </button>:
                    <button type='submit' onClick={handleSubmit(onEdit)} className="btn btn-default btn-nextrow" style={{marginLeft:"15px"}}>
                        Update
                    </button>
                    }
                </div>
            </div>
        </form>
    )
}

const Academic = () => {

    const appStore = userStore();

    const addValidationSchema = yup.object({
        comment: yup
            .string('⚠ Please enter reason for the gap')
            .required('⚠ Reason for the gap is required'),
    });

    const addAcademic = authQueries.useAcademicAdd(
        (response) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
            let datas = {
                comment: ''
            }
            reset({ ...datas });
        }
    );

    const onSubmit = (data) => {
        const datavalues = {
            applicant_id: appStore.appid,
            reason_for_the_gap: data.comment,
            status:"ACTIVE"
        }

        addAcademic.mutateAsync(datavalues);
    }

    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(addValidationSchema)
    });

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )

  return (
    <div>
        <div className="card">
            <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#academic_profile_employment_gap_history">
                    Academic/ Profile/ Employment Gap History, If Any
                </a>
                <div className={appStore?.appAllData?.academic_gap_historys?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                	<span className={appStore?.appAllData?.academic_gap_historys?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
            	</div>
            </div>

            <div id="academic_profile_employment_gap_history" className="collapse" data-parent="#accordion">
                <div className="card-body">
                {appStore?.appAllData?.academic_gap_historys?.map((item, index) => (
                        <AcademicView key={index} item={item} />
                    ))
                }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-10">
                                <label>State the period and reason for the gap</label>
                                <textarea {...register("comment")} name="comment" className="form-control"></textarea>
                                <div className="error-msg">{errors.comment?.message}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">&nbsp;</label><br/>
                                <button 
                                type='submit' 
                                className="btn btn-default btn-nextrow"
                                >Create</button>
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>		
    </div>
    )
}


export default Academic