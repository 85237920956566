import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../../store/userStore';
import {  message as messages, Popconfirm } from 'antd';
import { Pagination } from '@nextui-org/react';
import AddButton from '../../Common/AddButton';


const ListDuration =()=>{

    const [result, setResult] = useState([])
    const [status, setStatus] = useState('')
    const [name, setName] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const navigate = useNavigate()
    const master = userStore();

  
    useEffect(()=>{
        mutateAsync({search: '', status: '', page:0, size:10});
    },[]);


    const { mutateAsync } = authQueries.usePGDMutationList(

        (response) => {
            setResult(response)            
        }   
    
    );

    
    const editSelect =(id)=>{
        master.setMaster({pgdDetail:id})
        navigate('/editpgd')
    }


    const deletePGD = authQueries.usePGDMutationDelete(
        (response) => {
            mutateAsync({search: '', status: '', page:0, size:10});
            messages.success("successfully deleted")
        }
    );

    const deletePGDData = (event, id) => {
        event.preventDefault();
        deletePGD.mutateAsync(id)
    }

    const searchData = (e) => {
        mutateAsync({search: e.target.value, status: status, page:0, size:10});
        setName(e.target.value)
      }

      const filterData = (e) => {
        mutateAsync({search: name, status: e.target.value, page:0, size:10});
        setStatus(e.target.value)
      }




    return(
        <div>

            {/* <Link to="/addcountry">Add Country</Link>

            
            {
                result?.data?.items?.map((item, index) =>(
                    <div key={index}>
                        <button onClick={()=>{editSelect(item.id)}}>{sl++} {item.name}</button>
                    </div>
                ))
            }

        <tbody>
          {(() => {
            let rows = [];
            for (let i = 0; i < result?.data?.totalPages; i++) {
              rows.push(<button onClick={()=>{mutateAsync({page:i, size:10})}}>{i+1}</button>);
            }
            return rows;
          })()}

        </tbody> */}

            <div className="content-area">
				<div className="breadcrumb-and-otherbuttons">
					{/* <!-- breadcrumb --> */}
					<nav aria-label="breadcrumb">
					  <ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
						<li className="breadcrumb-item active" aria-current="page">Program Durations</li>
					  </ol>
					</nav>
                    <div className="other-buttons">

                        <AddButton url="/addpgd" name="Program Duration" />

                    </div>
				</div>
				
				{/* <!-- addclients --> */}
				<div className="add_clients">
				
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")}  width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Program Durations</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					

					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
						  <div className="card-header">
							{/* <a className="collapsed card-link"  href="" style="pointer-events:none;"> */}
							  Program Durations
							{/* </a> */}
							
						  </div>
						  <div id="appointment_any" className="collapse show" data-parent="#accordion">
							<div className="card-body">
									
                                <div className="row ">
                                    <div className="col-md-12">
                                    
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div className="table-search-wrpr">
                                                            <input style={{minWidth: '300px'}} onChange={searchData} className="form-control form-control-sm" type="text" placeholder="Search Duration" />
                                                        </div>
                                                        {/* <div className="table-filter-wrpr">
                                                            <select onChange={filterData} className="form-control  form-control-sm" >
                                                                <option value=''>Select</option>
                                                                <option value='ACTIVE'>Active</option>
                                                                <option value='INACTIVE'>Inactive</option>
                                                            </select>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        
                                            <table className="table collageprogram">
                                                <thead>
                                                    <tr>
                                                    <th width="50">#</th>
                                                    <th >Program Duration</th>
                                                    {/* <th width="150">Status</th> */}
                                                    <th width="100">&nbsp;</th>
                                                    </tr>
                                                </thead>

                                                {
                                                    result?.data?.items.map((item, index) =>(

                                                    <tbody key={index} className="ui-sortable">
                                                        <tr className="ui-sortable-handle">
                                                        <th className="">{item.id}</th>
                                                        <td>{item.duration}</td>
                                                        {/* <td>
                                                            <span style={{background : item.status === 'ACTIVE' ? 'rgb(222,239,239)' : '#FEE8EB', color: item.status === 'ACTIVE' ? '#5DC97A' : '#FBB41B', fontWeight: 'bold', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px' }}>{item.status}</span>
                                                        </td> */}
                                                        <td className="action-icons">
                                                            <button onClick={()=>{editSelect(item.id)}} className="icon-pencil3"></button>
                                                            <Popconfirm
                                                                placement="left"
                                                                title="Are you sure to delete this Duration ?"
                                                                onConfirm={(event) => {deletePGDData(event, item.id)}}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <button className="icon-trashcan delete"></button>
                                                            </Popconfirm>
                                                        </td>
                                                        </tr>

                                                    </tbody>

                                                ))
                                                }
                                            </table>

                                        
                                            
                                            
                                            <ul className="pagination pagination-sm justify-content-center table-pagination mt-5">

                                                <Pagination 
                                                    shadow
                                                    noMargin
                                                    color="secondary"
                                                    total={result?.data?.totalPages} 
                                                    onChange={(e) => {mutateAsync({search: name, status: status, page: e-1 , size:pageSize})}}
                                                    initialPage={1} 
                                                />  

                                            </ul>
                                    
                                    </div>
                                </div>
								
								</div>
							</div>
						</div>
						
						
					  </div>
					
				</div>
				
			</div>


        </div>
    )
}

export default ListDuration