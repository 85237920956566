import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../../store/userStore";

const EditAppStaffStatus =()=>{

    const master = userStore();
    const navigate = useNavigate();

    const validationSchema = yup.object({
      name: yup
        .string('⚠ Enter Duration')
        .required('⚠ Duration is required')
        .min(2,'⚠ Duration must be more than 2 characters '),
      status: yup
        .string('⚠ Enter your status')
        .required('⚠ Status is required'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');



    const { register, reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(()=>{
        appStaffStatusData.mutateAsync(master.appstaffstatus);
    },[])

    const appStaffStatusData = authQueries.useAppStaffStatusMutationView(
        (response) => {
            if(response?.data){
                let datas = ({
                    name:response?.data.name,
                    status:response?.data.status
                })
                reset({ ...datas });
            }
        }   
    );


    const editStatus = authQueries.useAppStaffStatusMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listappstaffstatus')
            }
            else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );
        
    
    const onSubmit = (data) => {
        console.log(data);
        const datavalues = {
            id:master.appstaffstatus,
            name: data.name,
            status: data.status,
        };
        
        editStatus.mutateAsync(datavalues);
    }
    

    
    return(
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/listappstaffstatus">Admission Status</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Admission Status</li>
                </ol>
            </nav>

            <div className="add_clients">
                <div className="card">
                    <div className="card-header">
                      Edit Admission Status
                    </div>
                    <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        
                                            <div id="parent" className="form-group">
                                                <label >Name</label>
                                                <input 
                                                    {...register("name")}
                                                    type="text" 
                                                    className="form-control"  
                                                    name="name"/>
                                                <div className='error-msg'>{errors.name?.message}{errName}</div>
                                            </div>
                                            <div id="output"></div>
                                        
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div className="form-group">
                                        
                                            <div id="parent" className="form-group">
                                                <label >Status</label>
                                                <select  {...register("status")}  className="form-control"  name="status">
                                                    <option value="">Select</option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="INACTIVE">INACTIVE</option>
                                                </select>       
                                                <div className='error-msg'>{errors.status?.message}</div>
                                            </div>

                                            <div id="output"></div>
                                        
                                    </div>
                                </div>                                                       
                                
                                <div className="col-md-12 text-center">
                                    <button type="submit" className="btn btn-default btn-save">Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>


        </div>
    )
}

export default EditAppStaffStatus