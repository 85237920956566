import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoCompleteAntd, AutoCompleteChild } from '../../Clients/AddClients/AutoComplete';


const validationSchema = yup.object({

    visa: yup
      .string('⚠ Select Visa Tye')
      .required('⚠ Visa Type is required'),
    name: yup
      .string('⚠ Enter Document Name')
      .required('⚠ Document Name is required')
      .min(2,'⚠ Document Name must be more than 2 characters '),
  });
  

const AddDoc = () => {

    const navigate = useNavigate();

    const [visaResult, setVisaResult] = useState([])
    const [errName, setErrName] = useState('')

    useEffect(()=>{
        VisaList.mutateAsync({search: '', status: '', page: 0, size: 500});
    },[])


    const { register, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const VisaList = authQueries.useVisaTypeMutationList(
        (response) => {
            setVisaResult(response)

            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.description,
                    value:datas?.id
                }
                dataArray = [...dataArray, dataValues]
            }
            setVisaResult(dataArray)
        }
    );

    const AddDocdata = authQueries.useDocDetailMutationAdd(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listdoc')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }
    );

    const onSubmit = (data) => {

    
        const datavalues = {
            vid: data.visa,
            document_name: data.name,
            status: "ACTIVE",
        };
    
        // e.preventDefault()
    
        AddDocdata.mutateAsync(datavalues);    
    
      }
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/listdoc">Document Details</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add Document Details</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Add Document Details
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                            <label htmlFor="">Visa Type :</label>

                                                <AutoCompleteAntd
                                                    control={control} 
                                                    data={visaResult} 
                                                    name={"visa"} 
                                                    value={{label: '', value: null}}
                                                    // value={visaData} 
                                                    // error={errors.country}
                                                />
                                                <div className='error-msg'>{errors.visa?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">

                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Document Name</label>
                                                <input className="form-control"  {...register("name")} type="text" name='name' />
                                                <div className='error-msg'>{errors.name?.message}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default AddDoc;