import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";

const CounselorReport =()=>{

    const [result, setResult] = useState([]);
    const [status, setStatus] = useState('');
    const [fromDate, setfromDate] = useState('2022-04-01');
    const [toDate, settoDate] = useState('2023-03-31');
    let staffTotal=0;
    let office='';
    let username='';
    let staffcount='';
    let officeCount ='';

    useEffect(()=>{
        counselorApi.mutateAsync({from: fromDate, to:toDate, page:0, size:10});
    },[]);

    
    const counselorApi = authQueries.useCounselorReport(

        (response) => {
            setResult(response);        
        }   
    
    );

    const handlefromDate = (event) => {
        const value = event.target.value;
        console.log("fromdate",value)
        setfromDate(value);
    };

    const handletoDate = (event) => {
        const value = event.target.value;
        console.log("todate",value)
        settoDate(value);
    };

    const filterData = (e) => {
        counselorApi.mutateAsync({ from: fromDate, to:toDate, page:0, size:10  });
        setStatus(e.target.value)
      }


    return(
        <div>

            {/* <Link to="/adddistrict">Add District</Link>

            
            {
                result?.data?.items?.map((item, index) =>(
                    <div key={index}>
                        <button onClick={()=>{editSelect(item.id)}}>{sl++} {item.name}</button>
                    </div>
                ))
            }

        <tbody>
          {(() => {
            let rows = [];
            for (let i = 0; i < result?.data?.totalPages; i++) {
              rows.push(<button onClick={()=>{mutateAsync({page:i, size:10})}}>{i+1}</button>);
            }
            return rows;
          })()}

        </tbody> */}


            <div className="content-area">
				<div className="breadcrumb-and-otherbuttons">
					{/* <!-- breadcrumb --> */}
					<nav aria-label="breadcrumb">
					  <ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
						<li className="breadcrumb-item active" aria-current="page">Counselor Visa Report</li>
					  </ol>
					</nav>
				</div>
				
				{/* <!-- addclients --> */}
				<div className="add_clients">
				
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Counselor Visa Report</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					

					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
						  <div className="card-header">
							{/* <a className="collapsed card-link"  href="" style="pointer-events:none;"> */}
							  Counselor Visa Report
							{/* </a> */}
							
						  </div>
						  <div id="appointment_any" className="collapse show" data-parent="#accordion">
							<div className="card-body">
									
                                <div className="row ">
                                    <div className="col-md-12">
                                    
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    
                                                </div>
                                                <div className="col-md-6 text-right">
                                                <div className="table-search-filter-wrpr">
                                                   <div style={{display: "-webkit-inline-box" }} className="table-search-wrpr">
                                                      <label style={{marginRight:7}}>From</label>
                                                      <input style={{minWidth: '100px'}} value={fromDate} onChange={handlefromDate}  className="form-control form-control-sm" type="date" placeholder="From" />
                                                      <label style={{marginRight:7}}>To</label>
                                                      <input style={{minWidth: '100px'}} value={toDate} onChange={handletoDate}  className="form-control form-control-sm" type="date" placeholder="TO" />
                                                      <button style={{background: '#323a51'}} onClick={filterData} className="btn btn-info">Search</button>
                                                    </div>
                                                </div>
                                                </div>
                                        </div>

                                        
                                        
                                            <table border={1} className="table report collageprogram">
                                                <thead>
                                                    <tr>
                                      
                                                    <th >Office</th>
                                                    <th width="150">Counselor</th>
                                                    <th width="200">Country</th>
                                                    <th width="200">Preparing Visa Documents</th>
                                                    <th width="200">Visa Applied</th>
                                                    <th width="200">Visa Approved</th>
                                                    <th width="200">Expected Total Values</th>
                                                    <th width="200">Total For Staff</th>
                                                    <th width="200">Total For Office</th>
                                                    <th width="100">&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { counselorApi.isLoading && "Loading" }
                                                {   
                                                    counselorApi?.data?.data[0]?.map((item, index) =>
                                                    {  
                                                    
                                                    let offices = counselorApi?.data?.data[0].filter(function(office){
                                                        return  office.office == item.office;
                                                    });

                                                    let staffs = counselorApi?.data?.data[0].filter(function(staff){
                                                        return  staff.UserName == item.UserName;
                                                    });
                                                      
                                                    return(
                                                        <tr className="ui-sortable-handle">
                     
                                                        {office!=item.office?<td rowSpan={offices.length}>{office=item.office }</td>: null}
                                                        {username!=item.UserName?<td rowSpan={staffs.length}>{username=item.UserName}</td>:null}
                                                        <td>{item.country}</td>
                                                        <td>{item.preparing_visa_doc}</td>
                                                        <td>{item.visa_applied}</td>
                                                        <td>{item.visa_approved}</td>
                                                        <td>{item.applicant_num}</td>
                                                        {staffcount!=item.UserName?<td {...staffcount=item.UserName} rowSpan={staffs.length}>{item.staffcount}</td>:null}
                                                        {officeCount!=item.office?<td {...officeCount=item.office} rowSpan={offices.length}>{item.office_count}</td>:null}
                                                        </tr>
                                                    )})
                                                }
                                               </tbody>

                                            </table>
                                    </div>
                                </div>
								
								</div>
							</div>
						</div>
						
						
					  </div>
					
				</div>
				
			</div>

        </div>

    </div>
    )

    


    
}
export default CounselorReport;