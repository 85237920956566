import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authQueries from "../../../queries/authQueries";
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { AutoCompleteAntd, AutoCompleteChild } from "../../Clients/AddClients/AutoComplete";

const ProgramAdd =()=>{

    const validationSchema = yup.object({
        cid: yup
          .string('⚠ Enter your country')
          .required('⚠ country is required'),
        name: yup
          .string('⚠ Enter your Name')
          .required('⚠ Name is required')
          .min(2, '⚠ Name should be of Minimum of 2 characters length'),
        code: yup
          .string('⚠ Enter your program code')
          .required('⚠ Program code is required')
          .min(2, '⚠ At least 2 characters Long'),
        link: yup
          .string('⚠ Enter your program link')
          .required('⚠ Program link is required')
          .min(2, '⚠ At least 2 characters Long'),
    });

      
    const [result, setResult] = useState([]);
    const [errName, setErrName] = useState('');

    const navigate = useNavigate();

    useEffect(()=>{
        collegeList.mutateAsync({search:'', status: '', page:0, size:500});
    },[])

    const { register , handleSubmit, control, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });



    const collegeList = authQueries.useCollegeMutationList(
        (response) => {
            let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setResult(dataArray)    
        }   
    );
   

    const addProgram = authQueries.useProgramMutationAdd(
        (response) => {
            if(response?.message === "Success"){
                navigate('/program')
            }else if(response?.error[0].name){
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );
        
    
    const onSubmit = (data) => {
        const datavalues = {
            cid: data.cid,
            name: data.name,
            program_code:data.code,
            programlink: data.link
        }        
        addProgram.mutateAsync(datavalues);
    }
    

    
    return(
        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/program">Program List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add Program</li>
                </ol>
            </nav>
            <div className="add_clients">

            <div className="card">
                <div className="card-header">
                    Create Program
                
                </div>
                <div id="intended_college_program" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group"> 
                                    <div id="parent" className="form-group">
                                    <label htmlFor="">Country :</label>

                                        <AutoCompleteAntd
                                            control={control} 
                                            data={result} 
                                            name={"cid"} 
                                            value={{label: '', value: null}}
                                        />
                                        <div className='error-msg'>{errors.cid?.message}</div>
                                    </div>
                                    <div id="output"></div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Name</label>
                                            <input
                                                {...register("name")}
                                                type="text" 
                                                className="form-control"  
                                                name="name"/>
                                            <div className='error-msg'>{errors.name?.message}{errName}</div>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Program Code</label>
                                            <input {...register("code")} type="text" className="form-control"  name="code"/>
                                            <div className='error-msg'>{errors.code?.message}</div>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    
                                        <div id="parent" className="form-group">
                                            <label >Program Link</label>
                                            <input {...register("link")} type="text" className="form-control"  name="link"/>
                                            <div className='error-msg'>{errors.link?.message}</div>
                                        </div>
                                        <div id="output"></div>
                                    
                                </div>
                            </div>                                                      
                            
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-default btn-save">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
        </div>

        </div>
    )
}

export default ProgramAdd