/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import authQueries from '../../queries/authQueries';
import { useForm } from 'react-hook-form';
import { usePaginationStore, userStore } from '../../store/userStore';
import moment from 'moment';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { decode } from 'html-entities';
import LoaderCommon from '../Common/LoaderCommon';
import FollowUp from '../Common/FollowUp';
import Swiper from 'swiper';
import $ from 'jquery'
import CountUp from 'react-countup';
import VisaStaffVisaData from '../Common/VisaStaffVisaData';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import DocumentUploadModal from '../Common/DocumentUploadModal';
import ApplicationStatusModal from '../Common/ApplicationStatusModal';
import { useApi } from '../Common/ApiProvider';
import PaginationComponent from '../Common/PaginationComponent';
import VisaStaffMyClients from '../VisaStaff/VisaStaffMyClients';
import { Vote } from 'lucide-react';
import { IconCash, IconCheckupList, IconCreditCardRefund, IconPlaneDeparture, IconSquareRoundedX } from '@tabler/icons-react';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import RouteConstants from '../../Constants/RouteConstans';
import { useNavigate } from 'react-router-dom';

const VisaStaffDashboard = () => {

    const [count, setCount] = useState({})
    const [loader, setLoader] = useState(true);

    const navigate = useNavigate()

    const setActiveTab = userStore((state)=> state.setActiveTab)
	const setStatus = useAdvancedSearch((state) => state.setStatus);
    const resetAll = useAdvancedSearch((state) => state.resetAll);
    const setTotalAssigned = useAdvancedSearch((state) => state.setTotalAssigned);

    const countDashboard = authQueries.useVisaStaffDashboardCountMutation(
		    async (response) => {
      	    setCount(response)
            setLoader(false)
        }
    )

    useEffect(() => {
        countDashboard.mutateAsync()
    },[])

    const slideArr = [
		{
			color: '#508D69',
			title: 'Total Assigned',
			icon: <Vote color='#508D69' size="20" />,
			value: "TOTAL",
			count: count?.total
		},
		{
			color: '#29ADB2',
			title: 'Visa Applied',
			icon: <IconCheckupList color='#29ADB2' size="20" />,
			value: "APPLIED",
			count: count?.applied
		},
		{
			color: '#FA7070',
			title: 'Visa Approved',
			icon: <IconPlaneDeparture color='#FA7070' size="20" />,
			value: "APPROVED",
			count: count?.approved
		},
        {
			color: '#FFB000',
			title: 'Visa Refused',
			icon: <IconSquareRoundedX color='#FFB000' size="20" />,
			value: "REFUSED",
			count: count?.refused
		},
		{
			color: '#DAC0A3',
			title: 'Refund Requested',
			icon: <IconCreditCardRefund color='#DAC0A3' size="20" />,
			value: "REQUESTED",
			count: count?.requested
		},
		{
			color: '#5F0F40',
			title: 'Refund Completed',
			icon: <IconCash color='#5F0F40' size="20" />,
			value: "COMPLETED",
			count: count?.completed
		},
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
        "1": {
					slidesPerView: 1,
					spaceBetween: 10,
        },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 6,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

    const tileClick = (value) => {
		resetAll()
        if(value === "TOTAL"){
            setTotalAssigned(true)
        }
		if(value === "APPLIED"){
            setStatus(16)
		}
		if(value === "APPROVED"){
            setStatus(17)
		}
		if(value === "REFUSED"){
            setStatus(18)
		}
		if(value === "REQUESTED"){
            setStatus(13)
		}
		if(value === "COMPLETED"){
            setStatus(20)
		}
        setActiveTab(RouteConstants.VISASTAFFMYCLIENTS)
		navigate(RouteConstants.VISASTAFFMYCLIENTS)
	}


  return (
    <div>

        {
            loader ?
            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'15%'}}>
                <LoaderCommon  />
            </div> :
            <div>
                <div className="carousel-section mb-5">
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{slideArr.map((item, index) => (
										<div key={index} className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && tileClick(item.value)}>
											<div className='flex flex-col p-3 justify-between h-full'>
												<div className='flex justify-between'>
													<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
													<div className='text-4xl'>
														<CountUp end={item?.count} duration={1} />
													</div>
												</div>
												<div>
													<div className='text-[14px]'>{item.title}</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>

                <FollowupToday />
                <FollowupPending />

            </div>
        }

    </div>
  )
}

export default VisaStaffDashboard