import { Loading } from '@nextui-org/react'
import React from 'react'

const LoaderCommon = () => {
  return (
    <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'5%', marginBottom:'5%'}}>
        <Loading  />
        <span className='' style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
    </div>
  )
}

export default LoaderCommon
