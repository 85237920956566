/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Empty, Select, Spin, message } from 'antd'
import { decode } from 'html-entities'
import { useNavigate } from 'react-router-dom';
import authQueries from '../../queries/authQueries';
import { useLoaderStore, usePaginationStore, userStore } from '../../store/userStore';
import Swiper from 'swiper';
import 'swiper/css';
import moment from 'moment';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import $, { error } from 'jquery'
import Charts from '../Charts/Charts';
import CountUp from 'react-countup';
import { Checkbox, Loading, Modal } from '@nextui-org/react';
import { Autocomplete, Pagination, PaginationItem, TextField } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import LoaderCommon from '../Common/LoaderCommon';
import FollowUp from '../Common/FollowUp';
import PaginationComponent from '../Common/PaginationComponent';
import { LayoutList, Vote } from 'lucide-react';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import ConfirmProgram from '../Common/ConfirmProgram';
import { useApi } from '../Common/ApiProvider';
import ApplicantProgramConfirmation from '../Common/ApplicantProgramConfirmation';
import { IconActivity, IconHourglass, IconSquareRoundedCheck, IconDiscountCheck, IconHourglassEmpty } from '@tabler/icons-react';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import RouteConstants from '../../Constants/RouteConstans';


const ProgramCoordinatorDashboard = () => {

    const [count, setCount] = useState({})
	const [loader, setLoader] = useState(true);
	const resetAll = useAdvancedSearch(state=> state.resetAll)
	const setActiveTab = userStore(state=> state.setActiveTab)
	const setPrgmCoordinatorPending = useAdvancedSearch(state=> state.setPrgmCoordinatorPending)
	const setExcluded = useAdvancedSearch(state=> state.setExcluded)
	const setFinancialYear = useAdvancedSearch(state=> state.setFinancialYear)

	const navigate = useNavigate()

    const counts = authQueries.useProgramDashboardCountMutation(
		async (response) => {
      	if(response?.message === "success")
		setLoader(false)
      	setCount(response)
    });

    useEffect(() => {
        counts.mutateAsync()
	}, [])

	const slideArr = [
		{
			color: '#D63484',
			title: 'Total Active Files',
			icon: <Vote color='#D63484' size="20" /> ,
			value: "ALL",
			count: count?.totalActive
		},
		{
			color: '#365486',
			title: 'Total Awaiting Program Confirmation',
			icon: <IconHourglass color='#365486' size="20" />,
			value: "PENDING",
			count: count?.totalPending,
		},
		{
			color: '#114232',
			title: 'Total Completed Files',
			icon: <IconDiscountCheck color='#114232' size="20" />,
			value: "COMPLETED",
			count: count?.totalActive - count?.totalPending,
			excluded: count?.totalPendingIds
		},
		{
			color: '#E8751A',
			title: 'Current FY Active Files',
			icon: <IconActivity color='#E8751A' size="20" />,
			value: "ALL",
			count: count?.currentActive,
			cfy: true
		},
		{
			color: '#240A34',
			title: 'Current FY Awaiting Program Confirmation',
			icon: <IconHourglassEmpty color='#240A34' size="20" />,
			value: "PENDING",
			count: count?.currentPending,
			cfy: true,
		},
		{
			color: '#5755FE',
			title: 'Current FY Completed Files',
			icon: <IconSquareRoundedCheck color='#5755FE' size="20" />,
			value: "COMPLETED",
			count: count?.currentActive - count?.currentPending,
			cfy: true,
			excluded: count?.currentPendingIds
		},
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 6,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	const tileClick = (item) => {
		resetAll()
		if(item.cfy){
			setFinancialYear("CURRENT")
		}
		if(item.value === "PENDING"){
			setPrgmCoordinatorPending(true)
		}
		if(item.value === "COMPLETED"){
			setExcluded(item?.excluded)
		}
		setActiveTab(RouteConstants.PROGRAMMYCLIENTS)
		navigate(RouteConstants.PROGRAMMYCLIENTS)
	}

	return ( 
		<div>
			{
				loader ?
				<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'15%'}}>
					<Loading  />
					<span style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
				</div>:

				<div>
					<div className="carousel-section mb-5">
						<div className="row">
							<div className="col-md-12">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArr.map((item, index) => (
											<div key={index} className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && tileClick(item)}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					 <FollowupToday />
					 <FollowupPending />

				</div>

			}

		</div>
	)
}

export default ProgramCoordinatorDashboard