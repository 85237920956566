import { Button, Popover } from 'antd';
import React, { useEffect,useState }  from 'react'
import { useNavigate } from 'react-router-dom';
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore';
import RouteConstants from '../../Constants/RouteConstans';


const AdminDashboard = () => {

    const [page, setPage] = useState(0);
    const [clients, setClients] = useState([]);
    const [clientCount, setClientCount] = useState(0);
    useEffect (() => { 
        userList.mutateAsync()
        visaList.mutateAsync()
        statusList.mutateAsync()
        officeList.mutateAsync()
        rolesList.mutateAsync()
        clientList.mutateAsync()
    }, []);

    const navigate = useNavigate();

    const master = userStore();

    const userList = authQueries.useAdminUserMutationList(
        (response) => {
        }
    );

    const visaList = authQueries.useAdminVisaMutationList(
        (response) => {
        }
    );

    const statusList = authQueries.useAdminStatusMutationList(
        (response) => {
        }
    );

    const officeList = authQueries.useAdminOfficeMutationList(
        (response) => {
        }
    );

    const rolesList = authQueries.useAdminRolesMutationList(
        (response) => {
        }
    );

    const clientList = authQueries.useAdminClientMutationList(
        (response) => {
        }
    );

  return (
    <div>
        <div className="ilets-tot-follow-longtem-section" style={{border:'none', background:'#fff', padding:'50px', borderRadius:'20px', boxShadow:'0 0 20px -12px #000'}}>
            <div className="row">
                <div className="col-md-12">
                    <div className="highlight-wrpr" style={{background:'#BBE9E5'}}>
                        <div className="left-group">
                            <div className="ilets">
                                <div className="ilets-icon" style={{background:'#4BBCAA', borderRadius:'10px', boxShadow:'0 0 0px 0px #fff'}}>
                                    <i style={{color:'#fff'}} className="icon-user2"></i>
                                </div>
                                <div style={{color:'#4BBCAA', fontWeight:500}} className="ilets-title">
                                    Staffs
                                </div>
                            </div>
                        </div>
                        <div className="right-group">
                            <div className="ilets">
                                <div className="ilets-count">
                                    <span style={{color:'#4BBCAA', fontWeight:500}}>{userList?.data?.count}</span>
                                </div>
                                <div className="ilets-dropdown">
                                    <a style={{color:'#4BBCAA'}} data-toggle="collapse" data-target="#users" className="icon-keyboard_arrow_down"></a>
                                </div>
                            </div>
                        </div>
                        <div id="users" className="collapse">
                            <table className="table prospect-table">
                                <tbody>
                                    {
                                        userList?.data?.data?.map((item, index) => (
                                            <tr key={index} style={{color:'#000', fontSize:'14px', fontWeight:400}}>
                                                <td>{item?.userdetail?.firstname} {item?.userdetail?.lastname}</td>
                                                <td>{item?.userdetail?.email}</td>
                                                <td>+91 {item?.userdetail?.phone}</td>
                                                <td onClick={()=>{ navigate(`/userlist?role=${item?.role?.id}`) }} style={{cursor:'pointer', color:'blue'}}>{item?.role?.label}</td>
                                                <td style={{textAlign:'end'}}>
                                                    <div className="other-buttons">
                                                        <Popover 
                                                            placement="left" 
                                                            title={
                                                                    <div style={{marginBottom:'3px'}}>
                                                                        <span>Roles</span>
                                                                    </div>
                                                                } 
                                                            content={
                                                                <div>
                                                                    {
                                                                        item?.rolemultiples?.map((item, index) => (
                                                                            <div onClick={()=>{navigate(`/userlist?role=${item?.role?.id}`)}} key={index} style={{marginBottom:'6px', cursor:'pointer', fontSize:'13px'}}>
                                                                                <span style={{color:'#00f', fontWeight:400}}>{item?.role?.label}</span>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            } 
                                                            trigger="hover"
                                                        >
                                                            <div style={{fontSize:'13px', outline:'none', minWidth:'90px', marginBottom:'0px', height:'33px', backgroundColor:'rgb(75, 188, 170)', border:'none', cursor:'pointer'}} className="btn btn-md btn-dark btn-add-new-client btn-add-new-client2">
                                                                <i className="icon-users1" style={{width:'23.5px', height:'23.5px'}}></i> Roles
                                                            </div>
                                                        </Popover>

                                                    </div>
                                                </td>
                                            </tr>   
                                    ))}
                                </tbody>
                            </table>
                            <button
                                onClick={() => {navigate('/userlist')}} 
                                style={{float:'right', outline:'none',fontSize:'13px', minWidth:'90px', marginBottom:'0px', height:'33px', backgroundColor:'#BBE9E5', border:'none', marginTop:'10px'}}>
                                Read More <i className="icon-expand_more" style={{width:'23.5px', height:'23.5px'}}></i>
                            </button>
                        </div>
                    </div>
                </div>



                <div className="col-md-12">
                    <div className="highlight-wrpr" style={{background:'#E6E5A3'}}>
                        <div className="left-group">
                            <div className="ilets">
                                <div className="ilets-icon" style={{background:'#AA8B56', borderRadius:'10px', boxShadow:'0 0 0px 0px #fff'}}>
                                    <i style={{color:'#fff'}} className="icon-user2"></i>
                                </div>
                                <div style={{color:'#AA8B56', fontWeight:500}} className="ilets-title">
                                    Clients
                                </div>
                            </div>
                        </div>
                        <div className="right-group">
                            <div className="ilets">
                                <div className="ilets-count">
                                    <span style={{color:'#AA8B56', fontWeight:500}}>{clientList?.data?.count}</span>
                                </div>
                                <div className="ilets-dropdown">
                                    <a style={{color:'#AA8B56'}} data-toggle="collapse" data-target="#clients" className="icon-keyboard_arrow_down"></a>
                                </div>
                            </div>
                        </div>
                        <div id="clients" className="collapse">
                            <table className="table prospect-table">
                                <tbody>
                                    {clientList?.data?.data?.map((item, index) => (
                                        <tr key={index} style={{color:'#000', fontSize:'14px', fontWeight:400}}>
                                            <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                            <td>{item?.status_of_prospect?.name}</td>
                                            <td>+{item.mobile_no}</td>
                                            <td>{item?.visatype?.description}</td>
                                        </tr>   
                                    ))}
                                </tbody>
                            </table>
                            <button
                                onClick={() => {navigate(RouteConstants.USERSLIST)}} 
                                style={{float:'right', outline:'none',fontSize:'13px', minWidth:'90px', marginBottom:'0px', height:'33px', backgroundColor:'#E6E5A3', border:'none', marginTop:'10px'}}>
                                Read More <i className="icon-expand_more" style={{width:'23.5px', height:'23.5px'}}></i>
                            </button>
                        </div>
                    </div>
                </div>
               
                <div className="col-md-5">
                    <div className="highlight-wrpr" style={{background:"#FAC5B5"}}>
                        <div className="left-group">
                            <div className="ilets">
                                <div className="ilets-icon" style={{background:'#D95F82', borderRadius:'10px', boxShadow:'0 0 0px 0px #fff'}}>
                                    <i style={{color:'#fff'}} className="icon-users1"></i>
                                </div>
                                <div style={{color:'#D95F82', fontWeight:500}} className="ilets-title">
                                    Roles
                                </div>
                            </div>
                        </div>
                        <div className="right-group">
                            <div className="ilets">
                                <div className="ilets-count">
                                    <span style={{color:'#D95F82', fontWeight:500}}>{rolesList?.data?.count}</span>
                                </div>
                                <div className="ilets-dropdown ">
                                    <a style={{color:'#D95F82'}} data-toggle="collapse" data-target="#roles" className="icon-keyboard_arrow_down"></a>
                                </div>
                            </div>
                        </div>
                        <div id="roles" className="collapse">
                        
                                <table className="table prospect-table">

                                <tbody>
                                    {
                                        rolesList?.data?.data?.map((item, index) => (
                                        <tr key={index} style={{color:'#000', fontSize:'14px', fontWeight:400}}>
                                            <td>{item?.label}</td>
                                            <td onClick={()=>{ navigate(`/userlist?role=${item?.id}`) }} style={{cursor:'pointer', color:'blue'}}>{item?.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button
                                onClick={() => {navigate('/role')}}
                                style={{float:'right', outline:'none',fontSize:'13px', minWidth:'90px', marginBottom:'0px', height:'33px', backgroundColor:'#FAC5B5', border:'none', marginTop:'10px'}}>
                                Read More <i className="icon-expand_more" style={{width:'23.5px', height:'23.5px'}}></i>
                            </button>                           
                        </div>
                    </div>
                    
                </div>
                <div className="col-md-7">
                    <div className="highlight-wrpr" style={{background:'#D1C4F1'}}>
                        <div className="left-group">
                            <div className="ilets">
                                <div className="ilets-icon" style={{background:'#6D5DD2', borderRadius:'10px', boxShadow:'0 0 0px 0px #fff'}}>
                                    <i style={{color:'#fff'}} className="icon-plane"></i>
                                </div>
                                <div style={{fontWeight: 500, color:'#6D5DD2'}} className="ilets-title">
                                    Visa Type
                                </div>
                            </div>
                        </div>
                        <div className="right-group">
                            <div className="ilets">
                                <div className="ilets-count">
                                    <span style={{fontWeight: 500, color:'#6D5DD2'}}>{visaList?.data?.count}</span>
                                </div>
                                <div className="ilets-dropdown ">
                                    <a style={{fontWeight: 500, color:'#6D5DD2'}} data-toggle="collapse" data-target="#visa" className="icon-keyboard_arrow_down"></a>
                                </div>
                            </div>
                        </div>
                        <div id="visa" className="collapse">
                        
                                <table className="table prospect-table">

                                <tbody>
                                    {
                                        visaList?.data?.data?.map((item, index) => (
                                            <tr key={index} style={{color:'#000', fontSize:'14px', fontWeight:400}}>
                                                <td>{item?.description}</td>
                                                <td>{item?.category}</td>
                                                <td>{item?.visacode}</td>
                                                <td>{item?.country?.name}</td>
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <button
                                onClick={() => {navigate('/visatype')}} 
                                style={{float:'right', outline:'none',fontSize:'13px', minWidth:'90px', marginBottom:'0px', height:'33px', backgroundColor:'transparent', border:'none', marginTop:'10px'}}>
                                Read More <i className="icon-expand_more" style={{width:'23.5px', height:'23.5px'}}></i>
                            </button>
                            
                        </div>
                    </div>
                    
                </div>
                <div className="col-md-7">
                    <div className="highlight-wrpr" style={{background:'#FFEFE9', marginBottom:'0px'}}>
                        <div className="left-group">
                            <div className="ilets">
                                <div className="ilets-icon" style={{background:'#F25424', borderRadius:'10px', boxShadow:'0 0 0px 0px #fff'}}>
                                    <i style={{color:'#fff'}} className="icon-clock5"></i>
                                </div>
                                <div style={{fontWeight: 500, color:'#F25424'}} className="ilets-title">
                                    Status
                                </div>
                            </div>
                        </div>
                        <div className="right-group">
                            <div className="ilets">
                                <div className="ilets-count">
                                    <span  style={{fontWeight: 500, color:'#F25424'}}>{statusList?.data?.count}</span>
                                </div>
                                <div className="ilets-dropdown ">
                                    <a  style={{fontWeight: 500, color:'#F25424'}} data-toggle="collapse" data-target="#status" className="icon-keyboard_arrow_down"></a>
                                </div>
                            </div>
                        </div>
                        <div id="status" className="collapse">
                        
                                <table className="table prospect-table">

                                <tbody>
                                    {
                                        statusList?.data?.data?.map((item, index) => (                                            
                                            <tr key={index} style={{color:'#000', fontSize:'14px', fontWeight:400}}>
                                                <td>{item?.name}</td>
                                                <td>
                                                    <Popover 
                                                        placement="left" 
                                                        title={
                                                                <div style={{marginBottom:'3px'}}>
                                                                    <span>Visa Types</span>
                                                                </div>
                                                            } 
                                                        content={
                                                            <div>
                                                                {
                                                                    item?.status_visas?.map((item, index) => (
                                                                        <div key={index} style={{marginBottom:'6px', cursor:'pointer', fontSize:'13px'}}>
                                                                            <span style={{color:'#00f', fontWeight:400}}>{item?.visatype?.description}</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        } 
                                                        trigger="hover"
                                                    >
                                                        <div style={{fontSize:'13px', outline:'none', minWidth:'120px', marginBottom:'0px', height:'33px', backgroundColor:'#F25424', border:'none', cursor:'pointer'}} className="btn btn-md btn-dark btn-add-new-client btn-add-new-client2">
                                                            <i className="icon-plane" style={{width:'23.5px', height:'23.5px'}}></i>Visa Type
                                                        </div>
                                                    </Popover>
                                                </td>
                                                <td>
                                                    <Popover 
                                                        placement="left" 
                                                        title={
                                                                <div style={{marginBottom:'3px'}}>
                                                                    <span>Roles</span>
                                                                </div>
                                                            } 
                                                        content={
                                                            <div>
                                                                {
                                                                    item?.status_roles?.map((item, index) => (
                                                                        <div onClick={()=>{navigate(`/userlist?role=${item?.role?.id}`)}} key={index} style={{marginBottom:'6px', cursor:'pointer', fontSize:'13px'}}>
                                                                            <span style={{color:'#00f', fontWeight:400}}>{item?.role?.label}</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        } 
                                                        trigger="hover"
                                                    >
                                                        <div style={{fontSize:'13px', outline:'none', minWidth:'120px', marginBottom:'0px', height:'33px', backgroundColor:'#F25424', border:'none', cursor:'pointer'}} className="btn btn-md btn-dark btn-add-new-client btn-add-new-client2">
                                                            <i className="icon-users1" style={{width:'23.5px', height:'23.5px'}}></i>Roles
                                                        </div>
                                                    </Popover>
                                                </td>
                                            </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button
                                onClick={() => {navigate('/listprospect')}} 
                                style={{float:'right', outline:'none',fontSize:'13px', minWidth:'90px', marginBottom:'0px', height:'33px', backgroundColor:'transparent', border:'none', marginTop:'10px'}}>
                                Read More <i className="icon-expand_more" style={{width:'23.5px', height:'23.5px'}}></i>
                            </button>
                        </div>
                    </div>
                    
                </div>
                <div className="col-md-5">
                    <div className="highlight-wrpr" style={{background:'#FFF3D4', marginBottom:'0px'}}>
                        <div className="left-group">
                            <div className="ilets">
                                <div className="ilets-icon" style={{background:'#FECF53', borderRadius:'10px', boxShadow:'0 0 0px 0px #fff'}}>
                                    <i style={{color:'#fff'}} className="icon-office"></i>
                                </div>
                                <div style={{fontWeight: 500, color:'#FECF53'}} className="ilets-title">
                                    Office
                                </div>
                            </div>
                        </div>
                        <div className="right-group">
                            <div className="ilets">
                                <div className="ilets-count">
                                    <span style={{fontWeight: 500, color:'#FECF53'}}>{officeList?.data?.count}</span>
                                </div>
                                <div className="ilets-dropdown ">
                                    <a style={{fontWeight: 500, color:'#FECF53'}} data-toggle="collapse" data-target="#office" className="icon-keyboard_arrow_down"></a>
                                </div>
                            </div>
                        </div>
                        <div id="office" className="collapse">
                        
                                <table className="table prospect-table">

                                <tbody>
                                    {
                                        officeList?.data?.data?.map((item, index) => (
                                        <tr key={index} style={{color:'#000', fontSize:'14px', fontWeight:400}}>
                                            <td>{item?.name}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.office_location}</td>
                                        </tr>   
                                    ))}                            
                                </tbody>
                            </table>
                            <button
                                onClick={() => {navigate('/office')}} 
                                style={{float:'right', outline:'none',fontSize:'13px', minWidth:'90px', marginBottom:'0px', height:'33px', backgroundColor:'transparent', border:'none', marginTop:'10px'}}>
                                Read More <i className="icon-expand_more" style={{width:'23.5px', height:'23.5px'}}></i>
                            </button>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
  )
}

export default AdminDashboard
