/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import authQueries from '../../queries/authQueries';
import LoaderCommon from '../Common/LoaderCommon';
import Swiper from 'swiper';
import $ from 'jquery'
import CountUp from 'react-countup';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store/userStore';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { Vote } from 'lucide-react';
import { IconCheckbox, IconCircleDashed } from '@tabler/icons-react';
import RouteConstants from '../../Constants/RouteConstans';
import { IconCircleDashedPercentage } from '@tabler/icons-react';


const FeeCoordinatorDashboard = () => {

    const [count, setCount] = useState({})
	const [loader, setLoader] = useState(true);


    const navigate = useNavigate()

    const setActiveTab = userStore((state)=> state.setActiveTab)
	const setFeePayment = useAdvancedSearch((state) => state.setFeePayment);
    const resetAll = useAdvancedSearch((state) => state.resetAll);
    const setTotalAssigned = useAdvancedSearch((state) => state.setTotalAssigned);

    const countDashboard = authQueries.useFeeDashboardCountMutation(
		async (response) => {
      	    setCount(response)
            setLoader(false)
        }
    )

    useEffect(() => {
        countDashboard.mutateAsync()
    }, []);

    const slideArr = [
		{
			color: '#508D69',
			title: 'Total No of Students Assigned',
			icon: <Vote color='#508D69' size="20" />,
			value: "TOTAL",
			count: count?.total
		},
		{
			color: '#29ADB2',
			title: 'Full Fee paid Students',
			icon: <IconCheckbox color='#29ADB2' size="20" />,
			value: "FULLPAID",
			count: count?.paid
		},
		{
			color: '#FA7070',
			title: 'Partial fee paid students',
			icon: <IconCircleDashedPercentage color='#FA7070' size="20" />,
			value: "PARTPAID",
			count: count?.pending
		},
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
                "1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	const tileClick = (value) => {
		resetAll()
        if(value === "TOTAL"){
            setTotalAssigned(true)
        }
		if(value === "FULLPAID"){
            setFeePayment("FULLPAID")
		}
		if(value === "PARTPAID"){
            setFeePayment("PARTPAID")
		}
        setActiveTab(RouteConstants.FEECOORDINATORMYCLIENTS)
		navigate(RouteConstants.FEECOORDINATORMYCLIENTS)
	}
    
  return (
    <div>

        {
            loader ?
            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
                <LoaderCommon  />
            </div>:
            <div>
                <div className="carousel-section mb-5">
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{slideArr.map((item, index) => (
										<div key={index} className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && tileClick(item.value)}>
											<div className='flex flex-col p-3 justify-between h-full'>
												<div className='flex justify-between'>
													<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
													<div className='text-4xl'>
														<CountUp end={item?.count} duration={1} />
													</div>
												</div>
												<div>
													<div className='text-[14px]'>{item.title}</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>

                <FollowupToday />
                <FollowupPending />


            </div>
        }

    </div>
  )
}

export default FeeCoordinatorDashboard