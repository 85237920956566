import { persist } from "zustand/middleware";
import create from "zustand";

export const useDelegateStore = create(
    persist(
      (set) => ({
        visible: false,
        setVisible: (value) => set({ visible: value }),
      }),
      { name: "delegateSchema", getStorage: () => localStorage }
))
