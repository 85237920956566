import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoCompleteAntd, AutoCompleteChild, AutoCompleteChilds } from '../../Clients/AddClients/AutoComplete';


const validationSchema = yup.object({

    college: yup
      .string('⚠ Enter College / University Name')
      .required('⚠ College / University Name is required'),
    name: yup
      .string('⚠ Enter Campus Name')
      .required('⚠ Campus Name is required')
      .min(2,'⚠ Campus Name must be more than 2 characters ')
  });
  

const AddCampus = () => {

    const navigate = useNavigate();

    const [result, setResult] = useState([])

    const [labelName, setlabelName] = useState('College / University')

    const [errName, setErrName] = useState('')


    const { register, handleSubmit, control, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(()=>{
        CollegeList.mutateAsync({search:'', status: '', page: 0, size: 500});
    },[])


    const CollegeList = authQueries.useCollegeMutationList(

            (response) => {

                let dataArray = []
                for(let datas of response?.data){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setResult(dataArray)

            }
        );


    const AddCampusData = authQueries.useCampusMutationAdd(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listcampus')
            }
        }
    );

    const onSubmit = (data) => {    
        const datavalues = {
            cid: data.college,
            name: data.name,
        };
        AddCampusData.mutateAsync(datavalues);  
    } 
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/listcampus">Campus</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Add Campus</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Add Campus
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">


                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            
                                            <label htmlFor="">{labelName}</label>
                                            
                                            <AutoCompleteAntd 
                                                control={control} 
                                                data={result} 
                                                name={"college"} 
                                                value={null}
                                            />
                                                
                                            <div className='error-msg'>{errors.college?.message}</div>
                                        </div>
                                    </div>
                                </div>
   


                                <div className="col-md-6">

                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Campus Name :</label>
                                            <input style={{height:'44px'}} className="form-control"  {...register("name")} type="text" name='name' />
                                            <div className='error-msg'>{errors.name?.message} {errName}</div>
                                        </div>
                                    </div>

                                </div>

                               
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default AddCampus;