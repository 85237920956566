import React, { useEffect, useState, Fragment } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { useAdvancedSearch } from '../../../store/advancedSearchStore';
import SelectSearch from "../../Common/SelectSearch";
import { userStore } from '../../../store/userStore';
import InputSearch from "../../Common/InputSearch";


const Employee =()=>{

    const [result, setResult] = useState([]);
    const [status, setStatus] = useState('');
    let staffTotal=0;
    let officeName='';
    let username='';
    let staffcount='';
    let officeCount ='';
    let address ='';
    let reviewgb ='';
    let reviewmg ='';
    let location ='';
    const office = useAdvancedSearch((state) => state.office);
    const setOffice = useAdvancedSearch((state) => state.setOffice);
    const store = userStore();
    const [masterData, setMasterData] = useState({})
    const name = useAdvancedSearch((state) => state.name);
    const setName = useAdvancedSearch((state) => state.setName);
    useEffect(()=>{
        employeeReportApi.mutateAsync();
    },[]);

    const employeeReportApi = authQueries.useEmployeeReport(
        (response) => {
            setResult(response);        
        }
    );

    
    const bmOffice = store?.user?.office?.map(item=>{
        return item?.office
    })

    const advancedMasterList = authQueries.useAdvancedSearchMutation(
        (response) => {
            setMasterData(response?.data)
        }
    );



    useEffect(() => {
        advancedMasterList.mutateAsync({visa: ''})
    }, [])


    useEffect(()=>{
        employeeReportApi.mutateAsync({
            name: name,
            office: office
        });
    },[name,office]);

    const inputSearchArry = [
        {
            label: "Employee Name",
            size: 2,
            state: name,
            setState: setName,
            type: "text",
            input: InputSearch,
            simple: true
        },
        {
            label: "Branch Office",
            size: 2,            
            state: office,
            setState: setOffice,
            input: SelectSearch,
            value: (store?.user?.role === "BMANAGER" || store?.user?.role === "SENIORPROGRAMMANAGER") ? bmOffice : masterData?.office,
            hidden: store?.user?.role === "RM" ? true : false,
            name: 'name',
            simple: true,
        }

    ]


    return(
    <div>
        <div className="breadcrumb-and-otherbuttons">
            {/* <!-- breadcrumb --> */}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Employee Details and Office Locations</li>
                </ol>
            </nav>
        </div>
        
        <div className="add_clients">
        
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                    </i>
                    <h2><span>&nbsp;</span>Employee Details and Office Locations</h2>	
                </div>
                    
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            

            <div id="accordion" className="add_clients_accordion_form">
                <div  className="card" id="appointments_wrpr_employee">
                    <div className="card-header">
                    {/* <a className="collapsed card-link"  href="" style="pointer-events:none;"> */}
                    Employee Details and Office Locations
                    {/* </a> */}
                    
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="card-body">

                    <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                    {
                        inputSearchArry?.map((item, index)=> {
                            if(item?.blank && !item?.hidden){
                                return(
                                    <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                    )
                            }
                            if(item?.hidden){
                                return
                            }
                            return(
                            <Fragment key={index}>
                            {
                                (item?.simple ) &&
                                <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                <item.input item={item} />
                                </div>
                            }
                            </Fragment>
                            )})
                    }
                    </div>
                            
                        <div className="row ">
                            <div className="col-md-12">
                            
                                <div className="table-list-search-wrpr">
                                    <div className="row">
                                        <div className="col-md-6">
                                            
                                        </div>
                                        <div className="col-md-6 text-right">
                                        </div>
                                </div>

                                
                                
                                    <table border={1} className="table report collageprogram">
                                        <thead>
                                            <tr>
                                
                                            <th >Branch</th>
                                            <th>Employee Name</th>
                                            <th>Employee Designation</th>
                                            <th >Employee Phone Number</th>
                                            <th >Employee Email</th>
                                            <th >Office Address</th>
                                            <th >Google Review GB</th>
                                            <th >Google Review M&G</th>
                                            <th >Office Location</th>
                                            </tr>
                                        </thead>
                                        <tbody  className="ui-sortable">
                                        { employeeReportApi.isLoading && "Loading" }
                                        {   
                                            result?.data?.map((item, index) =>
                                            {  
                                            
                                            let offices = result?.data?.filter(function(office){
                                                return  office.office == item.office;
                                            });

                                            
                                                
                                            return(
                                                <tr className="ui-sortable-handle">
                
                                                {officeName!==item.office?<td rowSpan={offices.length}>{officeName=item.office }</td>: null}
                                                <td>{item.firstname+' '+item.lastname}</td>
                                                <td>{item.designation}</td>
                                                <td>{item.phone}</td>
                                                <td>{item.email}</td>
                                                {address!=item.address?<td rowSpan={offices.length}>{address=item.address }</td>: null}
                                                {reviewgb!=item.review_GB?<td rowSpan={offices.length}>{reviewgb=item.review_GB }</td>: null}
                                                {reviewmg!=item.review_MAG?<td rowSpan={offices.length}>{reviewmg=item.review_MAG }</td>: null}
                                                {location!=item.location?<td rowSpan={offices.length}>{location=item.location }</td>: null}
                                                </tr>
                                            )})
                                        }
                                        </tbody>

                                    </table>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </div>
                
                
                </div>
            
        </div>
        
        </div>
    </div>
    )
}

export default Employee;