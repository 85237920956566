import React, { Fragment, useEffect, useState } from 'react'
import LoaderCommon from '../Common/LoaderCommon'
import { Empty } from 'antd'
import moment from 'moment'
import authQueries from '../../queries/authQueries'
import { usePaginationStore } from '../../store/userStore'
import PaginationComponent from '../Common/PaginationComponent'
import LeadStatus from '../Common/LeadStatus'
import ProspectStatusList from '../Common/ProspectStatusList'
import ApplicantViewButton from '../Common/ApplicantViewButton'
import InputSearch from '../Common/InputSearch'
import SelectSearch from '../Common/SelectSearch'
import ApplicantEditButton from '../Common/ApplicantEditButton'
import FollowUp from '../Common/FollowUp'
import { useAdvancedSearch } from '../../store/advancedSearchStore'
import AdvancedSearch from '../Common/AdvancedSearch'

const AssociatesMyClients = () => {

    const [result, setResult] = useState([]);
    const [loader, setLoader] = useState(true)

    const data = useAdvancedSearch((state) => state);
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const listAssociates = authQueries.useAssociatesMutationList(
        (response) => {
            setLoader(false)
            setResult(response)
        }   
    );

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);

    useEffect(() => {
        listAssociates.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
        })
    }, [data, currentPage])

  return (
    <Fragment>
        
        <div id="accordion" className="add_clients_accordion_form mt-4">
            <div className="card" id="appointments_wrpr">
                <div className="card-header flex justify-between">
                    <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                        My Clients
                    </a>
                </div>
                <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="card-body">

                        <AdvancedSearch />

                        <div className="table-list-search-wrpr simple-search">

                            <div className="search-results-count-wrpr">
                                {
                                result?.data?.totalItems > 0 ?
                                `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                `O results found`
                                }
                            </div>

                            {loader? 
                                <LoaderCommon />:	
                                result?.data?.totalItems > 0 ?
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12 mt-2">
                                                <table className="table prospect-table">
                                                    <thead>
                                                        <tr>
                                                            <th width="30">#</th>
                                                            <th>Profile Status</th>
                                                            <th>Office</th>
                                                            <th>Date Created</th>
                                                            <th>Applicant Name</th>
                                                            <th>Contact Number</th>
                                                            <th>Visa Type</th>
                                                            <th>Intake</th>
                                                            <th>Counselor</th>
                                                            <th>Reg. Date</th>
                                                            <th>Prospect Status</th>
                                                            <th>Reffered By</th>
                                                            <th width='150'>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ui-sortable">
                                                        {result?.data?.items?.map((item, index) => (
                                                            <tr className="ui-sortable-handle" key={index}>
                                                                <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                <td>
                                                                    <LeadStatus item={item} />
                                                                </td>
                                                                <td>{item?.office?.name}</td>
                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                <td>
                                                                    {item?.applicant_name} {item?.middlename} {item?.lastname} 
                                                                </td>
                                                                <td>
                                                                    <div className="email-mobile flex flex-col">
                                                                        <div>{item.mobile_no}</div>
                                                                        <div>{item.landline_no}</div>
                                                                        <div>{item.whatsapp_no}</div>
                                                                    </div>                                                                    
                                                                </td>
                                                                <td>{item?.visatype?.description}</td>
                                                                <td>{item?.intake?.intake}</td>
                                                                <td>{item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                                                <td>
                                                                    {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                </td>
                                                                <td>
                                                                    <ProspectStatusList item={item} />
                                                                </td>
                                                                <td>{item?.refferal?.refferal}</td>
                                                                <td className="flex justify-end gap-1">
                                                                    <div className='flex gap-1 min-w-[150px]'>
                                                                        <div className='w-[110px]'>
                                                                            <FollowUp item={item} label="View Comment" historyOnly={true}/>
                                                                        </div>
                                                                        {/* <div className='col-span-6'>
                                                                            <ApplicantEditButton item={item} />
                                                                        </div> */}
                                                                        <div className='w-[40px]'>
                                                                            <ApplicantViewButton item={item} />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}												  
                                                    </tbody>
                                                </table>
                                                <PaginationComponent result={result} setCurrentPage={setCurrentPage} currentPage={currentPage} fixed={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>:
                                <div className='flex justify-center mt-16 mb-16 w-full'>
                                    <Empty description={<div className=''> No applicants found!</div>} />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>
  )
}

export default AssociatesMyClients