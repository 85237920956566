import React, { Fragment } from 'react'
import ApplicationCreateModal from '../FairStaff/ApplicationCreateModal'
import FairStaffListing from '../FairStaff/FairStaffListing'

const FairStaffDashboard = () => {
  return (
    <Fragment>
      <ApplicationCreateModal />
      <div className='mt-4'>
        <FairStaffListing />
      </div>
    </Fragment>
  )
}

export default FairStaffDashboard