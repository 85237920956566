import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import {  Alert, message, message as messages, Popconfirm } from 'antd';
import PaginationComponent from "../../Common/PaginationComponent";
import MasterModal from "../../Common/MasterModal";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import MasterHeader from "../../Common/MasterHeader";
import LoaderCommon from "../../Common/LoaderCommon";
import InputUseForm from "../../Common/InputUseForm";
import AutoCompleteInput from "../../Common/AutoCompleteInput";
import InputCheckBox from "../../Common/InputCheckBox";
import InputSelectForm from "../../Common/InputSelectForm";
import { generateValidationSchema } from "../../Common/validationSchema";
import toast from "react-hot-toast";

const VisaTypeList =()=>{

    const [result, setResult] = useState([]);
    const [name, setName] = useState('')
    const [country, setCountry] = useState([])
    const [loader, setLoader] = useState(true)
    const master = userStore();

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const inputFields = [
        {
            name: "country_id",
            label: "Country",
            placeholder:"Country",
            size: "6",
            type: AutoCompleteInput,
            required: true,
            options: country?.data?.items,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "description",
            label: "Visa Description",
            placeholder:"Visa Description",
            size: "6",
            min: 2,
            max: 250,
            type: InputUseForm,
            required: true
        },
        {
            name: "visacode",
            label: "Visa Code",
            placeholder:"Visa Code",
            size: "3",
            type: InputUseForm,
            required: true
        },
        {
            name: "IsStudentVisa",
            label: "Student Visa",
            placeholder:"Student Visa",
            size: "3",
            type: InputSelectForm,
            required: true,
            options:[{name:"Yes", value: "YES"}, {name:"No", value: "NO"}],
            optionProps:{name: "name", name2: "", value: "value", value2: ""}
        },
        {
            name: "CanadaOffice",
            label: "Canada Office",
            placeholder:"Canada Office",
            size: "3",
            type: InputSelectForm,
            required: true,
            options:[{name:"Yes", value: "YES"}, {name:"No", value: "NO"}],
            optionProps:{name: "name", name2: "", value: "value", value2: ""}
        },     
        {
            name: "category",
            label: "Category",
            placeholder:"Category",
            size: "3",
            type: InputSelectForm,
            required: true,
            options:[{name:"Student Visa", value: "StudentVisa"}, {name:"Immigration", value: "Immigration"}],
            optionProps:{name: "name", name2: "", value: "value", value2: ""}
        },
        {
            name: "fee",
            label: "Fee Coordinator",
            placeholder:"Fee Coordinator",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "doc",
            label: "Document Staff",
            placeholder:"Document Staff",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "reg",
            label: "Mark Registration",
            placeholder:"Mark Registration",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "loan",
            label: "Loan Executive",
            placeholder:"Loan Executive",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "travel",
            label: "Travel Executive",
            placeholder:"Travel Executive",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "gic",
            label: "GIC Executive",
            placeholder:"GIC Executive",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "interview",
            label: "Interview Trainer",
            placeholder:"Interview Trainer",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "sop",
            label: "SOP Staff",
            placeholder:"SOP Staff",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "intake",
            label: "Intake Required",
            placeholder:"Intake Required",
            size: "2",
            type: InputCheckBox,
            required: false
        },
        {
            name: "program",
            label: "Program Required",
            placeholder:"Program Required",
            size: "2",
            type: InputCheckBox,
            required: false
        },
    ]

    const { register ,reset, handleSubmit, formState:{ errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    const visaTypeListing = authQueries.useVisaTypeMutationList(
        async(response) => {
            setResult(response)
            setLoader(false)           
        }   
    );

    const countryListing = authQueries.useCountryMutationList(
        async(response) => {
            setCountry(response)
            setLoader(false)           
        }   
    );

    useEffect(() => {
        countryListing.mutateAsync({size: 500000})
    }, []);

    useEffect(()=>{
        visaTypeListing.mutateAsync({search: name, page:currentPage, size:25});
    },[name, currentPage])

    const deleteVisaType = authQueries.useVisaTypeMutationDelete(
        (response) => {
            visaTypeListing.mutateAsync({search: name, page:currentPage, size:25});
            messages.success("Successfully deleted")
        }
    );

    const deleteMaster = (event, id) => {
        event.preventDefault();
        deleteVisaType.mutateAsync(id)
    }

    const [visible, setVisible] = useState(false)

    const handleOpen =async(item)=>{
        console.log(item)
        if(!item){
            await master.setMaster(null)
            setVisible(true)
            return 
        }else{
            reset(item)
            await master.setMaster(item.id)
            setVisible(true)
        }
    }

    const handleClose =async()=>{
        const getvalue = getValues()
        for(const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
              setValue(key, "")
            }
        }
        clearErrors()
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null)
    }

    const createMaster = authQueries.useVisaTypeMutationAdd(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        message.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            if(response?.message=== "Success"){
                toast.success("Successfully created visa type")
                visaTypeListing.mutateAsync({search: name, page:currentPage, size:25});
            }
        }   
    );
        
    const updateMaster = authQueries.useVisaTypeMutationEdit(
        (response) => {
            if(response?.message === "Error"){
                {response.error.map((error, index) => (
                    Object.values(error).map((errorMessage, innerIndex) => (
                        toast.error(errorMessage)
                    ))
                ))}
                return
            }
            handleClose()
            toast.success("Successfully updated visa type")
            visaTypeListing.mutateAsync({search: name, page:currentPage, size:25});
        }   
    );


    return(
        <div>
            <div className="">	
                <MasterHeader handleOpen={handleOpen} header="Visa Type" breadcrumb=" Visa Types" />			
				<div className="add_clients">
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span> Visa Types</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
                            <div className="card-header text-[14px]">
                                Visa Type
                            </div>
		    				<div id="appointment_any" className="collapse show" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-md-12">
                                        
                                            <div className="table-list-search-wrpr">
                                                <div className="row">
                                                    <div className="col-md-6" />
                                                    <div className="col-md-6 text-right">
                                                        <div className="table-search-filter-wrpr">
                                                            <div className="table-search-wrpr">
                                                                <input style={{minWidth: '300px'}} onChange={(e)=>{setName(e.target.value)}} className="form-control form-control-sm" type="text" placeholder="Search Visa Type" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                          
                                            {
                                                loader ?
                                                <LoaderCommon />:
                                                <div className="table-wrpr">
                                                    <div className="table-section">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="table prospect-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="5%">#</th>
                                                                            <th>Country</th>
                                                                            <th>Visa Type</th>
                                                                            <th>Fee Coordinator</th>
                                                                            <th>Document Staff</th>
                                                                            <th>Mark Registration</th>
                                                                            <th>Loan Executive</th>
                                                                            <th>Travel Executive</th>
                                                                            <th>GIC Executive</th>
                                                                            <th>Interview Trainer</th>
                                                                            <th>SOP Staff</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="ui-sortable">
                                                                        {
                                                                            result?.data?.items?.map((item, index) =>(
                                                                            <tr className="ui-sortable-handle" key={index} >
                                                                                <th className="">{(index + 1) + result?.data?.currentPage * 25}</th>
                                                                                <td>{item?.country?.icon} {item?.country?.name}</td>
                                                                                <td>{item?.description}</td>
                                                                                <td>
                                                                                    <i style={{color: item?.fee === "YES"?"#1a73e8":"#ff0000"}} className={item?.fee === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.fee}
                                                                                </td>
                                                                                <td>
                                                                                    <i style={{color: item?.doc === "YES"?"#1a73e8":"#ff0000"}} className={item?.doc === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.doc}
                                                                                </td>
                                                                                <td>
                                                                                    <i style={{color: item?.reg === "YES"?"#1a73e8":"#ff0000"}} className={item?.reg === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.reg}
                                                                                </td>
                                                                                <td>
                                                                                    <i style={{color: item?.loan === "YES"?"#1a73e8":"#ff0000"}} className={item?.loan === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.loan}
                                                                                </td>
                                                                                <td>
                                                                                    <i style={{color: item?.travel === "YES"?"#1a73e8":"#ff0000"}} className={item?.travel === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.travel}
                                                                                </td>
                                                                                <td>
                                                                                    <i style={{color: item?.gic === "YES"?"#1a73e8":"#ff0000"}} className={item?.gic === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.gic}
                                                                                </td>
                                                                                <td>
                                                                                    <i style={{color: item?.interview === "YES"?"#1a73e8":"#ff0000"}} className={item?.interview === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.interview}
                                                                                </td>
                                                                                <td>
                                                                                    <i style={{color: item?.sop === "YES"?"#1a73e8":"#ff0000"}} className={item?.sop === "YES"?"icon-checkmark3":"icon-cancel2"}></i> {item?.interview}
                                                                                </td>
                                                                                <td className="action-icons flex gap-2 justify-end">                                                     
                                                                                    <button onClick={()=>{handleOpen(item)}} className="btn-nextrow mb-0">Update</button>
                                                                                    <Popconfirm
                                                                                        placement="left"
                                                                                        title="Are you sure to delete this visa type?"
                                                                                        onConfirm={(event) => {deleteMaster(event, item.id)}}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button className="btn-removerow mb-0">Delete</button>
                                                                                    </Popconfirm>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <MasterModal
                                            width="70%"
                                            header="Visa Type"
                                            visible={visible} 
                                            handleClose={handleClose} 
                                            inputFields={inputFields} 
                                            reset={reset} 
                                            control={control}
                                            handleSubmit={handleSubmit} 
                                            register={register} 
                                            errors={errors} 
                                            getValues={master?.master}
                                            defaultValues={getValues()}
                                            create={createMaster}
                                            update={updateMaster}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
				</div>
			</div>
        </div>
    )
}

export default VisaTypeList