import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { userStore } from '../../../store/userStore';
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../Common/financialYear';
import LoaderCommon from "../../Common/LoaderCommon";
import OfficeDetailedReport from "./OfficeDetailedReport";


const Officefeepaid = () => {

    function formatDate(date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2); 
        var day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    }

    function getCurrentFinancialYearDates() {
        var today = new Date();
        var currentYear = today.getFullYear();
        var financialYearStart = new Date(currentYear, 3, 1); // April 1st is month 3 (0-indexed)
        if (today < financialYearStart) {
            financialYearStart = new Date(currentYear - 1, 3, 1); // April 1st is month 3 (0-indexed)
        }
        var nextYear = currentYear + 1;
        var financialYearEnd = new Date(nextYear, 2, 31); // March 31st is month 2 (0-indexed)
        if (financialYearEnd < today) {
            financialYearEnd = new Date(currentYear, 2, 31); // March 31st is month 2 (0-indexed)
        }
        var formattedStartDate = formatDate(financialYearStart);
        var formattedEndDate = formatDate(financialYearEnd);
        return {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        };
    }


    var financialYearDates = getCurrentFinancialYearDates();
    const user = userStore();
    const [result, setResult] = useState([]);
    const [loader, setLoader] = useState(false);
    const navigation = useNavigate();
    const currentFinancialYear = getCurrentFinancialYear();
    const nextFinancialYear = getNextFinancialYear();
    const previousFinancialYear = getPreviousFinancialYear();
    const [dateRange, setDateRange] = useState("CURRENT");
    const [fromDate, setfromDate] = useState(financialYearDates.startDate);
    const [toDate, settoDate] = useState(financialYearDates.endDate);
    

    useEffect(() => {
        officefeeepaidapi.mutateAsync({ from: fromDate, to: toDate});
    }, []);

    const officefeeepaidapi = authQueries.useOfficeFeePaidkReportMutation(
        (response) => {
            console.log("directQueryResults",response.data)
            setResult(response.data[0]);
        }
    );
    const handlefromDate = (event) => {
        const value = event.target.value;
        setfromDate(value);
    };

    const handletoDate = (event) => {
        const value = event.target.value;
        settoDate(value);
    };

    const handleSearch = () => {
        officefeeepaidapi.mutateAsync({ from: fromDate, to: toDate});
      };
    


    return (
        <Fragment>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Office Fee Paid Report</li>
                    </ol>
                </nav>
            </div>

            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <h2>Office Fee Paid Report</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr_employee">
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                    <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6">
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div style={{display: "-webkit-inline-box" }} className="table-search-wrpr">
                                                            <label style={{marginRight:7}}>From</label>
                                                            <input style={{minWidth: '100px'}} value={fromDate} onChange={handlefromDate}  className="form-control form-control-sm" type="date" placeholder="From" />
                                                            <label style={{marginRight:7}}>To</label>
                                                            <input style={{minWidth: '100px'}} value={toDate} onChange={handletoDate}  className="form-control form-control-sm" type="date" placeholder="TO" />
                                                            <button style={{background: '#323a51'}} onClick={handleSearch}  className="btn btn-info">Search</button>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div> 
                                        </div>
                                        <div className="table-head-wrpr">
                                            <table className="table collageprogram alt-colors">
                                                <thead className="fixedHeader table-hdr">
                                                    <tr>
                                                        <th style={{ textAlign: "center" }}>Sno</th>
                                                        <th style={{ textAlign: "center" }}>Office</th>
                                                        <th style={{ textAlign: "center" }}>Total Fee Paid</th>
                                                        <th style={{ textAlign: "center" }}>Expected Visa(YTD)</th>
                                                    </tr>
                                                </thead>

                                            </table>
                                        </div>
                                        {officefeeepaidapi.isLoading ?
                                        <LoaderCommon />:
                                        <div className="table-body-wrpr" style={{ height: "550px" }}>
                                            <div style={{ position: "relative" }}>
                                                <table border={1} className="table" style={{ fontSize: "12px" }}>
                                                    <tbody className="table-bdy">
                                                    { 
                                                        result.map((item, itemIndex) => {
                                                         
                                                            return (
                                                                <tr key={`-${itemIndex}`} className={`ui-sortable-handle`}>
                                                                    <td style={{ textAlign: "center" }}>{itemIndex+1}</td>
                                                                    <td  style={{ textAlign: "center", fontWeight: "bold" }}>{item?.office}</td>
                                                                    <td  style={{ textAlign: "center", fontWeight: "bold" }}>{item?.total_feepaid_count}</td>
                                                                    <td  style={{ textAlign: "center", fontWeight: "bold" }}>{item?.feepaid_count}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        

                                        }
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Officefeepaid;
