import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link } from "react-router-dom";



const UserDetailsList =()=>{

    const [result, setResult] = useState([])

  
    useEffect(()=>{
        mutateAsync({search: '', status: '', page:0, size:10});
    },[]);


    const { mutateAsync } = authQueries.useUserDetailsMutationList(

        (response) => {
            setResult(response)            
        }   
    
    );



    let sl = 1
    return(
        <div>

            <Link to="/dashboard">Add Country</Link>

            
            {
                result?.data?.items?.map((item, index) =>(
                    <div key={index}>
                        {sl++} {item.name}
                    </div>
                ))
            }

        <tbody>
          {(() => {
            let rows = [];
            for (let i = 0; i < result?.data?.totalPages; i++) {
              rows.push(<button onClick={()=>{mutateAsync({page:i, size:10})}}>{i+1}</button>);
            }
            return rows;
          })()}

        </tbody>

        </div>
    )
}

export default UserDetailsList