
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from '@nextui-org/react';
import AddButton from '../../Common/AddButton';
import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import MasterModal from "../../Common/MasterModal";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InputUseForm from "../../Common/InputUseForm";
import { generateValidationSchema } from "../../Common/validationSchema";
import toast from "react-hot-toast";
import LoaderCommon from "../../Common/LoaderCommon";
import { Select } from "antd";
import { IconMinus } from "@tabler/icons-react";
import { IconPlus } from "@tabler/icons-react";
const ListScore =()=>{

    const [result, setResult] = useState([]);

    const [selectedCountries, setSelectedCountries] = useState([]);

    const counselorScore = authQueries.useCounselorScoreMutation(
        (res)=>{
            setResult(res)
        }
    )

    useEffect(()=>{
        counselorScore.mutateAsync()
    },[])
    
    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Counselor score</li>
                    </ol>
                </nav>
            </div>	
            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Counselor Score</h2>	
                    </div>
                        
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header text-[14px]">
                            Counselor score
                        </div>
                        <div>
                        </div>
                        {
                            counselorScore.isLoading ? <LoaderCommon  /> :
                            <div id="appointment_any" className="collapse show" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Select
                                            className="w-full"
                                            mode="multiple"
                                            placeholder="All Countries"
                                            onChange={(value)=>setSelectedCountries(value)}
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            options={result?.countries?.map((country) => ({
                                                label: country.name,
                                                value: country.id
                                            }))}
                                            />
                                            
                                                
                                            <div className="table-list-search-wrpr table-report-ceo overflow-auto mt-4">
                                                <div className="overflow-x-auto max-h-[600px]">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table border={1}  className="table report associate-report collageprogram overflow-auto ">
                                                                <thead style={{zIndex:100}} className="sticky top-0">
                                                                    <tr>
                                                                        <th className="min-w-[50px] text-center fixed-column data py-2">#</th>
                                                                        <th className="min-w-[250px] !text-[13px] fixed-column text-center data" style={{backgroundColor:"var(--primary-color)"}}>Counselor name</th>
                                                                        {result?.countries?.map((country, index) => {
                                                                            if(selectedCountries.length === 0){                                                                                
                                                                                return(
                                                                                    <th className="min-w-[100px] !text-[13px] text-center py-2" key={index}>{country.name}</th>
                                                                                )
                                                                            }else if (selectedCountries.includes(country?.id)) {
                                                                                return(
                                                                                    <th className="min-w-[100px] !text-[13px] text-center py-2" key={index}>{country.name}</th>
                                                                                )        
                                                                            }
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                {result?.users?.map((counselor, counselorIndex) => (
                                                                    <tbody key={counselorIndex} className={`ui-sortable ${counselorIndex % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                                        <tr className="ui-sortable-handle">
                                                                            <td className="!text-[13px] text-center fixed-column data">{counselorIndex + 1}</td>
                                                                            <td className="!text-[13px] text-center fixed-column data bg-white">{counselor?.userdetail?.name}</td>
                                                                            {/* {result?.countries?.map((country, index) => (
                                                                                <td className="!text-[13px] text-center py-2" key={index}>
                                                                                    <CountryWiseScore result={result} counselor={counselor} country={country} />
                                                                                </td>
                                                                            ))} */}
                                                                            {result?.countries?.map((country, index) => {
                                                                            if(selectedCountries.length === 0){                                                                                
                                                                                return(
                                                                                    <td className="!text-[13px] text-center py-2" key={index}>
                                                                                        <CountryWiseScore result={result} counselor={counselor} country={country} />
                                                                                    </td>   
                                                                                )
                                                                            }else if (selectedCountries.includes(country?.id)) {
                                                                                return(
                                                                                    <td className="!text-[13px] text-center py-2" key={index}>
                                                                                        <CountryWiseScore result={result} counselor={counselor} country={country} />
                                                                                    </td>   
                                                                                )     
                                                                            }
                                                                        })}
                                                                        </tr>
                                                                    </tbody>
                                                                ))}
                                                            </table>   
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListScore

const CountryWiseScore = ({result, country, counselor}) => {

    const [score, setScore] = useState(result?.scores?.find(score => score?.user_id === counselor?.id && score?.country_id === country?.id)?.score || 0)

    const [editable, setEditable] = useState(false)

    const handleScore = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if(onlyNums > 11 || onlyNums < 0){
            return toast.error("Score should be 0-10")
        }
        setScore(e.target.value)
        const data = {
            user_id: counselor?.id,
            country_id: country?.id,
            score: e.target.value
        }
        createScore.mutateAsync(data)
    }

    const createScore = authQueries.useCounselorScoreCreateMutation(
        (data) => {
            if(data?.message === "success"){
                setEditable(false)
                toast.success("Counselor score updated")
            }
        }
    )

    return(
        <div>
            {
                editable ?
                <select className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-none" value={score} onChange={handleScore}>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                </select>
                :
                <button onClick={() => setEditable(!editable)}>
                    {score}
                </button>
            }
        </div>
    )
}