import React, { useEffect, useState } from "react"
import authQueries from '../../../queries/authQueries'
import { Link, useNavigate } from "react-router-dom"
import { Modal, useModal, Button, Text } from "@nextui-org/react"
import LoaderCommon from "../../Common/LoaderCommon"
import { set } from "react-hook-form"
import PaginationComponent from "../../Common/PaginationComponent";
import { usePaginationStore, userStore } from '../../../store/userStore';
import RegisteredCheckSearch from "../../Common/RegisteredCheckSearch"
import { Select } from 'antd';


const Pgmsuggestionreport =()=>{
    const user = userStore();
    const [result, setResult] = useState([])
    const [status, setStatus] = useState('')

    const [visaDesc, setvisaDesc] = useState('')
    const [dataType, setDataType] = useState('')
    const [applicantresult, setApplicantresult] = useState([])
    const [visibleStatus, setVisibleStatus] = useState(false)
    const [dropDownData, setDropdownData] = useState([])
    const [visa, setVisa] = useState("")
    const [qid, setQid] = useState("")
    const [intake, setIntake] = useState("")
    const [prosStatus, setProsStatus] = useState("")
    const [sid, setSid] = useState("7")
    const [college, setCollege] = useState("")
    const [counselor, setCounselor] = useState("")
    const [regstatus, setRegStatus] = useState('YES')
    const [intakeMaster, setIntakeMaster] = useState([]);
    const [loader, setLoader] = useState(false)
    const [resultcount, setResultcount] = useState(0)
    const [qualificationArray, setQualificationArray] = useState([])
    const [University, setUniversity] = useState([])
//popup modal
    const [applicantname, setApplicantname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
   
    const[staff, setStaff] = useState({});
    const master = userStore();
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    let sno=0;
    const userId= user?.user?.id;
    const role = user?.user?.rid;
    const officeId = user?.user?.office[0]?.oid;
    const vidArray = user?.user?.visa?.map(visa => visa.vid);
    const Vids = vidArray?.join(',');

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]


    const dropdown = authQueries.usePgmsuggestionReportMaster(
        (res)=>{
            setDropdownData(res?.data)
            const qualificationData= res?.data?.qualification;
            let qualificationArray = [{
                label:'ALL',
                value:''
            }]
           
            for(let datas of qualificationData){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }
    
                qualificationArray = [...qualificationArray, dataValues]
            }
            setQualificationArray(qualificationArray)
           
        }
    )

    const inatakemaster = authQueries.usePgmsuggestionIntakeMaster(
        (res)=>{
           
            setIntakeMaster(res?.data);
            let UniversityArray = [{
                label:'ALL',
                value:''
            }]
            const universityData= res?.data?.college;
            for(let datas of universityData){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }
    
                UniversityArray = [...UniversityArray, dataValues]
            }
            setUniversity(UniversityArray)
        }
    )

    useEffect(()=>{
        dropdown.mutateAsync()
    },[])

    const visOnchange = (e) => {

        setVisa(e.target.value)
        inatakemaster.mutateAsync({visatype:e.target.value})
        
    } 

    useEffect(()=>{
        setLoader(true)
        pgmsuggestionReportApi.mutateAsync({
            qid: qid, 
            visa: visa,
            sid: sid,
            college: college,
            counselor: counselor,
            intake: intake,
            prosStatus: prosStatus,
            regstatus: regstatus,
            page:currentPage,
            size:100, 
        });
    },[currentPage])

    const pgmsuggestionReportApi = authQueries.usePgmsuggestionReport(
        (response) => {
            setLoader(false)
            console.log("response",response.data[0])
            setResult(response)     
            setResultcount(response?.data?.totalItems)    
        }   
    
    )


    useEffect(()=>{
        //onSearch();
    },[visa])

    const onSearch =()=> {
        setLoader(true)
        pgmsuggestionReportApi.mutateAsync({
            qid: qid, 
            visa: visa,
            sid: sid,
            college: college,
            counselor: counselor,
            intake: intake,
            prosStatus: prosStatus,
            regstatus: regstatus,
            page:currentPage, size:100, 
        })
    }


    const statusModalOpen =(data)=>{
        //setLoader(true)
        setVisibleStatus(true)
        setStaff(data)
    }

    const handleCloseStatus = () => {
        setVisibleStatus(false)
    }

    const onselectSearch = (value) => {
        console.log('search:', value);
      };

      const onChange = (value) => {
        console.log(`selected ${value}`);
      };
      const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return(
        <div>

            <div className="breadcrumb-and-otherbuttons">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Program Suggestion – University/College wise</li>
                    </ol>
                </nav>
            </div>
            

            <div className="add_clients" style={{marginBottom:'0px'}}>
                
                    <div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Program Suggestion – University/College wise</h2>    
                        </div>
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    

                    <div id="accordion" className="add_clients_accordion_form"  style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                        Program Suggestion – University/College wise
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                    
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                        <div className='grid grid-cols-12 gap-4 max-[1214px]:grid-cols-10 max-[958px]:grid-cols-8 max-[824px]:grid-cols-6 max-[622px]:grid-cols-4 max-[374px]:grid-cols-2 !w-full mb-4'>
                                            <div className='col-span-2'>
                                                <label>Visa Type</label>
                                                <select value={visa} onChange={(e)=>{ visOnchange(e) }} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                                    <option value="">ALL</option>
                                                    {
                                                        dropDownData?.visatype?.map((item, index)=>(
                                                            <option key={index} value={item?.id}>{item?.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-span-3'>
                                            <label>Qualification</label>
                                            <Select
                                                
                                                showSearch
                                                size='large'
                                                placeholder="Qualification"
                                                optionFilterProp="children"
                                                onChange={(val)=>{setQid(val)}}
                                                onSearch={onselectSearch}
                                                filterOption={filterOption}
                                                options={qualificationArray}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />         
                                            </div>
                                            <div className='col-span-2'>
                                                <label>Application Status</label>
                                                <select value={sid} onChange={(e)=>{setSid(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                                <option value="">Select Application Status</option>
                                                {
                                                    dropDownData?.application_status?.map((item, index)=>(
                                                        <option key={index} value={item?.id} selected="selected" >{item?.name}</option>
                                                    ))
                                                }
                                                </select>
                                            </div>
                                            <div className='col-span-3'>
                                                <label>University/College</label>

                                                <Select
                                                
                                                showSearch
                                                size='large'
                                                placeholder="University/College"
                                                optionFilterProp="children"
                                                onChange={(val)=>{setCollege(val)}}
                                                onSearch={onselectSearch}
                                                filterOption={filterOption}
                                                options={University}
                                                style={{
                                                    width: '100%',
                                                }}
                                            /> 


                                                {/* <select value={college} onChange={(e)=>{setCollege(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                                <option value="">Select University/College</option>
                                                {
                                                    dropDownData?.college?.map((item, index)=>(
                                                        <option key={index} value={item?.id}>{item?.name}</option>
                                                    ))
                                                }
                                                </select> */}
                                            </div>
                                            <div className='col-span-2'>
                                                <label>Counselor</label>
                                                <select value={counselor} onChange={(e)=>{setCounselor(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                                <option value="">Select Counselor</option>
                                                {
                                                    dropDownData?.users?.map((item, index)=> {
                                                        const details = `${item?.userdetail?.firstname} ${item?.userdetail?.lastname}` 
                                                        return(
                                                            <option key={index} value={item?.id}>{details}</option>
                                                        )
                                                    })
                                                }
                                                </select>
                                            </div>
                                            <div className='col-span-2'>
                                                <label>Intake</label>
                                                <select value={intake} onChange={(e)=>{setIntake(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                                    <option value="">Select Intake</option>
                                                    {
                                                        intakeMaster?.intake?.map((item, index)=>(
                                                            <option key={index} value={item?.id}>{item?.intake}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-span-2'>
                                                <label>Prospect Status</label>
                                                <select value={prosStatus} onChange={(e)=>{setProsStatus(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                                <option value="">Prospect Status</option>
                                                {
                                                    dropDownData?.status_of_prospects?.map((item, index)=>(
                                                        <option key={index} value={item?.id} selected="selected" >{item?.name}</option>
                                                    ))
                                                }
                                                </select>
                                            </div>

                                            <div className='col-span-2 flex flex-col gap-2'>
                                              <RegisteredCheckSearch item={{label:'Registered', state:regstatus, setState:setRegStatus}} />
                                            </div>

                                            <div className='col-span-1 flex flex-col'>
                                                <label>&nbsp;</label>
                                                <button onClick={onSearch} className='btn-customized-large text-[14px]'>
                                                    Search
                                                </button>
                                            </div>
                                            
                                            
                                        </div>
                                        <div className='search-results-count-wrpr'>search result of { resultcount} </div>
                                        {loader ? (
                                    <div className="loader" >
                                            <LoaderCommon />
                                            </div>
                                    ) : (
                                            <table border={1} className="table report collageprogram  countrywise">
                                                <thead>
                                                    <tr>
                                                    <th width='40px'>S.No</th>
                                                    <th>Visa</th>
                                                    <th>Qualification</th>
                                                    <th>University/College</th>
                                                    <th style={{ width: '100px' }}>Intake</th>
                                                    <th>Program</th>
                                                    <th >Campus</th>
                                                    <th >Duration</th>
                                                    <th >Placement</th>
                                                    <th >Counselor</th>
                                                    <th >Prospect Status</th>
                                                    <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { pgmsuggestionReportApi.isLoading && "Loading" }
                                                {   
                                                    result?.data?.items?.map((item, index) =>
                                                    {  
                                                        
                                                    return(
                                                        <tr style={{ background: item.pros_status === 'Visa Approved' ? '#cfefaf' : '' }} className="ui-sortable-handle">
                                                        <td width='40px'> {currentPage*100 +1+index}</td>
                                                        <td>{item?.visatype }</td>
                                                        <td>{item?.qualification}</td>
                                                        <td>{item?.cname }</td>
                                                        <td>{item?.intake }</td>
                                                        <td className={`${item?.programlink ? 'text-[blue] underline' : ''}`} ><a target="_blank" href={item?.programlink}> {item?.pname}</a></td>
                                                        <td>{item?.campus_name?item?.campus_name:'NA'}</td>
                                                        <td>{item?.duration}</td>
                                                        <td>{item?.placement}</td>
                                                        <td>{item?.counselorname}</td>
                                                        <td>{item?.pros_status}</td>
                                                        <td class="text-[blue] underline"><button onClick={()=>{statusModalOpen(item)}} >View Details</button></td>
                                                        </tr>
                                                    )})
                                                }
                                                 <tr>
  
                                                </tr>
                                            </tbody>

                                            </table>
                                    )}
                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
                
            </div>
            
        <Modal
            scroll
            blur
            closeButton
            width="70%"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={visibleStatus}
            onClose={handleCloseStatus}
        >
            <Modal.Header>

                <Text b size="16px" className="text-center">Applicant Details</Text>
            
            </Modal.Header>
            <Modal.Body>
            {loader ? (
                <div className="loader" >
                <LoaderCommon />
                </div>
            ) : (
                <div>
                <table className="table report collageprogram pgmsuggestion countrywise-popup alt-colors">
                    <thead style={{color:'white'}}>
            
                        <tr>
                            <th>Applicant Name</th>
                            <th>Applicant Email</th>
                            <th>Applicant Mobile</th>


                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        <tr className="ui-sortable-handle">
                            <td>{staff.applicant_name}</td>
                            <td>{staff?.email}</td>
                            <td>{staff?.mobile_no}</td>

                        </tr>
                    </tbody>
                </table>
                <table className="table report collageprogram pgmsuggestion countrywise-popup alt-colors">
                    <thead style={{color:'white'}}>
            
                        <tr>
                            <th>Counselor Name</th>
                            <th>Counselor Phone</th>
                            <th>Counselor Email</th>

                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        <tr className="ui-sortable-handle">

                            <td>{staff?.counselorname}</td>
                            <td>{staff?.counselorphone}</td>
                            <td>{staff?.counseloremail}</td>

                        </tr>
                    </tbody>
                </table>

                <table className="table report collageprogram pgmsuggestion countrywise-popup alt-colors">
                    <thead style={{color:'white'}}>
            
                        <tr>

                      
                            <th>Program Staff Name</th>
                            <th>Program Staff Phone</th>
                            <th>Program Staff Email</th>
                            


                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        <tr className="ui-sortable-handle">

                            <td>{staff?.pgmname}</td>
                            <td>{staff?.pgmphone}</td>
                            <td>{staff?.pgmemail}</td>

                        </tr>
                    </tbody>
                </table>

                <table className="table report collageprogram pgmsuggestion countrywise-popup alt-colors">
                    <thead style={{color:'white'}}>
            
                        <tr >

                            
                            <th>Document Staff Name</th>
                            <th>Document Staff Phone</th>
                            <th>Document Staff Email</th>


                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        <tr className="ui-sortable-handle">

                            <td>{staff?.docname}</td>
                            <td>{staff?.docphone}</td>
                            <td>{staff?.docemail}</td>

                        </tr>
                    </tbody>
                </table>

                <table className="table report collageprogram pgmsuggestion countrywise-popup alt-colors">
                    <thead style={{color:'white'}}>
            
                        <tr>

                            <th>Admission Staff Name</th>
                            <th>Admission Staff Phone</th>
                            <th>Admission Staff Email</th>


                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        <tr className="ui-sortable-handle">

                            <td>{staff?.admname}</td>
                            <td>{staff?.admphone}</td>
                            <td>{staff?.admemail}</td>

                        </tr>
                    </tbody>
                </table>

                <table className="table report collageprogram pgmsuggestion countrywise-popup alt-colors">
                    <thead style={{color:'white'}}>
            
                        <tr>

                            <th>Visa Staff Name</th>
                            <th>Visa Staff Phone</th>
                            <th>Visa Staff Email</th>


                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        <tr className="ui-sortable-handle">

                            <td>{staff?.visaname}</td>
                            <td>{staff?.visaphone}</td>
                            <td>{staff?.visaemail}</td>

                        </tr>
                    </tbody>
                </table>

                <table className="table report collageprogram pgmsuggestion countrywise-popup alt-colors">
                    <thead style={{color:'white'}}>
            
                        <tr>
                            <th>Fee Staff Name</th>
                            <th>Fee Staff Phone</th>
                            <th>Fee Staff Email</th>

                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        <tr className="ui-sortable-handle">
                            <td>{staff?.feename}</td>
                            <td>{staff?.feephone}</td>
                            <td>{staff?.feeemail}</td>
                        </tr>
                    </tbody>
                </table>

        </div>    
            )}
            </Modal.Body>
        </Modal>
    </div>
    )    
}
export default Pgmsuggestionreport