import React,{useEffect,useState} from 'react'

const AddPnp = () => {

  return (

    <div>AddPnp</div>

  )
  
}

export default AddPnp