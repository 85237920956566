import { decode } from 'html-entities';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { userStore } from '../../../store/userStore';
import { message } from 'antd';


const OtherDetails = () =>  {

    const appStore = userStore();

    const [result, setResult] = useState([]);
    const [cData, setCData] = useState([]);

    const OtherDetails = authQueries.useInfoMutationList(
        (response) => {
            setResult(response?.data?.items) 
        }   
    );

    const  countryDatas  = authQueries.useCountryMutationList(
        (response) => {
            setCData(response?.data?.items)            
        }   
    );

    const startingCall = async()=> {
        await countryDatas.mutateAsync({search:'', status: '', page:0, size:500});
        await OtherDetails.mutateAsync({search:'', status: '', page:0, size:500});
    }

    useEffect(()=>{
        startingCall();
    },[])

    const { register, control, handleSubmit, reset, trigger, setError, formState:{ errors } } = useForm({
        // resolver: yupResolver(validationSchema)
    });

  return (

    <div>

        <div className="card">
            <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#other_details">
                    Additional Information
                </a>
            </div>
            <div id="other_details" className="collapse" data-parent="#accordion">
                <div className="card-body">
                    <div className="table-wrpr">
                        <div className="table-section">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table prospect-table">
                                        <thead>
                                            <tr>
                                                <th>Additional Information</th>
                                                <th width="10%">Status (Yes/No)</th>
                                                <th width="10%">Country</th>
                                                <th width="10%">Year</th>
                                                <th width="5%">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody id="sortable" className="ui-sortable">
                                            {result?.map((item, index) => {
                                                const additional = appStore?.appAllData?.additional_informations?.find(element=> element.qid === item.id)
                                                return(
                                                    <tr className="ui-sortable-handle" key={index}>
                                                        <AdditionalData index={index} item={item} cData={cData} additional={additional} />
                                                    </tr>
                                            )})}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default OtherDetails

const AdditionalData =({index, item, cData, additional})=> {

    const appStore = userStore();

    const [exist, setExist] = useState(false)

    useEffect(()=>{
        if(additional){
            const values = {
                answer_status: additional.answer_status,
                country_id: additional.country_id,
                year: additional.year,
            }
            reset({...values})
        }
    },[])

    const validationSchema = yup.object({
        answer_status: yup
            .string('⚠ Required')
            .required('⚠ Required'),
        country_id: yup
            .string('⚠ Required')
            .required('⚠ Required'),
        year: yup
            .string('⚠ Required')
            .required('⚠ Required'),
    })

    const { register, control, handleSubmit, reset, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const addAdditional = authQueries.useAddOtherDetailsCreate(
        (response) => {
            message.success(response.message)
            viewAllApplicationData.mutateAsync(appStore.appid)
        }   
    );

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20;
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }
    
    const onSubmit =(data)=> {
        const dataValue = {
            ...data,
            qid: item.id,
            appid: appStore.appid
        }
        addAdditional.mutateAsync(dataValue)
    }

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )

    return (
        <Fragment>
                <td>
                    {index + 1}. {decode(item?.question)}
                </td>     
                <td>
                    <select
                        {...register("answer_status")}
                        name="answer_status"
                        style={{width:'100%'}}
                        className="form-control form-control-sm">
                        <option value="">Select</option>
                        <option value="YES">Yes</option>
                        <option value="NO">No</option>
                    </select>
                    <div style={{color:'red', fontSize:'12px'}}>{errors?.answer_status?.message}</div>
                </td>
                <td>
                    <select
                        name="country_id"
                        {...register("country_id")}
                        style={{width:'100%'}}
                        className="form-control form-control-sm">
                        <option value="">Select</option>
                        {cData?.map((item, indexes) =>(
                            <option value={item.id} key={indexes}>{item.name}</option>
                        ))}
                    </select>
                    <div style={{color:'red', fontSize:'12px'}}>{errors?.country_id?.message}</div>
                </td>
                <td>
                    <select
                        {...register('year')}
                        name="year" 
                        style={{width:'100%'}}
                        type="number" 
                        className="form-control form-control-sm" 
                        placeholder="Enter Year">
                            <option value="">Select</option>
                            {years.map((item, indexes) =>(
                                <option value={item} key={indexes}>{item}</option>
                            ))}
                    </select>
                    <div style={{color:'red', fontSize:'12px'}}>{errors?.year?.message}</div>
                </td>
                <td>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <button style={{ color: '#fff' }} type='submit' className="btn-nextrow">
                            {additional ? "Edit" : "Add"} 
                        </button>
                    </form>
                </td>
        </Fragment>
    )
}