import React, { Fragment, useEffect, useRef, useState } from 'react'
import authQueries from '../../queries/authQueries'
import LeadStatus from './LeadStatus'
import { decode } from 'html-entities'
import moment from 'moment'
import LoaderCommon from './LoaderCommon'
import FollowUp from './FollowUp'
import ApplicantViewButton from './ApplicantViewButton'
import { Modal } from 'antd'
import { useExistModal } from '../../store/existModalStore'
  

const ValidationListingModal = () => {

    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(true)

    const appid = useExistModal((state) => state.appid)
    const open = useExistModal((state) => state.open)
    const setOpen = useExistModal((state) => state.setOpen)

    const applicants = authQueries.useExistingApplicantMutation(
        (res)=>{
            setResult(res?.data)
            setLoader(false)
        }
    )
    
    useEffect(() => {
        setLoader(true)
        if(appid){
            applicants.mutateAsync(appid)
        }
    }, [appid]);


  return (
    <Fragment>
        <Modal
        closeButton
        aria-labelledby="modal-title"
        closable
        wrapClassName='modal-customized-exist followup-modal-exist'
        centered
        style={{maxWidth:'100%', minHeight:'100%', height:'100%'}}
        width="100%"
        title="Duplicate Validation"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        >
            {
                loader ?
                <LoaderCommon />:
                <div className="table-wrpr w-full">
                    <div className="table-section">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table prospect-table">
                                    <thead>
                                        <tr className='alt-colors'>
                                            <th width="2%">#</th>
                                            <th>Profile Status</th>
                                            <th>Office</th>
                                            <th>Date Created</th>
                                            <th>Applicant Name</th>
                                            <th>Contact Number</th>
                                            <th>Email</th>
                                            <th>Visa Type</th>
                                            <th>Intake</th>
                                            <th>Counselor</th>
                                            <th>Reg. Date</th>
                                            <th>Prospect Status</th>
                                            <th>Reffered By</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {result?.map((item, index) => {
                                            const counselor = item?.assignUser?.CounselorAssign?.userdetail
                                            return(
                                                <tr className="ui-sortable" key={index}>
                                                    <td className='flex gap-2 items-center'>
                                                        
                                                        {
                                                            appid === item?.id ?
                                                            <div className='w-[25px] h-[25px] rounded-full  text-white flex justify-center items-center font-bold bg-[green]'>{index + 1}</div>:
                                                            <div className='w-[25px] h-[25px] rounded-full  text-white flex justify-center items-center font-bold bg-[red]'>{index + 1}</div>
                                                        }
                                                    </td>
                                                    <td><LeadStatus item={item} /></td>
                                                    <td>{item?.office?.name}</td>
                                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</td>
                                                    <td>
                                                        <div>{item?.mobile_no}</div>
                                                        <div>{item?.whatsapp_no}</div>
                                                        <div>{item?.landline_no}</div>
                                                    </td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.visatype?.description}</td>
                                                    <td>{decode(item?.intake?.intake)}</td>
                                                    <td>{counselor?.firstname} {counselor?.lastname}</td>
                                                    <td>
                                                        {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                    </td>
                                                    <td>{item?.status_of_prospect?.name}</td>
                                                    <td>{item?.refferal?.refferal}</td>
                                                    <td>
                                                        <div className='flex gap-2'>
                                                            <div className='w-[130px]'>
                                                                <FollowUp item={item} />
                                                            </div>
                                                            <div className='w-[60px]'>
                                                                <ApplicantViewButton closable={true}  setOpen={setOpen} item={item} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Modal>
    </Fragment>
  )
}

export default ValidationListingModal
