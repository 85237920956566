import { Autocomplete, Pagination, PaginationItem, TextField } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { Empty } from 'antd'
import { usePaginationStore, userStore } from '../../../store/userStore'
import { Modal } from '@nextui-org/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import LoaderCommon from '../../Common/LoaderCommon'
import { decode } from 'html-entities'
import PaginationComponent from '../../Common/PaginationComponent'
import FollowUp from '../../Common/FollowUp'
import LeadStatus from '../../Common/LeadStatus'
import ProspectStatusList from '../../Common/ProspectStatusList'

const AllSearchApplicant = () => {

    const [result, setResult] = useState([])
    const [search, setSearch] = useState([])
	const [applicantDetails, setApplicantDetails] = useState({})

    const applicantList = authQueries.useSearchAllApplicant(
        (response)=> {
            setResult(response)
        }
    )

    const store = userStore()
    const [visible, setVisible] = useState(false)

    const {register, handleSubmit} = useForm({
	})

    const onSubmit =(data)=>{

        const dataValues = {
            page: data.page || 0,
            size: 25,
            name: data.name,
            email: data.email,
            mobile: data.mobile,
        }

        setSearch(dataValues)
    
        applicantList.mutateAsync(dataValues)
    }


    const handleClick =(item)=> {
		store.setApplication(item.id)
		setVisible(true)
		setApplicantDetails(item)
	}

    const handleChange = async(e, value) => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
      
        if(value === 1){
            const tempData = search
            tempData.page = 0
            onSubmit(tempData)
        }else{
            const tempData = search
            tempData.page = value - 1
            onSubmit(search)
        }
    }

  return (
    <Fragment>		
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr items-center font-medium">
                    <i className="appointments-icon">
                        <img src={require('../../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <div>All Clients Search</div>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            Search All Clients
                        </a>
                    </div>
                    <div className="p-4 flex flex-col gap-4">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='grid grid-cols-12 gap-4'>
                                <div className="col-span-2 flex flex-col">
                                    <div className='text-[12px] h-[25px]'>Applicant Name:</div>
                                    <input placeholder='Applicant Name' {...register('name')} name="name" id="spouse_name" type="text" className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[38px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" />
                                </div>
                                <div className="col-span-2 flex flex-col">
                                    <div className='text-[12px] h-[25px]'>Phone No:</div>
                                    <input placeholder='Phone No' {...register('mobile')} name="mobile" id="spouse_name" type="text" className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[38px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"  />
                                </div>
                                <div className="col-span-2 flex flex-col">
                                    <div className='text-[12px] h-[25px]'>Email:</div>
                                    <input placeholder='Email' {...register('email')} name="email" id="spouse_name" type="text" className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[38px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" />
                                </div>
                                <div className='col-span-2 flex flex-col'>
                                    <div className='h-[25px]'></div>
                                    <button type="submit" className="btn-customized-large w-[120px] h-[35px] rounded-md">
                                        Search
                                    </button>
                                </div>
                            </div>
                        </form>


                    <div className="table-list-search-wrpr simple-search">

                        <div className="row">
                            <div className="col-md-12 search-results-count-wrpr">
                                Search Results {result?.data?.items?.length} of {result?.data?.totalItems}
                            </div>
                        </div>

                        {applicantList?.isLoading ?
                            <LoaderCommon />:
                            <Fragment>
                                {result?.data?.totalItems > 0 &&
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table mb-0">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Counselor</th>
                                                                <th>Reg. Date</th>
                                                                <th>Prospect Status</th>
                                                                <th>Reffered By</th>
                                                                <th width='150'>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((item, index) => (
                                                                <tr className="ui-sortable-handle" key={index}>
                                                                    <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                    <td>
                                                                        <LeadStatus item={item} />
                                                                    </td>
                                                                    <td>{item?.office?.name}</td>
                                                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                    <td>
                                                                        {item?.applicant_name} {item?.middlename} {item?.lastname} 
                                                                    </td>
                                                                    <td>
                                                                        <div className="email-mobile flex flex-col">
                                                                            <div>{item.mobile_no}</div>
                                                                            <div>{item.landline_no}</div>
                                                                            <div>{item.whatsapp_no}</div>
                                                                        </div>                                                                    
                                                                    </td>
                                                                    <td>{item?.visatype?.description}</td>
                                                                    <td>{item?.intake?.intake}</td>
                                                                    <td>{item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                                                    <td>
                                                                        {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                    </td>
                                                                    <td>
                                                                        <ProspectStatusList item={item} />
                                                                    </td>
                                                                    <td>{item?.refferal?.refferal}</td>
                                                                    <td className="flex justify-end">
                                                                        <div className='w-[130px]'>
                                                                            <FollowUp item={item} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}												  
                                                        </tbody>
                                                    </table>
                                                    {result?.data?.totalPages > 1 &&
                                                        <ul className="pagination pagination-sm justify-content-center table-pagination mt-4 mb-3 custom-pagination">
                                                            <Pagination
                                                                count={result?.data?.totalPages}
                                                                nextText="Next"
                                                                prevText="Previous"
                                                                renderItem={
                                                                    (item) => (
                                                                        <div>
                                                                        <PaginationItem 
                                                                        component="li"
                                                                        {...item}
                                                                        className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                                                                        />
                                                                        </div>
                                                                    )
                                                                }
                                                                variant="outlined"
                                                                page={result?.data?.currentPage + 1}
                                                                shape="rounded"                
                                                                onChange={handleChange}
                                                            />
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    result?.data?.totalItems === 0 &&
                                    <div style={{marginTop:'5%', marginBottom: '10%'}} className="text-center flex justify-center">
                                        <Empty
                                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                            imageStyle={{ height: 80, display:'flex', justifyContent:'center' }}
                                            description={
                                            <h6>
                                                Sorry! No Leads found.
                                            </h6>
                                            }
                                        >
                                        </Empty>
                                    </div>
                                }
                            </Fragment>
                        }
                    </div>

                </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default AllSearchApplicant