import React from 'react'

const ModalHeaderText = ({label}) => {
  return (
    <div className='text-[17px] font-semibold'>
      {label}
    </div>
  )
}

export default ModalHeaderText