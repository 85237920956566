import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react";
import CountryWise from "./CountryWise";
import LoaderCommon from "../../Common/LoaderCommon";
import { userStore } from '../../../store/userStore'

const Uniquecountrywise =({fromDate,toDate})=>{
    const user = userStore();
    const [result, setResult] = useState([]);
    const [status, setStatus] = useState('');
    const [applicantresult, setApplicantresult] = useState([]);
    const [visaDesc, setvisaDesc] = useState('');
    const [dataType, setDataType] = useState('');
    const [loader, setLoader] = useState(false);
    const userId= user?.user?.id;
    let sno=0;

    let office='';
    let officeCount ='';
    let applicantName='';
    let PhoneNum='';

    const role = user?.user?.rid;
    const officeId = user?.user?.office[0]?.oid;
    const vidArray = user?.user?.visa?.map(visa => visa.vid);
    const Vids = vidArray?.join(',');

    useEffect(()=>{
        uniquecountrywiseReportApi.mutateAsync({Vids:Vids, userId:userId, role:role, officeId:officeId,from: fromDate, to:toDate, page:0, size:10});
    },[]);


    useEffect(()=>{
        uniquecountrywiseReportApi.mutateAsync({Vids:Vids, userId:userId, role:role, officeId:officeId,from: fromDate, to:toDate, page:0, size:10});
    },[fromDate,toDate]);
    
    const uniquecountrywiseReportApi = authQueries.useUniqueCountrywiseReport(

        (response) => {
            console.log("response",response.data[0])
            setResult(response.data[0]);        
        }   
    
    );

    let DocpendingTotal=0;
    let docieltspending=0;
    let docpendingIelts=0;
    let fullDocs=0;
    let awaitingOfr=0;
    let ConditionalOffer=0;
    let UnConditionalOffer=0;
    let OfferAccepted=0;
    let NotProceeding=0;
    let TotalProcessingVisa=0;
    let TotalVisaApplied=0;
    let TotalVisaApproved=0;
    let RefundRequest=0;
    let VisaRefused=0;
    let ExpectedVisa=0;
    let total_expected_visa=0;
    let totaldefereRequested=0;
    let refundcompleted=0;
    let TotalProcVisaafterrefusal=0;
    let gte=0;
    let TotalProcessingVisafundready=0;
    let TotalProcessingVisafundnotready=0;
    let onhold=0;
    const [visibleStatus, setVisibleStatus] = useState(false);
    
    const handleCloseStatus = () => {
        setVisibleStatus(false)
    };

    // const handlefromDate = (event) => {
    //     const value = event.target.value;
    //     console.log("fromdate",value)
    //     setfromDate(value);
    // };

    // const handletoDate = (event) => {
    //     const value = event.target.value;
    //     console.log("todate",value)
    //     settoDate(value);
    // };

    const filterData = (e) => {
        uniquecountrywiseReportApi.mutateAsync({ from: fromDate, to:toDate });
        setStatus(e.target.value)
      }

    const statusModalOpen =(type, country, desc,visa )=>{
        setLoader(true)
        setVisibleStatus(true)
        setvisaDesc(desc)
        setDataType(type);
        if(type==1)
        {
            setStatus("Registered with Full Docs and IELTS Pending");
        }else if(type==2)
        {
            setStatus("Registered Docs Pending with IELTS");
        }else if(type==3)
        {
            setStatus("Full Docs Not Even single Application");
        }else if(type==4)
        {
            setStatus("Awaiting Offer");
        }else if(type==5)
        {
            setStatus("Conditional Offer");
        }else if(type==6)
        {
            setStatus("Unconditional Offer");
        }else if(type==7)
        {
            setStatus("Offer Accepted");
        }else if(type==8)
        {
            setStatus("GTE ON Process");
        }else if(type==9)
        {
            setStatus("Registered Docs & IELTS Pending");
        }
        else if(type==10)
        {
                setStatus("Registered OnHold");
        }
        else if(type==11)
        {
                    setStatus("Not Proceeding with offer");
        }
        else if(type==12)
        {
            setStatus("Preparing Visa - Fund + IELTS Not Ready");
        }
        else if(type==13)
        {
            setStatus("Preparing Visa");
        }
        else if(type==14)
        {
            setStatus("Visa Applied");
        }
        else if(type==15)
        {
            setStatus("Visa Approved");
        }
        else if(type==16)
        {
            setStatus("Visa Refused/Visa Withdrawn");
        }
        else if(type==17)
        {
            setStatus("Preparing Visa After refusal");
        }
        else if(type==18)
        {
            setStatus("Refund Requested");
        }
        else if(type==19)
        {
            setStatus("Refund Completed");
        }
        else if(type==20)
        {
            setStatus("Defer Requested");
        }
        
        listApplicant.mutateAsync({type:type, visa: visa,country:country, from: fromDate, to:toDate,role:role,officeId:officeId,Vids:Vids })
    }

    const listApplicant = authQueries.useApplicantList(
        (response) => {
            setApplicantresult(response.data[0]);
            setLoader(false)
            console.log("response data==>",)
        }
    );
    
    function formatDate(inputDate) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dateObj = new Date(inputDate);
        const day = dateObj.getDate();
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        return `${month.toUpperCase()} ${day} ${year}`;
    }

    return(
        <div>
            <div className="add_clients">			
                    <div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Unique Country Wise - Visa Status Report</h2>	
                        </div>
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    <div id="accordion" className="add_clients_accordion_form" style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Unique Country Wise - Visa Status Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">                         
                                            <table border={1} className="table report collageprogram countrywise">
                                                <thead>
                                                    <tr>
                                                    <th >Country</th>
                                                    <th style={{ width: '100px' }}>Visa Type</th>
                                                    <th >Registered Docs & IELTS Pending</th>
                                                    <th >Registered with Full Docs and IELTS Pending</th>
                                                    <th >Registered Docs Pending with IELTS</th>
                                                    <th >Full Docs Not Even single Application</th>
                                                    <th >Awaiting Offer</th>
                                                    <th >Conditional Offer</th>
                                                    <th >UnConditional Offer</th>
                                                    <th >Not Proceeding with any offer</th>
                                                    <th >Offer Accepted</th>
                                                    <th >GTE On Process(Australia)</th>
                                                    <th >Preparing Visa - Fund + IELTS Not Ready</th>
                                                    <th >Preparing Visa</th>
                                                    <th >Visa Applied</th>
                                                    <th >Visa Approved</th>
                                                    <th >Visa Refused/Visa Withdrawn</th>
                                                    <th >Preparing Visa After refusal</th>
                                                    <th >Refund Requested</th>
                                                    <th >Refund Completed</th>
                                                    <th >Defer requested</th>
                                                    <th >Registered On Hold</th>
                                                    <th >Expected Visa</th>
                                                    <th >Total Expected Visa(including onhold)</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { uniquecountrywiseReportApi.isLoading && "Loading" }
                                                {   
                                                    result?.map((item, index) =>
                                                    {  
                                                        
                                                        DocpendingTotal=DocpendingTotal+item.DocPending;
                                                        docieltspending=docieltspending+item.DocieltsPending;
                                                        docpendingIelts=docpendingIelts+item.Docs_Pending_IELTS;
                                                        fullDocs=fullDocs+item.full_docs;
                                                        awaitingOfr=awaitingOfr+item.awaiting_offer;
                                                        ConditionalOffer=ConditionalOffer+item.ConditionalOffer;
                                                        UnConditionalOffer=UnConditionalOffer+item.UnConditionalOffer;
                                                        OfferAccepted=OfferAccepted+item.OfferAccepted;
                                                        NotProceeding=NotProceeding+item.NotProceeding;
                                                        TotalProcessingVisa=TotalProcessingVisa+item.TotalProcessingVisa;
                                                        TotalVisaApplied=TotalVisaApplied+item.TotalVisaApplied;
                                                        TotalVisaApproved=TotalVisaApproved+item.TotalVisaApproved;
                                                        RefundRequest=RefundRequest+item.RefundRequest;
                                                        VisaRefused=VisaRefused+item.VisaRefused;
                                                        ExpectedVisa=ExpectedVisa+item.ExpectedVisa;
                                                        total_expected_visa=total_expected_visa+item.total_expected_visa;
                                                        totaldefereRequested=totaldefereRequested+item.defereRequested;
                                                        refundcompleted=refundcompleted+item.RefundCompleted;
                                                        TotalProcVisaafterrefusal=TotalProcVisaafterrefusal+item.TotalProcessingVisaafterrefusal;
                                                        gte=gte+item.gte;
                                                        TotalProcessingVisafundready=TotalProcessingVisafundready+item.TotalProcessingVisafundready;
                                                        TotalProcessingVisafundnotready=TotalProcessingVisafundnotready+item.totalProcessingVisafundnotready;
                                                        onhold=onhold+item.onhold;
                                                    return(
                                                        <tr className="ui-sortable-handle">
                                                        <td className="zui-sticky-col">{item.country }</td>
                                                        <td className="zui-sticky-col2">{item.description}</td>
                                                     
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(9, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.DocieltsPending?item.DocieltsPending:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(1, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.DocPending?item.DocPending:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(2, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.Docs_Pending_IELTS?item.Docs_Pending_IELTS:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(3, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.full_docs?item.full_docs:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(4, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.awaiting_offer?item.awaiting_offer:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(5, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.ConditionalOffer?item.ConditionalOffer:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(6, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.UnConditionalOffer?item.UnConditionalOffer:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(11, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.NotProceeding?item.NotProceeding:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(7, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.OfferAccepted?item.OfferAccepted:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(8, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.gte?item.gte:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(12, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.TotalProcessingVisafundready?item.TotalProcessingVisafundready:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(13, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.TotalProcessingVisa?item.TotalProcessingVisa:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(14, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.TotalVisaApplied?item.TotalVisaApplied:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(15, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.TotalVisaApproved?item.TotalVisaApproved:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(16, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.VisaRefused?item.VisaRefused:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(17, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.TotalProcessingVisaafterrefusal?item.TotalProcessingVisaafterrefusal:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(18, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.RefundRequest?item.RefundRequest:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(19, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.RefundCompleted?item.RefundCompleted:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(20, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.defereRequested?item.defereRequested:0}</a></td>
                                                        <td><a class="text-[blue] underline" onClick={()=>{statusModalOpen(10, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.onhold?item.onhold:0}</a></td>
                                                        <td>{item.ExpectedVisa?item.ExpectedVisa:0}</td>
                                                        <td>{item.total_expected_visa?item.total_expected_visa:0}</td>
                                                        </tr>
                                                    )})
                                                }
                                                <tr>
                                                    <td className="zui-sticky-col" colSpan={2}><b>Total</b></td>
                                                    <td><b>{docieltspending}</b></td>
                                                    <td ><b>{DocpendingTotal}</b></td>
                                                    <td><b>{docpendingIelts}</b></td>
                                                    <td><b>{fullDocs}</b></td>
                                                    <td><b>{awaitingOfr}</b></td>
                                                    <td><b>{ConditionalOffer}</b></td>
                                                    <td><b>{UnConditionalOffer}</b></td>
                                                    <td><b>{NotProceeding}</b></td>
                                                    <td><b>{OfferAccepted}</b></td>
                                                    <td><b>{gte}</b></td>
                                                    <td><b>{TotalProcessingVisafundready}</b></td>
                                                    <td><b>{TotalProcessingVisa}</b></td>
                                                    <td><b>{TotalVisaApplied}</b></td>
                                                    <td><b>{TotalVisaApproved}</b></td>
                                                    <td><b>{VisaRefused}</b></td>
                                                    <td><b>{TotalProcVisaafterrefusal}</b></td>
                                                    <td><b>{RefundRequest}</b></td>
                                                    <td><b>{refundcompleted}</b></td>
                                                    <td><b>{totaldefereRequested}</b></td>
                                                    <td><b>{onhold}</b></td>
                                                    <td><b>{ExpectedVisa}</b></td>
                                                    <td><b>{total_expected_visa}</b></td>
                                                </tr>
                                            </tbody>

                                            </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
                
            </div>


        <Modal
            scroll
            blur
            closeButton
            width="100%"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={visibleStatus}
            onClose={handleCloseStatus}
        >
            <Modal.Header>
                <Text b size="16px" className="text-center">Unique {status} - {visaDesc}</Text>
            </Modal.Header>
            <Modal.Body>
            {loader ? (
                <div className="loader" >
                <LoaderCommon />
                </div>
            ) : (
                <table className="table report collageprogram countrywise-popup alt-colors">
                    <thead>
            
                        <tr style={{background:'rgb(50 58 81 )'}}>
                        {dataType!=7?<th width="10%">Branch</th>:null}
                        <th>SINO</th>
                        <th width="20%">Name</th>
                        <th width="20%">Phone</th>
                        {dataType==7?<th width="10%">Email</th>:null}
                        {dataType==7?<th width="10%">Branch</th>:null}
                        <th width="20%">Active Intake</th>
                        <th width="20%">College</th>
                        <th width="20%">Program</th>
                       
                        {dataType!=7 && dataType!=11?<th width="20%">Program Intake</th>:null}
                        {<th width="20%">Counselor</th>}
                        {dataType!=11?<th width="20%">Admission</th>:null}
                        {dataType!=7 && dataType!=4 && dataType!=5 && dataType!=6 && dataType!=8 && dataType!=10 && dataType!=11 && dataType!=12 && dataType!=13 &&  dataType!=14 &&  dataType!=15 &&  dataType!=16 &&  dataType!=17 &&  dataType!=18 &&  dataType!=19 &&  dataType!=20?<th width="20%">Doc Staff</th>:null}
                        {dataType!=7 && dataType!=11 && dataType!=12 &&  dataType!=13 &&  dataType!=14 &&  dataType!=15 &&  dataType!=16 &&  dataType!=17 &&  dataType!=18 &&  dataType!=19 &&  dataType!=20?<th width="20%">Program Coordinator</th>:null}
                        {dataType==5 || dataType==6 || dataType==10 && dataType!=11?<th width="20%">Fee Coordinator</th>:null}
                        {dataType!=1 &&  dataType!=9 &&  dataType!=2 &&  dataType!=3 &&  dataType!=10 && dataType!=11 ?<th width="20%">Visa Staff</th>:null}
                        {dataType==3 || dataType==7 || dataType==11?<th width="20%">Application status</th>:null}
                        {dataType==4 || dataType==5 || dataType==6 ?<th width="20%">Offer Type</th>:null}
                        {dataType==5 || dataType==6 ?<th width="20%">Offer Received Date</th>:null}
                        {/* {dataType==7?<th width="10%">Status</th>:null} */}
                        {dataType==7?<th width="10%">Prospect Status</th>:null}
                        {dataType==5 || dataType==6 ?<th width="20%">Fee paid due date</th>:null}
                        {/* {dataType==4 || dataType==5 || dataType==6 ? <th width="20%">No of days after submitting the application</th>:null} */}
                        {/* {dataType==1?<th width="30%">No Of days Followup Pending</th>:null}
                        {dataType==3?<th width="30%">No Of days after moved to full docs</th>:null} */}
                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                    {
                            applicantresult?.map((item, index) => 
                            {

                                let offices = applicantresult?.filter(function(office){
                                    return  office.office == item.office;
                                });

                                if(applicantName!==item.applicant_name){
                                    sno= sno+1;
                                }

                                console.log("offices",offices)

                                let applicants = applicantresult?.filter(function(applicant){
                                    return  applicant.applicant_name == item.applicant_name
                                });

                                let phone = applicantresult?.filter(function(phon){
                                   // return  phon.mobile_no == item.mobile_no;
                                    return  phon.mobile_no == item.mobile_no && item.mobile_no!='' && item.mobile_no!=null;
                                });

                                return(
                               
                                    <tr className="ui-sortable-handle">
                                       
                                        {office!=item.office && dataType!=7?<td rowSpan={offices.length}>{office=item.office }</td>: null}
                                        {applicantName!=item.applicant_name?<td rowSpan={applicants.length}>{sno}</td>: null}
                                        {applicantName!=item.applicant_name?<td rowSpan={applicants.length}>{applicantName=item.applicant_name }</td>: null}
                                        {PhoneNum!=item.mobile_no && item.mobile_no!=null ?<td rowSpan={phone.length==0?1:phone.length}>{ PhoneNum=item.mobile_no }</td>: null} {item.mobile_no==null?<td></td>:null}
                                        {dataType==7?<td>{item.email}</td>:null}
                                        {dataType==7?<td>{item.office}</td>:null}
                                        <td>{item.intake}</td> 
                                        <td>{item.intended_college_or_university}</td> 
                                        <td>{item.pname}</td> 
                                        {dataType!=7 && dataType!=11? <td>{item.intake}</td> :null}
                                        {<td>{item.counselor}</td>}
                                        {dataType!=11 && dataType!=11?<td>{item.admission}</td>:null }
                                        {dataType!=7 && dataType!=4 && dataType!=5 && dataType!=6 && dataType!=8 && dataType!=10 && dataType!=11 && dataType!=12 && dataType!=13 &&  dataType!=14 &&  dataType!=15 &&  dataType!=16 &&  dataType!=17 &&  dataType!=18 &&  dataType!=19 &&  dataType!=20?<td>{item.doc}</td> :null}
                                        {dataType!=7 && dataType!=11 && dataType!=12 && dataType!=13 &&  dataType!=14 &&  dataType!=15 &&  dataType!=16 &&  dataType!=17  &&  dataType!=18 &&  dataType!=19 &&  dataType!=20?<td>{item.pgm}</td> :null}
                                        {dataType==5 || dataType==6 || dataType==10?<td>{item.fee}</td>:null}
                                        {dataType!=1 &&  dataType!=9 &&  dataType!=2 &&  dataType!=3 &&  dataType!=10 && dataType!=11?<td>{item.visastaff}</td> :null}
                                        {dataType==7 || dataType===11 ?<td>{item.application_status}</td>:null}
                                        {dataType==4 || dataType==5 || dataType==6 ?<td>{item.offertype}</td>:null}
                                        {dataType==5 || dataType==6 ?<td>{formatDate(item.application_submision_date)}</td>:null}
                                        {dataType==7?<td>{item.pros_status}</td>:null}
                                        {dataType==5 || dataType==6?<td>{item.due_date?formatDate(item.due_date):'NIL'}</td> :null}
                                        {/* {dataType!=7?<td>0</td>:null} */}
                                    </tr>
                            )})
                            
                    }
                    </tbody>
                </table>
            )}
            </Modal.Body>
        </Modal>

    </div>
    )

    


    
}
export default Uniquecountrywise;