import { Modal } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import AutoCompleteInput from '../../Common/AutoCompleteInput'
import InputSelectForm from '../../Common/InputSelectForm'
import { generateValidationSchema } from '../../Common/validationSchema'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { userStore } from '../../../store/userStore'
import authQueries from '../../../queries/authQueries'
import toast from 'react-hot-toast'
import InputUseForm from '../../Common/InputUseForm'
import moment from 'moment'

const EditOverseasEducationModal = ({item, viewAllApplicationData, countryResult}) => {

    const [visible, setVisible] = useState(false)
    
    const appStore  = userStore()

    const inputFields = [
        {
            label: "Country",
            size: 4,
            iptype: "select",
            required: true,
            options: countryResult?.data?.items,
            nameShow: true,
            name: 'country_id',
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            label: "College/University",
            size: 4,
            name: 'college',
            required: true,
            iptype: "text"
        },
        {
            label: "Program Name",
            size: 4,
            name: 'program_name',
            required: true,
            iptype: "text"
        },
        {
            label: "Visa Term",
            size: 4,
            name: 'visa_term',
            required: true,
            iptype: "text"
        },
        {
            label: "Existing/Closed",
            size: 4,
            iptype: "select",
            required: true,
            options: [{name:"Existing", id: "existing"}, {name: "Closed",id:"closed"}],
            name: 'existing_or_closed',
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            label: "Return Date",
            size: 4,
            name: 'return_date',
            iptype: "date"
        }
    ]

    const { register, reset, handleSubmit, formState: { errors }, setValue, control } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen =()=>{
        item.return_date = moment(item?.return_date).format("YYYY-MM-DD")
        reset(item)
        setVisible(true)
    }

    const handleClose =()=>{
        setVisible(false)
    }

    const editOverseas = authQueries.useOverseasEdit(
        (response) => {
            if(response?.message === "Success"){
                handleClose()
                toast.success("Successfully updated")
                viewAllApplicationData.mutateAsync(appStore.appid)
            }
        }
    );

    const onSubmit = (data) => {
        editOverseas.mutateAsync(data)
    }


  return (
    <Fragment>
        <button onClick={handleOpen} className='btn-customized-large h-[30px] w-[60px]'>
            Edit
        </button>
        <Modal
        open={visible}
        onCancel={handleClose}
        footer={null}
        width="50%"
        >
            <div className='flex flex-col gap-6'>
                <div>
                    <div className='text-[14px] font-medium'>Update Intended Program</div>
                </div>
                <form>
                    <div className='flex flex-col justify-center gap-6'>
                        <div className={`grid grid-cols-12 gap-x-6 gap-y-3`}>
                            {
                                inputFields?.map((input, index)=>(
                                    <div className={`col-span-${input?.size} flex flex-col gap-2`} key={index}>
                                    {
                                        input?.iptype === "select" ?
                                        <InputSelectForm
                                            item={input}
                                            register={register}
                                            errors={errors}
                                            options={input?.options}
                                            optionProps={input?.optionProps}
                                        />:
                                        (input?.iptype === "text" || input?.iptype === "number" || input?.iptype === "date" ) &&
                                        <InputUseForm
                                            control={control}
                                            item={input}
                                            register={register}
                                            errors={errors}
                                        />
                                    }
                                    </div>
                                ))
                            }
                        </div>
                        <div className='flex justify-end gap-5'>
                            <button 
                            onClick={handleClose}
                            className='bg-red-500 text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='button'
                            >
                            Cancel
                            </button>
                            <button 
                            onClick={handleSubmit(onSubmit)} 
                            className='bg-primary text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='submit'
                            >
                            Update
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </Modal>
    </Fragment>
  )
}

export default EditOverseasEducationModal
