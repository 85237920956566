import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries';
import { usePaginationStore } from '../../store/userStore';
import CountUp from 'react-countup';
import Swiper from 'swiper';
import FollowUp from '../Common/FollowUp';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import AssignModal from '../Common/AssignModal';
import ApplicantEditButton from '../Common/ApplicantEditButton';
import PaginationComponent from '../Common/PaginationComponent';
import { useApi } from '../Common/ApiProvider';
import { IconArrowsMaximize, IconLocation } from '@tabler/icons-react';
import { IconLocationOff } from '@tabler/icons-react';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import TelecallerMyClientsList from '../TeleCaller/TelecallerMyClientsList';
import LoaderCommon from '../Common/LoaderCommon';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../../Constants/RouteConstans';
import { useAdvancedSearch } from '../../store/advancedSearchStore';

const TeleCallerDashboard = () => {

    const {apiData} = useApi()
    const [loader, setLoader] = useState(true)
	const setCounselor = useAdvancedSearch((state) => state.setCounselor);


	const navigate = useNavigate()

    useEffect(()=> {
        slider()
    },[apiData, loader])

    useEffect(() => {
        telecallerCount.mutateAsync();
    }, [apiData]);

    const telecallerCount = authQueries.useTelecallerDashboardCount(
        (response) => {
            setLoader(false)
        }
    )

    const slideArr = [
		{
			color: '#D0A2F7',
			title: 'Total Clients',
			value: "TOTAL",
			icon: <IconArrowsMaximize color='#D0A2F7' size="20" />,
			count: telecallerCount?.data?.total
		},
		{
			color: '#7071E8',
			title: 'Assigned to Counselor',
			value: "ASSIGNED",
			icon: <IconLocation color='#7071E8' size="20" />,
			count: telecallerCount?.data?.assigned
		},
		{
			color: '#9ADE7B',
			title: 'Not Assigned to Counselor',
			value: "PENDING",
			icon: <IconLocationOff color='#9ADE7B' size="20" />,
			count: telecallerCount?.data?.pending
		},
	];

    const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
                "1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	const handleNavigate =(item)=> {
		navigate(RouteConstants.TELECALLERMYCLIENTS)
		if(item.value == "ASSIGNED"){
			setCounselor("ASSIGNED")
		}else if(item.value == "PENDING"){
			setCounselor("NOTASSIGNED")
		}
	}

  return (
    <Fragment>
        {
            loader?
            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
				<LoaderCommon  />
			</div>:
            <div>
                <div className="carousel-section mb-5">
                    <div className="row">
                        <div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{slideArr.map((item, index) => (
										<div key={index} className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && handleNavigate(item)}>
											<div className='flex flex-col p-3 justify-between h-full'>
												<div className='flex justify-between'>
													<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
													<div className='text-4xl'>
														<CountUp end={item?.count} duration={1} />
													</div>
												</div>
												<div>
													<div className='text-[14px]'>{item.title}</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
                        </div>
                    </div>
                </div>
                <FollowupToday />
                <FollowupPending />
            </div>
        }
    </Fragment>
  )
}

export default TeleCallerDashboard