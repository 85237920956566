import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { usePaginationStore, userStore } from '../../../store/userStore';
import {  message as messages, Popconfirm } from 'antd';
import { Pagination } from '@nextui-org/react';
import AddButton from '../../Common/AddButton';
import PaginationComponent from "../../Common/PaginationComponent";


const RoleList =()=>{

    const [result, setResult] = useState([])
    const [status, setStatus] = useState('')
    const [name, setName] = useState('')
    const [pageSize, setPageSize] = useState(25)
    const navigate = useNavigate()
    const master = userStore();

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
  
    useEffect(()=>{
        roleListing.mutateAsync({search: '', status: '', page:currentPage, size:25});
    },[currentPage]);


    const roleListing = authQueries.useRoleMutationList(
        (response) => {
            setResult(response)            
        }
    );

    return(
        <div>

            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Roles</li>
                    </ol>
                </nav>
            </div>
            
            <div className="add_clients">
            
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")}  width="" height="" alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Roles</h2>	
                    </div>
                        
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                

                <div id="accordion" className="add_clients_accordion_form">

                    <div className="card" id="appointments_wrpr">
                        <div className="card-header">
                            Roles
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                
                                <div className="row ">
                                    <div className="col-md-12">
                                    
                                        <div className="table-wrpr">
                                            <div className="table-section">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table prospect-table">
                                                            <thead>
                                                                <tr>
                                                                    <th width="50">#</th>
                                                                    <th >Label</th>
                                                                    <th >Roles</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ui-sortable">
                                                            {
                                                                result?.data?.items.map((item, index) =>(
                                                                <tr key={index} className="ui-sortable-handle">
                                                                    <td className="font-semibold">{(index + 1) + result?.data?.currentPage * 25}</td>
                                                                    <td>{item.label}</td>
                                                                    <td onClick={()=>{ navigate(`/userlist?role=${item?.id}`) }} style={{cursor:'pointer', color:'blue'}}>{item?.name}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                        <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoleList