import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";

const UniquestatusReport =()=>{

    const [result, setResult] = useState([]);

    useEffect(()=>{
        uniqueStatusAPi.mutateAsync({search: '', status: '', page:0, size:10});
    },[]);

    
    const uniqueStatusAPi = authQueries.useUniqueStatusReport(

        (response) => {
            setResult(response);        
        }   
    
    );


    return(
    <div>

        <div className="content-area">
				<div className="breadcrumb-and-otherbuttons">
					{/* <!-- breadcrumb --> */}
					<nav aria-label="breadcrumb">
					  <ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
						<li className="breadcrumb-item active" aria-current="page">Unique Status</li>
					  </ol>
					</nav>
				</div>
				
				{/* <!-- addclients --> */}
				<div className="add_clients">
				
					<div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Unique Status</h2>	
                        </div>
							
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
					</div>
					

					<div id="accordion" className="add_clients_accordion_form">
						<div className="card" id="appointments_wrpr">
						  <div className="card-header">
							{/* <a className="collapsed card-link"  href="" style="pointer-events:none;"> */}
                            Unique Status
							{/* </a> */}
							
						  </div>
						  <div id="appointment_any" className="collapse show" data-parent="#accordion">
							<div className="card-body">
									
                                <div className="row ">
                                    <div className="col-md-12">
                                    
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    
                                                </div>
                                                <div className="col-md-6 text-right">
                                            </div>
                                        </div>

                                        
                                        
                                            <table border={1} className="table report collageprogram">
                                                <thead>
                                                    <tr>
                                      
                                                    <th >Country</th>
                                                    <th width="150">Full Doc(No Active Applicaiton)</th>
                                                    <th width="200">Awaiting Offer</th>
                                                    <th width="200">Conditional Offer</th>
                                                    <th width="200">Unconditional Offer</th>
                                                    <th width="200">Not preceeding with offer</th>
                                                    <th width="200">Offer Accepted</th>
                                                    <th width="200">Preparing Visa Doc</th>
                                                    <th width="200">Visa Applied</th>
                                                    <th width="200">Visa Approved</th>
                                                    <th width="200">Visa Refused</th>
                                                    <th width="200">Expected Visa</th>
                                                    <th width="200">Total Expected Visa</th>
                                                    <th width="100">&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { uniqueStatusAPi.isLoading && "Loading" }
                                                {   
                                                    uniqueStatusAPi?.data?.data[0]?.map((item, index) =>
                                                    {    
                                                    return(
                                                        <tr className="ui-sortable-handle">
                     
                                                        <td>{item.name }</td>
                                                        <td>{item.doc_sent}</td>
                                                        <td>{item.awaiting_offer}</td>
                                                        <td>{item.conditional_offer}</td>
                                                        <td>{item.unconditional_offer}</td>
                                                        <td>{item.Not_proceeding_with_this_offer}</td>
                                                        <td>{item.Offer_accepted}</td>
                                                        <td>{item.preparing_visa_doc}</td>
                                                        <td>{item.visa_applied}</td>
                                                        <td>{item.visa_approved}</td>
                                                        <td>{item.visa_refused}</td>
                                                        <td>{item.expected_visa}</td>
                                                        <td>{item.total_expected_visa}</td>
                                                        </tr>
                                                    )})
                                                }
                                               </tbody>

                                            </table>
                                    </div>
                                </div>
								
								</div>
							</div>
						</div>
						
						
					  </div>
					
				</div>
				
			</div>

        </div>

    </div>
    )

    


    
}
export default UniquestatusReport;