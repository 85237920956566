import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { usePaginationStore, userStore } from "../../../store/userStore";
import { Empty, message as messages, Popconfirm } from 'antd';
import { Text, Collapse, Grid, Switch, Avatar } from '@nextui-org/react';
import { decode } from 'html-entities';
import AddButton from '../../Common/AddButton';
import { Pagination, PaginationItem } from "@mui/material";
import PaginationComponent from "../../Common/PaginationComponent";


const SeniorProgramManagerUsers =()=>{

    const [result, setResult] = useState([])
    const [name, setName] = useState('')
    const [counselor, setCounselor] = useState("")
    const [counsSwitch, setCounsSwitch] = useState(false)
    const [office, setOffice] = useState(null)

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    
    const seniorManagerList = authQueries.useSeniorManagerListingMaster(
        (response) => {
            setResult(response)            
        }
    )

    useEffect(()=>{
        seniorManagerList.mutateAsync({search: name, page:currentPage});
    },[name, currentPage]);

    useEffect(()=> {
        officeList.mutateAsync({size:2500, page:0})
    }, [])


    const officeList = authQueries.useOfficeMutationList(
        (res)=>{
        }
    )
    
    const searchData = (e) => {
        setName(e.target.value)
    }


    const addNewCounselor = authQueries.useSeniorManagerAddMaster(
        (res) => {
            if(res?.response?.data === "user already exist!"){
                messages.error('Counselor already used!')
            }
            seniorManagerList.mutateAsync({search: name, page:currentPage});
        }
    )

    const deleteCounselor = authQueries.useSeniorManagerRemoveMaster(
        (res) => {
            seniorManagerList.mutateAsync({search: name, page:currentPage});
        }
    )

    const handleSubmit =(id)=> {
        if(!counselor){
            messages.error("Please select counselor")
            return
        }
        addNewCounselor.mutateAsync({manager: id, user:counselor})
        setCounselor("")
    }

    const handleDelete = (mid, uid) => {
        deleteCounselor.mutateAsync({manager:mid, user: uid})
    }

    const handleSwitch =(e)=> {
        setCounsSwitch(e.target.checked)
    }

    const counselorList = authQueries.useOfficeWiseCounselorListMutation(
        (res)=>{
        }
    )

    const handleOffice =(e)=>{
        setOffice(e.target.value)
        counselorList.mutateAsync({role:3, office: e.target.value})
    }

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Senior Program Coordinator</li>
                    </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Senior Program Coordinator</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header">
                            Senior Program Coordinator							
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row mb-3">
                                                <div className="col-md-12" style={{display:'flex', justifyContent:'space-between'}}>
                                                    <div style={{display:'flex', gap: 12, alignItems:'center', marginTop:'5px', fontSize:'12px'}}>
                                                        {/* <Switch value={counsSwitch} onChange={handleSwitch} />
                                                        <span>Only show counselor-included offices</span> */}
                                                    </div>
                                                    <div style={{display:'flex', gap:'10px'}}>
                                                        <input style={{minWidth: '200px'}} onChange={searchData} className="form-control form-control-sm" type="text" placeholder="Search Name" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <Grid.Container style={{display:'block'}} gap={2}>
                                            <Grid>
                                                <Collapse.Group shadow>
                                                {
                                                    result?.data?.items?.map((item, index) =>{
                                                        let sl = 1
                                                        return(
                                                        <Collapse 
                                                            key={index} 
                                                            title={<Text h6>{item?.userdetail?.firstname} {item?.userdetail?.lastname}</Text>}
                                                            subtitle={<span style={{fontSize:'12px'}}>{item?.senior_program_users?.length} Associated Counselors</span>}
                                                        >
                                                        <div className="mb-4">
                                                            <div style={{display:'flex', gap: "15px"}}>
                                                                <select onChange={handleOffice} style={{width: '230px'}} className="form-control form-control-sm">
                                                                    <option value="">Select Office</option>
                                                                    {
                                                                        officeList?.data?.data?.items?.map((it,ind) => {
                                                                            return(
                                                                                <option key={ind} value={it?.id}>{it?.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                {office &&
                                                                <Fragment>
                                                                    <select value={counselor} onChange={(e)=> {setCounselor(e.target.value)}} style={{width: '230px'}} className="form-control form-control-sm">
                                                                        <option value="">Select Counselor</option>
                                                                        {
                                                                            counselorList?.data?.data?.map((it,ind) => {
                                                                                const exist = item?.allUsers?.find(element => element.id === it.id)
                                                                                if(exist){
                                                                                    return
                                                                                }
                                                                                return(
                                                                                    <option key={ind} value={it?.id}>{it?.userdetail.firstname} {it?.userdetail?.lastname}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    <button
                                                                        onClick={()=> handleSubmit(item?.id)}
                                                                        style={{
                                                                        minHeight:'31px', 
                                                                        height:'31px', 
                                                                        border:'none', 
                                                                        background:'#323a51', 
                                                                        color:'#fff', 
                                                                        fontSize:'12px',
                                                                        paddingLeft:"10px",
                                                                        paddingRight:"10px",
                                                                        borderRadius: "5px"
                                                                    }}>Add New</button>
                                                                </Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            item?.senior_program_users?.length > 0 &&
                                                            <div className="table-wrpr">
                                                                <div className="table-section">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <table className="table collageprogram">
                                                                                <thead>
                                                                                    <tr>
                                                                                    <th width="30">#</th>
                                                                                    <th width="60"></th>
                                                                                    <th>Name</th>
                                                                                    <th>Email</th>
                                                                                    <th>Mobile No</th>
                                                                                    <th width="100">&nbsp;</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="ui-sortable">
                                                                                {
                                                                                    item?.senior_program_users?.map((items, indexes) => {
                                                                                        return(
                                                                                        <tr key={indexes} className="ui-sortable-handle">
                                                                                            <th className="">{sl++}</th>
                                                                                            <td>
                                                                                                <Avatar
                                                                                                size="md"
                                                                                                src={items?.AssociatedUser?.userdetail?.image ? `${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${items?.AssociatedUser?.userdetail?.image}` : require('../../../assets/images/profile-avatar.jpg')}
                                                                                                bordered
                                                                                                />
                                                                                            </td>
                                                                                            <td>{decode(items?.AssociatedUser?.userdetail?.firstname)} {decode(items?.AssociatedUser?.userdetail?.lastname)}</td>
                                                                                            <td>{items?.AssociatedUser?.userdetail?.email}</td>
                                                                                            <td>{items?.AssociatedUser?.userdetail?.phone}</td>
                                                                                            <td className="action-icons">
                                                                                                <Popconfirm
                                                                                                placement="left"
                                                                                                title="Are you sure to delete this counselor?"
                                                                                                onConfirm={(event) => {handleDelete(item?.id, items?.AssociatedUser?.id)}}
                                                                                                okText="Yes"
                                                                                                cancelText="No"
                                                                                                >
                                                                                                <button className="btn-removerow mb-0">Delete</button>
                                                                                                </Popconfirm>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )})
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        }
                                                        {
                                                            item?.counselorAssociates?.length < 1 &&
                                                            <div className="mb-5">
                                                                <Empty description={<div className="">No description found!</div>} />
                                                            </div>
                                                        }

                                                        </Collapse>
                                                    )})
                                                }
                                                </Collapse.Group>
                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </Grid>
                                        </Grid.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeniorProgramManagerUsers