/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import Swiper from 'swiper';
import 'swiper/css';
import $ from 'jquery'
import authQueries from '../../queries/authQueries';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store/userStore';
import { Card, Col, Row, Skeleton } from 'antd';
import { Loading } from '@nextui-org/react';
import CountUp from 'react-countup';
import { IconCalendarMonth, IconCheckupList, IconClipboardList, IconCreativeCommonsSa, IconDotsCircleHorizontal, IconFreeRights, IconPlaneOff } from '@tabler/icons-react';
import { Ban, BookOpen, HelpingHandIcon, PlaneTakeoff, Vote, XCircle } from 'lucide-react';
import { IconDiscountCheck } from '@tabler/icons-react';
import { IconHourglass } from '@tabler/icons-react';
import { Ellipsis } from 'react-bootstrap/esm/PageItem';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import RouteConstants from '../../Constants/RouteConstans';
import CountryWiseReportModal from '../CEO/CountryWiseReportModal';

const Slides = ({ item, fy }) => {

	const navigate = useNavigate()

	const setStatus = useAdvancedSearch((state)=> state.setStatus)
	const resetAll = useAdvancedSearch((state)=> state.resetAll)
	const setActiveTab = userStore((state)=> state.setActiveTab)
	const setActiveOrAll = useAdvancedSearch((state)=> state.setActiveOrAll)
	const setVisaStatusRemove = useAdvancedSearch((state)=> state.setVisaStatusRemove)
	const setAssociated = useAdvancedSearch((state)=> state.setAssociated)
	const setFinancialYear = useAdvancedSearch((state)=> state.setFinancialYear)
	const setIncluded = useAdvancedSearch((state) => state.setIncluded);
	const setRegistered = useAdvancedSearch((state) => state.setRegistered);
	const setPrgmCoordinatorPending = useAdvancedSearch((state)=> state.setPrgmCoordinatorPending)


	const tileClick =()=> {
		resetAll()
		if(item?.value !== "AWAITING"){
			setActiveOrAll("ALL")
		}
		if(fy){
			setFinancialYear("CURRENT")
		}

		if(item?.value === "FOLLOWUPNONASSOCIATES"){
			setStatus(1)
			setAssociated("NONASSOCIATED")
		}
		if(item?.value === "FOLLOWUPASSOCIATES"){
			setStatus(1)
			setAssociated("ASSOCIATED")
		}
		if(item?.value === "FOLLOWUPAFTERNONASSOCIATES"){
			setVisaStatusRemove(true)
			setAssociated("NONASSOCIATED")
			setRegistered("YES")
		}
		if(item?.value === "FOLLOWUPAFTERASSOCIATES"){
			setVisaStatusRemove(true)
			setAssociated("ASSOCIATED")
			setRegistered("YES")
		}
		if(item?.value === "AWAITING"){
			setPrgmCoordinatorPending(true)
		}
		if(item?.value === "HOLD"){
			setStatus(7)
		}
		if(item?.value === "LONG"){
			setStatus(8)
		}
		if(item?.value === "PREPARING"){
			setStatus(15)
		}
		if(item?.value === "APPLIED"){
			setStatus(16)
		}
		if(item?.value === "APPROVED"){
			setStatus(17)
		}
		if(item?.value === "REFUSED"){
			setStatus(18)
		}
		if(item?.value === "DEFER"){
			setStatus(21)
		}
		if(item?.value === "NOTINTERESTED"){
			setStatus(2)
		}
		if(item?.value === "NOTQUALIFIED"){
			setStatus(3)
		}
		if(item?.value === "FULLDOC"){
			setIncluded(item.included)
		}
		setActiveTab(RouteConstants.USERSLIST)
		navigate(RouteConstants.USERSLIST)
	}

	return (
		<div className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} style={{background:item.color}} onClick={()=> item?.count > 0 && tileClick(item.value)}>
			<div className='flex flex-col p-3 justify-between h-full'>
				<div className='flex justify-between'>
					<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
					<div className='text-4xl'>
						<CountUp end={item?.count} duration={1} />
					</div>
				</div>
				<div>
					<div className='text-[14px]'>{item.title}</div>
				</div>
			</div>
		</div>
	)
}

const CEODashboard = () => {

	const setActiveTab = userStore((state)=> state.setActiveTab)
	
	useEffect(() => {
		setActiveTab(RouteConstants.DASHBOARD)
	}, []);

	const [loader, setLoader] = useState(true)
	const [limit, setLimit] = useState(4)

	const [feePaid, setFeePaid] = useState({
		previous: 0,
		current: 0,
		future: 0,
	})

	useEffect(() => {
		const delay = (fn, ms) => new Promise(resolve => setTimeout(() => resolve(fn()), ms));
	
		const callApisWithDelay = async () => {
		  await delay(CeoDashboardCount.mutateAsync, 0);
		  await delay(ceoDashboardTotal.mutateAsync, 100);
		  await delay(ceoDashboardCFY.mutateAsync, 1500);
		  await delay(referenceCount.mutateAsync, 2000);
		  await delay(feeDashboard.mutateAsync, 2500);
		};
	
		callApisWithDelay();
	  }, []);

	const CeoDashboardCount = authQueries.useCEODashboardMutation(
		(res) => {
			setLoader(false)
		}
	)

	const ceoDashboardTotal = authQueries.useCEODashboardTotalMutation(
		(res) => {
		}
	)

	const ceoDashboardCFY = authQueries.useCEODashboardCFYMutation(
		(res) => {
		}
	)

	const referenceCount = authQueries.useCeoDashboardReferenceCount(
		(res) => {
		}
	)

	const feeDashboard = authQueries.useCounselorDashboardFeePaidMutation(
		async (response) => {
		setFeePaid(
			{
			previous: response?.previous ? response?.previous : 0,
			current: response?.current ? response?.current : 0,
			future: response?.future ? response?.future : 0,
			}
		)
    });


	const slideArr = [
		{
			color: '#FF9F66',
			title: 'Follow-up required (Before Reg) - excluding associates files',
			value: "FOLLOWUPNONASSOCIATES",
            icon: <Vote color='#FF9F66' size="20" />,
			count: CeoDashboardCount?.data && CeoDashboardCount?.data?.followupnonassociates,
		},
		{
			color: '#6DC5D1',
			title: 'Follow-up required (Before Reg) - associates files only',
			value: "FOLLOWUPASSOCIATES",
            icon: <Vote color='#6DC5D1' size="20" />,
			count: CeoDashboardCount?.data && CeoDashboardCount?.data?.followupassociates,
		},
		{
			color: '#FFAAAA',
			title: 'Awaiting Program Confirmation',
			value: "AWAITING",
            icon: <IconHourglass color='#FFAAAA' size="20" />,
			count: CeoDashboardCount?.data && CeoDashboardCount?.data?.awaitingconfirmation,
		},
		{
			color: '#987D9A',
			title: 'On Hold',
			value: "HOLD",
			icon: <HelpingHandIcon color='#987D9A' size="20" />,
			count: CeoDashboardCount?.data && CeoDashboardCount?.data?.hold,
		},
		{
			color: '#50B498',
			title: 'Long Term Follow-up',
			value: "LONG",
            icon: <IconCreativeCommonsSa color='#50B498' size="20" />,
			count: CeoDashboardCount?.data && CeoDashboardCount?.data?.long,
		},
	];

    const slideArr2 = [
		{
			color: '#7ABA78',
			title: 'Visa Approved',
			value: "APPROVED",
            icon: <PlaneTakeoff color='#7ABA78' size="20" />,
			count: ceoDashboardTotal?.data && ceoDashboardTotal?.data?.visaapproved,
		},
		{
			color: '#9CAFAA',
			title: 'Visa Refused',
			value: "REFUSED",
            icon: <IconPlaneOff color='#9CAFAA' size="20" />,
			count: ceoDashboardTotal?.data && ceoDashboardTotal?.data?.visarefused,
		},
		{
			color: '#D20062',
			title: 'Defer Requested',
			value: "DEFER",
            icon: <IconDotsCircleHorizontal color='#D20062' size="20" />,
			count: ceoDashboardTotal?.data && ceoDashboardTotal?.data?.deferrequest,
		},
		{
			color: '#E2BFB3',
			title: 'Not Interested',
			value: "NOTINTERESTED",
			icon: <Ban color='#E2BFB3' size="20" />,
			count: ceoDashboardTotal?.data && ceoDashboardTotal?.data?.notinterested,
		},
		{
			color: '#E1AFD1',
			title: 'Not Qualified',
			value: "NOTQUALIFIED",
			icon: <XCircle color='#E1AFD1' size="20" />,
			count: ceoDashboardTotal?.data && ceoDashboardTotal?.data?.notqualified,
		},
	];

    const slideArr3 = [
		{
			color: '#433D8B',
			title: 'Follow-up required (After  Reg) - excluding associates files',
			value: "FOLLOWUPAFTERNONASSOCIATES",
            icon: <PlaneTakeoff color='#433D8B' size="20" />,
			count: ceoDashboardCFY?.data && ceoDashboardCFY?.data?.followupafternonassociates,
		},
		{
			color: '#32012F',
			title: 'Follow-up required (After  Reg) - associates files only',
			value: "FOLLOWUPAFTERASSOCIATES",
            icon: <IconPlaneOff color='#32012F' size="20" />,
			count: ceoDashboardCFY?.data && ceoDashboardCFY?.data?.followupafterassociates,
		},
		{
			color: '#D8AE7E',
			title: 'Full Docs No active application',
			value: "FULLDOC",
            icon: <IconDotsCircleHorizontal color='#D8AE7E' size="20" />,
			count: ceoDashboardCFY?.data && ceoDashboardCFY?.data?.fulldoc,
			included: ceoDashboardCFY?.data && ceoDashboardCFY?.data?.fullDocIds
		},
		{
			color: '#45474B',
			title: 'Preparing Visa',
			value: "PREPARING",
			icon: <Ban color='#45474B' size="20" />,
			count: ceoDashboardCFY?.data && ceoDashboardCFY?.data?.preparevisa,
		},
		{
			color: '#FF4C4C',
			title: 'Visa Applied',
			value: "APPLIED",
			icon: <XCircle color='#FF4C4C' size="20" />,
			count: ceoDashboardCFY?.data && ceoDashboardCFY?.data?.visaapplied,
		},
		{
			color: '#88D66C',
			title: 'Visa Approved',
			value: "APPROVED",
			icon: <XCircle color='#88D66C' size="20" />,
			count: ceoDashboardCFY?.data && ceoDashboardCFY?.data?.visaapproved,
		},
	];

	const slider = () => {

		new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

		var swiper = new Swiper(".geebee-carousel-big.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 6,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}


	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	
	return (
		
		<div>
			<Fragment>

				<div className="row">

					<div className="col-md-12 flex items-center justify-between">
						<div className='flex items-center gap-[4px]'>
							<i className="followup-icon">
								<IconClipboardList size="30" className='text-[#7D6FAB]' />
							</i>
							<div style={{display:'flex', flexDirection:'column'}}>
								<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
									<span className="text-[16px] font-medium mt-[3px]">Overview</span>	
								</div>
							</div>
						</div>
						<CountryWiseReportModal />
					</div>

				</div>

				<div className="carousel-section mt-4" style={{border:'none', marginBottom:'0px'}}>
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{
										(CeoDashboardCount.isLoading || CeoDashboardCount.isIdle) ?
										<Skeleton active />:
										slideArr.map((item, index) => (
											<Slides item={item} key={index} />
										))
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">

					<div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
						<i className="followup-icon">
							<IconClipboardList size="30" className='text-[#7D6FAB]' />
						</i>
						<div style={{display:'flex', flexDirection:'column'}}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
								<span className="text-[16px] font-medium mt-[3px]">Total Count</span>	
							</div>
						</div>
					</div>

				</div>

				<div className="carousel-section mt-4" style={{border:'none', marginBottom:'0px'}}>
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{
										(ceoDashboardTotal.isLoading || ceoDashboardTotal.isIdle) ?
										<Skeleton active />:
										slideArr2.map((item, index) => (
											<Slides item={item} key={index} />
										))
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">

					<div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
						<i className="followup-icon">
							<IconCalendarMonth size="30" className='text-[#7D6FAB]' />
						</i>
						<div style={{display:'flex', flexDirection:'column'}}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
								<span className="text-[16px] font-medium mt-[3px]">Current Financial Year</span>	
							</div>
						</div>
					</div>

				</div>

				<div className="carousel-section mt-4 mb-0" style={{border:'none'}}>
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel-big">
								<div className="swiper-wrapper">
									{
										(ceoDashboardCFY.isLoading || ceoDashboardCFY.isIdle) ?
										<Skeleton active />:
										slideArr3.map((item, index) => (
											<Slides item={item} key={index} fy={true} />
										))
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row mb-4">
					<div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
						<i className="followup-icon">
							<IconFreeRights size="30" className='text-[#7D6FAB]' />
						</i>
						<div style={{display:'flex', flexDirection:'column'}}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
								<span className="text-[16px] font-medium mt-[3px]">Fee Paid</span>	
							</div>
						</div>
					</div>
				</div>

				{
					feeDashboard.isLoading || feeDashboard.isIdle ? <Skeleton active /> :
					<div className="performance-report-section">
						<div className="grid grid-cols-12 max-xl:grid-cols-6 gap-6">
							<div className="col-span-6">
								<div className="card border-0">
									<div className="grid grid-cols-12 max-sm:grid-cols-6 gap-5 justify-center p-6">
										<div className="col-span-12">
											<div className="pr-boxes-wrpr p-0 myallocatedClients">
												<div className="flex flex-col gap-2 fee-paid-list">
													<div className="visa-column previous mb-0 w-full">
														<div className="list-item list-none">Previous Financial Year</div>
														<div className="visa-count"><span>{feePaid.previous}</span></div>
													</div>
													<div className="visa-column current mb-0 w-full">
														<div className="list-item list-none">Current Financial Year</div>
														<div className="visa-count"><span>{feePaid.current}</span></div>
													</div>
													<div className="visa-column future mb-0 w-full">
														<div className="list-item list-none">Future Financial Year</div>
														<div className="visa-count"><span>{feePaid.future}</span></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>	
							</div>
						</div>	
					</div>
				}

				<div className="row my-4">
					<div className="col-md-12 flex items-center justify-between">
						<div className='flex items-center gap-[4px]'>
							<i className="followup-icon">
								<IconCheckupList size="30" className='text-[#7D6FAB]' />
							</i>
							<div style={{display:'flex', flexDirection:'column'}}>
								<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
									<span className="text-[16px] font-medium mt-[3px]">Reference Report</span>	
								</div>
							</div>
						</div>
						{
							limit < 5 ?
							<button onClick={() => setLimit(referenceCount?.data?.data?.length)} style={{display:'flex', alignItems:'center', gap:'10px'}}>
								<span className="text-[13px] font-medium mt-[3px] text-blue-700">View More</span>	
							</button>:
							<button onClick={() => setLimit(4)} style={{display:'flex', alignItems:'center', gap:'10px'}}>
								<span className="text-[13px] font-medium mt-[3px] text-blue-700">View Less</span>	
							</button>
						}
					</div>
				</div>

				{
					referenceCount.isLoading || referenceCount.isIdle ?
					<Skeleton active />:
					<div className="carousel-section" style={{border:'none', marginBottom:'0px'}}>
						<div className="row">
							<div className="col-md-12">
								<div className='grid grid-cols-12 gap-4'>
									{
										referenceCount?.data?.data?.slice(0, limit).map((item, index) => (
											<Fragment>
												<div className='bg-white flex items-center justify-between gap-3 col-span-3 max-[1465px]:col-span-4 max-[977px]:col-span-6 max-[639px]:col-span-12 p-4 rounded-xl'>
													<div className='text-[16px] font-semibold'>
														{item?.name}
														<div className='text-[14px] font-normal'>{item?.phone}</div>
													</div>
													<div className='text-[24px] font-semibold font-montserrat'>{item?.count}</div>
												</div>
											</Fragment>
										))
									}
								</div>
							</div>
						</div>
					</div>
				}

			</Fragment>
		</div>

	)
}

export default CEODashboard

