/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries';
import { useLoaderStore, usePaginationStore, userStore } from '../../store/userStore';
import moment from 'moment';
import LoaderCommon from '../../components/Common/LoaderCommon';
import PaginationComponent from '../../components/Common/PaginationComponent';
import LeadStatus from '../../components/Common/LeadStatus';
import ProspectStatusList from '../../components/Common/ProspectStatusList';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import AssignModal from '../Common/AssignModal';
import FollowUp from '../Common/FollowUp';
import ApplicantEditButton from '../Common/ApplicantEditButton';
import AdvancedSearch from '../Common/AdvancedSearch';
import { IconClearAll } from '@tabler/icons-react';
import { useApi } from '../Common/ApiProvider';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { Empty } from 'antd';
import ApplicationStatusModal from '../Common/ApplicationStatusModal';
import { decode } from 'html-entities';
import { useRmStore } from '../../store/rmRoleStore';

const ApplicantListingRM = () => {

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const data = useAdvancedSearch((state) => state);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)
    const office = useRmStore((state) => state.office);
    const [active, setActive] = useState({ index: null, status: false });
    const [result, setResult] = useState([]);

    const { apiData } = useApi();

    const ApplicantListing = authQueries.useApplicantMutationRMList(
        (response) => {
            setResult(response)
            setLoader(false)
        }
    );

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);


    useEffect(() => {
        ApplicantListing.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
            office: office
        })
    }, [data, apiData, currentPage, office])


    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

    const expantButton = (index) => {
        if (active.index === index) {
            setActive({
                index: null,
                status: false
            })
        } else {
            setActive({
                index: index,
                status: true
            })
        }
    }

    return (
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Clients</li>
                    </ol>
                </nav>
            </div>
           
            <div className="add_clients" style={{borderBottom:'0px'}}>
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                        </i>
                        <h2><span>My</span>Clients</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">

                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                My Clients
                            </a>
                            <div>
                                <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                    <IconClearAll size={17} />
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='col-md-12'>

                                    <AdvancedSearch />

                                    <div className="search-results-count-wrpr">
                                        Search Results {result?.data?.items?.length} of {result?.data?.totalItems}
                                    </div>

                                    <div className="">
                                    {loader ? 
                                        <LoaderCommon />:
                                            result?.data?.totalItems > 0 ?
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table mb-0">
                                                                <thead>
                                                                    <tr className='alt-colors'>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Counselor</th>
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Reffered By</th>
                                                                        <th width='150'>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items?.map((item, index) => (
                                                                        <Fragment key={index}>
                                                                            <tr className="ui-sortable-handle">
                                                                                <th className="">{(index + 1) + (result?.data?.currentPage * 25)} </th>
                                                                                <td>
                                                                                    <LeadStatus item={item} />
                                                                                </td>
                                                                                <td>{item?.office?.name}</td>
                                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {
                                                                                        item?.intended_programs?.length > 0 ?
                                                                                        <a style={{ cursor: 'pointer', color: '#1000FF' }} onClick={() => { expantButton(index) }}>
                                                                                            {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)} <i className='icon-arrow-down-thick'></i>
                                                                                        </a> :
                                                                                        <Fragment>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</Fragment>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{item.mobile_no}</div>
                                                                                        <div>{item.landline_no}</div>
                                                                                        <div>{item.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                                                <td>{item?.intake?.intake}</td>
                                                                                <td>
                                                                                    {item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.registered_date ?
                                                                                        <span className='registered'>
                                                                                            {item.registered_date && moment(item?.registered_date).format('DD-MM-YYYY')}
                                                                                        </span>:
                                                                                        "-"
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <ProspectStatusList item={item} />
                                                                                </td>
                                                                                <td>{item?.refferal?.refferal}</td>
                                                                                <td>
                                                                                    <div className="grid grid-cols-12 gap-1 w-[160px]">
                                                                                        <div className='col-span-12'>
                                                                                            <FollowUp item={item} />
                                                                                        </div>
                                                                                        <div className='col-span-6'>
                                                                                            <AssignModal
                                                                                            item={item} 
                                                                                            roles={[3]} 
                                                                                            selectedRole={3} 
                                                                                            label="Reassign" 
                                                                                            noOffice={true} />
                                                                                        </div>
                                                                                        <div className='col-span-3'>
                                                                                            <ApplicantEditButton item={item} />
                                                                                        </div>
                                                                                        <div className='col-span-3'>
                                                                                            <ApplicantViewButton item={item} />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="12" className={active.index === index && active.status ? "dropdown-table-wrpr active" : "dropdown-table-wrpr"}>
                                                                                    <table className="table dropdown-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Prioriy</th>
                                                                                                <th>College / University</th>
                                                                                                <th>Program Name</th>
                                                                                                <th>Intake</th>
                                                                                                <th>Application Status</th>
                                                                                                <th>Duration</th>
                                                                                                <th>Code</th>
                                                                                                <th>Status</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {item?.intended_programs
                                                                                            ?.sort((a, b) => a.priority - b.priority)
                                                                                            ?.map((items, indexes) => (
                                                                                                <tr key={indexes}>
                                                                                                    <td width="15">
                                                                                                        <div
                                                                                                        className='font-[600] flex items-center justify-center w-[25px] h-[25px] rounded-full text-white'
                                                                                                        style={{background: items?.status === "CONFIRMED" ? 'green' : 'orange'}}>
                                                                                                            {items?.priority}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{items?.college?.name}</td>
                                                                                                    <td>
                                                                                                        {items?.program?.programlink ?
                                                                                                        <a className='text-blue-700' href={items?.program?.programlink} target='_blank'>
                                                                                                            {items?.program?.name}
                                                                                                        </a>:
                                                                                                        <div>{items?.program?.name}</div>
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{items?.intake?.intake}</td>
                                                                                                    <td>
                                                                                                        <ApplicationStatusModal item={items} />
                                                                                                    </td>
                                                                                                    <td>{items?.program?.duration && `${items?.program?.duration} Year`}</td>
                                                                                                    <td>{items?.program?.program_code}</td>
                                                                                                    <td>{items?.status}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>:
                                            <div className='mt-24 mb-24'>
                                                <Empty
                                                description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                                />
                                            </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicantListingRM