import { Modal } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import moment from 'moment'
import ModalHeaderText from './ModalHeaderText'

const PaymentHistoryModal = ({item}) => {

  const [open, setOpen] = useState(false)
  const [result, setResult] = useState([])

  const modalFeeDataList = authQueries.useFeeCoordinatorMutationModalPaymentList(
    (response) => {
      setResult(response)
    }
  )

  const handleOpen =()=> {
      setOpen(true)
      modalFeeDataList.mutateAsync(item?.id)
  }

  const handleClose =()=> {
      setOpen(false)
  }

  return (
    <Fragment>
        <button onClick={handleOpen} className="btn-customized">History</button>

        <Modal
        closeButton
        blur
        scroll
        width="68%"
        aria-labelledby="modal-title"
        open={open}
        onClose={handleClose}
        >
          <Modal.Header>
              <ModalHeaderText label="Payment History" />
          </Modal.Header>
          <Modal.Body>
              <div className="table-wrpr">
                <div className="table-section">
                  <div className="row">
                    <div className='col-md-12'>
                      <table className="table prospect-table">
                        <thead>
                          <tr className='text-[14px]'>
                              <th>#</th>
                              <th>Date</th>
                              <th>Type</th>
                              <th>Payment Method</th>
                              <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody className='text-[14px]'>
                          {
                              result?.data?.map((item, index) => (
                                  <tr className="ui-sortable-handle" key={index}>
                                      <td className="">{index+1}</td>
                                      <td>{item?.createdAt && moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                      <td>{item?.admission_feetype?.feeType?.name}</td>
                                      <td>{item?.method}</td>
                                      <td>{item?.amount}</td>
                                  </tr>
                              ))
                          }
                        </tbody>
                        <tfoot className="ui-sortable h-[65px]">
                            <tr className="ui-sortable-handle font-bold text-[15px]">
                                <th className=""></th>
                                <td></td>
                                <th>Total Fee</th>
                                <td></td>
                                <th>{TotalFee(result?.data)}</th>
                            </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer> 
        </Modal>

    </Fragment>
  )
}

export default PaymentHistoryModal

const TotalFee = (data) => {
  console.log(data)
  const total = data?.reduce((accumulator, item) => {
    return accumulator + parseFloat(item.amount || 0);
  }, 0);
  const roundedTotal = Math.round(total * 100) / 100;
  let formattedNumber = roundedTotal.toFixed(2);
  return formattedNumber
}