import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import authQueries from '../../../queries/authQueries';
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { userStore } from "../../../store/userStore";
import { MultipleSelect } from '../../Common/MultupleAutoComplete';


const validationSchema = yup.object({

    name: yup
        .string('⚠ Please Select ')
        .required('⚠ Name is required'),   
    visa: yup
        .array()
        .test('len', '⚠ Visa Type is required', (val) => val?.length > 0)
        .required('⚠ Visa Type is required'),
    roles: yup
        .array()
        .test('len', '⚠ Role is required', (val) => val?.length > 0)
        .required('⚠ Roles is required'),
        
  });
  

const EditProspect = () => {

    const navigate = useNavigate();

    const master = userStore();

    const [visaResult, setVisaResult] = useState([])

    const [roleResult, setRoleResult] = useState([]);

    const [errName, setErrName] = useState('')


    useEffect(()=>{
        startData();
    },[])

    const startData = async() => {
       await VisaList.mutateAsync({search: '', status: '',page: 0, size: 500});
       await RoleList.mutateAsync({search: '', status: '',page: 0, size: 500});
       await ProspectData.mutateAsync(master.pros);
    }

    const ProspectData = authQueries.useProspectView(
        (response) => {
            if(response?.data){
                let datas = ({
                    visa:response?.data?.status_visas?.map((item) => item.vid),
                    name:response?.data.name,
                    status:response?.data.status,
                    roles:response?.data?.status_roles?.map((item) => item.rid)
                })
                reset({ ...datas });
            }
        }   
    );


    const { register, reset, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
    });


    const VisaList = authQueries.useVisaTypeMutationList(

        (response) => {

            let visaArray = [];
            response?.data?.items?.map((item) => { 
                
                let visa = {
                    value: item.id,
                    label: item.description
                }

                visaArray = [...visaArray, visa]  
            });

            setVisaResult(visaArray);

        }
    );

    const RoleList = authQueries.useRoleMutationList(
        (response) => {

            let roleArray = [];
            response?.data?.items?.map((item) => { 
                
                let visa = {
                    value: item.id,
                    label: item.label
                }

                roleArray = [...roleArray, visa]  
            });

            setRoleResult(roleArray);
        }   
    );


    const editProspect = authQueries.useProspectEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/listprospect')
            }else if(response?.error[0].name)   {
                setErrName("⚠ "+response?.error[0].name)
            }
        }   
    );

    const onSubmit = (data) => {

    
        const datavalues = {
            id:master.pros,
            visa: data.visa,
            role: data.roles,
            name: data.name,
            status: data.status,
        };
    
        // e.preventDefault()
    
        editProspect.mutateAsync(datavalues);    
    
      }
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/listprospect">Status of Prospects</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Status of Prospects</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                      Edit Status of Prospects
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                <div className="col-md-6">

                                    <div className='form-group'>
                                        <div id='parent' className='form-group'>
                                            <label htmlFor="">Name</label>
                                            <input className="form-control"  {...register("name")} type="text" name='name' />
                                            <p className='error'>{errors.name?.message} {errName}</p>
                                        </div>
                                    </div>

                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Visa Type</label>
                                                {/* <select  className="form-control" name='visa'  {...register("visa")}>
                                                    <option value=''>Select</option>
                                                    {
                                                        visaResult?.data?.map((item, index) =>(
                                                            <option key={index} value={item.id}>
                                                                {item.description}
                                                            </option>
                                                        ))
                                                    }
                                                </select> */}

                                                <MultipleSelect value={master?.proStatus?.status_visas.map((item) => item.vid)} options={visaResult} name="visa" control={control} />


                                                <p className='error'>{errors.visa?.message}</p>
                                            </div>
                                        </div>
                                    </div>

                                   

                                    <div className="col-md-6">
                                        <label htmlFor="">Role</label>
                                            <MultipleSelect value={master?.proStatus?.status_roles.map((item) => item.rid)} options={roleResult} name="roles" control={control} />
                                        <p className='error'>{errors.roles?.message}</p>
                                    </div>

                                </div>
                                
                                
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Submit</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default EditProspect;