import { Modal } from '@nextui-org/react'
import { IconAlertCircle, IconAlertTriangle, IconCheck, IconCircleX, IconExclamationCircle } from '@tabler/icons-react'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import LeadStatus from './LeadStatus'
import { decode } from 'html-entities'
import moment from 'moment'
import { Tooltip } from 'antd'

const ValidationMobileModal = ({data, visaid, input, reset}) => {

    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])

    const [popup, setPopup] = useState(false)

    const handleClose = () => setOpen(false)

    const handleOpen = () => {
        setOpen(true)
        const ids = data?.map(item => item?.id)
        applicationListing.mutateAsync({ids})
    }

    const applicationListing = authQueries.useApplicantsValidationList(
        (res) => {
            setResult(res?.data)
        }
    )

    useEffect(() => {
        if(data?.length > 0){
            setPopup(true)
        }
    }, [data]);

    const handleReset =()=> {
        reset()
        setPopup(false)
    }

  return (
    <Fragment>
        {
            (!popup && data?.length > 0) && 
            <button className='absolute top-0 right-0' type='button' onClick={handleOpen}>
                <IconAlertTriangle color='#dc3545' size={18} />
            </button>
        }
        <Tooltip
        title={
        <div className='flex flex-col text-center p-2'>
            <div>
                An active file is already existing for this profile. Do you want to proceed? 
            </div>
            <div className='flex justify-between'>
                <button className='bg-[red] text-[12px] text-white px-3 py-[6px] rounded-lg mt-1' type='button' onClick={()=>setPopup(false)}>
                    Yes
                </button>
                <button className='bg-[white] text-[12px] text-black px-3 py-[6px] rounded-lg mt-1' type='button' onClick={handleReset}>
                    No
                </button>
                <button className='bg-[white] text-[12px] text-black px-3 py-[6px] rounded-lg mt-1' type='button' onClick={handleOpen}>
                    View Details
                </button>
            </div>
        </div>
        }
        trigger="click"
        open={popup ? true : false}
        >
            {input}
        </Tooltip>

        <Modal
        closeButton
        aria-labelledby="modal-title"
        width='80%'
        scroll
        open={open}
        onClose={handleClose}
        >
        <Modal.Header>
            <div className="text-[14px] font-semibold">
                Mobile Validation
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className="table-wrpr w-full">
                <div className="table-section">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table prospect-table">
                                <thead>
                                    <tr className='alt-colors'>
                                        <th width="2%">#</th>
                                        <th>Profile Status</th>
                                        <th>Office</th>
                                        <th>Date Created</th>
                                        <th>Applicant Name</th>
                                        <th>Contact Number</th>
                                        <th>Visa Type</th>
                                        <th>Intake</th>
                                        <th>Counselor</th>
                                        <th>Reg. Date</th>
                                        <th>Prospect Status</th>
                                        <th>Reffered By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {result?.map((item, index) => {
                                        const counselor = item?.assignUser?.CounselorAssign?.userdetail
                                        return(
                                            <tr className="ui-sortable">
                                                <td>
                                                    <div
                                                    style={{backgroundColor: item?.visa_type_id === visaid ? '#EE4E4E' : '#E88D67'}}
                                                    className='w-[25px] h-[25px] rounded-full  text-white flex justify-center items-center font-bold'
                                                    >
                                                        {index + 1}
                                                    </div>
                                                </td>
                                                <td><LeadStatus item={item} /></td>
                                                <td>{item?.office?.name}</td>
                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                <td>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</td>
                                                <td>
                                                    <div>{item?.mobile_no}</div>
                                                    <div>{item?.whatsapp_no}</div>
                                                    <div>{item?.landline_no}</div>
                                                </td>
                                                <td>{item?.visatype?.description}</td>
                                                <td>{decode(item?.intake?.intake)}</td>
                                                <td>{counselor?.firstname} {counselor?.lastname}</td>
                                                <td>
                                                    {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                </td>
                                                <td>{item?.status_of_prospect?.name}</td>
                                                <td>{item?.refferal?.refferal}</td>
                                            </tr>
                                        )}
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default ValidationMobileModal
